// SnackbarStyles.js
import styled from 'styled-components';
import { SnackbarContent } from 'notistack';
import { theme } from '@/constants';

export const SuccessSnackbar = styled(SnackbarContent)`
  background-color: ${theme.colors.success} !important;
`;

export const ErrorSnackbar = styled(SnackbarContent)`
  background-color: ${theme.colors.error} !important;
`;

export const WarningSnackbar = styled(SnackbarContent)`
  background-color: ${theme.colors.warning} !important;
`;

export const InfoSnackbar = styled(SnackbarContent)`
  background-color: ${theme.colors.blue} !important;
`;

// Default style if needed
export const DefaultSnackbar = styled(SnackbarContent)`
  background-color: ${theme.colors.primary} !important;
`;
