import { IconEdit } from '@/assets/icons';
import { UIBox, IconButton, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { JobPostingSteps } from '@/enums';
import { JobReviewCard } from '@/modules/Job/SponsorJobs/NewJob';
import JobProperties from '@/modules/Job/WorkerJobs/JobProperties';
import { ProjectJson } from '@/types';
import { useOutletContext } from 'react-router-dom';

interface SponsorReviewJobPostProps {
  onClickEdit: (step: JobPostingSteps) => void;
}
const SponsorReviewJobPost: React.FC<SponsorReviewJobPostProps> = ({
  onClickEdit,
}) => {
  const { job } = useOutletContext<{ job: ProjectJson.Project }>();
  return (
    <UIBox display="flex" gap={30}>
      <UIBox flexGrow={1}>
        <JobReviewCard job={job} onClickGotoStep={onClickEdit} />
      </UIBox>
      <UIBox width={390} minWidth={390}>
        <UIBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography
            variant="h6"
            marginBottom={0}
            color={theme.colors.textPrimary}
          >
            At a glance
          </Typography>
          <IconButton onClick={() => onClickEdit(JobPostingSteps.STEP_3)}>
            <IconEdit />
          </IconButton>
        </UIBox>
        <JobProperties job={job} />
      </UIBox>
    </UIBox>
  );
};

export default SponsorReviewJobPost;
