import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconMessage({
  width = 18,
  height = 18,
  color = theme.colors.icon,
  active,
  activeColor = theme.colors.red,
}: AppTypes.IconProps & { active?: boolean; activeColor?: string }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6621 2.16899C13.6621 0.971995 14.6341 0 15.8311 0C17.0281 0 18.0001 0.971995 18.0001 2.16899C18.0001 3.36598 17.0281 4.33798 15.8311 4.33798C14.6341 4.33798 13.6621 3.36598 13.6621 2.16899ZM10.1969 11.4844L12.7979 8.12834L12.7619 8.14634C12.9059 7.94834 12.9329 7.69634 12.8339 7.47134C12.7358 7.24634 12.5189 7.09334 12.2858 7.07534C12.0419 7.04834 11.7998 7.15634 11.6549 7.35434L9.47781 10.1713L6.98392 8.20934C6.83092 8.09234 6.65092 8.04644 6.47092 8.06534C6.29182 8.09234 6.12982 8.19044 6.02092 8.33444L3.35784 11.8003L3.30294 11.8813C3.14994 12.1684 3.22194 12.5374 3.49194 12.7363C3.61793 12.8173 3.75293 12.8713 3.90593 12.8713C4.11383 12.8803 4.31093 12.7714 4.43693 12.6013L6.69592 9.69343L9.26091 11.6203L9.34191 11.6734C9.6299 11.8264 9.9899 11.7553 10.1969 11.4844ZM12.1049 1.60272C12.0689 1.82772 12.0509 2.05272 12.0509 2.27771C12.0509 4.3027 13.6889 5.9398 15.7049 5.9398C15.9299 5.9398 16.1459 5.9137 16.3709 5.8777V13.1398C16.3709 16.1916 14.5709 18.0006 11.5109 18.0006H4.86088C1.79999 18.0006 0 16.1916 0 13.1398V6.48069C0 3.42071 1.79999 1.60272 4.86088 1.60272H12.1049Z"
        fill={color}
      />
      {active && (
        <ellipse
          opacity="0.5"
          cx="15.75"
          cy="2.24999"
          rx="2.24999"
          ry="2.24999"
          fill={activeColor}
        />
      )}
    </svg>
  );
}
