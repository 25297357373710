import { UIBox, Typography } from '@/components/UI';
import { jobsMock } from '@/mock';

import {
  SponsorJobCard,
  SponsorJobFilter,
  SponsorJobSearch,
} from '@/modules/Job/SponsorJobs';
import JobDeleteConfirmModal from '@/modules/Job/SponsorJobs/JobDeleteConfirmModal';
import { ProjectJson } from '@/types';
import { useState } from 'react';

const SponsorMyJobs: React.FC = () => {
  const [selectedJob, setSelectedJob] = useState<
    ProjectJson.Project | undefined
  >(undefined);
  return (
    <UIBox>
      <Typography variant="h5">My Jobs</Typography>
      <UIBox display="flex" gap={30}>
        <UIBox flexGrow={1} display="flex" flexDirection="column" gap={30}>
          <SponsorJobSearch />
          <UIBox display="flex" flexDirection="column" gap={20}>
            {jobsMock.map((job, id) => (
              <SponsorJobCard
                key={id}
                job={job}
                onClickDelete={() => setSelectedJob(job)}
              />
            ))}
          </UIBox>
        </UIBox>
        <UIBox minWidth={390} flexGrow={1}>
          <SponsorJobFilter />
        </UIBox>
      </UIBox>
      <JobDeleteConfirmModal
        show={!!selectedJob}
        jobTitle={selectedJob?.title}
        onClose={() => setSelectedJob(undefined)}
        onDelete={() => setSelectedJob(undefined)}
      />
    </UIBox>
  );
};

export default SponsorMyJobs;
