import {
  Autocomplete,
  UIBox,
  Checkbox,
  UIGrid,
  Input,
  RichEditor,
} from '@/components/UI';
import { monthOptions, pastYears } from '@/constants';
import { UserJson } from '@/types';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  formatIndustriesToGrouped,
  getFormatISODate,
  getMonthYearFromString,
  inputStatus,
} from '@/utils';
import { useIndustriesQuery } from '@/redux/apis';

const validationSchema = Yup.object({
  school: Yup.string().required('Company is required'),
  fromMonth: Yup.string().required('From month is required'),
  fromYear: Yup.number().required('From year is required'),
});

export interface EducationEditFormHandle {
  submitForm: () => void;
}

type FormikData = Omit<UserJson.EducationInput, 'startAt' | 'endAt'> & {
  fromMonth?: string;
  fromYear?: number;
  toMonth?: string;
  toYear?: number;
};

interface EducationEditFormProps {
  education?: UserJson.Education;
  onSubmit: (updatedEducation: UserJson.EducationInput) => void;
}
const EducationEditForm = forwardRef<
  EducationEditFormHandle,
  EducationEditFormProps
>(function ({ education, onSubmit }, ref) {
  const { data: { data: industries = [] } = {}, isFetching } =
    useIndustriesQuery({ limit: 1000 });
  const formik = useFormik<FormikData>({
    initialValues: {
      id: education?.id,
      school: education?.school || '',
      description: education?.description,
      degree: education?.degree,
      industryId: education?.industryId,
      isProgress: !!education?.isProgress,
      isDefault: !!education?.isDefault,
      fromMonth: getMonthYearFromString(education?.startAt).month,
      fromYear: getMonthYearFromString(education?.startAt).year,
      toMonth: getMonthYearFromString(education?.endAt).month,
      toYear: getMonthYearFromString(education?.endAt).year,
    },
    validationSchema,
    onSubmit: (values) => {
      const { fromMonth, fromYear, toMonth, toYear, ...v } = values;
      const saveToValue: UserJson.EducationInput = {
        ...v,
        startAt: getFormatISODate(`${fromMonth} ${fromYear}`),
        endAt:
          toMonth && toYear && !v.isProgress
            ? getFormatISODate(`${toMonth} ${toYear}`)
            : undefined,
      };
      onSubmit(saveToValue);
    },
  });
  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
  }));
  const groupedIndustries = useMemo(
    () => formatIndustriesToGrouped(industries),
    [industries]
  );
  return (
    <UIBox display="flex" flexDirection="column" gap={20} mt={1.5}>
      <Input
        label="School"
        fullWidth
        {...formik.getFieldProps('school')}
        {...inputStatus(formik, 'school')}
      />
      <UIGrid columns={2} gap={20}>
        <Autocomplete
          label="From (month)"
          fullWidth
          isClearable
          options={monthOptions}
          value={monthOptions.find((m) => formik.values.fromMonth === m.value)}
          onChange={(v) => formik.setFieldValue('fromMonth', v?.value)}
          onBlur={() => formik.setFieldTouched('fromMonth')}
          {...inputStatus(formik, 'fromMonth')}
        />
        <Autocomplete
          label="From (or expected), year"
          fullWidth
          isClearable
          options={pastYears}
          value={pastYears.find((m) => formik.values.fromYear === m.value)}
          onChange={(v) => formik.setFieldValue('fromYear', v?.value)}
          onBlur={() => formik.setFieldTouched('fromYear')}
          {...inputStatus(formik, 'fromYear')}
        />
        <Autocomplete
          label="To (month)"
          fullWidth
          isClearable
          options={monthOptions}
          value={monthOptions.find((m) => formik.values.toMonth === m.value)}
          onChange={(v) => formik.setFieldValue('toMonth', v?.value)}
        />
        <Autocomplete
          label="To (or expected), year"
          fullWidth
          isClearable
          options={pastYears}
          value={pastYears.find((m) => formik.values.toYear === m.value)}
          onChange={(v) => formik.setFieldValue('toYear', v?.value)}
        />
      </UIGrid>
      <Checkbox
        id={`work-here-${education?.id || '0'}`}
        label="I currently attend here"
        checked={formik.values.isProgress}
        {...formik.getFieldProps('isProgress')}
        onChange={(v) => formik.setFieldValue('isProgress', v.target.checked)}
      />
      <Autocomplete
        label="Area of Study (Optional)"
        fullWidth
        isLoading={isFetching}
        options={groupedIndustries}
        getOptionLabel={(industry) => industry.name}
        getOptionValue={(industry) => industry.id}
        value={industries.find((m) => formik.values.industryId === m.id)}
        onChange={(v) => formik.setFieldValue('industryId', v?.id)}
        isClearable
      />
      <UIBox>
        <RichEditor
          label="Description (Optional)"
          defaultValue={formik.values.description}
          onChange={(v) => formik.setFieldValue('description', v)}
        />
      </UIBox>
    </UIBox>
  );
});

export default EducationEditForm;
