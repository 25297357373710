import { IconRoundedArrowUp } from '@/assets/icons';
import { Typography, UIBox, UICard } from '@/components/UI';
import { theme } from '@/constants';
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';

const data = [
  { name: '01', uv: 3000 },
  { name: '04', uv: 2000 },
  { name: '08', uv: 2780 },
  { name: '12', uv: 1890 },
  { name: '14', uv: 2390 },
  { name: '16', uv: 3490 },
  { name: '18', uv: 4000 },
];

const DailyTrafficWidget: React.FC = () => {
  return (
    <UICard>
      <UIBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={0.5}
      >
        <Typography fontSize={14} fontWeight={500} color={theme.colors.gray540}>
          Daily Traffic
        </Typography>
        <UIBox display="flex" alignItems="center" gap={10} mb={0.3}>
          <Typography
            fontSize={12}
            fontWeight={700}
            color={theme.colors.success}
          >
            +2.45%
          </Typography>
          <IconRoundedArrowUp
            color={theme.colors.success}
            width={9}
            height={6}
          />
        </UIBox>
      </UIBox>
      <UIBox display="flex" alignItems="flex-end" gap={10} mb={2}>
        <Typography
          fontSize={28}
          fontWeight={600}
          color={'#2B3674'}
          lineHeight="28x"
        >
          12,579
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={500}
          color={theme.colors.gray540}
          marginBottom={0}
          lineHeight="17px"
        >
          Visitors
        </Typography>
      </UIBox>

      <BarChart
        data={data}
        dataKey="uv"
        width={300}
        height={200}
        margin={{ top: 20, right: -14, left: -30, bottom: 0 }}
      >
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: 10,
            color: theme.colors.gray540,
            fontFamily: 'Gantari',
          }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{
            fontSize: 10,
            color: theme.colors.gray540,
            fontFamily: 'Gantari',
            textAnchor: 'left',
            dx: -20,
          }}
        />
        <Tooltip />
        <Bar
          dataKey="uv"
          fill={theme.colors.primary}
          barSize={12}
          radius={[6, 6, 0, 0]}
        />
      </BarChart>
    </UICard>
  );
};

export default DailyTrafficWidget;
