import styled from 'styled-components';
import { UIBox } from './Box';
import { theme } from '@/constants';
import {
  IconPaginationFirst,
  IconPaginationLast,
  IconPaginationNext,
  IconPaginationPrev,
} from '@/assets/icons';
import { Typography } from '.';

const StyledButton = styled.button<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  height: 32px;
  min-width: 32px;
  background: ${({ $active }) =>
    $active ? theme.colors.primary : theme.colors.white};
  color: ${({ $active }) =>
    $active ? theme.colors.white : theme.colors.companyBrand};
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  &:disabled {
    color: ${theme.colors.companyBrand};
    cursor: not-allowed;
  }
`;

interface PaginationProps {
  total?: number;
  offset?: number; // default value: 0
  limit?: number; // default value: 10
  onChangeOffset?: (offset: number) => void;
}
const Pagination: React.FC<PaginationProps> = ({
  total = 0,
  offset = 0,
  limit = 10,
  onChangeOffset: setOffset,
}) => {
  const totalPages = Math.floor((total - 1) / limit) + 1;
  const currentPage = Math.floor((offset - 1) / limit) + 1 + 1;
  const buttonCounts = 5;
  const minPageNumberLimit = Math.max(
    0,
    currentPage -
      Math.round(buttonCounts / (currentPage > buttonCounts / 2 ? 2 : 1))
  );
  const maxPageNumberLimit = Math.min(minPageNumberLimit + buttonCounts, total);
  const handleNextBtn = () => {
    setOffset?.(offset + limit);
  };

  const handlePrevBtn = () => {
    setOffset?.(offset - limit);
  };

  const handleFirstBtn = () => {
    setOffset?.(0);
  };

  const handleLastBtn = () => {
    setOffset?.((totalPages - 1) * limit);
  };

  const renderPageNumbers = () => {
    const pages = [];
    for (let page = 1; page <= totalPages; page++) {
      if (minPageNumberLimit < page && page < maxPageNumberLimit + 1) {
        pages.push(
          <StyledButton
            key={page}
            $active={currentPage === page}
            onClick={() => setOffset?.((page - 1) * limit)}
          >
            {page}
          </StyledButton>
        );
      }
    }
    return pages;
  };
  return (
    <UIBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={10}
    >
      <Typography>Total: {total}</Typography>
      <UIBox display="flex" gap={5}>
        <StyledButton onClick={handleFirstBtn} disabled={currentPage === 1}>
          <IconPaginationFirst
            color={currentPage === 1 ? theme.colors.icon : theme.colors.primary}
          />
        </StyledButton>
        <StyledButton onClick={handlePrevBtn} disabled={currentPage === 1}>
          <IconPaginationPrev
            color={currentPage === 1 ? theme.colors.icon : theme.colors.primary}
          />
        </StyledButton>

        {minPageNumberLimit >= 1 && <StyledButton>…</StyledButton>}
        {renderPageNumbers()}
        {totalPages > maxPageNumberLimit && <StyledButton>…</StyledButton>}

        <StyledButton
          onClick={handleNextBtn}
          disabled={currentPage === totalPages}
        >
          <IconPaginationNext
            color={
              currentPage === totalPages
                ? theme.colors.icon
                : theme.colors.primary
            }
          />
        </StyledButton>
        <StyledButton
          onClick={handleLastBtn}
          disabled={currentPage === totalPages}
        >
          <IconPaginationLast
            color={
              currentPage === totalPages
                ? theme.colors.icon
                : theme.colors.primary
            }
          />
        </StyledButton>
      </UIBox>
    </UIBox>
  );
};

export default Pagination;
