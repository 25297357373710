import { AppSideModal } from '@/components/App';
import { IconArrowSquareOut, IconBookmark } from '@/assets/icons';
import { UIBox, Button, IconButton, Typography } from '@/components/UI';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { appRoutes, theme } from '@/constants';
import { UserJson } from '@/types';
import SponsorWorkerStatus from './SponsorWorkerStatus';
import { workersMock } from '@/mock';
const SponsorSidebarWorkerProposal: React.FC = () => {
  const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState<UserJson.Worker | undefined>(undefined);
  useEffect(() => {
    const workerId = searchParams.get('workerId');
    setShow(!!workerId);
    if (workerId) {
      setUser(workersMock.find((item) => item.id === workerId));
    } else {
      setUser(undefined);
    }
  }, [searchParams]);

  const handleCloseSideModal = () => {
    const { workerId, ...currentSearchParams } =
      Object.fromEntries(searchParams);
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <AppSideModal
      isOpen={show}
      width={720}
      onClose={handleCloseSideModal}
      customTools={
        <Link to={appRoutes.sponsorWorkerProfile(user?.id)} target="_blank">
          <IconButton>
            <IconArrowSquareOut />
          </IconButton>
        </Link>
      }
    >
      <UIBox display="flex" flexDirection="column" gap={30} px={1.5} py={2}>
        <SponsorWorkerStatus worker={user} />
        <UIBox>
          <Typography variant="h6">Applicant</Typography>
          <Typography>
            John has applied to or been invited to your company’s job Senior JS
            Developer with Backend
          </Typography>
        </UIBox>
        <UIBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Proposal Details</Typography>
          <Typography color={theme.colors.textPrimary}>
            ${user?.hourlyRate}/hour
          </Typography>
        </UIBox>
        <UIBox>
          <Typography variant="h6">Cover Letter</Typography>
          {user?.coverLetter
            ?.split('\n')
            .map((sentence, id) => (
              <Typography key={id}>{sentence}</Typography>
            ))}
        </UIBox>
      </UIBox>

      <UIBox
        position="absolute"
        bottom={0}
        background={theme.colors.white}
        boxShadow="10px -1px 30px 0px #7B7D9633"
        left={0}
        right={0}
        py={1}
        px={1.5}
        display="flex"
        gap={10}
      >
        <UIBox flexGrow={1}>
          <Button fullWidth variant="primary">
            Hire
          </Button>
        </UIBox>
        <UIBox flexGrow={1}>
          <Button fullWidth variant="outlined-primary">
            Message
          </Button>
        </UIBox>
        <Button variant="outlined-primary">
          <IconBookmark color={theme.colors.primary} />
        </Button>
      </UIBox>
    </AppSideModal>
  );
};

export default SponsorSidebarWorkerProposal;
