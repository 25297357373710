import { createApi } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { AppTypes, AuthJson, UserJson } from '@/types';
import { defaultBaseQuery } from '@/utils';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: defaultBaseQuery,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    authenticate: builder.mutation<AuthJson.Response, AuthJson.LoginInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: '/auth/authorize',
            method: 'POST',
            body: arg,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as AuthJson.Response,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    register: builder.mutation<
      AuthJson.RegisterResponse,
      AuthJson.RegisterInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: '/auth/register',
            method: 'POST',
            body: arg,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as AuthJson.RegisterResponse,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    registerGoogleOrg: builder.mutation<
      AuthJson.RegisterResponse,
      AuthJson.OrgGoogleRegisterInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: '/auth/register-org',
            method: 'POST',
            body: arg,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as AuthJson.RegisterResponse,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    resendVerificationCode: builder.mutation<
      AppTypes.Message,
      { userId: number }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/auth/verification/resend/${arg.userId}`,
            method: 'POST',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as AppTypes.Message,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    verify: builder.mutation<AuthJson.VerifyOutput, AuthJson.VerifyInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: '/auth/verify',
            method: 'POST',
            body: arg,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as AuthJson.VerifyOutput,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    forgotPassword: builder.mutation<UserJson.User, { email: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: '/auth/forgot-password',
            method: 'POST',
            body: arg,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.User,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    resetPassword: builder.mutation<
      UserJson.User,
      { password: string; userId: string }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: '/auth/reset-password',
            method: 'POST',
            body: arg,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.User,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const {
  useAuthenticateMutation,
  useRegisterMutation,
  useResendVerificationCodeMutation,
  useVerifyMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useRegisterGoogleOrgMutation,
} = authApi;
