import { IconDelete, IconDoc, IconPdf } from '@/assets/icons';
import { IconButton, Typography, UIBox } from '@/components/UI';
import { theme } from '@/constants';
import { formatFileSize, getFileExtension } from '@/utils';
import { useEffect, useState } from 'react';

interface FilePreviewProps {
  file: File;
  onClose: () => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file, onClose }) => {
  const fileExtension = getFileExtension(file.name);
  const isImageFile = file.type.includes('image');
  const [img, setImg] = useState('');
  useEffect(() => {
    if (file) {
      const preview = URL.createObjectURL(file);
      setImg(preview);
      return () => URL.revokeObjectURL(preview);
    }
  }, [file]);
  return (
    <UIBox
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={4}
      borderStyle="dashed"
      borderWidth={1}
      px={1}
      py={0.8}
    >
      <UIBox display="flex" alignItems="center" gap={15}>
        {fileExtension === 'pdf' && <IconPdf />}
        {(fileExtension === 'doc' || fileExtension === 'docx') && <IconDoc />}
        {isImageFile && <img src={img} alt="" style={{ width: 100 }} />}
        <UIBox>
          <Typography color={theme.colors.primary}>{file.name}</Typography>
          <Typography fontSize={12} lineHeight="17px">
            {formatFileSize(file.size)}
          </Typography>
        </UIBox>
      </UIBox>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <IconDelete />
      </IconButton>
    </UIBox>
  );
};
export default FilePreview;
