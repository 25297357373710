import { UserStatus } from '@/enums';
import { UIBox, Typography } from '@/components/UI';
import { theme } from '@/constants';

interface UserStatusProps {
  status?: UserStatus;
  label?: string;
}
const UserStatusTag: React.FC<UserStatusProps> = ({
  status = UserStatus.DEFAULT,
  label,
}) => {
  const colors: Record<UserStatus, string> = {
    [UserStatus.DEFAULT]: theme.colors.primary,
    [UserStatus.PENDING]: theme.colors.green300,
    [UserStatus.BLOCKED]: theme.colors.red500,
    [UserStatus.DELETED]: theme.colors.red500,
    [UserStatus.REJECTED]: theme.colors.red500,
  };
  const bgColors: Record<UserStatus, string> = {
    [UserStatus.DEFAULT]: '#ecf5fc',
    [UserStatus.PENDING]: '#efffdb',
    [UserStatus.BLOCKED]: '#fef5f5',
    [UserStatus.DELETED]: '#fef5f5',
    [UserStatus.REJECTED]: '#fef5f5',
  };
  return (
    <UIBox
      height={28}
      px={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      background={bgColors[status]}
      borderRadius={33}
    >
      <Typography
        variant="span"
        color={colors[status]}
        textTransform="capitalize"
      >
        {label ?? status.toLocaleLowerCase()}
      </Typography>
    </UIBox>
  );
};

export default UserStatusTag;
