import {
  Autocomplete,
  UIBox,
  Button,
  UICard,
  Checkbox,
  UIGrid,
  Radio,
  Typography,
} from '@/components/UI';
import { theme } from '@/constants';
import { CancelSaveConfirmModal } from '@/modules/Shared';
import { useState } from 'react';

const WorkerPreferencePage: React.FC = () => {
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  return (
    <>
      <UIBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1.5}
      >
        <Typography
          variant="h3"
          fontWeight={600}
          marginTop={0}
          marginBottom={0}
        >
          Preference
        </Typography>
        <UIBox display="flex" alignItems="center" gap={10}>
          <Button
            variant="secondary"
            fontWeight={500}
            size="sm"
            onClick={() => setShowCancelConfirmModal(true)}
          >
            Cancel
          </Button>
          <Button size="sm" fontWeight={500}>
            Save
          </Button>
        </UIBox>
      </UIBox>
      <UICard borderRadius={10}>
        <Typography variant="h6" marginBottom={10}>
          US work authorization
        </Typography>
        <UIGrid columns={2} gap={30}>
          <UIBox>
            <Typography color={theme.colors.textPrimary} marginBottom={10}>
              Do you or will you require sponsorship for a US employment visa
              (e.g. H-1B)?
            </Typography>
            <UIBox display="flex" gap={30}>
              <Radio label="Yes" />
              <Radio label="No" />
            </UIBox>
          </UIBox>
          <UIBox>
            <Typography color={theme.colors.textPrimary} marginBottom={10}>
              Are you legally authorized to work in the United States?
            </Typography>
            <UIBox display="flex" gap={30}>
              <Radio label="Yes" />
              <Radio label="No" />
            </UIBox>
          </UIBox>
        </UIGrid>

        <Typography variant="h6" marginBottom={10} marginTop={30}>
          What type of job are you interested in?
        </Typography>

        <UIGrid columns={2} gap={30}>
          <Autocomplete label="Preferred Employment Type" height={40} />
          <Autocomplete
            label="Also open to the following job types:"
            height={40}
          />
          <Autocomplete label="What is your desired salary?" height={40} />
        </UIGrid>

        <Typography variant="h6" marginBottom={10} marginTop={30}>
          Identity
        </Typography>
        <Typography color={theme.colors.textPrimary} marginBottom={20}>
          Race/Ethnicity
        </Typography>
        <UIBox display="flex" flexDirection="column" gap={20}>
          <Checkbox
            id="Black/African-American"
            label="Black/African-American"
          />
          <Checkbox
            id="East Asian (including Chinese, Japanese, Korean, and Mongolian"
            label="East Asian (including Chinese, Japanese, Korean, and Mongolian"
          />
          <Checkbox
            id="Hispanic or Latino/a/x"
            label="Hispanic or Latino/a/x"
          />
          <Checkbox id="Middle Eastern" label="Middle Eastern" />
          <Checkbox
            id="Native American or Alaskan Native"
            label="Native American or Alaskan Native"
          />
          <Checkbox id="Pacific Islander" label="Pacific Islander" />
          <Checkbox
            id="South Asian (including Bangladeshi, Bhutanese, Indian, Nepali, Pakistani, and Sri Lankan"
            label="South Asian (including Bangladeshi, Bhutanese, Indian, Nepali, Pakistani, and Sri Lankan"
          />
          <Checkbox
            id="Southeast Asian (including Burmese, Cambodian, Filipino, Hmong, Indonesian, Laotian, Malaysian, Mien, Singaporean, Thai, and Vietnamese"
            label="Southeast Asian (including Burmese, Cambodian, Filipino, Hmong, Indonesian, Laotian, Malaysian, Mien, Singaporean, Thai, and Vietnamese"
          />
          <Checkbox id="Prefer not to say" label="Prefer not to say" />
          <Checkbox id="Self-describe" label="Self-describe" />
        </UIBox>
      </UICard>

      <CancelSaveConfirmModal
        show={showCancelConfirmModal}
        onClose={() => setShowCancelConfirmModal(false)}
        onCancel={() => {
          setShowCancelConfirmModal(false);
        }}
      />
    </>
  );
};

export default WorkerPreferencePage;
