import { AppUserAvatar } from '@/components/App';
import {
  IconCamera,
  IconDelete,
  IconEllipsisVertical,
  IconLock,
  IconPaperclip,
  IconPhone,
  IconSend1,
  IconSmile,
  IconUserPlus,
} from '@/assets/icons';
import {
  UIBox,
  UICard,
  UIDivider,
  Dropdown,
  IconButton,
  Typography,
} from '@/components/UI';
import { DropdownButton } from '@/components/UI/Dropdown';
import { theme } from '@/constants';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { channelMessagesMock, workerProfileMock } from '@/mock';

const StyledMessageInput = styled.input`
  flex-grow: 1;
  color: ${theme.colors.textPrimary};
  border: none;
  background: transparent;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  &::placeholder {
    color: ${theme.colors.icon};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
`;

interface StyledMessageProps {
  self: boolean;
}
const StyledMessage = styled(
  ({
    self,
    ...rest
  }: StyledMessageProps &
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
    <div {...rest} />
  )
)<StyledMessageProps>`
  width: 360px;
  padding: 15px 20px;
  color: ${theme.colors.textDescription};
  font-size: 14px;
  line-height: 17px;
  border-radius: 10px;
  background: ${({ self }) => (self ? '#f6f6f6' : theme.colors.green600)};
  margin-left: ${({ self }) => (self ? 'auto' : '0')};
`;

const MessagePanel: React.FC = () => {
  const user = workerProfileMock;

  return (
    <UICard height={'100%'} position="relative">
      <UIBox display="flex" justifyContent="space-between">
        <UIBox display="flex" gap={20} alignItems="center">
          <AppUserAvatar user={user} size={50} showOnlineStatus />
          <UIBox>
            <Typography color={theme.colors.textPrimary} fontWeight={500}>
              {user.firstName} {user.lastName}
            </Typography>

            <Typography
              fontSize={14}
              lineHeight="17px"
              color={theme.colors.textDescription}
            >
              Online
            </Typography>
          </UIBox>
        </UIBox>
        <UIBox display="flex" gap={10}>
          <IconButton rounded>
            <IconPhone color={theme.colors.primary} />
          </IconButton>
          <IconButton rounded>
            <IconCamera color={theme.colors.primary} />
          </IconButton>
          <Dropdown
            button={
              <IconButton rounded>
                <IconEllipsisVertical color={theme.colors.primary} />
              </IconButton>
            }
            disableDefaultToggleStyle
          >
            <DropdownButton variant="primary">
              <IconUserPlus />
              Invite to job
            </DropdownButton>
            <DropdownButton variant="secondary">
              <IconDelete width={8} height={10} color={theme.colors.icon} />
              Clean all
            </DropdownButton>
            <DropdownButton variant="danger">
              <IconLock color={theme.colors.red600} />
              Block John
            </DropdownButton>
          </Dropdown>
        </UIBox>
      </UIBox>
      <UIDivider />
      <UIBox
        position="relative"
        display="flex"
        flexDirection="column"
        gap={10}
        height={`calc(100% - 164px)`}
        overflow="auto"
      >
        <UIBox flexGrow={1} />
        <UIBox
          position="absolute"
          left={`50%`}
          top={0}
          transform="translateX(-50%)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={1}
          background={theme.colors.gray810}
          height={16}
          borderRadius={12}
        >
          <Typography fontSize={12} lineHeight="15px" color={theme.colors.icon}>
            Today
          </Typography>
        </UIBox>

        {channelMessagesMock.map((msg, id) => (
          <StyledMessage self={msg.user.id === user.id} key={id}>
            {msg.message}
            <Typography fontSize={10} lineHeight="12px" textAlign="right">
              {msg.arrivedAt?.toLocaleTimeString()}
            </Typography>
          </StyledMessage>
        ))}
      </UIBox>
      <UIBox
        display="flex"
        px={2}
        gap={10}
        borderRadius={10}
        background={theme.colors.gray980}
        height={60}
        alignItems="center"
        position="absolute"
        bottom={20}
        left={20}
        right={20}
      >
        <IconButton>
          <IconPaperclip />
        </IconButton>
        <UIDivider vertical ml={0} mr={0} height={20} />
        <StyledMessageInput placeholder="Type a message" />
        <IconButton>
          <IconSmile />
        </IconButton>
        <IconButton>
          <IconSend1 />
        </IconButton>
      </UIBox>
    </UICard>
  );
};

export default MessagePanel;
