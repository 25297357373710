import FileDropzone from '@/modules/Shared/FileDropzone';
import { UIBox, Button, UICard, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { JobPostingSteps } from '@/enums';
import { ProjectJson } from '@/types';

interface NewJobStep4Props {
  job: ProjectJson.Project;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  updateJob: (updated: Partial<ProjectJson.Project>) => void;
}
const NewJobStep4: React.FC<NewJobStep4Props> = ({
  onClickNext,
  onClickPrev,
}) => {
  return (
    <UICard
      id={JobPostingSteps.STEP_4}
      borderRadius={10}
      display="flex"
      flexDirection="column"
      gap={20}
      p={2}
    >
      <Typography variant="h6" marginBottom={0}>
        Add file
      </Typography>
      <FileDropzone label="Click or drag to upload file" />
      {onClickNext && (
        <UIBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <UIBox display="flex" alignItems="center" gap={20}>
            <Button variant="secondary" onClick={onClickPrev} size="sm">
              Back
            </Button>
            <Typography>
              <Typography variant="span" color={theme.colors.textPrimary}>
                4
              </Typography>
              /5
            </Typography>
          </UIBox>
          <Button size="sm" onClick={onClickNext}>
            Next
          </Button>
        </UIBox>
      )}
    </UICard>
  );
};

export default NewJobStep4;
