import { IconBookmark } from '@/assets/icons';
import { UIBox, Button, UICard, Tag, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { UserJson } from '@/types';
import SponsorWorkerStatus from './SponsorWorkerStatus';

interface SponsorInviteWorkerCardProps {
  worker: UserJson.Worker;
  onClick: () => void;
  onClickBookmark: () => void;
  onClickHire: () => void;
  onClickInviteToJob: () => void;
}

const SponsorInviteWorkerCard: React.FC<SponsorInviteWorkerCardProps> = ({
  worker,
  onClick,
  onClickBookmark,
  onClickHire,
  onClickInviteToJob,
}) => {
  return (
    <UICard
      borderRadius={10}
      display="flex"
      flexDirection="column"
      onClick={onClick}
      cursor="pointer"
    >
      <UIBox display="flex" justifyContent="space-between">
        <SponsorWorkerStatus worker={worker} />
        <UIBox display="flex" gap={10}>
          <Button
            variant="outlined-primary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onClickBookmark();
            }}
          >
            <IconBookmark color={theme.colors.primary} />
          </Button>
          <Button
            variant="outlined-primary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onClickHire();
            }}
          >
            Hire
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onClickInviteToJob();
            }}
          >
            Invite to Job
          </Button>
        </UIBox>
      </UIBox>

      <UIBox mt={1}>
        <Typography fontSize={14} lineHeight="17px">
          Here are their top 5 relevant skills to your job
        </Typography>
        <UIBox display="flex" gap={10} mt={0.5}>
          {worker.userSkills
            ?.slice(0, 5)
            .map((skill, id) => <Tag key={id}>{skill.skill?.name}</Tag>)}
        </UIBox>
      </UIBox>
    </UICard>
  );
};

export default SponsorInviteWorkerCard;
