import { AppModal } from '@/components/App';
import { Button, Spinner, UIBox } from '@/components/UI';
import { UserJson } from '@/types';
import { useEffect, useRef } from 'react';
import { useCreateUserCertificationMutation } from '@/redux/apis';
import CertificationEditForm, {
  CertificationEditFormHandle,
} from './CertificationEditForm';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  onAdded: () => void;
}
const CertificationEditModal: React.FC<ModalProps> = ({
  show,
  onClose,
  onAdded,
}) => {
  const [createUserCertification, { isLoading, isSuccess }] =
    useCreateUserCertificationMutation();
  const formRef = useRef<CertificationEditFormHandle>(null);
  const handleSubmit = async (v: UserJson.CertificationInput) => {
    await createUserCertification(v);
  };
  const handleAddClick = () => {
    formRef.current?.submitForm();
  };
  useEffect(() => {
    if (isSuccess) {
      onAdded();
    }
  }, [isLoading, isSuccess]);
  return (
    <AppModal
      isOpen={show}
      header={
        <UIBox display="flex" alignItems="center" gap={8}>
          Certification
        </UIBox>
      }
      onClose={onClose}
      width={800}
      actions={
        <UIBox display="flex" justifyContent="flex-end" gap={20}>
          <Button size="md" variant="outlined-secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="md"
            variant="primary"
            onClick={handleAddClick}
            disabled={isLoading}
          >
            {isLoading && <Spinner />}
            Add
          </Button>
        </UIBox>
      }
    >
      <UIBox mt={-1.5}>
        <CertificationEditForm ref={formRef} onSubmit={handleSubmit} />
      </UIBox>
    </AppModal>
  );
};

export default CertificationEditModal;
