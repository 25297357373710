import styled from 'styled-components';
import SharedNavbar from './SharedNavbar';

const StyledNavbarContainer = styled.header(({ theme }) => ({
  paddingLeft: 30,
  paddingRight: 30,
  flexGrow: 1,
  position: 'fixed',
  zIndex: 99,
  left: 0,
  right: 0,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: theme.colors.white,
  boxShadow: `0px 4px 10px 0px #0000000D`,
}));

const OrgNavbar: React.FC = () => {
  return (
    <StyledNavbarContainer>
      <SharedNavbar />
    </StyledNavbarContainer>
  );
};

export default OrgNavbar;
