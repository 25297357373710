import { SkillJson, UserJson } from '@/types';
import { faker } from '@faker-js/faker';

export function createRandomUserSkill(): UserJson.Skill {
  return {
    id: faker.string.uuid(),
    certificationId: faker.string.uuid(),
    createdAt: faker.date.past({ years: 1 }).toDateString(),
    skill: createRandomSkill(),
  };
}
export function createRandomSkill(): SkillJson.Skill {
  return {
    id: faker.string.uuid(),
    name: faker.helpers.arrayElement([
      'Github',
      'SQL',
      'REST API',
      'JavaScript',
      'TypeScript',
      'React',
      'Angular',
      'Vue',
      'Node',
      'PHP',
      'Python',
      '.NET',
      'Java',
      'AI',
    ]),
    description: faker.lorem.paragraph(),
    industry: {
      id: faker.string.uuid(),
      name: faker.helpers.arrayElement(['Develop', 'Crypto', 'Web', 'Mobile']),
      createdAt: faker.date.past({ years: 1 }).toDateString(),
    },
    createdAt: faker.date.past({ years: 1 }).toDateString(),
  };
}
export const skillsMock: SkillJson.Skill[] = faker.helpers.multiple(
  createRandomSkill,
  { count: 15 }
);
