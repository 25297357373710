import { createApi } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SkillJson } from '@/types';
import { authorizeQueryWithRefreshToken } from '@/utils';

export const skillApi = createApi({
  reducerPath: 'skillApi',
  baseQuery: authorizeQueryWithRefreshToken,
  tagTypes: ['Skill'],
  endpoints: (builder) => ({
    skill: builder.query<SkillJson.Skill, { id: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/skills/${arg.id}`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as SkillJson.Skill,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    createSkill: builder.mutation<SkillJson.Skill, SkillJson.SkillInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/skills`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as SkillJson.Skill,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    updateSkill: builder.mutation<SkillJson.Skill, SkillJson.SkillInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/skills/${arg.id}`,
            method: 'PATCH',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as SkillJson.Skill,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    deleteSkill: builder.mutation<SkillJson.Skill, { skillId: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/skills/${arg.skillId}`,
            method: 'DELETE',
          });
          if (result?.data) {
            return {
              data: result.data as SkillJson.Skill,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    skills: builder.query<SkillJson.PagedSkill, SkillJson.SkillPagination>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const queryString = new URLSearchParams(arg as any).toString();
          const result = await baseQuery({
            url: `/skills?${queryString}`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as SkillJson.PagedSkill,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const {
  useSkillQuery,
  useSkillsQuery,
  useCreateSkillMutation,
  useDeleteSkillMutation,
  useUpdateSkillMutation,
} = skillApi;
