import { IconSearch } from '@/assets/icons';
import { UIBox, Button } from '@/components/UI';
import { theme } from '@/constants';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledJobSearchFilterContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: ${theme.colors.white};
`;
const sharedInputStyles = `
  flex-grow: 1;
  border: none;
  color: ${theme.colors.textPrimary};
  font-size: 16px;
  line-height: 22px;
  padding: 5px 20px;
  height: 44px;
  &:placeholder {
    color: ${theme.colors.gray500};
    font-size: 16px;
    line-height: 22px;
  }
  &:focus {
    outline: none;
  }
`;

const StyledJobTitleInput = styled.input`
  ${sharedInputStyles}
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: 'Gantari';
`;
const StyledJobLocationInput = styled.input`
  ${sharedInputStyles}
  max-width: 200px;
  font-family: 'Gantari';
`;

const WorkerJobSearchFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [jobTitle, setJobTitle] = useState(searchParams.get('jobTitle') || '');
  const [location, setLocation] = useState(searchParams.get('location') || '');
  const handleClickFind = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('jobTitle', jobTitle);
    newSearchParams.set('location', location);
    setSearchParams(newSearchParams);
  };
  return (
    <StyledJobSearchFilterContainer>
      <StyledJobTitleInput
        placeholder="Job Title or Company name"
        value={jobTitle}
        onChange={(e) => setJobTitle(e.target.value)}
      />
      <UIBox
        height={20}
        borderLeftStyle="solid"
        borderLeftWidth={2}
        borderLeftColor="#E7E7E9"
      />
      <StyledJobLocationInput
        placeholder="Location"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
      />
      <Button onClick={handleClickFind}>
        <IconSearch color={theme.colors.white} />
        Find jobs
      </Button>
    </StyledJobSearchFilterContainer>
  );
};

export default WorkerJobSearchFilter;
