import {
  IconArrowDown,
  IconArrowUp,
  IconBuildings,
  IconDashboard,
  IconRanking,
  IconServer,
  IconSetting,
  IconTaskSquare,
  IconUser,
  IconWork,
} from '@/assets/icons';
import { appRoutes, theme } from '@/constants';
import AsideNavLinkItem from './AsideNavLinkItem';
import styled from 'styled-components';
import { useState } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { UIBox } from '@/components/UI';

const StyledCollapseContainer = styled.div``;
const StyledCollapseHeader = styled.div<{ $active: boolean }>`
  position: relative;
  > svg,
  > .status {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;
const StyledCollapseBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0 0 30px;
  border-left: solid 1px #40549c33;
`;

interface StyledSubNavLinkProps {
  active: boolean;
}

const StyledSubNavLink = styled(
  ({
    active,
    ...rest
  }: StyledSubNavLinkProps &
    LinkProps &
    React.RefAttributes<HTMLAnchorElement>) => <Link {...rest} />
)<StyledSubNavLinkProps>`
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 30px 0 15px;
  gap: 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: ${({ active }) => (active ? 500 : 400)};
  color: ${({ active }) => (active ? theme.colors.primary : theme.colors.icon)};
  transition: all ease 0.3s;
`;

const StyledBullet = styled(UIBox)`
  width: 5px;
  height: 5px;
  border-radius: 5px;
`;

const colors: Record<string, string> = {
  [appRoutes.adminWorkers]: `${theme.colors.red500}77`,
  [appRoutes.adminSponsors]: theme.colors.green700,
  [appRoutes.adminMentors]: theme.colors.secondary,
  [appRoutes.adminInvitees]: theme.colors.primary,
};
const subNavs = [
  appRoutes.adminWorkers,
  appRoutes.adminSponsors,
  appRoutes.adminMentors,
  appRoutes.adminInvitees,
];
const AdminAsideBar: React.FC<{ expanded: boolean }> = ({ expanded }) => {
  const [collapse, setCollapse] = useState(false);
  const { pathname } = useLocation();
  const isActiveUserNav = subNavs.some((v) => pathname.includes(v));
  const activeRoute = subNavs.find((item) => pathname.includes(item)) || '';
  return (
    <>
      <AsideNavLinkItem to={appRoutes.adminDashboard} icon={<IconDashboard />}>
        Dashboard
      </AsideNavLinkItem>
      <StyledCollapseContainer>
        <StyledCollapseHeader
          onClick={() => setCollapse(!collapse)}
          $active={isActiveUserNav}
        >
          <AsideNavLinkItem
            icon={<IconUser width={14} height={17} />}
            active={isActiveUserNav}
            to="#"
          >
            Users
          </AsideNavLinkItem>

          {!expanded && (
            <StyledBullet className="status" background={colors[activeRoute]} />
          )}
          {expanded &&
            (collapse ? (
              <IconArrowDown
                color={
                  isActiveUserNav ? theme.colors.primary : theme.colors.gray810
                }
              />
            ) : (
              <IconArrowUp
                color={
                  isActiveUserNav ? theme.colors.primary : theme.colors.gray810
                }
              />
            ))}
        </StyledCollapseHeader>
        {!collapse && expanded && (
          <StyledCollapseBody>
            <StyledSubNavLink
              active={pathname.includes(appRoutes.adminWorkers)}
              to={appRoutes.adminWorkers}
            >
              <StyledBullet backgroundColor={`${theme.colors.red500}77`} />
              Workers
            </StyledSubNavLink>
            <StyledSubNavLink
              active={pathname.includes(appRoutes.adminSponsors)}
              to={appRoutes.adminSponsors}
            >
              <StyledBullet backgroundColor={theme.colors.green700} />
              Sponsors
            </StyledSubNavLink>
            <StyledSubNavLink
              active={pathname.includes(appRoutes.adminMentors)}
              to={appRoutes.adminMentors}
            >
              <StyledBullet backgroundColor={theme.colors.secondary} />
              Mentors
            </StyledSubNavLink>
            <StyledSubNavLink
              active={pathname.includes(appRoutes.adminInvitees)}
              to={appRoutes.adminInvitees}
            >
              <StyledBullet backgroundColor={theme.colors.primary} />
              Invitees
            </StyledSubNavLink>
          </StyledCollapseBody>
        )}
      </StyledCollapseContainer>
      <AsideNavLinkItem
        icon={<IconBuildings width={20} height={20} />}
        to={appRoutes.adminOrganizations}
      >
        Organizations
      </AsideNavLinkItem>
      <AsideNavLinkItem icon={<IconWork />} to={appRoutes.adminProjects}>
        Projects
      </AsideNavLinkItem>
      <AsideNavLinkItem icon={<IconServer />} to={appRoutes.adminIndustries}>
        Industries
      </AsideNavLinkItem>
      <AsideNavLinkItem icon={<IconRanking />} to={appRoutes.adminSkills}>
        Skills
      </AsideNavLinkItem>
      <AsideNavLinkItem
        icon={<IconTaskSquare />}
        to={appRoutes.adminAssessments}
        disabled
      >
        Assessments
      </AsideNavLinkItem>
      <AsideNavLinkItem
        icon={<IconSetting width={16} height={16} />}
        to={appRoutes.adminSettings}
      >
        Settings
      </AsideNavLinkItem>
    </>
  );
};

export default AdminAsideBar;
