import { AppUserAvatar } from '@/components/App';
import { IconArrowSquareOut } from '@/assets/icons';
import { UIBox, UICard, IconButton, Typography } from '@/components/UI';
import { theme } from '@/constants';

import WorkerSidebarMyResumes from './WorkerSidebarMyResumes';
import { useSearchParams } from 'react-router-dom';
import { workerProfileMock } from '@/mock';

const user = workerProfileMock;
const WorkerSummaryCard: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickCard = () => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.openMyResume = `true`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <UIBox>
      <UICard
        borderRadius={10}
        display="flex"
        flexDirection="column"
        gap={10}
        onClick={handleOnClickCard}
        cursor="pointer"
      >
        <Typography color={theme.colors.textPrimary} fontWeight={500}>
          My resume
        </Typography>
        <UIBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <UIBox display="flex" alignItems="center" gap={20}>
            <AppUserAvatar user={user} />
            <UIBox>
              <Typography color={theme.colors.textPrimary} fontWeight={500}>
                {user.firstName} {user.lastName}
              </Typography>
              <Typography fontSize={14} lineHeight="24px" fontWeight={400}>
                {user.profession}
              </Typography>
            </UIBox>
          </UIBox>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconArrowSquareOut />
          </IconButton>
        </UIBox>
      </UICard>
      <WorkerSidebarMyResumes />
    </UIBox>
  );
};

export default WorkerSummaryCard;
