import { AppStatusTag } from '@/components/App';
import UserAvatar from '@/components/App/UserAvatar';
import {
  Typography,
  UIBox,
  UICard,
  UITable,
  UITh,
  UIThead,
  UITr,
} from '@/components/UI';
import { theme } from '@/constants';
import { jobsMock } from '@/mock';

const OrgJobsProgress: React.FC = () => {
  return (
    <UICard>
      <Typography variant="h4" fontSize={20} fontWeight={500}>
        Jobs progress
      </Typography>
      <UITable width={'100%'}>
        <UIThead>
          <tr>
            <UITh $borderBottom>ID</UITh>
            <UITh $borderBottom>Title</UITh>
            <UITh $borderBottom>Poster</UITh>
            <UITh $borderBottom>Type</UITh>
            <UITh $borderBottom>Employment type</UITh>
            <UITh $borderBottom align="center">
              Status
            </UITh>
            <UITh $borderBottom align="right">
              Joined at
            </UITh>
          </tr>
        </UIThead>
        <tbody>
          {jobsMock.slice(0, 3).map((job, id) => (
            <UITr key={id} style={{ boxShadow: 'none' }}>
              <td width={70}>
                <Typography
                  fontSize={14}
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                >
                  {job.id}
                </Typography>
              </td>
              <td>
                <Typography color={theme.colors.textPrimary} fontWeight={500}>
                  {job.title}
                </Typography>
              </td>
              <td>
                <UIBox display="flex" alignItems="center" gap={10}>
                  <UserAvatar user={job.poster} size={30} />
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {job.poster.firstName} {job.poster.lastName}
                  </Typography>
                </UIBox>
              </td>
              <td width={150}>
                <Typography
                  fontSize={14}
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                >
                  {job.type}
                </Typography>
              </td>
              <td width={200}>
                <Typography
                  fontSize={14}
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                >
                  {job.employmentType}
                </Typography>
              </td>
              <td align="center" width={120}>
                <AppStatusTag status={'default'} label="Posted" />
              </td>
              <td align="right" width={150}>
                <Typography
                  fontSize={14}
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                >
                  {job.postedAt}
                </Typography>
              </td>
            </UITr>
          ))}
        </tbody>
      </UITable>
    </UICard>
  );
};

export default OrgJobsProgress;
