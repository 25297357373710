import { UIBox, UICard, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { UserJson } from '@/types';
import { useEffect, useRef, useState } from 'react';
import ProfileEditButton from '../Shared/ProfileEditButton';
import EducationEditForm, {
  EducationEditFormHandle,
} from './EducationEditForm';
import { useUpdateUserEducationMutation } from '@/redux/apis';

interface EducationCardProps {
  editable?: boolean;
  education: UserJson.Education;
  onClickSave?: () => void;
  onClickDelete?: () => void;
}
const EducationCard: React.FC<EducationCardProps> = ({
  editable,
  education,
  onClickDelete,
  onClickSave,
}) => {
  const editFormRef = useRef<EducationEditFormHandle>(null);

  const [updateUserEducation, { isLoading: isSaving, isSuccess }] =
    useUpdateUserEducationMutation();
  const [editing, setEditing] = useState(false);
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnSave = async (v: UserJson.EducationInput) => {
    await updateUserEducation(v);
  };
  useEffect(() => {
    if (isSuccess) {
      onClickSave?.();
      setEditing(false);
    }
  }, [isSuccess]);
  const handleClickOnSave = () => {
    editFormRef.current?.submitForm();
  };
  const handleOnCancel = () => {
    setEditing(false);
  };

  return (
    <UICard
      position="relative"
      borderRadius={12}
      p={1.5}
      borderWidth={1}
      borderStyle="solid"
      borderColor={theme.colors.gray810}
      boxShadow={theme.colors.lightBoxShadow}
    >
      {editable && (
        <UIBox
          position="absolute"
          right={15}
          top={15}
          display="flex"
          alignItems="center"
          gap={10}
        >
          <ProfileEditButton
            editing={editing}
            hasDelete
            saving={isSaving}
            onClickCancel={handleOnCancel}
            onClickEdit={handleOnEdit}
            onClickSave={handleClickOnSave}
            onClickDelete={onClickDelete}
          />
        </UIBox>
      )}
      {editing ? (
        <EducationEditForm
          ref={editFormRef}
          education={education}
          onSubmit={handleOnSave}
        />
      ) : (
        <>
          <Typography color={theme.colors.textPrimary} fontWeight={500}>
            {education.school}
          </Typography>
          <Typography
            color={theme.colors.textPrimary}
            fontSize={14}
            fontWeight={500}
          >
            {education.degree}
          </Typography>
          <Typography
            color={theme.colors.textDescription}
            fontSize={14}
            fontWeight={500}
          >
            {`${new Date(education.startAt).toLocaleDateString()} - ${education.endAt ? new Date(education.endAt).toLocaleDateString() : 'Present'}`}
          </Typography>
          {education.description
            ?.split('\n')
            .map((p, id) => <Typography key={id}>{p}</Typography>)}
        </>
      )}
    </UICard>
  );
};

export default EducationCard;
