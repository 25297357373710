import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconBookmark({
  width = 12,
  height = 14,
  color = theme.colors.icon,
  marked,
}: AppTypes.IconProps & { marked?: boolean }) {
  return marked ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.05191 0.25H3.17669C1.48089 0.25 0.0991211 0.91875 0.0991211 2.61875V12.1062C0.0991211 12.4562 0.381755 12.7437 0.733477 12.7437C0.84025 12.7437 0.94765 12.7119 1.04123 12.6625L5.11744 10.6375L9.18737 12.6625C9.33811 12.75 9.52025 12.775 9.68355 12.725C9.85313 12.675 9.9913 12.5625 10.073 12.4125C10.1232 12.3187 10.1483 12.2125 10.1483 12.1062V2.61875C10.1295 0.91875 8.75399 0.25 7.05191 0.25Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.20801 12.753L1.89534 12.1267L1.88768 12.1305L1.88011 12.1345L2.20801 12.753ZM5.99384 10.863L6.30689 10.2369L5.99408 10.0805L5.68118 10.2367L5.99384 10.863ZM9.77384 12.753L10.1266 12.1483L10.1071 12.137L10.0869 12.1269L9.77384 12.753ZM10.2347 12.8113L10.0358 12.1401L10.0288 12.1423L10.2347 12.8113ZM10.5963 12.5196L11.2119 12.853L11.214 12.849L10.5963 12.5196ZM10.6663 3.3788H11.3664L11.3663 3.37108L10.6663 3.3788ZM7.79051 0.467969H4.19134V1.86797H7.79051V0.467969ZM4.19134 0.467969C3.32822 0.467969 2.45465 0.636314 1.77615 1.10075C1.05916 1.59154 0.633008 2.36515 0.633008 3.3788H2.03301C2.03301 2.80578 2.24853 2.47398 2.56694 2.25602C2.92386 2.01171 3.47947 1.86797 4.19134 1.86797V0.467969ZM0.633008 3.3788V12.2338H2.03301V3.3788H0.633008ZM0.633008 12.2338C0.633008 12.9381 1.19996 13.5288 1.92217 13.5288V12.1288C1.99106 12.1288 2.03301 12.1829 2.03301 12.2338H0.633008ZM1.92217 13.5288C2.15721 13.5288 2.36892 13.46 2.53591 13.3714L1.88011 12.1345C1.87884 12.1352 1.87996 12.1343 1.88481 12.133C1.88962 12.1317 1.90222 12.1288 1.92217 12.1288V13.5288ZM2.52067 13.3793L6.3065 11.4893L5.68118 10.2367L1.89534 12.1267L2.52067 13.3793ZM5.68079 11.4891L9.46079 13.3791L10.0869 12.1269L6.30689 10.2369L5.68079 11.4891ZM9.42113 13.3576C9.71751 13.5305 10.0883 13.5887 10.4405 13.4803L10.0288 12.1423C10.0543 12.1344 10.0753 12.1342 10.0899 12.1361C10.1042 12.1379 10.1162 12.1423 10.1266 12.1483L9.42113 13.3576ZM10.4335 13.4825C10.7686 13.3832 11.0471 13.1571 11.2118 12.853L9.98084 12.1862C9.99387 12.1622 10.0158 12.1461 10.0358 12.1401L10.4335 13.4825ZM11.214 12.849C11.3179 12.6542 11.3663 12.4409 11.3663 12.2338H9.96634C9.96634 12.225 9.9681 12.2101 9.97869 12.1902L11.214 12.849ZM11.3663 12.2338V3.3788H9.96634V12.2338H11.3663ZM11.3663 3.37108C11.3552 2.36042 10.9264 1.58971 10.2093 1.10023C9.52968 0.636324 8.65657 0.467969 7.79051 0.467969V1.86797C8.50528 1.86797 9.06134 2.0117 9.42004 2.25654C9.74127 2.47581 9.96003 2.81051 9.96638 3.38652L11.3663 3.37108Z"
        fill={color}
      />
    </svg>
  );
}
