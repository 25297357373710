import { IconFacebook, IconGoogle, IconLinkedin } from '@/assets/icons';
import {
  UIBox,
  Button,
  UICard,
  Checkbox,
  Image,
  Input,
  Label,
  Link,
  UIOrDivider,
  SocialButton,
  Typography,
  Spinner,
  StatusAlert,
} from '@/components/UI';
import { appConfig, appRoutes } from '@/constants';
import { Provider, RoleNames } from '@/enums';
import { useAuth } from '@/hooks/useAuth';
import { useAuthenticateMutation } from '@/redux/apis';
import { AppTypes, AuthJson } from '@/types';
import { determineInputType, inputStatus } from '@/utils';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as yup from 'yup';

// const savedLogins: AuthJson.Response[] = [
//   {
//     accessToken: '',
//     refreshToken: '',
//     webView: '',
//     roleName: 'Organization',
//     userInfo: {
//       id: 0,
//       email: '',
//       username: '',
//       phone: '',
//       image: '/images/img_team1.png',
//     },
//     orgId: 1,
//     lastLoginAt: new Date(2023, 1, 3),
//   },
//   {
//     accessToken: '',
//     refreshToken: '',
//     webView: '',
//     roleName: 'Admin',
//     userInfo: {
//       id: 2,
//       email: '',
//       username: '',
//       phone: '',
//       image: '',
//     },
//     orgId: 1,
//     lastLoginAt: new Date(2023, 1, 1),
//   },
// ];

type LoginInputForm = AuthJson.LoginInput & {
  rememberMe: boolean;
};

const initialValues: LoginInputForm = {
  identifier: '',
  password: '',
  rememberMe: false,
  provider: Provider.WEB_FORM,
};

const loginSchema = yup.object().shape({
  identifier: yup.string().required('User name or email address is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});

function AuthLoginPage() {
  const theme = useTheme();
  const { setAuth } = useAuth();
  const [googleAuthError, setGoogleAuthError] = useState('');
  const navigate = useNavigate();
  const [authenticate, { data, isLoading, error }] = useAuthenticateMutation();
  const formik = useFormik<LoginInputForm>({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setErrors }) => {
      const identifierType = determineInputType(values.identifier);
      if (identifierType === 'unknown') {
        setErrors({
          identifier:
            'Invalid input! Please enter a valid username, email, or phone number.',
        });
        return;
      }

      await authenticate(values);
    },
  });
  useEffect(() => {
    if (error) {
      const err = error as AppTypes.ResponseError;
      formik.setErrors({
        identifier: err.data?.message || 'Invalid login attempt',
      });
    }
    if (!isLoading && data) {
      setAuth(data, formik.values.rememberMe);
      if (data.roleName === RoleNames.SUPER_ADMIN) {
        navigate(appRoutes.adminDashboard);
      } else if (data.roleName === RoleNames.SITE_ADMIN) {
        navigate(appRoutes.adminDashboard);
      } else if (data.roleName === RoleNames.ORG_ADMIN) {
        navigate(appRoutes.orgDashboard);
      } else if (data.roleName === RoleNames.SPONSOR) {
        navigate(appRoutes.sponsorMyJobs);
      } else if (data.roleName === RoleNames.WORKER) {
        navigate(appRoutes.workerFindWork());
      }
    }
  }, [isLoading, data, error]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const failed = urlParams.get('failed');
    if (failed) {
      if (failed === 'unregistered') {
        setGoogleAuthError('User not registered. Please try to sign up');
      } else {
        setGoogleAuthError(
          "You can't sign in with your Google account. Please try to sign up"
        );
      }
    }
  }, [navigate]);

  return (
    <UIBox display="flex" justifyContent="space-between" flexGrow={1}>
      <UIBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        pt={2}
      >
        <UIBox display="flex" alignItems="flex-end">
          <Image src="/images/img_young_woman.png" />
          <UIBox ml={2}>
            <Typography
              variant="h2"
              fontSize={45}
              fontWeight={500}
              lineHeight="60px"
              opacity={0.5}
            >
              Welcome Back
            </Typography>
            <Typography color={theme.colors.textPrimary} fontSize={18}>
              to <strong>Non-Linear Careers</strong>
            </Typography>
          </UIBox>
        </UIBox>
        <UIBox>
          {/* <Typography variant="h3" marginBottom={10}>
            Login as
          </Typography> */}
          <UIBox display="flex" gap={20}>
            {/* {savedLogins.map((saved, id) => (
              <SavedLogin authStatus={saved} key={id} />
            ))} */}
          </UIBox>
        </UIBox>
      </UIBox>
      <UIBox maxWidth={420} width={'100%'}>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          autoComplete="off"
          id="nl-login-form"
        >
          <UICard
            p={2}
            borderRadius={32}
            boxShadow="0px 4px 35px 0px #00000014"
          >
            <Typography variant="h3" marginTop={0}>
              Sign in
            </Typography>
            <Typography color={theme.colors.primary} marginBottom={20}>
              If you don’t have an account you can <br />
              <Link to={appRoutes.register} color={theme.colors.secondary}>
                Register here
              </Link>
            </Typography>
            <UIBox display="flex" gap={10} mb={1}>
              <SocialButton
                style={{ flexGrow: 1 }}
                onClick={() =>
                  (window.location.href =
                    appConfig.API_URL +
                    `/auth/google?frontendDomain=${encodeURIComponent(window.location.origin)}`)
                }
                type="button"
              >
                <IconGoogle />
                Sign in with Google
              </SocialButton>
              <SocialButton disabled type="button">
                <IconFacebook disabled />
              </SocialButton>
              <SocialButton disabled type="button">
                <IconLinkedin disabled />
              </SocialButton>
            </UIBox>
            {googleAuthError && (
              <StatusAlert status="error">{googleAuthError}</StatusAlert>
            )}
            <UIOrDivider>or</UIOrDivider>
            {formik.status && (
              <Typography color={theme.colors.red500} marginBottom={10}>
                {formik.status}
              </Typography>
            )}
            <UIBox mb={1}>
              <Label>Enter your username or email address</Label>
              <Input
                type="string"
                autoComplete="off"
                fullWidth
                placeholder="Username or email"
                {...formik.getFieldProps('identifier')}
                {...inputStatus(formik, 'identifier')}
              />
            </UIBox>
            <UIBox mb={1}>
              <Label>Enter your Password</Label>
              <Input
                type="password"
                fullWidth
                autoComplete="new-password"
                placeholder="Password"
                {...formik.getFieldProps('password')}
                {...inputStatus(formik, 'password')}
              />
            </UIBox>
            <UIBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Checkbox
                label="Remember me"
                id="rememberMe"
                {...formik.getFieldProps('rememberMe')}
              />
              <Link to={appRoutes.forgotPassword} color={theme.colors.blue}>
                Forgot Password?
              </Link>
            </UIBox>
            <Button
              fullWidth
              type="submit"
              rounded
              size="lg"
              disabled={isLoading}
            >
              {isLoading && <Spinner />}
              Sign in
            </Button>
          </UICard>
        </form>
      </UIBox>
    </UIBox>
  );
}

export default AuthLoginPage;
