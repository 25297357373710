import { FileDropzone } from '@/modules/Shared';
import { UIBox, Button, UICard, Typography, Spinner } from '@/components/UI';
import { useEffect, useState } from 'react';
import {
  useGetParsedAffindaResumeMutation,
  useGetParsedLinkedInResumeMutation,
} from '@/redux/apis';
import { UserAssetType, ResumeType } from '@/enums';
import { useAuth } from '@/hooks/useAuth';
import { AppModal } from '@/components/App';
import { useUploadFile } from '@/hooks/useUploadFile';

interface UpdateProfileFromFileModalProps {
  show: boolean;
  onClose: () => void;
  onUpdated: () => void;
}
const UpdateProfileFromFileModal: React.FC<UpdateProfileFromFileModalProps> = ({
  show,
  onClose,
  onUpdated,
}) => {
  const [errMsg, setErrMsg] = useState('');
  const {
    acceptedFiles,
    setAcceptedFiles,
    uploading,
    progress,
    loading: isUploading,
    error,
    uploadFile,
  } = useUploadFile({
    onUploadedFile: async (uploadedUrl) => {
      const fileName = acceptedFiles[0].name.replaceAll(' ', '+');
      const resumeType =
        fileName === 'Profile.pdf' ? ResumeType.LINKEDIN : ResumeType.AFFINDA;
      setErrMsg('');
      if (resumeType === ResumeType.LINKEDIN) {
        await getParsedLinkedInResume({
          file_link: uploadedUrl,
          user_id: user?.id || '',
          access_token: auth?.accessToken || '',
          resume_type: 'linkedin',
        });
      } else {
        await getParsedAffindaResume({
          file_link: uploadedUrl,
          user_id: user?.id || '',
          access_token: auth?.accessToken || '',
          resume_type: 'affinda',
        });
      }
    },
  });
  const { user, auth } = useAuth();
  const [
    getParsedLinkedInResume,
    {
      data: parsedLinkedInResume,
      isSuccess: isSuccessParsedLinkedInResume,
      isLoading: isLoadingParsedLinkedInResume,
      error: linkedinParsingError,
    },
  ] = useGetParsedLinkedInResumeMutation();

  const [
    getParsedAffindaResume,
    {
      data: parsedAffindaResume,
      isSuccess: isSuccessParsedAffindaResume,
      isLoading: isLoadingParsedAffindaResume,
      error: affindaParsingError,
    },
  ] = useGetParsedAffindaResumeMutation();
  async function handleUploadResume() {
    setErrMsg('');
    await uploadFile({
      contentType: UserAssetType.RESUME,
      userId: user?.id || '',
    });
  }

  useEffect(() => {
    if (
      (parsedAffindaResume && isSuccessParsedAffindaResume) ||
      (parsedLinkedInResume && isSuccessParsedLinkedInResume)
    ) {
      onUpdated();
    }
  }, [
    parsedLinkedInResume,
    isSuccessParsedLinkedInResume,
    parsedAffindaResume,
    isSuccessParsedAffindaResume,
  ]);
  const loading =
    isUploading ||
    isLoadingParsedAffindaResume ||
    isLoadingParsedLinkedInResume;

  useEffect(() => {
    setErrMsg(
      error ||
        (affindaParsingError || linkedinParsingError
          ? 'Failed to parse resume. Try to upload another template'
          : '')
    );
  }, [error, affindaParsingError, linkedinParsingError]);
  return (
    <AppModal
      isOpen={show}
      header={
        <UIBox display="flex" alignItems="center" gap={8}>
          Upload resume file
        </UIBox>
      }
      onClose={onClose}
      width={800}
      actions={
        <UIBox display="flex" justifyContent="flex-end" gap={20}>
          <Button size="md" variant="outlined-secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button size="md" onClick={handleUploadResume} disabled={loading}>
            {loading && <Spinner />}
            {isUploading
              ? 'Uploading file...'
              : isLoadingParsedAffindaResume || isLoadingParsedLinkedInResume
                ? 'Parsing resume...'
                : 'Add Resume'}
          </Button>
        </UIBox>
      }
    >
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={4}
        py={2}
      >
        <Typography variant="h3" textAlign="center" marginBottom={30}>
          Ready to upload your resume?
        </Typography>
        <UIBox mb={2} width="100%">
          <FileDropzone
            onChangeFiles={(files) => setAcceptedFiles(files)}
            label="Click or drag to upload your resume"
            uploading={uploading}
            progress={progress}
            error={errMsg}
          />
        </UIBox>
      </UICard>
    </AppModal>
  );
};

export default UpdateProfileFromFileModal;
