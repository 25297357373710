import { UIBox, Button, Typography, Tabs, Tab } from '@/components/UI';
import { useAuth, useConfirmModal } from '@/hooks';
import { useDeleteUserMutation } from '@/redux/apis';
import { useEffect } from 'react';
import OrgAccountSettingsForm from './OrgAccountSettingsForm';
import AccountChangePasswordForm from './AccountChangePasswordForm';

const OrgSettings: React.FC = () => {
  const { user, logout } = useAuth();
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();
  const [deleteUser, { isSuccess: isDeleted }] = useDeleteUserMutation();
  const handleDeleteProfile = async () => {
    await deleteUser({ userId: user?.id || '' });
  };
  useEffect(() => {
    if (isDeleted) {
      hideConfirmModal();
      logout();
    }
  }, [isDeleted]);
  return (
    <>
      <Typography variant="h5">Settings</Typography>

      <Tabs defaultActiveKey="accountInfo">
        <Tab eventKey="accountInfo" title="Account Info">
          <OrgAccountSettingsForm />
        </Tab>
        <Tab eventKey="changePassword" title="Change Password">
          <AccountChangePasswordForm />
        </Tab>
      </Tabs>
      <UIBox display="flex" justifyContent="flex-end" mt={1.5}>
        <Button
          variant="danger"
          size="sm"
          width={160}
          onClick={() =>
            showConfirmModal({
              question: 'Do you want to close your account?',
              confirmButton: 'Yes, Close My Account',
              onClose: () => hideConfirmModal(),
              onConfirm: handleDeleteProfile,
            })
          }
        >
          Close Account
        </Button>
      </UIBox>
    </>
  );
};

export default OrgSettings;
