import { IconFilter, IconSearch } from '@/assets/icons';
import {
  UIBox,
  Dropdown,
  DropdownItem,
  Input,
  Pagination,
  Switch,
  Typography,
} from '@/components/UI';
import { theme } from '@/constants';
import { RoleNames } from '@/enums';
import { workersMock } from '@/mock';

import { SidebarWorkerDetail } from '@/modules/Job';
import { SponsorInviteWorkerCard } from '@/modules/Job/SponsorJobs';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { SponsorViewJobPageOutletContext } from './view-job-post';

const SponsorInviteWorkers: React.FC = () => {
  const { setShowInviteModal, setShowHireModal } =
    useOutletContext<SponsorViewJobPageOutletContext>();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickWorkerCard = (workerId: string) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.workerId = `${workerId}`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <UIBox display="flex" flexDirection="column" gap={30}>
      <UIBox display="flex" gap={30} alignItems="center">
        <UIBox flexGrow={1}>
          <Input
            placeholder={
              <>
                <IconSearch color={theme.colors.companyBrand} />
                Search
              </>
            }
            fullWidth
          />
        </UIBox>
        <UIBox display="flex" alignItems="center" gap={10}>
          <Switch />
          <Typography color={theme.colors.textPurple}>Available now</Typography>
        </UIBox>
        <Dropdown
          button={
            <UIBox display="flex" alignItems="center" gap={10} minWidth={150}>
              <IconFilter />
              <Typography
                fontSize={14}
                fontWeight={600}
                color={theme.colors.textPurple}
              >
                Filters
              </Typography>
            </UIBox>
          }
        >
          <DropdownItem>filters</DropdownItem>
        </Dropdown>
      </UIBox>
      <UIBox display="flex" flexDirection="column" gap={20}>
        {workersMock.map((item, id) => (
          <SponsorInviteWorkerCard
            key={id}
            worker={item}
            onClick={() => handleOnClickWorkerCard(item.id)}
            onClickBookmark={() => null}
            onClickHire={() => setShowHireModal(item)}
            onClickInviteToJob={() => setShowInviteModal(item)}
          />
        ))}
      </UIBox>
      <UIBox display="flex" justifyContent="flex-end">
        <Pagination onChangeOffset={() => null} total={10} />
      </UIBox>
      <SidebarWorkerDetail
        role={RoleNames.SPONSOR}
        onClickInviteToJob={(worker) => setShowInviteModal(worker)}
        onClickHire={(worker) => setShowHireModal(worker)}
      />
    </UIBox>
  );
};

export default SponsorInviteWorkers;
