import {
  IconGlobalCompany,
  IconInfoCircle,
  IconPrivateOrg,
} from '@/assets/icons';
import { AppModal } from '@/components/App';
import { Typography, UIBox } from '@/components/UI';
import { theme } from '@/constants';

interface AccountDeleteConfirmModalProps {
  show: boolean;
  onClose: () => void;
}
const CompanyInfoModal: React.FC<AccountDeleteConfirmModalProps> = ({
  show,
  onClose,
}) => {
  return (
    <AppModal
      isOpen={show}
      header={
        <UIBox display="flex" alignItems="center" gap={8}>
          <IconInfoCircle width={16} height={16} /> Company
        </UIBox>
      }
      onClose={onClose}
      width={315}
    >
      <UIBox display="flex" alignItems="center" gap={10} mb={1}>
        <IconGlobalCompany />
        <Typography
          color={theme.colors.gray500}
          fontWeight={500}
          fontSize={14}
          lineHeight="17px"
        >
          Global company
        </Typography>
      </UIBox>
      <UIBox display="flex" alignItems="center" gap={10}>
        <IconPrivateOrg />
        <Typography
          color={theme.colors.gray500}
          fontWeight={500}
          fontSize={14}
          lineHeight="17px"
        >
          Private Organizations
        </Typography>
      </UIBox>
    </AppModal>
  );
};

export default CompanyInfoModal;
