import { UserJson } from '@/types';
import { faker } from '@faker-js/faker';

export function createRandomEducation(): UserJson.Education {
  return {
    id: faker.string.uuid(),
    school: faker.helpers.arrayElement([
      'CodeAcademy',
      'University of Computer Science',
    ]),
    degree: faker.helpers.arrayElement([
      'Bachelor degree',
      'Mater degree',
      'Graduate degree',
    ]),
    description: faker.lorem.paragraphs(),
    isProgress: false,
    startAt: faker.date.past({ years: 3 }).toLocaleDateString(),
    endAt: faker.date.past({ years: 2 }).toLocaleDateString(),
    createdAt: faker.date.past({ years: 1 }).toLocaleDateString(),
    updatedAt: faker.date.past({ years: 1 }).toLocaleDateString(),
  };
}
export const educationsMock: UserJson.Education[] = faker.helpers.multiple(
  createRandomEducation,
  { count: 2 }
);
