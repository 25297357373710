import {
  UIBox,
  Image,
  UIContainer,
  UIFlexCenterBox,
  UIFlexColumnBox,
  UIFlexSpaceBox,
  Typography,
} from '@/components/UI';
import { useTheme } from 'styled-components';

export default function AboutUs() {
  const theme = useTheme();
  return (
    <UIBox background={theme.colors.white} id="about_us" mb={10}>
      <UIBox position="relative" width="100%">
        <UIContainer position="relative" pt="300px">
          <Image src="/images/img_about_us_bg.png" alt="about us background" />
          <Image
            src="/images/ic_puzzle.svg"
            alt=""
            width={58}
            height={55}
            position="absolute"
            bottom="128px"
            right="90px"
          />
        </UIContainer>
        <UIBox
          position="absolute"
          top={120}
          left={0}
          width="100%"
          height="100%"
        >
          <UIContainer>
            <UIFlexSpaceBox>
              <UIFlexCenterBox flex={1} pr="24px">
                <Image
                  src="/images/img_about1.png"
                  alt="individuals"
                  width={271}
                  height={283}
                />
                <UIBox
                  backgroundColor="#E7EFF3"
                  borderRadius="20px"
                  py="20px"
                  px="10px"
                >
                  <Image
                    src="/images/img_about2.png"
                    alt="individuals"
                    width={244}
                    height={257}
                  />
                </UIBox>
              </UIFlexCenterBox>
              <UIFlexColumnBox flex={1} pl="24px">
                <Typography fontSize={18}>About us</Typography>
                <Typography variant="h2">
                  The concept for Non-Linear Careers
                </Typography>
                <Typography style={{ paddingRight: '100px' }} fontSize={18}>
                  comes from our personal experiences and those of many of our
                  close friends and colleagues as they re-engage into the
                  workforce or volunteer their time to non-profits. and success.
                </Typography>
              </UIFlexColumnBox>
            </UIFlexSpaceBox>
            <UIFlexSpaceBox>
              <UIBox
                flex={1}
                textAlign="right"
                display="flex"
                justifyContent="flex-end"
                pr="30px"
              >
                <UIBox
                  backgroundColor="#369F731F"
                  width="fit-content"
                  borderRadius="20px"
                  px="40px"
                  py="30px"
                >
                  <Image
                    src="/images/img_about3.png"
                    alt="individuals"
                    width={358}
                    height={254}
                  />
                </UIBox>
              </UIBox>
              <UIBox flex={1}>
                <Image
                  src="/images/img_about4.png"
                  alt="individuals"
                  width={441}
                  height={370}
                />
              </UIBox>
            </UIFlexSpaceBox>
            <UIFlexCenterBox>
              <UIBox width="80%" mt={3}>
                <Typography
                  fontSize={28}
                  lineHeight="40px"
                  color={theme.colors.greenLight}
                >
                  We believe there is tremendous human capital that is not on
                  the radar screen of the current job market and, as a
                  consequence, significant economic and personal costs.
                </Typography>
                <UIBox display="flex" mt={1} gap={100}>
                  <UIBox maxWidth={366}>
                    <Typography fontSize={18}>
                      Non-linear candidates come in many forms but have one
                      trait in common – they don’t have traditional backgrounds
                      for the roles they seek. This leads to their exclusion in
                      the world of algorithms and high-volume recruiting.
                    </Typography>
                    <Typography marginTop={20} fontSize={18}>
                      Non-Linear Careers is fundamentally different because it
                      is based on transferrable skills and reduces hiring risk
                      through short-term project work.
                    </Typography>
                  </UIBox>
                  <UIBox maxWidth={367}>
                    <Typography fontSize={18}>
                      While hiring organizations can benefit greatly from
                      engaging with these workers (e.g. greater diversity, a
                      larger field of choice and candidates with unique
                      capabilities) - matching the non-linear candidates with
                      internal openings is complex, time consuming and often
                      appears risky.
                    </Typography>
                  </UIBox>
                </UIBox>
              </UIBox>
            </UIFlexCenterBox>
          </UIContainer>
        </UIBox>
      </UIBox>
    </UIBox>
  );
}
