import {
  LazyLoadImage,
  LazyLoadImageProps,
} from 'react-lazy-load-image-component';
import { IconImage } from '@/assets/icons';

interface ImageProps extends LazyLoadImageProps {
  position?: 'relative' | 'absolute' | 'static' | 'sticky' | 'fixed';
  top?: number | string;
  left?: number | string;
  bottom?: number | string;
  right?: number | string;
  borderRadius?: number | string;
}

const Image: React.FC<ImageProps> = ({
  position,
  top,
  left,
  bottom,
  right,
  style,
  borderRadius,
  src,
  ...props
}) => {
  return src ? (
    <LazyLoadImage
      src={src}
      style={{
        ...(style ?? {}),
        ...(position && { position }),
        ...(left && { left }),
        ...(bottom && { bottom }),
        ...(right && { right }),
        ...(top && { top }),
        ...(borderRadius && { borderRadius }),
      }}
      {...props}
    />
  ) : (
    <IconImage width={props.width} height={props.height} />
  );
};

export default Image;
