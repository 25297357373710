import {
  IconDelete,
  IconEdit1,
  IconEllipsis,
  IconRoundedArrowDown,
  IconSearch,
  IconSwap,
} from '@/assets/icons';
import {
  UIBox,
  Dropdown,
  DropdownItem,
  IconButton,
  Input,
  Pagination,
  UITable,
  UITh,
  UIThead,
  UITr,
  Typography,
} from '@/components/UI';
import { DropdownButton } from '@/components/UI/Dropdown';
import { theme } from '@/constants';
import { RoleNames } from '@/enums';

import { SidebarWorkerDetail } from '@/modules/Job';
import { useSearchParams } from 'react-router-dom';
import { workersMock } from '@/mock';
import { AppUserAvatar, AppUserStatusTag } from '@/components/App';

const OrgWorkersPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickWorkerCard = (workerId: string) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.workerId = `${workerId}`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <UIBox>
      <UIBox display="flex" justifyContent="space-between">
        <Typography variant="h5">Workers</Typography>
        <UIBox display="flex" gap={20}>
          <Input
            placeholder={
              <>
                <IconSearch color={theme.colors.icon} />
                Search
              </>
            }
            height={40}
          />
          <Dropdown
            button={
              <UIBox display="flex" alignItems="center" width={200} gap={10}>
                <IconSwap /> Status
              </UIBox>
            }
            height={40}
          >
            <DropdownItem>Status</DropdownItem>
          </Dropdown>
        </UIBox>
      </UIBox>
      <UIBox>
        <UITable width="100%">
          <UIThead>
            <tr>
              <UITh align="left">ID</UITh>
              <UITh></UITh>
              <UITh align="left">Name</UITh>
              <UITh align="left">Email</UITh>
              <UITh align="left">Specialization</UITh>
              <UITh align="left">Role</UITh>
              <UITh align="center">Status</UITh>
              <UITh align="right">Joined at</UITh>
              <UITh></UITh>
            </tr>
          </UIThead>
          <tbody>
            {workersMock.map((item, id) => (
              <UITr
                key={id}
                onClick={() => handleOnClickWorkerCard(item.id)}
                style={{ cursor: 'pointer' }}
              >
                <td width={30}>
                  <Typography
                    color={theme.colors.textPrimary}
                    fontWeight={500}
                    fontSize={14}
                  >
                    {id + 1}
                  </Typography>
                </td>
                <td width={30}>
                  <AppUserAvatar user={item} size={30} />
                </td>
                <td>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {item.firstName} {item.lastName}
                  </Typography>
                </td>
                <td>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {item.email}
                  </Typography>
                </td>
                <td>
                  <Dropdown
                    button={
                      <UIBox display="flex" alignItems="center" gap={10}>
                        <IconRoundedArrowDown opacity={0.2} />
                        <Typography
                          variant="span"
                          color={theme.colors.textPrimary}
                          fontWeight={500}
                        >
                          Full Stack Engineer
                        </Typography>
                      </UIBox>
                    }
                    disableDefaultToggleStyle
                  >
                    <DropdownItem>
                      <Typography
                        variant="span"
                        color={theme.colors.textPrimary}
                      >
                        Tech Lead
                      </Typography>
                    </DropdownItem>
                    <DropdownItem>
                      <Typography
                        variant="span"
                        color={theme.colors.textPrimary}
                      >
                        Senior Front JS
                      </Typography>
                    </DropdownItem>
                  </Dropdown>
                </td>
                <td>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    Worker
                  </Typography>
                </td>
                <td align="center" width={82}>
                  <AppUserStatusTag status={item.status} />
                </td>
                <td align="right">
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {new Date(item.createdAt).toLocaleDateString()}
                  </Typography>
                </td>
                <td align="right">
                  <Dropdown
                    button={
                      <IconButton>
                        <IconEllipsis />
                      </IconButton>
                    }
                    disableDefaultToggleStyle
                  >
                    <DropdownButton variant="primary">
                      <IconEdit1 />
                      Edit
                    </DropdownButton>
                    <DropdownButton variant="danger">
                      <IconDelete width={8} height={9} />
                      Delete
                    </DropdownButton>
                  </Dropdown>
                </td>
              </UITr>
            ))}
          </tbody>
        </UITable>
        <UIBox display="flex" justifyContent="flex-end" mt={1}>
          <Pagination total={10} offset={1} />
        </UIBox>
      </UIBox>
      <SidebarWorkerDetail role={RoleNames.ORG_ADMIN} />
    </UIBox>
  );
};

export default OrgWorkersPage;
