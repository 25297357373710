import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { IconButton } from '@/components/UI';
import { IconCopy } from '@/assets/icons';

interface CopyToClipboardProps {
  value: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ value }) => {
  const [copied, setCopied] = useState(false);
  const handleOnCopy = async () => {
    await navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  const id = `copy-to-clipboard-${value}`;

  return (
    <IconButton onClick={handleOnCopy} data-tooltip-id={id}>
      <Tooltip place="top" content={copied ? 'Copied' : 'Copy'} id={id} />
      <IconCopy />
    </IconButton>
  );
};

export default CopyToClipboard;
