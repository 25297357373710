import {
  OptionsWithExtraProps,
  SnackbarKey,
  SnackbarMessage,
  VariantType,
  useSnackbar,
} from 'notistack';
import React, { createContext, ReactNode } from 'react';

interface ToastContextType<V extends VariantType> {
  showToast: (
    message: SnackbarMessage,
    options?: OptionsWithExtraProps<V>
  ) => void;
  closeToast: (key?: SnackbarKey) => void;
}

export const ToastContext = createContext<ToastContextType<VariantType>>({
  showToast: () => '',
  closeToast: () => null,
});

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  function handleShowToast(
    message: SnackbarMessage,
    options?: OptionsWithExtraProps<VariantType>
  ) {
    return enqueueSnackbar(message, {
      ...options,
      // content: (key, message) => {
      //   const variant = options?.variant;
      //   switch (variant) {
      //     case 'success':
      //       return <SuccessSnackbar>{message}</SuccessSnackbar>;
      //     case 'error':
      //       return <ErrorSnackbar>{message}</ErrorSnackbar>;
      //     case 'warning':
      //       return <WarningSnackbar>{message}</WarningSnackbar>;
      //     case 'info':
      //       return <InfoSnackbar>{message}</InfoSnackbar>;
      //     default:
      //       return <DefaultSnackbar>{message}</DefaultSnackbar>;
      //   }
      // },
    });
  }
  return (
    <ToastContext.Provider
      value={{
        showToast: handleShowToast,
        closeToast: closeSnackbar,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
