import { ProfileEditButton } from '@/modules/Shared';
import {
  UIBox,
  UICard,
  Typography,
  RichEditor,
  SectionSpinner,
} from '@/components/UI';
import { OrganizationJson } from '@/types';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useUpdateOrganizationMutation } from '@/redux/apis';
import { useFormik } from 'formik';
import { inputStatus } from '@/utils';

const validationSchema = yup.object({
  bio: yup.string().required('Required.'),
});
type FormikData = Pick<OrganizationJson.OrganizationInput, 'id' | 'bio'>;
interface Props {
  loading?: boolean;
  editable?: boolean;
  company?: OrganizationJson.Organization;
  refetch?: () => void;
}
const CompanyAboutSection: React.FC<Props> = ({
  company,
  editable,
  loading,
  refetch,
}) => {
  const [editing, setEditing] = useState(false);
  const [updateOrg, { isSuccess, isLoading: updating }] =
    useUpdateOrganizationMutation();
  const formik = useFormik<FormikData>({
    initialValues: {
      id: company?.id,
      bio: company?.bio || '',
    },
    validationSchema,
    onSubmit: async (v) => {
      await updateOrg(v);
    },
  });
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnCancel = () => {
    setEditing(false);
  };

  useEffect(() => {
    if (isSuccess) {
      refetch?.();
      setEditing(false);
    }
  }, [updating, isSuccess]);
  return (
    <UICard>
      <UIBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h5" marginBottom={0}>
          About
        </Typography>
        {editable && (
          <ProfileEditButton
            editing={editing}
            saving={updating}
            onClickSave={() => formik.handleSubmit()}
            onClickCancel={handleOnCancel}
            onClickEdit={handleOnEdit}
          />
        )}
      </UIBox>
      {loading && <SectionSpinner />}
      {!editing && (
        <>
          {(company?.bio || '').split('\n').map((text, id) => (
            <Typography key={id}>{text}</Typography>
          ))}
        </>
      )}
      {editing && (
        <RichEditor
          defaultValue={formik.values.bio}
          onChange={(v) => formik.setFieldValue('bio', v)}
          onBlur={() => formik.setFieldTouched('bio', true)}
          {...inputStatus(formik, 'bio')}
        />
      )}
    </UICard>
  );
};

export default CompanyAboutSection;
