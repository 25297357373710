import {
  IconArrowDown,
  IconArrowSquareOut,
  IconClose,
  IconInfoCircle,
  IconNotification,
} from '@/assets/icons';
import {
  UIBox,
  Button,
  Dropdown,
  IconButton,
  ReadMoreButton,
  Typography,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { Link, useNavigate } from 'react-router-dom';
import { Notification } from '@/modules/Notification';
import { useAuth } from '@/hooks';
import { RoleNames } from '@/enums';
const NotificationsDropdown: React.FC = () => {
  const { auth } = useAuth();
  const isOrg = auth?.roleName === RoleNames.ORG_ADMIN;
  const isSponsor = auth?.roleName === RoleNames.SPONSOR;
  const isAdmin =
    auth?.roleName === RoleNames.SUPER_ADMIN ||
    auth?.roleName === RoleNames.SITE_ADMIN;
  const navigate = useNavigate();
  return (
    <Dropdown
      button={
        <IconButton>
          <IconNotification />
        </IconButton>
      }
      disableDefaultToggleStyle
      disableCloseMenuOnClickItem
    >
      <UIBox width={430}>
        <UIBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={1}
          py={1}
        >
          <Typography fontWeight={500} color={theme.colors.textPrimary}>
            Notifications
          </Typography>
          <UIBox display="flex" gap={20}>
            <Link
              to={
                isAdmin
                  ? appRoutes.adminNotifications
                  : isOrg
                    ? appRoutes.orgNotifications
                    : isSponsor
                      ? appRoutes.sponsorNotifications
                      : appRoutes.workerNotifications
              }
              target="_blank"
            >
              <IconButton>
                <IconArrowSquareOut />
              </IconButton>
            </Link>
            <IconButton>
              <IconClose width={16} height={16} color={theme.colors.primary} />
            </IconButton>
          </UIBox>
        </UIBox>
        <UIBox display="flex" flexDirection="column" gap={6}>
          <Notification category="note">
            <UIBox display="flex" gap={20}>
              <UIBox width={20} pt={0.2}>
                <IconInfoCircle
                  width={20}
                  height={20}
                  color={theme.colors.blue400}
                />
              </UIBox>
              <UIBox display="flex" flexDirection="column" gap={10}>
                <Typography
                  fontSize={14}
                  lineHeight="17px"
                  fontWeight={600}
                  color={theme.colors.textPrimary}
                >
                  Note
                </Typography>
                <Typography
                  fontSize={14}
                  lineHeight="17px"
                  fontWeight={500}
                  color={theme.colors.textDescription}
                >
                  This product is under active development and is available in a
                  Private Beta, please contact NL support for access.
                </Typography>
                <UIBox width={100}>
                  <Button size="sm" variant="outlined-secondary" fullWidth>
                    Accept
                  </Button>
                </UIBox>
              </UIBox>
            </UIBox>
          </Notification>
          <Notification category="success">
            <Typography
              fontSize={14}
              lineHeight="17px"
              fontWeight={500}
              color={theme.colors.textDescription}
            >
              <Typography color={theme.colors.green700} variant="span">
                New job added!
              </Typography>{' '}
              Create stunning content with our latest designs.
            </Typography>
          </Notification>
          <Notification category="danger">
            <UIBox display="flex" gap={20}>
              <UIBox width={20} pt={0.2}>
                <IconInfoCircle
                  width={20}
                  height={20}
                  color={theme.colors.blue400}
                />
              </UIBox>
              <Typography
                fontSize={14}
                lineHeight="17px"
                fontWeight={500}
                color={theme.colors.textDescription}
              >
                <Typography color={theme.colors.red800} variant="span">
                  Account Security Alert :
                </Typography>{' '}
                Suspicious login detected. Verify recent activity to secure your
                account.
              </Typography>
            </UIBox>
          </Notification>
          <Notification category="success">
            <Typography
              fontSize={14}
              lineHeight="17px"
              fontWeight={500}
              color={theme.colors.textDescription}
            >
              <Typography color={theme.colors.green700} variant="span">
                New job added!
              </Typography>{' '}
              Create stunning content with our latest designs.
            </Typography>
          </Notification>
        </UIBox>
        <UIBox display="flex" justifyContent="center" mt={1.5} mb={0.5}>
          <ReadMoreButton
            color={theme.colors.icon}
            onClick={() => navigate(appRoutes.sponsorNotifications)}
          >
            Read more <IconArrowDown color={theme.colors.icon} />
          </ReadMoreButton>
        </UIBox>
      </UIBox>
    </Dropdown>
  );
};

export default NotificationsDropdown;
