import styled from 'styled-components';
import { IconCloudUpload } from '@/assets/icons';
import { ProgressBar, StatusAlert, Typography, UIBox } from '@/components/UI';
import { theme } from '@/constants';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useState } from 'react';
import FilePreview from './FilePreview';

type DropzoneActions = {
  isDragAccept: boolean;
  isDragReject: boolean;
  isDragActive: boolean;
};
const getColor = (props: DropzoneActions) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#d6dfe680';
};

const StyledFileDropzoneContainer = styled.div<DropzoneActions>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f7f9fa;
  border: 1px solid ${(props) => getColor(props)};
  border-radius: 12px;
  padding: 40px 20px;
`;

interface FileDropzoneProps {
  label?: string;
  description?: string;
  options?: DropzoneOptions;
  multi?: boolean;
  uploading?: boolean;
  progress?: number; // this should be between 0 ~ 100
  onChangeFiles?: (files: File[]) => void;
  error?: string;
}
const FileDropzone: React.FC<FileDropzoneProps> = ({
  label = 'Click or drag to upload your file',
  description = '*.pdf / *.doc (2 MB)',
  onChangeFiles,
  multi,
  uploading,
  progress = 0,
  options = {
    accept: {
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [],
    },
  },
  error,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      let newFiles = [];
      if (multi) {
        newFiles = [...files, ...acceptedFiles];
      } else {
        newFiles = [acceptedFiles[0]];
      }
      setFiles(newFiles);
      onChangeFiles?.(newFiles);
    },
    ...options,
  });
  function handleDeleteFile(id: number) {
    const newFiles = [...files];
    newFiles.splice(id, 1);
    setFiles(newFiles);
  }
  return (
    <UIBox>
      <StyledFileDropzoneContainer
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        {!files.length && (
          <>
            <IconCloudUpload />
            <Typography textAlign="center" color={theme.colors.primary}>
              {label}
            </Typography>
            <Typography textAlign="center" color={theme.colors.companyBrand}>
              {description}
            </Typography>
          </>
        )}

        <UIBox display="flex" flexDirection="column" gap={10} width="100%">
          {files.map((file, id) => (
            <FilePreview
              key={id}
              file={file}
              onClose={() => handleDeleteFile(id)}
            />
          ))}
        </UIBox>
      </StyledFileDropzoneContainer>
      {error && <StatusAlert status="error">{error}</StatusAlert>}
      {uploading && (
        <UIBox my={1}>
          <ProgressBar
            value={progress}
            label={`${progress}% of uploading is completed`}
          />
        </UIBox>
      )}
    </UIBox>
  );
};

export default FileDropzone;
