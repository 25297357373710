import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const AnchorScrollingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      const id = hash.replace('#', '');
      const timer = setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          const yOffset = -70;
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [hash]);

  return <>{children}</>;
};

export default AnchorScrollingProvider;
