import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconCamera({
  width = 15,
  height = 11,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33137 0.1875H7.43408C9.14968 0.1875 10.3475 1.36973 10.3475 3.06398V7.93602C10.3475 9.63027 9.14968 10.8125 7.43408 10.8125H3.33137C1.61577 10.8125 0.417969 9.63027 0.417969 7.93602V3.06398C0.417969 1.36973 1.61577 0.1875 3.33137 0.1875ZM13.1382 1.87261C13.4492 1.71441 13.814 1.73095 14.1108 1.91792C14.4075 2.10417 14.5846 2.42777 14.5846 2.78158V8.21885C14.5846 8.57337 14.4075 8.89626 14.1108 9.08251C13.9485 9.18391 13.7672 9.23569 13.5845 9.23569C13.4322 9.23569 13.2799 9.19973 13.1375 9.1271L12.0884 8.59783C11.7003 8.40079 11.4594 8.00527 11.4594 7.56589V3.43382C11.4594 2.99372 11.7003 2.59821 12.0884 2.40261L13.1382 1.87261Z"
        fill={color}
      />
    </svg>
  );
}
