import { IconRightArrow } from '@/assets/icons';
import {
  UIBox,
  UICard,
  UIGrid,
  Image,
  ReadMoreButton,
  Typography,
} from '@/components/UI';
import { individuals } from '@/constants';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

export default function Individuals() {
  const [readMore, setReadMore] = useState<boolean[]>([]);
  const theme = useTheme();
  useEffect(() => {
    const tempReadMore = [];
    for (let i = 0; i < individuals.length; i++) {
      tempReadMore.push(false);
    }
    setReadMore(tempReadMore);
  }, []);

  return (
    <UIGrid columns={2} gap={30}>
      {individuals.map((item, index) => (
        <UICard
          display="flex"
          flexDirection="column"
          borderRadius={20}
          boxShadow="0px 0px 100px 0px #00000012"
          key={index}
        >
          <UIBox mb={2}>
            <UIBox display="flex" alignItems="center">
              <Image
                src={item.src}
                alt={`team-${item.id}`}
                width={60}
                height={60}
                style={{ borderRadius: '50%', padding: '1px' }}
              />
              <UIBox ml="16px" textAlign="left">
                <Typography
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                  fontSize="20px"
                >
                  {item.name}
                </Typography>
                <Typography fontSize="16px">{item.summary}</Typography>
              </UIBox>
            </UIBox>
            <UIBox mt={2} mb={2}>
              <Typography fontSize={18}>{item.desc}</Typography>
            </UIBox>
            {item.degree.map((degree, index) => (
              <Typography key={index}>{degree}</Typography>
            ))}
          </UIBox>
          {readMore[index] && (
            <UIBox mt={0} mb={2}>
              <Typography>{item.more}</Typography>
            </UIBox>
          )}
          <UIBox flexGrow={1} />
          <ReadMoreButton>
            <span
              onClick={() => {
                const tempReadMore: boolean[] = Object.assign([], readMore);
                tempReadMore[index] = !readMore[index];
                setReadMore(tempReadMore);
              }}
            >
              {readMore[index] ? 'Less' : 'Read More'} &nbsp;&nbsp;
              {!readMore[index] && (
                <IconRightArrow color={theme.colors.greenLight} />
              )}
            </span>
          </ReadMoreButton>
        </UICard>
      ))}
    </UIGrid>
  );
}
