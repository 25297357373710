import { theme } from '@/constants';
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { IconRoundedArrowDown } from '@/assets/icons';
import { UIBox } from './Box';

const StyledDropdownContainer = styled.div<{ $fullWidth?: boolean }>`
  position: relative;
  ${(props) => (props.$fullWidth ? 'width: 100%;' : '')}
`;

const StyledDropdownButtonContainer = styled.div`
  width: 100%;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 40px;
  box-shadow: ${theme.colors.lightBoxShadow};
  background: ${theme.colors.white};
  gap: 10px;
  height: 44px;
  color: ${theme.colors.textPrimary};
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
`;

const StyledDropdownToggleContainer = styled.div`
  cursor: pointer;
  color: ${theme.colors.textPrimary};
  font-size: 14px;
  font-weight: 600;
`;

const StyledDropdownArrowContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledDropdownMenu = styled.div<{ open: boolean }>`
  z-index: 99;
  position: absolute;
  display: block;
  visibility: ${({ open }) => (open ? 'show' : 'hidden')};
  transition: all ease 0.3s;
  top: calc(100% + 8px);
  right: 0;
  min-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  width: max-content;
  background: ${theme.colors.white};
  color: ${theme.colors.textPrimary};
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  box-shadow: 0px 4px 30px 0px #7b7d9633;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const DropdownItem = styled(UIBox)`
  cursor: pointer;
  padding: 10px 20px;
  background-color: ${theme.colors.white};
  &:hover {
    background-color: ${theme.colors.gray960};
  }
`;
interface DropdownButtonProps {
  variant?: 'primary' | 'secondary' | 'danger';
}

export const DropdownButton = styled(
  ({
    variant,
    ...rest
  }: DropdownButtonProps &
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >) => <button {...rest} />
)<DropdownButtonProps>`
  font-family: Gantari;
  border: none;
  min-width: 115px;
  margin: 0 10px;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 5px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: ${({ variant }) =>
    variant === 'danger'
      ? theme.colors.red600
      : variant === 'secondary'
        ? theme.colors.icon
        : theme.colors.blue300};
  background-color: ${({ variant }) =>
    variant === 'danger'
      ? '#fef4f5'
      : variant === 'secondary'
        ? '#f7f7f8'
        : '#f7faff'};
`;

interface DropdownProps extends PropsWithChildren {
  button: React.ReactNode;
  height?: number;
  disableDefaultToggleStyle?: boolean;
  fullWidth?: boolean;
  disableCloseMenuOnClickItem?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  button,
  children,
  height,
  disableCloseMenuOnClickItem,
  disableDefaultToggleStyle,
  fullWidth,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleOnClickOutside = (event: MouseEvent) => {
    if (disableCloseMenuOnClickItem) {
      if (
        dropdownRef.current &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleOnClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleOnClickOutside);
    };
  }, []);
  return (
    <StyledDropdownContainer ref={dropdownRef} $fullWidth={fullWidth}>
      {!disableDefaultToggleStyle ? (
        <StyledDropdownButtonContainer
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          style={{ height: height }}
        >
          {button}
          <StyledDropdownArrowContainer>
            <IconRoundedArrowDown opacity={0.2} />
          </StyledDropdownArrowContainer>
        </StyledDropdownButtonContainer>
      ) : (
        <StyledDropdownToggleContainer
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {button}
        </StyledDropdownToggleContainer>
      )}
      <StyledDropdownMenu open={open}>{children}</StyledDropdownMenu>
    </StyledDropdownContainer>
  );
};

export default Dropdown;
