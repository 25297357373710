import { UIBox, IconButton, Image, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { IconDelete, IconInfoCircle } from '@/assets/icons';
import { ProjectBudgetType } from '@/enums';
import { ProjectJson } from '@/types';
import { formatCurrency } from '@/utils';

interface ProjectCardProps {
  editable?: boolean;
  project: ProjectJson.Project;
  onClickDelete?: () => void;
}
const ProjectCard: React.FC<ProjectCardProps> = ({
  editable,
  project,
  onClickDelete,
}) => {
  return (
    <UIBox
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      height={195}
      position="relative"
      background={theme.colors.gray950}
      borderRadius={10}
      p={1.5}
    >
      {editable && (
        <UIBox position="absolute" right={15} top={15}>
          <IconButton onClick={onClickDelete}>
            <IconDelete />
          </IconButton>
        </UIBox>
      )}
      <UIBox display="flex" gap={10} alignItems="center">
        <Image src="/images/apple-logo.png" width={12} height={15} />
        <Typography
          fontSize={14}
          lineHeight="19px"
          color={theme.colors.gray500}
        >
          Apple
        </Typography>
      </UIBox>
      <Typography color={theme.colors.textPrimary} fontWeight={600}>
        {project.title}
      </Typography>
      <Typography color={theme.colors.textDescription}>
        {project.employmentType}
      </Typography>
      <Typography color={theme.colors.textPrimary}>
        {project.budget === ProjectBudgetType.HOURLY
          ? `${formatCurrency(project.minRate)} - ${formatCurrency(project.maxRate)}`
          : `${formatCurrency(project.minSalary)} - ${formatCurrency(project.maxSalary)}`}
      </Typography>
      <UIBox display="flex" alignItems="center" gap={10} transform="low">
        <IconInfoCircle />
        <Typography textTransform="lowercase">est. {project.budget}</Typography>
      </UIBox>
    </UIBox>
  );
};

export default ProjectCard;
