import { UIBox, Input, UIDivider, LocationAutocomplete } from '@/components/UI';
import { appConfig } from '@/constants';
import { useAuth } from '@/hooks';
import { CancelSaveConfirmModal, ProfileEditButton } from '@/modules/Shared';
import { useUpdateUserMutation } from '@/redux/apis';
import { AppTypes, UserJson } from '@/types';
import { formatAddressFromLocation, geocodeAddress } from '@/utils';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

type FormikData = Omit<
  UserJson.UserInfoInput,
  'profession' | 'summary' | 'firstName' | 'lastName'
>;
const validationSchema = Yup.object({});

const AccountChangeInfoForm: React.FC = () => {
  const { user, refetchUser } = useAuth();
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [updateUserInfo, { isLoading: saving, isSuccess: isSaved }] =
    useUpdateUserMutation();
  const initialValues: FormikData = {
    id: user?.id || '',
    userName: user?.userName || '',
    phone: user?.phone || '',
    address1: user?.address1,
    address2: user?.address2,
    zipcode: user?.zipcode,
    city: user?.city,
    state: user?.state,
    country: user?.country,
  };
  const formik = useFormik<FormikData>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await updateUserInfo(values);
    },
  });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: appConfig.GOOGLE_MAP_API_KEY,
    libraries: appConfig.libraries,
  });
  const [center, setCenter] = useState<AppTypes.Position | undefined>(
    undefined
  );
  useEffect(() => {
    const loadPosition = async () => {
      const address = formatAddressFromLocation(user);
      const position = await geocodeAddress(address);
      setCenter(position);
    };
    if (user) {
      loadPosition();
      const initialValues: FormikData = {
        id: user?.id || '',
        userName: user?.userName || '',
        phone: user?.phone || '',
        address1: user?.address1,
        address2: user?.address2,
        zipcode: user?.zipcode,
        city: user?.city,
        state: user?.state,
        country: user?.country,
      };
      formik.setValues(initialValues);
    }
  }, [user]);
  useEffect(() => {
    if (isSaved) {
      refetchUser();
      setEditing(false);
    }
  }, [isSaved]);
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnCancel = () => {
    setShowCancelConfirmModal(true);
  };
  return (
    <UIBox>
      <UIBox display="flex" justifyContent="flex-end" mb={1}>
        <ProfileEditButton
          editing={editing}
          onClickSave={() => formik.handleSubmit()}
          onClickCancel={handleOnCancel}
          onClickEdit={handleOnEdit}
          fullWidth
          saving={saving}
        />
      </UIBox>
      <UIBox display="flex" gap={30}>
        <UIBox display="flex" flexDirection="column" gap={16} flexGrow={1}>
          <Input
            label="Account Type"
            fullWidth
            variant="secondary"
            labelSize={14}
            defaultValue={user?.role?.name}
            readOnly
          />
          <Input
            label="Username"
            fullWidth
            variant="secondary"
            labelSize={14}
            {...formik.getFieldProps('userName')}
            disabled={!editing}
          />
          <Input
            label="Email"
            fullWidth
            variant="secondary"
            labelSize={14}
            defaultValue={user?.email}
            readOnly
          />
          <Input
            label="Phone number"
            fullWidth
            variant="secondary"
            labelSize={14}
            {...formik.getFieldProps('phone')}
            disabled={!editing}
          />
        </UIBox>
        <UIDivider vertical height={300} style={{ alignSelf: 'center' }} />
        <UIBox
          flexGrow={1}
          display="flex"
          flexDirection="column"
          gap={30}
          height="100%"
        >
          <LocationAutocomplete
            label="Search Location"
            fullWidth
            variant="secondary"
            value={formatAddressFromLocation(formik.values)}
            onChange={(formattedAddress, position, location) => {
              setCenter(position);
              formik.setValues({
                ...formik.values,
                ...location,
              });
            }}
            disabled={!editing}
            onBlur={() => formik.setFieldTouched('state')}
            status={
              formik.touched.state &&
              (formik.errors.country ||
                formik.errors.state ||
                formik.errors.city)
                ? 'error'
                : undefined
            }
            statusLabel={
              (formik.touched.state &&
                (formik.errors.country ||
                  formik.errors.state ||
                  formik.errors.city)) ||
              ''
            }
          />
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '380px',
              }}
              center={center}
              zoom={10}
              options={{
                fullscreenControl: false,
              }}
            >
              {center && <Marker position={center} />}
            </GoogleMap>
          )}
        </UIBox>
      </UIBox>
      <CancelSaveConfirmModal
        show={showCancelConfirmModal}
        onClose={() => {
          setShowCancelConfirmModal(false);
          formik.resetForm();
        }}
        onCancel={() => {
          setShowCancelConfirmModal(false);
          setEditing(false);
          formik.resetForm();
        }}
      />
    </UIBox>
  );
};

export default AccountChangeInfoForm;
