import { AppSideModal } from '@/components/App';
import { IconEdit, IconSearch } from '@/assets/icons';
import {
  Autocomplete,
  UIBox,
  Button,
  Input,
  RichEditor,
  Typography,
  IconButton,
  Tag,
  UIGrid,
  SectionSpinner,
  Spinner,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import CancelSaveConfirmModal from './CancelSaveConfirmModal';
import NewIndustryPublishedModal from './NewIndustryPublishedModal';
import {
  useCreateIndustryMutation,
  useIndustryParentsQuery,
  useIndustryQuery,
  useIndustrySkillsQuery,
  useSkillsQuery,
  useUpdateIndustryMutation,
  useUpdateSkillMutation,
} from '@/redux/apis';
import { useFormik } from 'formik';
import { IndustryJson, SkillJson } from '@/types';
import * as yup from 'yup';
import { inputStatus } from '@/utils';
type FormikData = IndustryJson.IndustryInput;
const initialValues: FormikData = {
  name: '',
  description: '',
  parentId: '',
  isDefault: false,
};
const validationSchema = yup.object({
  name: yup.string().required('Industry name is required'),
});
const SidebarIndustry: React.FC<{
  show: boolean;
  setShow: (show: boolean) => void;
}> = ({ show, setShow }) => {
  const [submitted, setSubmitted] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPublishedModal, setShowPublishedModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSkills, setSelectedSkills] = useState<SkillJson.Skill[]>([]);
  const industryId = searchParams.get('industryId') || '';

  const formik = useFormik<IndustryJson.IndustryInput>({
    initialValues,
    validationSchema,
    onSubmit: (v) => {
      setSubmitted(true);
      if (industryId) {
        updateIndustry(v);
      } else {
        createIndustry(v);
      }
    },
  });

  const isCreateMode = !industryId;
  const [
    createIndustry,
    { isLoading: isCreating, data: createdIndustry, isSuccess: isCreated },
  ] = useCreateIndustryMutation();
  const [
    updateIndustry,
    { isLoading: isUpdating, data: updatedIndustry, isSuccess: isUpdated },
  ] = useUpdateIndustryMutation();
  const [updateSkill] = useUpdateSkillMutation();
  const {
    data: industry,
    isLoading,
    refetch,
  } = useIndustryQuery(
    {
      id: industryId,
    },
    { skip: !industryId }
  );
  const {
    data: industrySkills = [],
    refetch: refetchIndustrySkills,
    isUninitialized: isNotFetchedYet,
  } = useIndustrySkillsQuery(
    {
      id: industryId,
    },
    { skip: !industryId }
  );
  const {
    data: { data: nonIndustrySkills = [] } = {},
    refetch: refetchNonIndustrySkills,
  } = useSkillsQuery({
    hasIndustry: 'false',
    limit: 10000,
  });
  const { data: parentIndustries = [], isLoading: loadingParentIndustries } =
    useIndustryParentsQuery();
  useEffect(() => {
    if (industryId) {
      setShow(true);
      setEditMode(false);
      refetch();
      refetchIndustrySkills();
    } else {
      setEditMode(true);
      formik.resetForm();
    }
  }, [industryId]);
  useEffect(() => {
    if (show)
      formik.setValues(
        industryId && industry
          ? {
              id: industry.id,
              name: industry.name,
              description: industry.description,
              parentId: industry.parent?.id,
              isDefault: !!industry.isDefault,
            }
          : initialValues
      );
  }, [industry, show, industryId]);
  const handleCloseSideModal = () => {
    setShow(false);
    setShowConfirmModal(false);
    setSubmitted(false);
    setShowPublishedModal(false);
    setSelectedSkills([]);
    formik.resetForm();
    const { industryId, ...currentSearchParams } =
      Object.fromEntries(searchParams);
    setSearchParams(new URLSearchParams(currentSearchParams));
  };

  const updateIndustrySkills = async (
    industry: IndustryJson.Industry | undefined
  ) => {
    await Promise.all(
      selectedSkills.map((skill) =>
        updateSkill({
          id: skill.id,
          industryId: industry?.id,
          isDefault: false,
        })
      )
    );
    setSelectedSkills([]);
    setShowPublishedModal(true);
    if (!isNotFetchedYet) refetchIndustrySkills();
    refetchNonIndustrySkills();
  };
  useEffect(() => {
    if (isCreated && submitted) {
      if (selectedSkills.length > 0) {
        updateIndustrySkills(createdIndustry);
      } else {
        setShowPublishedModal(true);
      }
    }
  }, [isCreated, submitted, createdIndustry]);
  useEffect(() => {
    if (isUpdated && submitted) {
      if (selectedSkills.length > 0) {
        const updateIndustrySkills = async () => {
          await Promise.all(
            selectedSkills.map((skill) =>
              updateSkill({
                id: skill.id,
                industryId: updatedIndustry?.id,
                isDefault: false,
              })
            )
          );
          setSelectedSkills([]);
          setShowPublishedModal(true);
          refetchIndustrySkills();
          refetchNonIndustrySkills();
        };
        updateIndustrySkills();
      } else {
        setShowPublishedModal(true);
      }
    }
  }, [isUpdated, updatedIndustry, submitted]);
  async function handleDeleteSkill(skill: SkillJson.Skill) {
    await updateSkill({ id: skill.id, industryId: null, isDefault: false });
    refetchIndustrySkills();
    refetchNonIndustrySkills();
  }
  const curIndustrySkills = industryId ? industrySkills : [];
  return (
    <AppSideModal
      isOpen={show}
      width={730}
      onClose={() =>
        editMode && formik.dirty
          ? setShowConfirmModal(true)
          : handleCloseSideModal()
      }
      customTools={
        !editMode && (
          <IconButton onClick={() => setEditMode(true)}>
            <IconEdit />
          </IconButton>
        )
      }
    >
      {isLoading ? (
        <SectionSpinner />
      ) : (
        <>
          {!editMode && (
            <UIBox
              display="flex"
              flexDirection="column"
              gap={30}
              px={1.5}
              py={2}
            >
              <UIBox>
                <Typography variant="h6" fontSize={20} marginBottom={0}>
                  {industry?.name}
                </Typography>
                <Typography>{industry?.parent?.name}</Typography>
              </UIBox>

              <UIBox>
                <Typography variant="h6" marginBottom={10}>
                  Description
                </Typography>
                {industry?.description?.split('\n').map((desc, id) => (
                  <Typography key={id} marginBottom={10}>
                    {desc}
                  </Typography>
                ))}
              </UIBox>
              <UIBox>
                <Typography variant="h6" marginBottom={10}>
                  Skills
                </Typography>
                <UIBox display="flex" flexWrap="wrap" gap={10}>
                  {curIndustrySkills?.map((skill, item) => (
                    <Tag key={item}>{skill.name}</Tag>
                  ))}
                </UIBox>
              </UIBox>
            </UIBox>
          )}
          {editMode && (
            <>
              <UIBox
                display="flex"
                flexDirection="column"
                gap={30}
                px={1.5}
                py={2}
                pb={5}
                minHeight={'calc(100vh - 60px)'}
              >
                <Typography variant="h6" fontSize={20} marginBottom={0}>
                  {isCreateMode ? '+Add new Industry' : industry?.name}
                </Typography>
                <UIGrid columns={2} gap={20}>
                  <Input
                    label="Industry name"
                    variant="secondary"
                    {...formik.getFieldProps('name')}
                    {...inputStatus(formik, 'name')}
                    height={40}
                    fullWidth
                  />
                  <Autocomplete
                    label="Parent name"
                    options={parentIndustries}
                    isLoading={loadingParentIndustries}
                    value={parentIndustries.find(
                      (item) => item.id === formik.values.parentId
                    )}
                    onChange={(v) => formik.setFieldValue('parentId', v?.id)}
                    getOptionLabel={(v) => v.name}
                    getOptionValue={(v) => v.id}
                    height={40}
                    fullWidth
                  />
                </UIGrid>
                <RichEditor
                  label="Description"
                  defaultValue={formik.values.description}
                  onChange={(v) => formik.setFieldValue('description', v)}
                />
                <UIBox>
                  <Autocomplete
                    label="Skills"
                    placeholder={
                      <UIBox display="flex" gap={10}>
                        <IconSearch /> Search
                      </UIBox>
                    }
                    height={40}
                    fullWidth
                    isMulti
                    isClearable
                    closeMenuOnSelect={false}
                    value={selectedSkills}
                    options={nonIndustrySkills}
                    getOptionLabel={(v) => v.name}
                    getOptionValue={(v) => v.id}
                    onChange={(v) => setSelectedSkills([...v])}
                  />
                  <UIBox display="flex" flexWrap="wrap" gap={10} mt={1}>
                    {curIndustrySkills?.map((skill, item) => (
                      <Tag
                        closable
                        key={item}
                        onClose={() => handleDeleteSkill(skill)}
                      >
                        {skill.name}
                      </Tag>
                    ))}
                  </UIBox>
                </UIBox>
                {
                  <Link to={appRoutes.adminSkills + '?skillId=new'}>
                    <Button variant="secondary" fullWidth size="md">
                      +Add more skills
                    </Button>
                  </Link>
                }
              </UIBox>

              <UIBox
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                background={theme.colors.white}
                boxShadow="10px -1px 30px 0px #7B7D9633"
                py={1}
                px={1.5}
                display="flex"
                gap={10}
              >
                <UIBox width={'50%'}>
                  <Button
                    fullWidth
                    variant="primary"
                    size="md"
                    onClick={() => formik.handleSubmit()}
                    disabled={isCreating || isUpdating}
                  >
                    {(isCreating || isUpdating) && <Spinner size="sm" />}
                    {!isCreateMode ? 'Save' : 'Add industries'}
                  </Button>
                </UIBox>
                <UIBox width={'50%'}>
                  <Button
                    fullWidth
                    variant="outlined-primary"
                    size="md"
                    onClick={() => {
                      formik.resetForm();
                      if (isCreateMode) setShow(false);
                      else setEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                </UIBox>
              </UIBox>
              <NewIndustryPublishedModal
                createdIndustry={
                  isCreateMode ? createdIndustry : updatedIndustry
                }
                show={showPublishedModal}
                onClose={() => {
                  // setSubmitted(false);
                  // setShowPublishedModal(false);
                  handleCloseSideModal();
                }}
                onLookIndustries={() => {
                  handleCloseSideModal();
                }}
              />
              <CancelSaveConfirmModal
                show={showConfirmModal}
                onCancel={handleCloseSideModal}
                onClose={() => setShowConfirmModal(false)}
              />
            </>
          )}
        </>
      )}
    </AppSideModal>
  );
};

export default SidebarIndustry;
