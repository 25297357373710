import { AppUserAvatar } from '@/components/App';
import { UIBox, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { UserJson } from '@/types';
import styled from 'styled-components';

const StyledUserContainer = styled.div`
  padding: 20px;
  display: flex;
  gap: 20px;
  height: 100px;
  cursor: pointer;
  border-radius: 5px;
  border-bottom: solid 1px ${theme.colors.gray840};
  &:hover {
  }
`;

interface MessageUserProps {
  selected?: boolean;
  user: UserJson.User;
  onClick: () => void;
}
const MessageUser: React.FC<MessageUserProps> = ({
  user,
  selected,
  onClick,
}) => {
  return (
    <StyledUserContainer
      style={{ background: selected ? '#f7f7ff' : 'transparent' }}
      onClick={onClick}
    >
      <AppUserAvatar user={user} size={50} showOnlineStatus />
      <UIBox flexGrow={1}>
        <UIBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
        >
          <Typography
            color={theme.colors.textPrimary}
            fontWeight={selected ? 600 : 500}
          >
            {user.firstName} {user.lastName}
          </Typography>

          <Typography fontSize={10} lineHeight="12px" color={theme.colors.icon}>
            1 min ago
          </Typography>
        </UIBox>
        <Typography
          fontSize={13}
          lineHeight="16px"
          color={
            selected ? theme.colors.textPrimary : theme.colors.textDescription
          }
        >
          {user.profession}
        </Typography>
      </UIBox>
    </StyledUserContainer>
  );
};

export default MessageUser;
