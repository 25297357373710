import { IconPlus, IconSearch } from '@/assets/icons';
import { UIBox, UICard, IconButton, Input, Typography } from '@/components/UI';
import { theme } from '@/constants';
import MessageUser from './MessageUser';
import { useState } from 'react';
import MessagePanel from './MessagePanel';
import { workersMock } from '@/mock';

const MessagesPage: React.FC = () => {
  const [selectedWorkerId, setSelectedWorkerId] = useState('12');
  return (
    <UIBox display="flex" gap={30} height={'calc(100vh - 115px)'}>
      <UICard
        width={390}
        minWidth={390}
        display="flex"
        flexDirection="column"
        gap={20}
      >
        <UIBox display="flex" justifyContent="space-between">
          <Typography fontWeight={500} color={theme.colors.textPrimary}>
            Messages
          </Typography>
          <IconButton>
            <IconPlus />
          </IconButton>
        </UIBox>
        <Input
          placeholder={
            <>
              <IconSearch color={theme.colors.companyBrand} />
              Search
            </>
          }
          fullWidth
        />
        <UIBox>
          {workersMock.slice(0, 7).map((worker, id) => (
            <MessageUser
              onClick={() => setSelectedWorkerId(worker.id)}
              key={id}
              user={worker}
              selected={selectedWorkerId === worker.id}
            />
          ))}
        </UIBox>
      </UICard>
      <UIBox flexGrow={1}>
        <MessagePanel />
      </UIBox>
    </UIBox>
  );
};

export default MessagesPage;
