import { AppStatusTag } from '@/components/App';
import {
  Image,
  Typography,
  UIBox,
  UICard,
  UITable,
  UITh,
  UIThead,
  UITr,
} from '@/components/UI';
import { theme } from '@/constants';
import { createRandomCompany } from '@/mock';
import { faker } from '@faker-js/faker';
const companies = faker.helpers.multiple(createRandomCompany, { count: 4 });
const TopOrganizations: React.FC = () => {
  return (
    <UICard>
      <Typography variant="h4" fontSize={20} fontWeight={500}>
        TopOrganizations
      </Typography>
      <UITable width={'100%'}>
        <UIThead>
          <tr>
            <UITh $borderBottom>ID</UITh>
            <UITh $borderBottom>Name</UITh>
            <UITh $borderBottom>Sponsor</UITh>
            <UITh $borderBottom>Size</UITh>
            <UITh $borderBottom>Vacancies</UITh>
            <UITh $borderBottom align="center">
              Status
            </UITh>
            <UITh $borderBottom align="right">
              Joined at
            </UITh>
          </tr>
        </UIThead>
        <tbody>
          {companies.map((company, id) => (
            <UITr key={id} style={{ boxShadow: 'none' }}>
              <td width={70}>
                <Typography
                  fontSize={14}
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                >
                  {company.id}
                </Typography>
              </td>
              <td>
                <UIBox display="flex" alignItems="center" gap={10}>
                  <Image src={'company.logo'} width={30} />
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {company.name}
                  </Typography>
                </UIBox>
              </td>
              <td>
                <UIBox display="flex" alignItems="center" gap={10}>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    Shelby Goode
                  </Typography>
                </UIBox>
              </td>
              <td width={150}>
                <Typography
                  fontSize={14}
                  color={theme.colors.textDescription}
                  fontWeight={500}
                >
                  {company.numEmployee}
                </Typography>
              </td>
              <td width={200}>
                <Typography
                  fontSize={14}
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                >
                  {320}
                </Typography>
              </td>
              <td align="center" width={120}>
                <AppStatusTag status={'default'} label="Posted" />
              </td>
              <td align="right" width={150}>
                <Typography
                  fontSize={14}
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                >
                  2024/01/15
                </Typography>
              </td>
            </UITr>
          ))}
        </tbody>
      </UITable>
    </UICard>
  );
};

export default TopOrganizations;
