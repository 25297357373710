import {
  UIBox,
  Button,
  UICard,
  UIGrid,
  Input,
  Typography,
  StatusAlert,
  Spinner,
} from '@/components/UI';
import { appRoutes } from '@/constants';
import { Provider, RoleNames } from '@/enums';
import { useAuth, useToast } from '@/hooks';
import { useRegisterGoogleOrgMutation } from '@/redux/apis';
import { AuthJson } from '@/types';
import { inputStatus } from '@/utils';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

type RegisterInputForm = AuthJson.OrgGoogleRegisterInput;

const OrgGoogleRegister: React.FC = () => {
  const { org, user, refetchUser } = useAuth();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [register, { isLoading, error, data }] = useRegisterGoogleOrgMutation();

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required first name'),
    lastName: Yup.string().required('Required last name'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Required email'),
    companyName: Yup.string().required('Required last name'),
  });
  const initialValues: RegisterInputForm = {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    roleName: RoleNames.ORG_ADMIN,
    provider: Provider.WEB_FORM,
  };

  const formik = useFormik<RegisterInputForm>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await register(values);
    },
  });
  useEffect(() => {
    if (!isLoading && data) {
      refetchUser();
      navigate(appRoutes.authCompanyLocation);
    }
    if (error) {
      const msg = 'Cannot create company';
      showToast(msg, { variant: 'error' });
      setErrMsg(msg);
    }
  }, [isLoading, error, data]);
  useEffect(() => {
    if (user) {
      formik.setValues({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        companyName: org?.name || '',
        roleName: RoleNames.ORG_ADMIN,
        provider: Provider.WEB_FORM,
      });
    }
  }, [user, org]);
  return (
    <UICard borderRadius={32} boxShadow="0px 4px 35px 0px #00000014">
      <Typography variant="h3" marginBottom={30}>
        Welcome to join us!
      </Typography>
      <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
        <UIGrid gap={20} columns={2}>
          <UIBox mb={1.5}>
            <Input
              placeholder="First Name"
              label="Enter your First Name*"
              fullWidth
              autoComplete="off"
              {...formik.getFieldProps('firstName')}
              {...inputStatus(formik, 'firstName')}
            />
          </UIBox>
          <UIBox>
            <Input
              placeholder="Last Name"
              label="Enter your Last name*"
              fullWidth
              autoComplete="off"
              {...formik.getFieldProps('lastName')}
              {...inputStatus(formik, 'lastName')}
            />
          </UIBox>
        </UIGrid>
        <UIBox mb={1.5}>
          <Input
            label="Enter Company Name*"
            placeholder="Company Name"
            fullWidth
            {...formik.getFieldProps('companyName')}
            {...inputStatus(formik, 'companyName')}
          />
        </UIBox>
        <UIBox mb={1.5}>
          <Input
            placeholder="Email"
            type="email"
            label="your Email"
            disabled
            fullWidth
            autoComplete="off"
            {...formik.getFieldProps('email')}
            {...inputStatus(formik, 'email')}
          />
        </UIBox>
        {error && (
          <UIBox mb={1}>
            <StatusAlert status="error">{errMsg}</StatusAlert>
          </UIBox>
        )}
        <Button type="submit" fullWidth rounded size="lg" disabled={isLoading}>
          {isLoading && <Spinner />}
          Register Company
        </Button>
      </form>
    </UICard>
  );
};

export default OrgGoogleRegister;
