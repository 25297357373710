import { AppLogo } from '@/components/App';
import { UIBox, UIContainer } from '@/components/UI';
import { appRoutes } from '@/constants';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SharedNavbar from './SharedNavbar';

const StyledNavbarContainer = styled.header(({ theme }) => ({
  flexGrow: 1,
  position: 'fixed',
  zIndex: 99,
  left: 0,
  right: 0,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  boxShadow: `0px 4px 10px 0px #0000000D`,
}));

const StyledNavLink = styled(NavLink)<{ $active: boolean }>(
  ({ theme, $active }) => ({
    fontWeight: 500,
    lineHeight: '22px',
    fontSize: 16,
    color: $active ? theme.colors.primary : theme.colors.gray500,
  })
);
const WorkerNavbar: React.FC = () => {
  const { pathname } = useLocation();
  const isActive = (path: string) => pathname.includes(path);
  return (
    <StyledNavbarContainer>
      <UIContainer>
        <UIBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <AppLogo />
          <UIBox display="flex" alignItems="center" gap={18}>
            <StyledNavLink
              to={appRoutes.workerFindWork()}
              $active={isActive('w/jobs')}
            >
              Find Work
            </StyledNavLink>
            <StyledNavLink
              to={appRoutes.workerMyJobs()}
              $active={isActive('w/my-jobs')}
            >
              My Jobs
            </StyledNavLink>

            <SharedNavbar />
          </UIBox>
        </UIBox>
      </UIContainer>
    </StyledNavbarContainer>
  );
};

export default WorkerNavbar;
