import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconPaperclip({
  width = 27,
  height = 27,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8744 8.4375L8.08801 17.3698C7.77154 17.6862 7.59375 18.1155 7.59375 18.563C7.59375 19.0106 7.77154 19.4398 8.08801 19.7563C8.40448 20.0727 8.8337 20.2505 9.28125 20.2505C9.7288 20.2505 10.158 20.0727 10.4745 19.7563L20.9484 9.13649C21.2618 8.82309 21.5104 8.45103 21.68 8.04156C21.8496 7.63208 21.9369 7.19321 21.9369 6.75C21.9369 6.30679 21.8496 5.86792 21.68 5.45844C21.5104 5.04897 21.2618 4.67691 20.9484 4.36351C20.635 4.05012 20.2629 3.80152 19.8534 3.63191C19.444 3.4623 19.0051 3.375 18.5619 3.375C18.1187 3.375 17.6798 3.4623 17.2703 3.63191C16.8609 3.80152 16.4888 4.05012 16.1754 4.36351L5.70152 14.9833C4.75212 15.9327 4.21875 17.2204 4.21875 18.563C4.21875 19.9057 4.75212 21.1933 5.70152 22.1427C6.65093 23.0921 7.93859 23.6255 9.28125 23.6255C10.6239 23.6255 11.9116 23.0921 12.861 22.1427L21.515 13.5"
        stroke={color}
        strokeWidth="1.6875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
