import {
  SponsorEditJobPost,
  SponsorReviewJobPost,
} from '@/modules/Job/SponsorJobs';
import { useLocation, useNavigate } from 'react-router-dom';

const SponsorJobDetail: React.FC = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const hashId = hash.replace('#', '');
  const editing = !!hashId;
  return editing ? (
    <SponsorEditJobPost
      onCancel={() => navigate('#')}
      onDelete={() => navigate('#')}
      onPublish={() => navigate('#')}
    />
  ) : (
    <SponsorReviewJobPost
      onClickEdit={(step) => {
        navigate(`#${step}`);
      }}
    />
  );
};

export default SponsorJobDetail;
