import { AppSideModal } from '@/components/App';
import { IconArrowSquareOut, IconBookmark } from '@/assets/icons';
import {
  UIBox,
  Button,
  IconButton,
  Tag,
  Typography,
  Spinner,
} from '@/components/UI';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { appRoutes, theme } from '@/constants';
import { UserJson } from '@/types';
import SponsorWorkerStatus from './SponsorJobs/SponsorWorkerStatus';
import { RoleNames } from '@/enums';
import {
  CertificationCard,
  EducationCard,
  ExperienceCard,
  ProjectCard,
} from '../Profile';
import {
  useGetUserProjectsByUserIdQuery,
  useUserByIdQuery,
} from '@/redux/apis';
const SidebarWorkerDetail: React.FC<{
  role?: RoleNames;
  hideSticky?: boolean;
  onClickInviteToJob?: (worker: UserJson.User) => void;
  onClickHire?: (worker: UserJson.User) => void;
  onClickBookmark?: (worker: UserJson.User) => void;
  onClickEdit?: (worker: UserJson.User) => void;
  onClickMessage?: (worker: UserJson.User) => void;
}> = ({
  role,
  hideSticky,
  onClickBookmark,
  onClickEdit,
  onClickHire,
  onClickInviteToJob,
  onClickMessage,
}) => {
  const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('workerId');
  const { data: user, isLoading } = useUserByIdQuery(
    {
      userId: userId || '',
    },
    { skip: !userId }
  );
  const { data: projects = [], isLoading: loadingProjects } =
    useGetUserProjectsByUserIdQuery(
      {
        userId: userId || '',
      },
      { skip: !userId }
    );
  useEffect(() => {
    const workerId = searchParams.get('workerId');
    setShow(!!workerId);
  }, [searchParams]);

  const handleCloseSideModal = () => {
    const { workerId, ...currentSearchParams } =
      Object.fromEntries(searchParams);
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <AppSideModal
      isOpen={show}
      width={710}
      onClose={handleCloseSideModal}
      customTools={
        <Link
          to={
            role === RoleNames.SUPER_ADMIN
              ? appRoutes.adminWorkerProfile(user?.id)
              : role === RoleNames.ORG_ADMIN
                ? appRoutes.orgWorkerProfile(user?.id)
                : role === RoleNames.SPONSOR
                  ? appRoutes.sponsorWorkerProfile(user?.id)
                  : appRoutes.workerProfile
          }
          target="_blank"
        >
          <IconButton>
            <IconArrowSquareOut />
          </IconButton>
        </Link>
      }
    >
      <UIBox display="flex" flexDirection="column" gap={30} px={1.5} py={2}>
        <SponsorWorkerStatus worker={user} />
        <UIBox>
          <Typography variant="h6">About</Typography>
          {!isLoading && <Typography>{user?.summary}</Typography>}
          {isLoading && (
            <UIBox display="flex" justifyContent="center">
              <Spinner size="xl" />
            </UIBox>
          )}
        </UIBox>
        <UIBox>
          <Typography variant="h6">Projects</Typography>
          <UIBox display="flex" gap={18} overflow="auto" pb={1}>
            {!loadingProjects &&
              projects.map((project, id) => (
                <UIBox key={id} minWidth={275}>
                  <ProjectCard project={project.project} />
                </UIBox>
              ))}
          </UIBox>
          {loadingProjects && (
            <UIBox display="flex" justifyContent="center">
              <Spinner size="xl" />
            </UIBox>
          )}
        </UIBox>
        <UIBox>
          <Typography variant="h6">Experience</Typography>
          <UIBox display="flex" flexDirection="column" gap={20}>
            {user?.userExperiences?.map((experience, id) => (
              <ExperienceCard key={id} experience={experience} />
            ))}
          </UIBox>
        </UIBox>
        <UIBox>
          <Typography variant="h6">Education</Typography>
          <UIBox display="flex" flexDirection="column" gap={20}>
            {user?.userEducations?.map((education, id) => (
              <EducationCard key={id} education={education} />
            ))}
          </UIBox>
        </UIBox>
        <UIBox>
          <Typography variant="h6">Skills</Typography>
          <UIBox display="flex" flexWrap="wrap" gap={10}>
            {user?.userSkills?.map((item, id) => (
              <Tag key={id}>{item.skill?.name}</Tag>
            ))}
          </UIBox>
        </UIBox>
        <UIBox>
          <Typography variant="h6">Certifications</Typography>
          <UIBox display="flex" flexDirection="column" gap={20}>
            {user?.userCertifications?.map((cert, id) => (
              <CertificationCard key={id} certification={cert} />
            ))}
          </UIBox>
        </UIBox>
        {/* <UIBox>
          <Typography variant="h6">Assessment</Typography>
          <UIBox display="flex" flexDirection="column" gap={20}>
            {user?.assessments.map((assessment, id) => (
              <AssessmentCard key={id} assessment={assessment} />
            ))}
          </UIBox>
        </UIBox> */}
      </UIBox>
      {!hideSticky &&
        (role === RoleNames.ORG_ADMIN || role === RoleNames.SPONSOR) && (
          <UIBox
            position="sticky"
            bottom={0}
            background={theme.colors.white}
            boxShadow="10px -1px 30px 0px #7B7D9633"
            py={1}
            px={1.5}
            display="flex"
            gap={10}
          >
            <UIBox flexGrow={1}>
              <Button
                fullWidth
                variant="primary"
                size="md"
                onClick={() => user && onClickInviteToJob?.(user)}
              >
                Invite to Job
              </Button>
            </UIBox>
            <UIBox flexGrow={1}>
              <Button
                fullWidth
                variant="outlined-primary"
                size="md"
                onClick={() => user && onClickHire?.(user)}
              >
                Hire
              </Button>
            </UIBox>
            <Button
              variant="outlined-primary"
              size="md"
              width={44}
              style={{ padding: 0 }}
              onClick={() => user && onClickBookmark?.(user)}
            >
              <IconBookmark color={theme.colors.primary} />
            </Button>
          </UIBox>
        )}
      {!hideSticky && role === RoleNames.SUPER_ADMIN && (
        <UIBox
          position="sticky"
          bottom={0}
          background={theme.colors.white}
          boxShadow="10px -1px 30px 0px #7B7D9633"
          py={1}
          px={1.5}
          display="flex"
          gap={10}
        >
          <UIBox flexGrow={1}>
            <Button
              fullWidth
              variant="primary"
              size="md"
              onClick={() => user && onClickEdit?.(user)}
            >
              Edit
            </Button>
          </UIBox>
          <UIBox flexGrow={1}>
            <Button
              fullWidth
              variant="outlined-primary"
              size="md"
              onClick={() => user && onClickMessage?.(user)}
            >
              Message
            </Button>
          </UIBox>
        </UIBox>
      )}
    </AppSideModal>
  );
};

export default SidebarWorkerDetail;
