import { UIBox } from '@/components/UI';
import { theme } from '@/constants';
import { PropsWithChildren } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import styled from 'styled-components';

interface StyledLinkItemProps {
  active: boolean;
  disabled: boolean;
}

const StyledLinkItem = styled(
  ({
    active,
    disabled,
    ...rest
  }: StyledLinkItemProps &
    LinkProps &
    React.RefAttributes<HTMLAnchorElement>) => <Link {...rest} />
)<StyledLinkItemProps>`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 30px;
  gap: 10px;
  font-size: 16px;
  line-height: 22px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  color: ${({ active }) => (active ? theme.colors.primary : theme.colors.icon)};
  background: ${({ active }) =>
    active
      ? 'linear-gradient(90deg, #aca9ff33 0%, rgba(172, 169, 255, 0) 91.25%)'
      : 'transparent'};
  transition: all ease 0.3s;
  svg.stroke-icon > path {
    transition: all ease 0.3s;
    stroke: ${({ active }) =>
      active ? theme.colors.primary : theme.colors.icon};
  }
  svg.fill-icon > path {
    transition: all ease 0.3s;
    fill: ${({ active }) =>
      active ? theme.colors.primary : theme.colors.icon};
  }
`;

interface AsideNavLinkItemProps extends PropsWithChildren {
  to?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  active?: boolean;
}
const AsideNavLinkItem: React.FC<AsideNavLinkItemProps> = ({
  to,
  icon,
  disabled,
  children,
  active = false,
}) => {
  const { pathname } = useLocation();
  const isActive = (p: string) => pathname.includes(p) || active;
  return (
    <StyledLinkItem
      active={!!to && isActive(to)}
      to={to || '#'}
      disabled={!!disabled}
    >
      <UIBox width={18} display="flex" justifyContent="center">
        {icon}
      </UIBox>
      <div className="nav-link-label">{children}</div>
    </StyledLinkItem>
  );
};

export default AsideNavLinkItem;
