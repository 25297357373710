import { IconVector } from '@/assets/icons';
import { Dropdown, Typography, UIBox, UICard } from '@/components/UI';
import { theme } from '@/constants';
import { Pie, PieChart, Tooltip } from 'recharts';

const projectStatus = [
  {
    label: 'Closed',
    value: 63,
    fill: theme.colors.primary,
  },
  {
    label: 'In progress',
    value: 25,
    fill: theme.colors.secondary,
  },
  {
    label: 'Archived',
    value: 12,
    fill: theme.colors.icon,
  },
];
const ProjectStatusWidget: React.FC<{ title: string }> = ({ title }) => {
  return (
    <UICard>
      <UIBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" marginBottom={0}>
          {title}
        </Typography>
        <Dropdown button="Monthly"></Dropdown>
      </UIBox>
      <UIBox
        display="flex"
        justifyContent="center"
        mt={1}
        mb={1}
        position="relative"
      >
        <UIBox
          position="absolute"
          width={58}
          height={58}
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          background={`${theme.colors.success}17`}
          borderRadius={58}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconVector />
        </UIBox>
        <PieChart width={150} height={150}>
          <Pie
            cornerRadius={10}
            data={projectStatus}
            cx="50%"
            cy="50%"
            outerRadius={70}
            innerRadius={55}
            dataKey={'value'}
            nameKey={'label'}
            startAngle={90}
            endAngle={-270}
          />
          <Tooltip formatter={(value: number) => `${value}%`} />
        </PieChart>
      </UIBox>
      <UICard
        display="flex"
        justifyContent="space-between"
        boxShadow={theme.colors.boxShadow}
        p={1}
      >
        {projectStatus.map((item, id) => (
          <UIBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            key={id}
          >
            <UIBox display="flex" alignItems="center" gap={5}>
              <UIBox
                width={8}
                height={8}
                borderRadius={8}
                backgroundColor={item.fill}
              />
              <Typography
                fontSize={12}
                fontWeight={500}
                color={theme.colors.gray540}
              >
                {item.label}
              </Typography>
            </UIBox>
            <Typography fontWeight={500} color={theme.colors.primary}>
              {item.value}%
            </Typography>
          </UIBox>
        ))}
      </UICard>
    </UICard>
  );
};

export default ProjectStatusWidget;
