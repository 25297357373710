import { UIBox, Dropdown } from '@/components/UI';
import { Link } from 'react-router-dom';
import { appRoutes, theme } from '@/constants';
import styled from 'styled-components';
import {
  IconBuildings,
  IconLogout,
  IconSetting,
  IconUser,
} from '@/assets/icons';
import UserAvatar from '../UserAvatar';
import { useAuth } from '@/hooks/useAuth';
import { RoleNames } from '@/enums';

const UserDropdownItem = styled.button`
  background: transparent;
  outline: none;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 120px;
  height: 34px;
  gap: 10px;
  transition: all ease 0.3s;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: ${theme.colors.textDescription};
  &:hover {
    color: ${theme.colors.primary};
    background: #f5f6fa;
    svg.fill-icon > path {
      transition: all ease 0.3s;
      fill: ${theme.colors.primary};
    }
    svg.stroke-icon > path {
      transition: all ease 0.3s;
      stroke: ${theme.colors.primary};
    }
  }
`;
const UserDropdown: React.FC = () => {
  const { auth, user, logout } = useAuth();
  const isOrg = auth?.roleName === RoleNames.ORG_ADMIN;
  const isSponsor = auth?.roleName === RoleNames.SPONSOR;
  const isAdmin =
    auth?.roleName === RoleNames.SUPER_ADMIN ||
    auth?.roleName === RoleNames.SITE_ADMIN;
  function handleLogout() {
    logout();
  }
  return (
    <Dropdown button={<UserAvatar user={user} />} disableDefaultToggleStyle>
      <Link
        to={
          isAdmin
            ? appRoutes.adminProfile
            : isOrg
              ? appRoutes.orgProfile
              : isSponsor
                ? appRoutes.sponsorProfile
                : appRoutes.workerProfile
        }
      >
        <UserDropdownItem>
          <UIBox
            width={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconUser />
          </UIBox>
          My Profile
        </UserDropdownItem>
      </Link>
      <Link
        to={
          isAdmin
            ? appRoutes.adminPreference
            : isOrg
              ? appRoutes.orgPreference
              : isSponsor
                ? appRoutes.sponsorPreference
                : appRoutes.workerPreference
        }
      >
        <UserDropdownItem>
          <UIBox
            width={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconBuildings />
          </UIBox>
          Preference
        </UserDropdownItem>
      </Link>
      <Link
        to={
          isAdmin
            ? appRoutes.adminSettings
            : isOrg
              ? appRoutes.orgSettings
              : isSponsor
                ? appRoutes.sponsorSettings
                : appRoutes.workerSettings
        }
      >
        <UserDropdownItem>
          <UIBox
            width={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconSetting />
          </UIBox>
          Settings
        </UserDropdownItem>
      </Link>
      <UserDropdownItem onClick={handleLogout}>
        <UIBox
          width={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <IconLogout />
        </UIBox>
        Logout
      </UserDropdownItem>
    </Dropdown>
  );
};

export default UserDropdown;
