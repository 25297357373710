import {
  BackButton,
  IconButton,
  Image,
  ReadMoreButton,
  Typography,
  UIBox,
  UICard,
  UITable,
  UITh,
  UIThead,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { useParams } from 'react-router-dom';
import { jobsMock } from '@/mock';
import { CompanyProfile } from '@/modules/Profile';
import { UITypography } from '@/components/UI/Typography';
import { IconArrowDown, IconDone, IconEllipsisVertical } from '@/assets/icons';
import styled from 'styled-components';
import { formatCurrency } from '@/utils';

export const UITr = styled.tr`
  > td {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.colors.textDescription};
    background: ${theme.colors.white};
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: solid 1px #eff2f5;
    border-top: solid 1px #eff2f5;
    &:first-child {
      border-left: solid 1px #eff2f5;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-right: solid 1px #eff2f5;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-right: 20px;
    }
  }
`;

const AdminOrgDetailPage: React.FC = () => {
  const { id: companyId } = useParams();
  return (
    <UIBox flexGrow={1} display="flex" flexDirection="column" gap={21}>
      <BackButton to={appRoutes.adminOrganizations}>
        Back to Organizations List
      </BackButton>
      <CompanyProfile companyId={companyId} editable />
      <UICard>
        <UITypography variant="h5">Vacancies</UITypography>
        <UIBox display="flex" alignItems="center" gap={5}>
          <IconDone width={13} height={13} />
          <Typography
            fontSize={14}
            fontWeight={600}
            color={theme.colors.textPrimary}
          >
            30 done
          </Typography>
          <Typography fontSize={14} fontWeight={500}>
            this month
          </Typography>
        </UIBox>

        <UITable width="100%">
          <UIThead>
            <tr>
              <UITh align="left">Logo</UITh>
              <UITh align="left">Name</UITh>
              <UITh align="left">Work Time</UITh>
              <UITh align="left">Salary</UITh>
              <UITh align="right">Candidate</UITh>
              <UITh align="right">Posted</UITh>
              <UITh></UITh>
            </tr>
          </UIThead>
          <tbody>
            {jobsMock.slice(0, 4).map((job, id) => (
              <UITr key={id}>
                <td>
                  <Image src={''} height={40} />
                </td>
                <td>
                  <UITypography
                    color={theme.colors.textPrimary}
                    fontWeight={500}
                  >
                    {job.title}
                  </UITypography>
                </td>
                <td>
                  <Typography fontSize={14} fontWeight={500}>
                    {job.type} - {job.employmentType}
                  </Typography>
                </td>
                <td>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color={theme.colors.textPrimary}
                  >
                    {formatCurrency(job.minRate)} -{' '}
                    {formatCurrency(job.maxRate)}
                  </Typography>
                </td>
                <td align="right">
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color={theme.colors.icon}
                  >
                    23
                  </Typography>
                </td>
                <td align="right">
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color={theme.colors.icon}
                  >
                    23 min ago
                  </Typography>
                </td>
                <td align="right">
                  <IconButton>
                    <IconEllipsisVertical />
                  </IconButton>
                </td>
              </UITr>
            ))}
          </tbody>
        </UITable>
        <UIBox display="flex" justifyContent="center">
          <ReadMoreButton>
            Show all <IconArrowDown />
          </ReadMoreButton>
        </UIBox>
      </UICard>
    </UIBox>
  );
};

export default AdminOrgDetailPage;
