import { createApi } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { authorizeQueryWithRefreshToken } from '@/utils/data.util';
import { AssetJson } from '@/types';

export const assetApi = createApi({
  reducerPath: 'assetApi',
  baseQuery: authorizeQueryWithRefreshToken,
  tagTypes: ['Asset'],
  endpoints: (builder) => ({
    generateSignedUrl: builder.mutation<
      { url: string },
      AssetJson.GenerateSignedUrl
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/assets/gen-signed-url`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as { url: string },
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    createAsset: builder.mutation<AssetJson.Asset, AssetJson.AssetInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/assets`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as AssetJson.Asset,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const { useCreateAssetMutation, useGenerateSignedUrlMutation } =
  assetApi;
