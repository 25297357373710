import { BackButton, UIBox } from '@/components/UI';
import { appRoutes } from '@/constants';
import { useParams } from 'react-router-dom';
import { CompanyProfile } from '@/modules/Profile';

const WorkerCompanyDetailPage: React.FC = () => {
  const { id: companyId } = useParams();
  return (
    <UIBox flexGrow={1} display="flex" flexDirection="column" gap={21}>
      <BackButton to={appRoutes.workerFindWork()}>Back to Job</BackButton>
      <CompanyProfile companyId={companyId} />
    </UIBox>
  );
};

export default WorkerCompanyDetailPage;
