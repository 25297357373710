import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconTaskSquare({
  width = 20,
  height = 20,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className="fill-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.493 1.66602H6.50964C3.4763 1.66602 1.66797 3.47435 1.66797 6.50768V13.491C1.66797 16.5244 3.4763 18.3327 6.50964 18.3327H13.493C16.5263 18.3327 18.3346 16.5244 18.3346 13.491V6.50768C18.3346 3.47435 16.5263 1.66602 13.493 1.66602ZM8.30964 12.416L6.43464 14.291C6.30964 14.416 6.1513 14.4743 5.99297 14.4743C5.83464 14.4743 5.66797 14.416 5.5513 14.291L4.9263 13.666C4.6763 13.4243 4.6763 13.0243 4.9263 12.7827C5.16797 12.541 5.55964 12.541 5.80964 12.7827L5.99297 12.966L7.4263 11.5327C7.66797 11.291 8.05964 11.291 8.30964 11.5327C8.5513 11.7743 8.5513 12.1743 8.30964 12.416ZM8.30964 6.58268L6.43464 8.45768C6.30964 8.58268 6.1513 8.64102 5.99297 8.64102C5.83464 8.64102 5.66797 8.58268 5.5513 8.45768L4.9263 7.83268C4.6763 7.59102 4.6763 7.19102 4.9263 6.94935C5.16797 6.70768 5.55964 6.70768 5.80964 6.94935L5.99297 7.13268L7.4263 5.69935C7.66797 5.45768 8.05964 5.45768 8.30964 5.69935C8.5513 5.94102 8.5513 6.34102 8.30964 6.58268ZM14.6346 13.8493H10.2596C9.91797 13.8493 9.63464 13.566 9.63464 13.2243C9.63464 12.8827 9.91797 12.5993 10.2596 12.5993H14.6346C14.9846 12.5993 15.2596 12.8827 15.2596 13.2243C15.2596 13.566 14.9846 13.8493 14.6346 13.8493ZM14.6346 8.01602H10.2596C9.91797 8.01602 9.63464 7.73268 9.63464 7.39102C9.63464 7.04935 9.91797 6.76602 10.2596 6.76602H14.6346C14.9846 6.76602 15.2596 7.04935 15.2596 7.39102C15.2596 7.73268 14.9846 8.01602 14.6346 8.01602Z"
        fill={color}
      />
    </svg>
  );
}
