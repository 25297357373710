import { ProfileEditButton } from '@/modules/Shared';
import {
  UIBox,
  UICard,
  Typography,
  Input,
  IconButton,
  SectionSpinner,
} from '@/components/UI';
import { OrganizationJson } from '@/types';
import { useEffect, useState } from 'react';
import { IconPhotoCamera } from '@/assets/icons';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useUpdateOrganizationMutation } from '@/redux/apis';
import LogoChangeModal from '../Shared/LogoChangeModal';
import { inputStatus } from '@/utils';
import { useAuth } from '@/hooks';

const validationSchema = yup.object({
  name: yup.string().required('Company name is required.'),
});
type FormikData = Pick<OrganizationJson.OrganizationInput, 'id' | 'name'>;
interface Props {
  loading?: boolean;
  editable?: boolean;
  company?: OrganizationJson.Organization;
  refetch?: () => void;
}
const CompanyHeroSection: React.FC<Props> = ({
  company,
  editable,
  loading,
  refetch,
}) => {
  const [showLogoChangeModal, setShowLogoChangeModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const { user } = useAuth();
  const [updateOrg, { isSuccess, isLoading: updating }] =
    useUpdateOrganizationMutation();
  const formik = useFormik<FormikData>({
    initialValues: {
      id: company?.id,
      name: company?.name || '',
    },
    validationSchema,
    onSubmit: async (v) => {
      await updateOrg(v);
    },
  });
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnCancel = () => {
    setEditing(false);
  };

  useEffect(() => {
    if (isSuccess) {
      refetch?.();
      setEditing(false);
    }
  }, [updating, isSuccess]);
  return (
    <UICard p={0} borderRadius={10} borderWidth={0} position="relative">
      <UIBox
        background="url(/images/public-profile-header-background.png)"
        height={120}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        borderTopLeftRadius={10}
        borderTopRightRadius={10}
        position="relative"
        mb={6.5}
      />

      {loading ? (
        <UIBox position="absolute" top={0} bottom={0} left={0} right={0}>
          <SectionSpinner />
        </UIBox>
      ) : (
        <UIBox
          display="flex"
          gap={30}
          px={2}
          py={1.5}
          zIndex={1}
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          alignItems="flex-end"
        >
          <UIBox
            width={120}
            height={120}
            borderRadius={120}
            minWidth={120}
            background="#F5F8FF"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            style={{
              backgroundImage: `url("${company?.logo?.url}")`,
              backgroundSize: 'contain',
              cursor: 'pointer',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            {editing && (
              <UIBox
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                background="#1C295566"
                borderRadius={120}
              >
                <IconButton onClick={() => setShowLogoChangeModal(true)}>
                  <IconPhotoCamera />
                </IconButton>
              </UIBox>
            )}
          </UIBox>
          {!editing && (
            <UIBox
              display="flex"
              flexDirection="column"
              gap={10}
              width={'100%'}
              justifyContent="flex-start"
            >
              <Typography variant="h4">{company?.name}</Typography>
              <Typography>{company?.owner?.email}</Typography>
            </UIBox>
          )}
          {editing && (
            <UIBox display="flex" gap={30}>
              <Input
                variant="secondary"
                label="Company name"
                {...formik.getFieldProps('name')}
                style={{ width: 300 }}
                {...inputStatus(formik, 'name')}
                statusLabelPosition="absolute"
              />
              <Input
                variant="secondary"
                label="Email"
                defaultValue={company?.owner?.email}
                style={{ width: 300 }}
              />
            </UIBox>
          )}
          {editable && (
            <UIBox ml="auto">
              <ProfileEditButton
                editing={editing}
                saving={updating}
                onClickSave={() => formik.handleSubmit()}
                onClickCancel={handleOnCancel}
                onClickEdit={handleOnEdit}
              />
            </UIBox>
          )}
        </UIBox>
      )}
      <LogoChangeModal
        show={showLogoChangeModal}
        onClose={() => setShowLogoChangeModal(false)}
        onUploaded={() => {
          setShowLogoChangeModal(false);
          refetch?.();
        }}
        url={company?.logo?.url}
        userId={user?.id || ''}
        orgId={company?.id}
      />
    </UICard>
  );
};

export default CompanyHeroSection;
