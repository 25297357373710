import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconLinkedin({
  width = 16,
  height = 16,
  disabled,
}: AppTypes.IconProps & { disabled?: boolean }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.459229 1.53932C0.459229 0.943008 0.958452 0.458984 1.57385 0.458984H14.4262C15.0418 0.458984 15.5408 0.943008 15.5408 1.53932V14.4604C15.5408 15.0569 15.0418 15.5406 14.4262 15.5406H1.57385C0.958511 15.5406 0.459229 15.0569 0.459229 14.4606V1.53914V1.53932Z"
        fill={disabled ? theme.colors.gray710 : '#0A66C2'}
      />
      <path
        d="M5.0423 13.0804V6.29063H2.78549V13.0804H5.04254H5.0423ZM3.91437 5.36376C4.7012 5.36376 5.19106 4.84239 5.19106 4.19082C5.17633 3.5244 4.7012 3.01758 3.92933 3.01758C3.15693 3.01758 2.65247 3.5244 2.65247 4.19076C2.65247 4.84233 3.14215 5.3637 3.89958 5.3637H3.91419L3.91437 5.36376ZM6.29148 13.0804H8.54812V9.28909C8.54812 9.08644 8.56285 8.88325 8.62247 8.7385C8.78554 8.33289 9.15686 7.91302 9.78045 7.91302C10.5969 7.91302 10.9236 8.53561 10.9236 9.44845V13.0804H13.1802V9.18735C13.1802 7.10191 12.067 6.13145 10.5822 6.13145C9.36494 6.13145 8.83031 6.81183 8.53328 7.27523H8.5483V6.29087H6.2916C6.32106 6.92783 6.29142 13.0806 6.29142 13.0806L6.29148 13.0804Z"
        fill="white"
      />
    </svg>
  );
}
