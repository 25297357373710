import { UIBox, Button, UICard, Image, Typography } from '@/components/UI';
import { appRoutes } from '@/constants';
import { useNavigate } from 'react-router-dom';

export default function AuthPasswordChangedPage() {
  const navigate = useNavigate();
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={520}
        px={5}
        py={2}
        borderRadius={32}
        boxShadow="0px 4px 35px 0px #00000014"
      >
        <Typography
          variant="h3"
          textAlign="center"
          fontSize={26}
          lineHeight="35px"
          marginBottom={10}
        >
          Password changed
        </Typography>

        <Typography textAlign="center" fontWeight={400} marginBottom={0}>
          You have successfully changed your password on our website. Please
          keep it safe. But if you manage to forget it again do not hesitate to
          ask us for help!
        </Typography>
        <Image src="/images/password-changed.png" />
        <UIBox
          mt={2}
          width={300}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Button fullWidth rounded onClick={() => navigate(appRoutes.login)}>
            Login
          </Button>
        </UIBox>
      </UICard>
    </UIBox>
  );
}
