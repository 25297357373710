import {
  IconDelete,
  IconEdit1,
  IconEllipsis,
  IconSearch,
  IconSwap,
} from '@/assets/icons';
import { AppUserStatusTag } from '@/components/App';
import {
  UIBox,
  Dropdown,
  DropdownItem,
  IconButton,
  Image,
  Input,
  Typography,
  DataTableColumn,
  DataTable,
} from '@/components/UI';
import { DropdownButton } from '@/components/UI/Dropdown';
import { appRoutes, theme } from '@/constants';
import { SortDirection } from '@/enums';
import { useConfirmModal } from '@/hooks';
import {
  useDeleteOrganizationMutation,
  useOrganizationsQuery,
} from '@/redux/apis';
import { AppTypes, OrganizationJson } from '@/types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const AdminOrganizationsPage: React.FC = () => {
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();
  const [deleteOrg, { isSuccess: isDeleted }] = useDeleteOrganizationMutation();
  const [pagination, setPagination] = useState<AppTypes.PaginationParam>({
    search: '',
    sortField: 'createdAt',
    sortBy: SortDirection.DESC,
    offset: 0,
    limit: 10,
  });
  const {
    data: { data: organizations = [], total = 0 } = {},
    isLoading,
    refetch,
  } = useOrganizationsQuery({
    ...pagination,
  });
  const columns: DataTableColumn<OrganizationJson.Organization>[] = [
    {
      field: 'id',
      label: 'ID',
      width: 50,
      renderer: (row, id) => <Typography fontSize={14}>{id + 1}</Typography>,
    },
    {
      field: 'logo',
      width: 30,
      renderer: (row) => <Image src={row.logo?.url} width={30} />,
    },
    {
      field: 'numEmployee',
      label: 'Size',
      width: 250,
      sortable: true,
    },
    {
      field: 'email',
      label: 'Invitee Email',
      width: 250,
      renderer: (row) => row.owner?.email,
    },
    {
      field: 'role',
      label: 'Role',
      width: 250,
      renderer: (row) => row.owner?.role?.name,
    },
    {
      field: 'status',
      label: 'Status',
      sortable: true,
      renderer: (row) => <AppUserStatusTag status={row.owner?.status} />,
    },
    {
      field: 'createdAt',
      label: 'Joined At',
      align: 'right',
      sortable: true,
      renderer: (row) => new Date(row.createdAt).toLocaleDateString(),
    },
    {
      field: 'action',
      width: 50,
      renderer: (row) => (
        <Dropdown
          button={
            <IconButton>
              <IconEllipsis />
            </IconButton>
          }
          disableDefaultToggleStyle
        >
          <Link to={appRoutes.adminOrganizationProfile(row.id || '')}>
            <DropdownButton variant="primary">
              <IconEdit1 />
              Edit
            </DropdownButton>
          </Link>
          <DropdownButton
            variant="danger"
            onClick={(e) => {
              e.stopPropagation();
              showConfirmModal({
                onConfirm: async () =>
                  await deleteOrg({ orgId: row?.id || '' }),
              });
            }}
          >
            <IconDelete width={8} height={9} />
            Delete
          </DropdownButton>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    if (isDeleted) {
      hideConfirmModal();
      refetch();
    }
  }, [isDeleted]);
  return (
    <>
      <UIBox display="flex" justifyContent="space-between">
        <Typography variant="h5">Organizations</Typography>
        <UIBox display="flex" gap={20}>
          <Input
            placeholder={
              <>
                <IconSearch color={theme.colors.icon} />
                Search
              </>
            }
            height={40}
            value={pagination.search}
            onChange={(e) =>
              setPagination({
                ...pagination,
                search: e.target.value,
              })
            }
          />
          <Dropdown
            button={
              <UIBox display="flex" alignItems="center" width={200} gap={10}>
                <IconSwap /> Status
              </UIBox>
            }
            height={40}
          >
            <DropdownItem>Status</DropdownItem>
          </Dropdown>
        </UIBox>
      </UIBox>
      <UIBox>
        <DataTable
          columns={columns}
          data={organizations}
          total={total}
          loading={isLoading}
          pagination={pagination}
          onUpdatePagination={(p) => setPagination({ ...pagination, ...p })}
        />
      </UIBox>
    </>
  );
};

export default AdminOrganizationsPage;
