import styled from 'styled-components';
import { UIBox } from './Box';

const UICard = styled(UIBox)({
  background: 'white',
  border: '1px solid #FFFFFF8C',
  borderRadius: '10px',
  padding: '24px',
  color: '#7B7D96',
  textAlign: 'left',
});

export default UICard;
