import { UIBox, Typography } from '@/components/UI';
import { useAuth } from '@/hooks';
import { CompanyProfile } from '@/modules/Profile';

const OrgProfilePage: React.FC = () => {
  const { org } = useAuth();
  return (
    <UIBox display="flex" flexDirection="column" gap={20}>
      <Typography variant="h5" marginBottom={0}>
        Organization profile
      </Typography>
      <CompanyProfile companyId={org?.id} editable />
    </UIBox>
  );
};

export default OrgProfilePage;
