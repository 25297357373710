import { AppModal } from '@/components/App';
import { Autocomplete, Button, UIBox } from '@/components/UI';

interface HireToJobModalProps {
  show: boolean;
  onClose: () => void;
}
const HireToJobModal: React.FC<HireToJobModalProps> = ({ show, onClose }) => {
  const handleHire = () => {
    onClose();
  };
  return (
    <AppModal
      isOpen={show}
      headerAlign="center"
      onClose={onClose}
      header="Hire to Job"
      width={390}
    >
      <UIBox display="flex" flexDirection="column" alignItems="center" gap={20}>
        <Autocomplete
          label="Choose job"
          height={40}
          labelSize={14}
          variant="secondary"
          fullWidth
        />
        <Button height={40} onClick={handleHire} fullWidth size="sm">
          Hire
        </Button>
      </UIBox>
    </AppModal>
  );
};

export default HireToJobModal;
