import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RegisterState {
  email: string;
  password: string;
}

const initialState: RegisterState = {
  email: '',
  password: '',
};

export const registerSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCredentials: (
      state,
      action: PayloadAction<{ email: string; password: string }>
    ) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
    },
    clearCredentials: (state) => {
      state.email = '';
      state.password = '';
    },
  },
});

// Export the actions
export const { setCredentials, clearCredentials } = registerSlice.actions;
// Default export the reducer
export default registerSlice;
