import Welcome from '@/modules/Home/Welcome';
import HomeLayout from './layout';
import {
  AboutDetails,
  AboutUs,
  Contact,
  IndividualsAndEmployers,
} from '@/modules/Home';
import { AppScrollTop } from '@/components/App';
import { useAuth } from '@/hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleNames } from '@/enums';
import { appRoutes } from '@/constants';

function HomePage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth) {
      if (auth.roleName === RoleNames.SUPER_ADMIN) {
        navigate(appRoutes.adminDashboard);
      } else if (auth.roleName === RoleNames.SITE_ADMIN) {
        navigate(appRoutes.adminDashboard);
      } else if (auth.roleName === RoleNames.ORG_ADMIN) {
        navigate(appRoutes.orgDashboard);
      } else if (auth.roleName === RoleNames.SPONSOR) {
        navigate(appRoutes.sponsorMyJobs);
      } else if (auth.roleName === RoleNames.WORKER) {
        navigate(appRoutes.workerFindWork());
      }
    }
  }, [auth]);
  return (
    <HomeLayout>
      <Welcome />
      <AboutDetails />
      <AboutUs />
      <IndividualsAndEmployers />
      <Contact />
      <AppScrollTop />
    </HomeLayout>
  );
}

export default HomePage;
