import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconEmail({
  width = 13,
  height = 13,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58687 0.875C10.425 0.875 11.2312 1.20625 11.8244 1.80062C12.4181 2.39375 12.75 3.19375 12.75 4.03125V8.96875C12.75 10.7125 11.3313 12.125 9.58687 12.125H3.4125C1.66813 12.125 0.25 10.7125 0.25 8.96875V4.03125C0.25 2.2875 1.66187 0.875 3.4125 0.875H9.58687ZM10.5813 4.9625L10.6313 4.9125C10.7806 4.73125 10.7806 4.46875 10.6244 4.2875C10.5375 4.19437 10.4181 4.1375 10.2937 4.125C10.1625 4.11812 10.0375 4.1625 9.94312 4.25L7.125 6.5C6.7625 6.80062 6.24312 6.80062 5.875 6.5L3.0625 4.25C2.86812 4.10625 2.59938 4.125 2.4375 4.29375C2.26875 4.4625 2.25 4.73125 2.39312 4.91875L2.475 5L5.31875 7.21875C5.66875 7.49375 6.09312 7.64375 6.5375 7.64375C6.98063 7.64375 7.4125 7.49375 7.76187 7.21875L10.5813 4.9625Z"
        fill={color}
      />
    </svg>
  );
}
