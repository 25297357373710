import { AuthJson, OrganizationJson, UserJson } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  auth?: AuthJson.Response;
  user?: UserJson.User;
  org?: OrganizationJson.Organization;
}

const initialState: UserState = {
  auth: undefined,
  user: undefined,
  org: undefined,
};

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthJson.Response>) => {
      state.auth = action.payload;
    },
    setAuthUser: (state, action: PayloadAction<UserJson.User>) => {
      state.user = action.payload;
    },
    setUserOrg: (
      state,
      action: PayloadAction<OrganizationJson.Organization>
    ) => {
      state.org = action.payload;
    },
    // Action to clear the user (log out)
    clearAuth: (state) => {
      state.auth = undefined;
      state.user = undefined;
      state.org = undefined;
    },
  },
});

// Export the actions
export const { setAuth, setAuthUser, clearAuth, setUserOrg } =
  authSlice.actions;
// Default export the reducer
export default authSlice;
