import { ProfileEditButton } from '@/modules/Shared';
import { IconEarth, IconShieldDone, IconUsers3 } from '@/assets/icons';
import {
  UIBox,
  UICard,
  UIDivider,
  Typography,
  RichEditor,
  Autocomplete,
  Input,
  LocationAutocomplete,
} from '@/components/UI';
import { appConfig, orgSizeOptions, theme } from '@/constants';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { AppTypes, OrganizationJson } from '@/types';
import { useEffect, useMemo, useState } from 'react';
import {
  useIndustriesQuery,
  useUpdateOrganizationMutation,
} from '@/redux/apis';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  formatAddressFromLocation,
  formatIndustriesToGrouped,
  geocodeAddress,
  inputStatus,
} from '@/utils';

const validationSchema = Yup.object({
  vision: Yup.string().required('Vision is required'),
  website: Yup.string().required('Website is required'),
  industryId: Yup.string().required('Industry is required'),
  numEmployee: Yup.string().required('Number of employees is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
});
type FormikData = Omit<
  OrganizationJson.OrganizationInput,
  'name' | 'bio' | 'ownerId' | 'logoId'
>;
interface Props {
  loading?: boolean;
  editable?: boolean;
  company?: OrganizationJson.Organization;
  refetch?: () => void;
}
const CompanyDetailSection: React.FC<Props> = ({
  company,
  editable,
  loading,
  refetch,
}) => {
  const { data: { data: industries = [] } = {}, isFetching } =
    useIndustriesQuery({ limit: 1000 });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: appConfig.GOOGLE_MAP_API_KEY,
    libraries: appConfig.libraries,
  });
  const [center, setCenter] = useState<AppTypes.Position | undefined>(
    undefined
  );
  const [editing, setEditing] = useState(false);
  const [updateOrg, { isSuccess, isLoading: updating }] =
    useUpdateOrganizationMutation();
  const initialValues = {
    id: company?.id,
    vision: company?.vision || '',
    website: company?.website || '',
    industryId: company?.industryId || '',
    numEmployee: company?.numEmployee || '',
    country: company?.country || '',
    state: company?.state || '',
    city: company?.city || '',
    zipcode: company?.zipcode || '',
    address1: company?.address1 || '',
    address2: company?.address2 || '',
  };
  const formik = useFormik<FormikData>({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      await updateOrg(v);
    },
  });
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnCancel = () => {
    setEditing(false);
  };
  const groupedIndustries = useMemo(
    () => formatIndustriesToGrouped(industries),
    [industries]
  );
  useEffect(() => {
    if (isSuccess) {
      refetch?.();
      setEditing(false);
    }
  }, [updating, isSuccess]);
  useEffect(() => {
    const loadPosition = async () => {
      const address = formatAddressFromLocation(company);
      const position = await geocodeAddress(address);
      setCenter(position);
    };
    if (company) {
      loadPosition();
    }
    formik.resetForm();
  }, [company]);
  return (
    <UICard>
      <UIBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h5" marginBottom={0}>
          Details
        </Typography>
        {editable && (
          <ProfileEditButton
            editing={editing}
            saving={updating}
            onClickSave={() => formik.handleSubmit()}
            onClickCancel={handleOnCancel}
            onClickEdit={handleOnEdit}
          />
        )}
      </UIBox>
      <UIBox display="flex" gap={50}>
        {editing && (
          <UIBox
            flexGrow={1}
            maxWidth={470}
            display="flex"
            flexDirection="column"
            gap={20}
          >
            <UIBox>
              <RichEditor
                label="Our vision"
                defaultValue={formik.values.vision}
                onChange={(v) => formik.setFieldValue('vision', v)}
                onBlur={() => formik.setFieldTouched('vision', true)}
                {...inputStatus(formik, 'vision')}
              />
            </UIBox>
            <Autocomplete
              label="What kind of industry will in need?"
              fullWidth
              variant="secondary"
              isLoading={isFetching}
              options={groupedIndustries}
              getOptionLabel={(industry) => industry.name}
              getOptionValue={(industry) => industry.id}
              value={industries.find((m) => formik.values.industryId === m.id)}
              onChange={(v) => formik.setFieldValue('industryId', v?.id)}
              isClearable
              onBlur={() => formik.setFieldTouched('industryId')}
              {...inputStatus(formik, 'industryId')}
            />
            <Autocomplete
              label="Your company's number of employees"
              fullWidth
              variant="secondary"
              options={orgSizeOptions}
              value={orgSizeOptions.find(
                (m) => formik.values.numEmployee === m.value
              )}
              onChange={(v) => formik.setFieldValue('numEmployee', v?.value)}
              isClearable
              onBlur={() => formik.setFieldTouched('numEmployee')}
              {...inputStatus(formik, 'numEmployee')}
            />
            <Input
              label="Site URL"
              fullWidth
              variant="secondary"
              {...formik.getFieldProps('website')}
              {...inputStatus(formik, 'website')}
            />
          </UIBox>
        )}
        {!editing && (
          <UIBox flexGrow={1} maxWidth={470}>
            <Typography
              fontWeight={500}
              color={theme.colors.textPrimary}
              marginBottom={10}
            >
              Our vision
            </Typography>
            <UIBox mb={1}>
              {company?.vision
                ?.split('\n')
                .map((text, id) => <Typography key={id}>{text}</Typography>)}
            </UIBox>
            <UIBox display="flex" flexDirection="column" gap={5}>
              <UIBox display="flex" gap={10}>
                <IconShieldDone />
                <Typography fontSize={14}>{company?.industry?.name}</Typography>
              </UIBox>
              <UIBox display="flex" gap={10}>
                <IconUsers3 />
                <Typography fontSize={14}>
                  ({company?.numEmployee} employees)
                </Typography>
              </UIBox>
              <UIBox display="flex" gap={10}>
                <IconEarth />
                <Typography fontSize={14}>{company?.website}</Typography>
              </UIBox>
            </UIBox>
          </UIBox>
        )}
        <UIDivider vertical height={306} style={{ alignSelf: 'center' }} />

        <UIBox
          flexGrow={1}
          display="flex"
          flexDirection="column"
          gap={20}
          maxWidth="50%"
        >
          {!editing && (
            <Typography
              fontWeight={500}
              color={theme.colors.textPrimary}
              marginBottom={10}
            >
              Location
              <Typography
                variant="span"
                fontSize={14}
                fontWeight={500}
                color={theme.colors.textDescription}
                marginLeft={10}
              >
                {formatAddressFromLocation(company)}
              </Typography>
            </Typography>
          )}
          {editing && (
            <LocationAutocomplete
              label="Search Location"
              fullWidth
              variant="secondary"
              value={formatAddressFromLocation(formik.values)}
              onChange={(formattedAddress, position, location) => {
                setCenter(position);
                formik.setValues({
                  ...formik.values,
                  ...location,
                });
              }}
              onBlur={() => formik.setFieldTouched('state')}
              status={
                formik.touched.state &&
                (formik.errors.country ||
                  formik.errors.state ||
                  formik.errors.city)
                  ? 'error'
                  : undefined
              }
              statusLabel={
                (formik.touched.state &&
                  (formik.errors.country ||
                    formik.errors.state ||
                    formik.errors.city)) ||
                ''
              }
            />
          )}
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '323px',
              }}
              center={center}
              zoom={10}
              options={{
                fullscreenControl: false,
              }}
            >
              {center && <Marker position={center} />}
            </GoogleMap>
          )}
        </UIBox>
      </UIBox>
    </UICard>
  );
};

export default CompanyDetailSection;
