import { AppUserAvatar, AppUserStatusTag } from '@/components/App';
import {
  IconDelete,
  IconEdit1,
  IconEllipsis,
  IconSearch,
  IconSwap,
} from '@/assets/icons';
import {
  UIBox,
  Dropdown,
  DropdownItem,
  IconButton,
  Input,
  Typography,
  DataTableColumn,
  DataTable,
} from '@/components/UI';
import { DropdownButton } from '@/components/UI/Dropdown';
import { theme } from '@/constants';
import { RoleNames, SortDirection, UserStatus } from '@/enums';
import { SidebarWorkerDetail } from '@/modules/Job';
import { useSearchParams } from 'react-router-dom';
import { AppTypes, UserJson } from '@/types';
import { useEffect, useState } from 'react';
import { useDeleteUserMutation, useGetUsersQuery } from '@/redux/apis';
import { useConfirmModal } from '@/hooks';
import { allUserStatuses } from '@/enums/users';

const AdminWorkersPage: React.FC = () => {
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();
  const [deleteUser, { isSuccess: isDeleted }] = useDeleteUserMutation();
  const [pagination, setPagination] = useState<AppTypes.PaginationParam>({
    search: '',
    sortField: 'createdAt',
    sortBy: SortDirection.DESC,
    offset: 0,
    limit: 10,
  });
  const [status, setStatus] = useState<UserStatus | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickWorkerCard = (workerId: string) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.workerId = `${workerId}`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  const { data: { data: users = [], total = 0 } = {}, isLoading } =
    useGetUsersQuery({
      ...pagination,
      role: 'workers',
      status,
    });
  const columns: DataTableColumn<UserJson.User>[] = [
    {
      field: 'id',
      label: 'ID',
      renderer: (row, id) => <Typography fontSize={14}>{id + 1}</Typography>,
    },
    {
      field: 'img',
      renderer: (row) => <AppUserAvatar user={row} size={30} />,
    },
    {
      field: 'firstName',
      label: 'Name',
      sortable: true,
      renderer: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      field: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      field: 'role',
      label: 'Role',
      renderer: (row) => row.role?.name,
    },
    {
      field: 'status',
      label: 'Status',
      sortable: true,
      renderer: (row) => <AppUserStatusTag status={row.status} />,
    },
    {
      field: 'createdAt',
      label: 'Joined At',
      align: 'right',
      sortable: true,
      renderer: (row) => new Date(row.createdAt).toLocaleDateString(),
    },
    {
      field: 'action',
      renderer: (row) => (
        <Dropdown
          button={
            <IconButton>
              <IconEllipsis />
            </IconButton>
          }
          disableDefaultToggleStyle
        >
          <DropdownButton variant="primary">
            <IconEdit1 />
            Edit
          </DropdownButton>
          <DropdownButton
            variant="danger"
            onClick={(e) => {
              e.stopPropagation();
              showConfirmModal({
                onConfirm: async () =>
                  await deleteUser({ userId: row?.id || '' }),
              });
            }}
          >
            <IconDelete width={8} height={9} />
            Delete
          </DropdownButton>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    if (isDeleted) {
      hideConfirmModal();
    }
  }, [isDeleted]);
  return (
    <>
      <UIBox display="flex" justifyContent="space-between">
        <Typography variant="h5">Workers</Typography>
        <UIBox display="flex" gap={20}>
          <Input
            placeholder={
              <>
                <IconSearch color={theme.colors.icon} />
                Search
              </>
            }
            height={40}
            value={pagination.search}
            onChange={(e) =>
              setPagination({
                ...pagination,
                search: e.target.value,
              })
            }
          />
          <Dropdown
            button={
              <UIBox display="flex" alignItems="center" width={200} gap={10}>
                <IconSwap />
                {status ? <AppUserStatusTag status={status} /> : 'All Status'}
              </UIBox>
            }
            height={40}
          >
            <DropdownItem onClick={() => setStatus(undefined)}>
              All status
            </DropdownItem>
            {allUserStatuses.map((item, id) => (
              <DropdownItem key={id} onClick={() => setStatus(item)}>
                <AppUserStatusTag status={item} />
              </DropdownItem>
            ))}
          </Dropdown>
        </UIBox>
      </UIBox>
      <UIBox>
        <DataTable
          columns={columns}
          data={users}
          total={total}
          loading={isLoading}
          onClickRow={(row) => handleOnClickWorkerCard(row.id)}
          pagination={pagination}
          onUpdatePagination={(p) => setPagination({ ...pagination, ...p })}
        />
      </UIBox>
      <SidebarWorkerDetail role={RoleNames.SUPER_ADMIN} />
    </>
  );
};

export default AdminWorkersPage;
