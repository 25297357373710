import { Navigate, useRoutes } from 'react-router-dom';

import { default as HomePage } from './home';
import {
  AuthAccountCreatedPage,
  AuthChangePasswordPage,
  AuthCompanyInfoPage,
  AuthCompanyLocationPage,
  AuthCompanyLogoPage,
  AuthPage,
  AuthForgotPasswordPage,
  AuthLoginPage,
  AuthPasswordChangedPage,
  AuthRegisterPage,
  AuthUploadResumePage,
  AuthVerifyPage,
  AuthCallbackPage,
} from './auth';
import {
  OrgGoogleRegister,
  OrganizationRegister,
  Register,
  SponsorRegister,
  WorkerRegister,
} from '@/modules/Auth';
import {
  WorkerAccountSettingsPage,
  WorkerCompanyDetailPage,
  WorkerLayout,
  WorkerMessagesPage,
  WorkerNotificationsPage,
  WorkerPreferencePage,
  WorkerProfile,
  WorkerPublicProfile,
} from './worker';
import {
  WorkerJobDetailPage,
  WorkerJobsPage,
  WorkerMyJobsPage,
} from './worker/jobs';
import { WorkerJobs } from '@/modules/Job';
import {
  SponsorJobRouteCategory,
  WorkerJobRouteCategory,
  WorkerMyJobRouteCategory,
} from '@/enums';
import { appRoutes } from '@/constants';
import {
  NothingsApplications,
  WorkerApplications,
  WorkerAppliedJobs,
  WorkerInterviews,
  WorkerInvitedJobs,
  WorkerRecentlyViewed,
  WorkerSavedJobs,
} from '@/modules/Job/WorkerJobs';
import {
  SponsorAccountSettingsPage,
  SponsorLayout,
  SponsorMessagesPage,
  SponsorNotificationsPage,
  SponsorProfile,
  SponsorPublicProfile,
} from './sponsor';
import {
  SponsorHire,
  SponsorInviteWorkers,
  SponsorJobDetail,
  SponsorMyJobs,
  SponsorNewJob,
  SponsorReviewProposals,
  SponsorViewJobPostPage,
} from './sponsor/jobs';
import {
  OrgDashboardPage,
  OrgLayout,
  OrgMessagesPage,
  OrgNotificationsPage,
  OrgProfilePage,
  OrgProjectsPage,
  OrgSettingsPage,
  OrgSponsorsPage,
  OrgWorkersPage,
} from './org';
import {
  AdminDashboardPage,
  AdminIndustriesPage,
  AdminInviteesPage,
  AdminLayout,
  AdminMentorsPage,
  AdminMessagesPage,
  AdminNotificationsPage,
  AdminOrgDetailPage,
  AdminOrganizationsPage,
  AdminProfilePage,
  AdminProjectsPage,
  AdminSettingsPage,
  AdminSkillsPage,
  AdminSponsorsPage,
  AdminWorkersPage,
} from './admin';
import { Error, ErrorPageLayout } from './error';
import { Error404, Error500, Error503 } from '@/modules/Errors';
import { PublicLayout, SharedProfile } from './public';

function AppRoutes() {
  const routes = useRoutes([
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/auth/*',
      element: <AuthPage />,
      children: [
        { path: 'login', element: <AuthLoginPage /> },
        {
          path: 'register/*',
          element: <AuthRegisterPage />,
          children: [
            { path: '', element: <Register /> },
            { path: 'worker', element: <WorkerRegister /> },
            { path: 'sponsor', element: <SponsorRegister /> },
            { path: 'organization', element: <OrganizationRegister /> },
            { path: 'org-google', element: <OrgGoogleRegister /> },
          ],
        },
        { path: 'verify', element: <AuthVerifyPage /> },
        { path: 'callback', element: <AuthCallbackPage /> },
        { path: 'account-created', element: <AuthAccountCreatedPage /> },
        { path: 'forgot-password', element: <AuthForgotPasswordPage /> },
        { path: 'change-password', element: <AuthChangePasswordPage /> },
        { path: 'password-changed', element: <AuthPasswordChangedPage /> },

        { path: 'upload-resume', element: <AuthUploadResumePage /> },

        { path: 'company-location', element: <AuthCompanyLocationPage /> },
        { path: 'company-logo', element: <AuthCompanyLogoPage /> },
        { path: 'company-info', element: <AuthCompanyInfoPage /> },
        { path: '*', element: <Navigate to={appRoutes.login} replace /> },
      ],
    },
    {
      path: '/w/*',
      element: <WorkerLayout />,
      children: [
        { path: 'profile', element: <WorkerProfile /> },
        { path: 'public', element: <WorkerPublicProfile /> },
        { path: 'settings', element: <WorkerAccountSettingsPage /> },
        { path: 'preference', element: <WorkerPreferencePage /> },
        { path: 'job/:id', element: <WorkerJobDetailPage /> },
        { path: 'company/:id', element: <WorkerCompanyDetailPage /> },
        {
          path: 'jobs/*',
          element: <WorkerJobsPage />,
          children: [
            { path: WorkerJobRouteCategory.ALL, element: <WorkerJobs /> },
            { path: WorkerJobRouteCategory.NEW },
            {
              path: WorkerJobRouteCategory.MOST_RECENT,
            },
            { path: WorkerJobRouteCategory.SAVED },
            {
              path: '*',
              element: <Navigate to={appRoutes.workerFindWork()} replace />,
            },
          ],
        },
        {
          path: 'my-jobs/*',
          element: <WorkerMyJobsPage />,
          children: [
            {
              path: WorkerMyJobRouteCategory.APPLIED,
              element: <WorkerAppliedJobs />,
            },
            {
              path: WorkerMyJobRouteCategory.INVITED,
              element: <WorkerInvitedJobs />,
            },
            {
              path: WorkerMyJobRouteCategory.IN_PROGRESS,
              element: <NothingsApplications />,
            },
            {
              path: WorkerMyJobRouteCategory.COMPLETED,
            },
            {
              path: WorkerMyJobRouteCategory.ARCHIVE,
            },
            {
              path: WorkerMyJobRouteCategory.INTERVIEWS,
              element: <WorkerInterviews />,
            },
            {
              path: WorkerMyJobRouteCategory.APPLICATIONS,
              element: <WorkerApplications />,
            },
            {
              path: WorkerMyJobRouteCategory.SAVED_JOBS,
              element: <WorkerSavedJobs />,
            },
            {
              path: WorkerMyJobRouteCategory.RECENTLY_VIEWED,
              element: <WorkerRecentlyViewed />,
            },
            {
              path: '*',
              element: <Navigate to={appRoutes.workerMyJobs()} replace />,
            },
          ],
        },
        {
          path: 'messages',
          element: <WorkerMessagesPage />,
        },
        {
          path: 'notifications',
          element: <WorkerNotificationsPage />,
        },
        {
          path: '*',
          element: <Navigate to={appRoutes.workerFindWork()} replace />,
        },
      ],
    },
    {
      path: '/s/*',
      element: <SponsorLayout />,
      children: [
        {
          path: 'new-job',
          element: <SponsorNewJob />,
        },
        {
          path: 'my-jobs',
          element: <SponsorMyJobs />,
        },
        {
          path: 'job/:id/*',
          element: <SponsorViewJobPostPage />,
          children: [
            {
              path: SponsorJobRouteCategory.VIEW_JOB_POST,
              element: <SponsorJobDetail />,
            },
            {
              path: SponsorJobRouteCategory.INVITE_WORKERS,
              element: <SponsorInviteWorkers />,
            },
            {
              path: SponsorJobRouteCategory.REVIEW_PROPOSALS,
              element: <SponsorReviewProposals />,
            },
            {
              path: SponsorJobRouteCategory.CONTACTED_WORKERS,
              element: <SponsorHire />,
            },
            {
              path: '*',
              element: (
                <Navigate to={SponsorJobRouteCategory.VIEW_JOB_POST} replace />
              ),
            },
          ],
        },
        {
          path: 'workers/:id',
          element: <WorkerPublicProfile />,
        },
        { path: 'profile', element: <SponsorProfile /> },
        { path: 'public', element: <SponsorPublicProfile /> },
        {
          path: 'settings',
          element: <SponsorAccountSettingsPage />,
        },
        {
          path: 'messages',
          element: <SponsorMessagesPage />,
        },
        {
          path: 'notifications',
          element: <SponsorNotificationsPage />,
        },

        {
          path: '*',
          element: <Navigate to={appRoutes.sponsorMyJobs} replace />,
        },
      ],
    },
    {
      path: '/o/*',
      element: <OrgLayout />,
      children: [
        {
          path: 'dashboard',
          element: <OrgDashboardPage />,
        },
        {
          path: 'sponsors',
          element: <OrgSponsorsPage />,
        },
        {
          path: 'workers',
          element: <OrgWorkersPage />,
        },
        {
          path: 'workers/:id',
          element: <WorkerPublicProfile />,
        },
        {
          path: 'projects',
          element: <OrgProjectsPage />,
        },
        {
          path: 'projects/:id/*',
          element: <SponsorViewJobPostPage />,
          children: [
            {
              path: SponsorJobRouteCategory.VIEW_JOB_POST,
              element: <SponsorJobDetail />,
            },
            {
              path: SponsorJobRouteCategory.INVITE_WORKERS,
              element: <SponsorInviteWorkers />,
            },
            {
              path: SponsorJobRouteCategory.REVIEW_PROPOSALS,
              element: <SponsorReviewProposals />,
            },
            {
              path: SponsorJobRouteCategory.CONTACTED_WORKERS,
              element: <SponsorHire />,
            },
            {
              path: '*',
              element: (
                <Navigate to={SponsorJobRouteCategory.VIEW_JOB_POST} replace />
              ),
            },
          ],
        },
        {
          path: 'profile',
          element: <OrgProfilePage />,
        },
        {
          path: 'settings',
          element: <OrgSettingsPage />,
        },
        {
          path: 'messages',
          element: <OrgMessagesPage />,
        },
        {
          path: 'notifications',
          element: <OrgNotificationsPage />,
        },
        {
          path: '',
          element: <Navigate to={appRoutes.orgDashboard} />,
        },
        { path: '*', element: <Error /> },
      ],
    },
    {
      path: '/admin/*',
      element: <AdminLayout />,
      children: [
        {
          path: 'dashboard',
          element: <AdminDashboardPage />,
        },
        {
          path: 'workers',
          element: <AdminWorkersPage />,
        },
        {
          path: 'workers/:id',
          element: <WorkerPublicProfile />,
        },
        {
          path: 'sponsors',
          element: <AdminSponsorsPage />,
        },
        {
          path: 'mentors',
          element: <AdminMentorsPage />,
        },
        {
          path: 'invitees',
          element: <AdminInviteesPage />,
        },
        {
          path: 'organizations',
          element: <AdminOrganizationsPage />,
        },
        {
          path: 'organizations/:id',
          element: <AdminOrgDetailPage />,
        },
        {
          path: 'projects',
          element: <AdminProjectsPage />,
        },
        {
          path: 'projects/:id/*',
          element: <SponsorViewJobPostPage />,
          children: [
            {
              path: SponsorJobRouteCategory.VIEW_JOB_POST,
              element: <SponsorJobDetail />,
            },
            {
              path: SponsorJobRouteCategory.INVITE_WORKERS,
              element: <SponsorInviteWorkers />,
            },
            {
              path: SponsorJobRouteCategory.REVIEW_PROPOSALS,
              element: <SponsorReviewProposals />,
            },
            {
              path: SponsorJobRouteCategory.CONTACTED_WORKERS,
              element: <SponsorHire />,
            },
            {
              path: '*',
              element: (
                <Navigate to={SponsorJobRouteCategory.VIEW_JOB_POST} replace />
              ),
            },
          ],
        },
        {
          path: 'industries',
          element: <AdminIndustriesPage />,
        },
        {
          path: 'skills',
          element: <AdminSkillsPage />,
        },
        {
          path: 'profile',
          element: <AdminProfilePage />,
        },
        {
          path: 'settings',
          element: <AdminSettingsPage />,
        },
        {
          path: 'messages',
          element: <AdminMessagesPage />,
        },
        {
          path: 'notifications',
          element: <AdminNotificationsPage />,
        },
        {
          path: '',
          element: <Navigate to={appRoutes.adminDashboard} />,
        },
        {
          path: '*',
          element: <Error />,
        },
      ],
    },
    {
      path: '/public/*',
      element: <PublicLayout />,
      children: [
        {
          path: 'profile/:id',
          element: <SharedProfile />,
        },
      ],
    },
    {
      path: '/error/*',
      element: <ErrorPageLayout />,
      children: [
        {
          path: '404',
          element: <Error404 />,
        },
        {
          path: '500',
          element: <Error500 />,
        },
        {
          path: '503',
          element: <Error503 />,
        },
        {
          path: '*',
          element: <Navigate to={'404'} replace />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to={appRoutes.error} replace />,
    },
  ]);

  return routes;
}

export default AppRoutes;
