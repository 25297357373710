import { appConfig } from '@/constants';
import {
  BaseQueryApi,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { getAccessToken } from './cookies';
import { formatISO, parse, format, formatDistanceToNow } from 'date-fns';
import { AppTypes, AuthJson, IndustryJson, UserJson } from '@/types';
import { UserAssetType } from '@/enums';
import { FormikErrors, FormikTouched } from 'formik';
import { setAuth } from '@/redux/reducers';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

export const determineInputType = (value: string) => {
  const usernamePattern = /^[a-zA-Z0-9_]{3,16}$/;
  const phoneNumberPattern =
    /^(\+?1)?\d{10}$|^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (phoneNumberPattern.test(value)) {
    return 'phone';
  } else if (usernamePattern.test(value)) {
    return 'username';
  } else if (emailPattern.test(value)) {
    return 'email';
  } else {
    return 'unknown';
  }
};

const baseApiUrl = appConfig.API_URL;
const resumeUploadApiUrl = appConfig.PARSE_API_URL;

const parseBaseQuery = fetchBaseQuery({
  baseUrl: resumeUploadApiUrl,
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // const token = getAccessToken();
    // if (token) {
    //   headers.set('Authorization', `Bearer ${token}`);
    // }
    headers.set('Content-type', 'application/json; charset=UTF-8');
    return headers;
  },
});

export const parseBaseQueryWithErrorHandler = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await parseBaseQuery(args, api, extraOptions);
  return handleApiError(result);
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseApiUrl,
});

export const defaultBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await baseQuery(args, api, extraOptions);
  return handleApiError(result);
};

const authorizeBaseQuery = fetchBaseQuery({
  baseUrl: baseApiUrl,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getAccessToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('Content-type', 'application/json');
    headers.set('Accept', 'application/json');
    return headers;
  },
});

export const authorizeQueryWithRefreshToken = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  let result = await authorizeBaseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // Assuming we have a refresh token and a method to refresh the token
    const refreshResult = await authorizeBaseQuery(
      { url: '/auth/refresh', method: 'POST' },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      const tokenData = refreshResult.data as AuthJson.Response;
      api.dispatch(setAuth(tokenData)); // Action to set the new token in the store
      // Retry the original query with the new token
      result = await authorizeBaseQuery(args, api, extraOptions);
    }
  }
  return handleApiError(result);
};

function handleApiError(
  result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
) {
  if (result.error) {
    let message = '';
    switch (result.error.status) {
      // Client Error Responses
      case 400:
        message = 'Bad Request';
        break;
      case 401:
        message = 'Unauthorized';
        break;
      case 402:
        message = 'Payment Required';
        break;
      case 403:
        message = 'Forbidden access';
        break;
      case 404:
        message = 'Resource not found';
        break;
      case 405:
        message = 'Method Not Allowed';
        break;
      case 406:
        message = 'Not Acceptable';
        break;
      case 407:
        message = 'Proxy Authentication Required';
        break;
      case 408:
        message = 'Request Timeout';
        break;
      case 409:
        message = 'Conflict';
        break;
      case 410:
        message = 'Gone';
        break;
      case 411:
        message = 'Length Required';
        break;
      case 412:
        message = 'Precondition Failed';
        break;
      case 413:
        message = 'Payload Too Large';
        break;
      case 414:
        message = 'URI Too Long';
        break;
      case 415:
        message = 'Unsupported Media Type';
        break;
      case 416:
        message = 'Range Not Satisfiable';
        break;
      case 417:
        message = 'Expectation Failed';
        break;
      case 418:
        message = "I'm a teapot";
        break;
      case 422:
        message = 'Unprocessable Entity';
        break;
      case 425:
        message = 'Too Early';
        break;
      case 426:
        message = 'Upgrade Required';
        break;
      case 428:
        message = 'Precondition Required';
        break;
      case 429:
        message = 'Too Many Requests';
        break;
      case 431:
        message = 'Request Header Fields Too Large';
        break;
      case 451:
        message = 'Unavailable For Legal Reasons';
        break;

      // Server Error Responses
      case 500:
        message = 'Internal Server Error';
        break;
      case 501:
        message = 'Not Implemented';
        break;
      case 502:
        message = 'Bad Gateway';
        break;
      case 503:
        message = 'Service Unavailable';
        break;
      case 504:
        message = 'Gateway Timeout';
        break;
      case 505:
        message = 'HTTP Version Not Supported';
        break;
      case 506:
        message = 'Variant Also Negotiates';
        break;
      case 507:
        message = 'Insufficient Storage';
        break;
      case 508:
        message = 'Loop Detected';
        break;
      case 510:
        message = 'Not Extended';
        break;
      case 511:
        message = 'Network Authentication Required';
        break;
      default:
        message = 'An unexpected error occurred';
    }
    if (!result.error.data) {
      result.error.data = { message };
    }
  }
  return result;
}

export const formatDateToNow = (date: string) => {
  if (typeof date === 'undefined') {
    return 'Invalid';
  }
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
};

export function formatPhoneNumber(phoneNumber: string): string | null {
  const cleaned: string = phoneNumber.replace(/\D/g, '');

  const isValidLength: boolean =
    cleaned.length === 10 ||
    (cleaned.length === 11 && cleaned.charAt(0) === '1');

  const cleanedNumber: string =
    isValidLength && cleaned.length === 11 ? cleaned.substring(1) : cleaned;

  const isValidPrefix: boolean = /^[2-9]/.test(cleanedNumber.charAt(0));

  if (isValidLength && isValidPrefix) {
    const areaCode: string = cleanedNumber.substring(0, 3);
    const exchange: string = cleanedNumber.substring(3, 6);
    const lineNumber: string = cleanedNumber.substring(6);
    return `(${areaCode}) ${exchange}-${lineNumber}`;
  }

  return null;
}

interface FormatOptions {
  locale?: string;
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}
export function formatCurrency(
  value: number = 0,
  options: FormatOptions = {
    locale: 'en-US',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }
): string {
  const formatter = new Intl.NumberFormat(options.locale, {
    style: 'currency',
    currency: options.currency,
    // You can add more options here as needed
    minimumFractionDigits: options.minimumFractionDigits,
    maximumFractionDigits: options.maximumFractionDigits,
  });

  return formatter.format(value);
}

export function getFileExtension(fileName: string): string | undefined {
  // eslint-disable-next-line no-useless-escape
  const match = fileName.match(/\.[^\.]+$/);
  return match ? match[0].substring(1) : undefined; // Remove the dot at the beginning
}
export function formatFileSize(bytes: number): string {
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';

  const i: number = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}

export function getFormatISODate(date: string): string {
  if (date.includes(' ')) {
    return formatISO(parse(date, 'MMMM yyyy', new Date()));
  } else {
    return formatISO(parse(date, 'yyyy', new Date()));
  }
}

export function getMonthYearFromString(dateString: string = ''): {
  month?: string;
  year?: number;
} {
  if (!dateString) return { month: undefined, year: undefined };
  const date = format(new Date(dateString), 'MMMM-yyyy');
  const [month, year] = date.split('-');
  return {
    month,
    year: parseInt(year),
  };
}

export function formatAddressFromLocation(
  location?: AppTypes.Location
): string {
  if (!location) return '';
  let parts: string[] = [];

  if (location.address1) {
    parts.push(location.address1);
  }

  if (location.address2) {
    parts.push(location.address2);
  }

  // Combining city, state, and zipcode in a single line if they exist
  let cityStateZip = [];
  if (location.city) {
    cityStateZip.push(location.city);
  }
  if (location.state) {
    cityStateZip.push(location.state);
  }
  if (location.zipcode) {
    cityStateZip.push(location.zipcode);
  }

  if (cityStateZip.length > 0) {
    parts.push(cityStateZip.join(', '));
  }

  if (location.country) {
    parts.push(location.country);
  }

  // Join all parts with a comma and a space, skipping over any empty parts
  return parts.join(', ');
}

export function formatIndustriesToGrouped(industries: IndustryJson.Industry[]) {
  const grouped = industries.reduce(
    (
      grouped: Record<
        string,
        { label: string; options: IndustryJson.Industry[] }
      >,
      item
    ) => {
      if (!item.parentId) return grouped;
      const key = item.parentId;
      if (!grouped[key])
        grouped[key] = {
          label: item.name,
          options: [],
        };
      grouped[key].options.push(item);
      return grouped;
    },
    {}
  );
  const groupedSorted = Object.values(grouped).sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  return groupedSorted;
}

export function downloadFile(url: string, name: string) {
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  document.body.appendChild(anchor);
  anchor.href = url;
  anchor.download = name;
  anchor.click();
  document.body.removeChild(anchor);
}
export const geocodeAddress = async (
  address: string
): Promise<AppTypes.Position | undefined> => {
  const url = new URL(`https://maps.googleapis.com/maps/api/geocode/json`);
  url.search = new URLSearchParams({
    address: address,
    key: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '', // Your API key
  }).toString();
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === 'OK') {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    } else {
      console.error('Geocoding error:', data.error_message);
      return undefined;
    }
  } catch (error) {
    console.error('Network error:', error);
    return undefined;
  }
};

export const getUserAvatarUrl = (user?: UserJson.User) => {
  const avatarAssets =
    user?.userAssets?.filter((item) => item.type === UserAssetType.AVATAR) ||
    [];
  if (avatarAssets.length === 0) return '';
  return (
    avatarAssets.reduce((latest, item) =>
      new Date(item.createdAt) > new Date(latest.createdAt) ? item : latest
    ).asset?.url || ''
  );
};

export function getInputStatus<Values>(
  formik: { errors: FormikErrors<Values>; touched: FormikTouched<Values> },
  field: keyof Values
): 'error' | 'info' | 'valid' | undefined {
  return formik.touched[field] && formik.errors[field] ? 'error' : undefined;
}

export function getInputError<Values>(
  formik: { errors: FormikErrors<Values>; touched: FormikTouched<Values> },
  field: keyof Values
) {
  return (formik.touched[field] && formik.errors[field]) || '';
}

export function inputStatus<Values>(
  formik: { errors: FormikErrors<Values>; touched: FormikTouched<Values> },
  field: keyof Values
) {
  return {
    status: getInputStatus(formik, field),
    statusLabel: getInputError(formik, field),
  };
}
