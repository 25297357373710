import { theme } from '@/constants';
import { SortDirection } from '@/enums';
import { AppTypes } from '@/types';

export default function IconSort({
  width = 10,
  height = 16,
  color = theme.colors.primary,
  disableColor = theme.colors.gray,
  sort,
}: AppTypes.IconProps & { sort?: SortDirection; disableColor?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 100 160"
    >
      <polygon
        points="50.0,10 10,70.0 90,70.0"
        fill={sort === SortDirection.ASC ? color : disableColor}
      />
      <polygon
        points="10,90.0 90,90.0 50.0,150.0"
        fill={sort === SortDirection.DESC ? color : disableColor}
      />
    </svg>
  );
}
