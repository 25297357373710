import {
  IconBarChart,
  IconCalendar,
  IconDone,
  IconRoundedArrowDown,
  IconRoundedArrowUp,
} from '@/assets/icons';
import { Typography, UIBox, UICard } from '@/components/UI';
import { theme } from '@/constants';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
interface ChartData {
  name: string;
  value1: number;
  value2: number;
}
const data: ChartData[] = [
  { name: 'SEP', value1: 190, value2: 165 },
  { name: 'OCT', value1: 165, value2: 158 },
  { name: 'NOV', value1: 200, value2: 157 },
  { name: 'DEC', value1: 190, value2: 170 },
  { name: 'JAN', value1: 200, value2: 160 },
  { name: 'FEB', value1: 210, value2: 150 },
];

const HireStatusWidget: React.FC = () => {
  return (
    <UICard height={'100%'}>
      <UIBox display="flex" justifyContent="space-between" mb={1}>
        <UIBox
          background="#F4F7FE"
          borderRadius={7}
          width={123}
          height={37}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={5}
        >
          <IconCalendar />
          <Typography
            fontSize={14}
            fontWeight={500}
            color={theme.colors.gray540}
          >
            This month
          </Typography>
        </UIBox>
        <UIBox
          width={33}
          height={33}
          background={theme.colors.gray960}
          borderRadius={8}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconBarChart />
        </UIBox>
      </UIBox>
      <UIBox display="flex" gap={30}>
        <UIBox mt={2}>
          <UIBox display="flex" alignItems="center" gap={10} mb={0.3}>
            <Typography fontSize={28} fontWeight={600} color="#2B3674">
              200
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={700}
              color={theme.colors.success}
            >
              +2.45%
            </Typography>
            <IconRoundedArrowUp
              color={theme.colors.success}
              width={9}
              height={6}
            />
          </UIBox>
          <Typography
            fontSize={14}
            fontWeight={500}
            color={theme.colors.gray540}
          >
            Total Hires
          </Typography>
          <UIBox display="flex" alignItems="center" gap={10} mb={0.3} mt={1}>
            <Typography
              fontSize={28}
              fontWeight={600}
              color={theme.colors.secondary}
            >
              160
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={700}
              color={theme.colors.error}
            >
              -2.45%
            </Typography>
            <IconRoundedArrowDown
              color={theme.colors.error}
              width={9}
              height={7}
            />
          </UIBox>
          <Typography
            fontSize={14}
            fontWeight={500}
            color={theme.colors.gray540}
          >
            Total Hires
          </Typography>
          <UIBox display="flex" alignItems="center" gap={10} mb={0.3} mt={1}>
            <IconDone />
            <Typography color={theme.colors.success} fontWeight={500}>
              On track
            </Typography>
          </UIBox>
        </UIBox>
        <UIBox flexGrow={1}>
          <ResponsiveContainer width="100%" height={250}>
            <LineChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3" vertical={false} />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis domain={['dataMin - 10', 'dataMax + 10']} hide />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value1"
                stroke={theme.colors.primary}
                strokeWidth={4}
                dot={{ r: 0 }}
                activeDot={{
                  r: 6,
                  stroke: theme.colors.primary,
                  strokeWidth: 4,
                  fill: 'white',
                }}
              />
              <Line
                type="monotone"
                dataKey="value2"
                stroke={theme.colors.secondary}
                activeDot={{
                  r: 6,
                  stroke: theme.colors.secondary,
                  strokeWidth: 4,
                  fill: 'white',
                }}
                dot={{ r: 0 }}
                strokeWidth={4}
              />
            </LineChart>
          </ResponsiveContainer>
        </UIBox>
      </UIBox>
    </UICard>
  );
};

export default HireStatusWidget;
