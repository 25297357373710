import { AppSideModal } from '@/components/App';
import {
  IconArrowDown,
  IconArrowSquareOut,
  IconPaperclip,
} from '@/assets/icons';
import {
  UIBox,
  UIDivider,
  IconButton,
  Image,
  ReadMoreButton,
  RichEditor,
  Tag,
  Typography,
} from '@/components/UI';
import { useEffect, useState } from 'react';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';
import JobProperties from './WorkerJobs/JobProperties';
import { appRoutes, theme } from '@/constants';
import JobApplyButton from './WorkerJobs/JobApplyButton';
import { WorkerLayoutContextType } from '@/pages/worker/layout';
import { RoleNames } from '@/enums';
import { jobsMock } from '@/mock';
import { ProjectJson } from '@/types';
import { WorkerCompanySummaryCard } from './WorkerJobs';

const SidebarJobDetail: React.FC<{
  viewOnly?: boolean;
  role?: RoleNames;
}> = ({ viewOnly = RoleNames.WORKER, role }) => {
  const { applyToJob } = useOutletContext<WorkerLayoutContextType>();

  const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [job, setJob] = useState<ProjectJson.Project | undefined>(undefined);
  useEffect(() => {
    const jobId = searchParams.get('jobId');
    setShow(!!jobId);
    if (jobId) {
      setJob(jobsMock.find((item) => item.id === parseInt(jobId)));
    } else {
      setJob(undefined);
    }
  }, [searchParams]);

  const handleCloseSideModal = () => {
    const { jobId, ...currentSearchParams } = Object.fromEntries(searchParams);
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  const matching = Math.round(Math.random() * 50) + 50;
  const handleClickApply = () => {
    applyToJob?.();
  };
  const handleClickBookmark = () => {};
  const isWorker = role === RoleNames.WORKER;
  const isOrgAdmin = role === RoleNames.ORG_ADMIN;
  return (
    <AppSideModal
      isOpen={show}
      onClose={handleCloseSideModal}
      width={isWorker ? 500 : 730}
      customTools={
        <Link
          to={
            isOrgAdmin
              ? appRoutes.orgProjectDetail(job?.id)
              : role === RoleNames.SPONSOR
                ? appRoutes.sponsorJobDetail(job?.id)
                : appRoutes.workerJobDetail(job?.id)
          }
          target="_blank"
        >
          <IconButton>
            <IconArrowSquareOut />
          </IconButton>
        </Link>
      }
    >
      <UIBox display="flex" flexDirection="column" gap={30} px={1.5} py={2}>
        <UIBox>
          {isWorker && (
            <UIBox
              px={1}
              borderRadius={10}
              width={'fit-content'}
              background={
                matching > 80 ? theme.colors.green600 : theme.colors.yellow500
              }
              mb={0.5}
            >
              {`${matching}% skills matching`}
            </UIBox>
          )}
          <UIBox>
            <Typography
              fontSize={18}
              fontWeight={600}
              lineHeight="24px"
              color={theme.colors.textPrimary}
              marginBottom={2}
            >
              {job?.title}
            </Typography>
            <UIBox
              display="flex"
              alignItems="center"
              gap={10}
              flexDirection={!isOrgAdmin ? 'row' : 'row-reverse'}
              justifyContent={!isOrgAdmin ? 'flex-start' : 'space-between'}
            >
              <UIBox display="flex" alignItems="center" gap={10}>
                <Image src={'job?.companyData?.logo'} width={12} height={15} />
                <Typography
                  fontSize={14}
                  lineHeight="19px"
                  color={theme.colors.textPrimary}
                >
                  {job?.companyData?.name}
                </Typography>
                <Typography fontSize={12}>
                  ({job?.companyData?.numEmployee} employees)
                </Typography>
              </UIBox>
              <UIBox display="flex" alignItems="center" gap={10}>
                <Typography
                  fontSize={14}
                  lineHeight="17px"
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                  style={{ textDecoration: 'underline' }}
                >
                  {`${job?.poster.firstName} ${job?.poster.lastName}`}
                </Typography>
                <Typography fontSize={12}>Posted 23 minutes ago</Typography>
              </UIBox>
            </UIBox>
          </UIBox>
        </UIBox>
        <JobProperties skillsMatching={matching} job={job} />
        <UIBox>
          <Typography
            color={theme.colors.textPrimary}
            fontSize={18}
            fontWeight={600}
            marginBottom={5}
          >
            Job Description
          </Typography>
          {job?.description
            ?.split('\n')
            .map((d, i) => <Typography key={i}>{d}</Typography>)}
          <UIBox mb={1} />
          <ReadMoreButton onClick={() => null} color={theme.colors.secondary}>
            Read More
            <IconArrowDown color={theme.colors.secondary} />
          </ReadMoreButton>
        </UIBox>
        <UIBox>
          <Typography
            color={theme.colors.textPrimary}
            fontSize={18}
            fontWeight={600}
            marginBottom={5}
          >
            Job budget
          </Typography>
          <Typography>
            ***$1,000 Sign-On Bonus Available!*** <br />
            Starting Pay Rate: $30.13 - $33.14 / hour
          </Typography>
        </UIBox>
        <UIBox>
          <Typography
            color={theme.colors.textPrimary}
            fontSize={18}
            fontWeight={600}
            marginBottom={5}
          >
            Skills
          </Typography>
          <UIBox display="flex" flexWrap="wrap" gap={10} mb={1} mt={1}>
            {job?.projectSkills
              ?.slice(0, 5)
              .map((skill, id) => <Tag key={id}>{skill.name}</Tag>)}
          </UIBox>
          <ReadMoreButton onClick={() => null} color={theme.colors.secondary}>
            Read More
            <IconArrowDown color={theme.colors.secondary} />
          </ReadMoreButton>
        </UIBox>

        <UIBox>
          <Typography
            color={theme.colors.textPrimary}
            fontSize={18}
            fontWeight={600}
            marginBottom={10}
          >
            Company Info
          </Typography>
          <WorkerCompanySummaryCard
            company={job?.companyData}
            hideDescription
          />
        </UIBox>
        {!viewOnly && <UIDivider />}
        {!viewOnly && (
          <UIBox>
            <Typography
              color={theme.colors.textPrimary}
              fontSize={18}
              fontWeight={600}
              marginBottom={16}
            >
              +Add a cover letter
            </Typography>
            <UIBox position="relative">
              <RichEditor />
              <UIBox position="absolute" bottom={10} right={0}>
                <IconButton>
                  <IconPaperclip />
                </IconButton>
              </UIBox>
            </UIBox>
          </UIBox>
        )}
      </UIBox>

      {!viewOnly && (
        <UIBox
          position="sticky"
          bottom={0}
          background={theme.colors.white}
          boxShadow="10px -1px 30px 0px #7B7D9633"
          py={1}
          px={1.5}
        >
          <JobApplyButton
            onClickApply={handleClickApply}
            onClickBookmark={handleClickBookmark}
          />
        </UIBox>
      )}
    </AppSideModal>
  );
};

export default SidebarJobDetail;
