import {
  IconDashboard,
  IconProfile,
  IconSetting,
  IconUsers2,
  IconUsers3,
  IconWork,
} from '@/assets/icons';
import { appRoutes } from '@/constants';
import AsideNavLinkItem from './AsideNavLinkItem';

const OrgAsideBar: React.FC = () => {
  return (
    <>
      <AsideNavLinkItem to={appRoutes.orgDashboard} icon={<IconDashboard />}>
        Dashboard
      </AsideNavLinkItem>
      <AsideNavLinkItem to={appRoutes.orgSponsors} icon={<IconUsers2 />}>
        Sponsors
      </AsideNavLinkItem>
      <AsideNavLinkItem icon={<IconUsers3 />} to={appRoutes.orgWorkers}>
        Workers
      </AsideNavLinkItem>
      <AsideNavLinkItem icon={<IconWork />} to={appRoutes.orgProjects}>
        Projects
      </AsideNavLinkItem>
      <AsideNavLinkItem
        icon={<IconProfile width={14} height={17} />}
        to={appRoutes.orgProfile}
      >
        Profile
      </AsideNavLinkItem>
      <AsideNavLinkItem
        icon={<IconSetting width={16} height={16} />}
        to={appRoutes.orgSettings}
      >
        Settings
      </AsideNavLinkItem>
    </>
  );
};

export default OrgAsideBar;
