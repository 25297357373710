import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconShieldDone({
  width = 16,
  height = 18,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77276 17.2627C7.86505 17.3109 7.96824 17.3354 8.07143 17.3346C8.17462 17.3338 8.27697 17.3084 8.37009 17.2594L11.3433 15.67C12.1865 15.2206 12.8467 14.718 13.3618 14.1329C14.4818 12.8581 15.0934 11.2311 15.0825 9.5535L15.0473 4.01962C15.0439 3.38223 14.6253 2.81348 14.0061 2.60673L8.47496 0.750939C8.1419 0.638169 7.77696 0.640621 7.44977 0.756659L1.93955 2.67864C1.32376 2.89356 0.91268 3.4664 0.916036 4.10461L0.951272 9.6344C0.962178 11.3145 1.59475 12.9341 2.7332 14.1958C3.25335 14.7728 3.91947 15.268 4.77184 15.7101L7.77276 17.2627ZM6.985 10.7582C7.10916 10.8775 7.27024 10.9364 7.43132 10.9347C7.5924 10.9339 7.75264 10.8734 7.87512 10.7525L11.1244 7.54919C11.3685 7.30813 11.366 6.92079 11.1193 6.68299C10.8718 6.44519 10.4733 6.44683 10.2292 6.68789L7.42209 9.45483L6.27273 8.35002C6.02524 8.11222 5.62758 8.11467 5.38261 8.35574C5.13847 8.5968 5.14099 8.98414 5.38848 9.22194L6.985 10.7582Z"
        fill={color}
      />
    </svg>
  );
}
