import { createApi } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { AppTypes, UserJson } from '@/types';
import { defaultBaseQuery } from '@/utils';

export const defaultPublicApi = createApi({
  reducerPath: 'defaultPublicApi',
  baseQuery: defaultBaseQuery,
  tagTypes: ['DefaultPublicApi'],
  endpoints: (builder) => ({
    contactUs: builder.mutation<AppTypes.Message, AppTypes.ContactUsInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: '/contact-us',
            method: 'POST',
            body: arg,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as AppTypes.Message,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    publicUserById: builder.mutation<UserJson.User, { userId: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/public/users/${arg.userId}`,
            method: 'POST',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.User,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const { useContactUsMutation, usePublicUserByIdMutation } =
  defaultPublicApi;
