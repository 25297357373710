import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconWork({
  width = 18,
  height = 18,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      className="fill-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9216 1.93183C7.36298 1.93183 6.88775 2.32002 6.75435 2.84322H11.2399C11.1065 2.32002 10.6313 1.93183 10.0727 1.93183H7.9216ZM12.5072 2.84322H14.158C15.9089 2.84322 17.3346 4.28625 17.3346 6.05839C17.3346 6.05839 17.2846 6.8086 17.2679 7.85332C17.2663 7.93602 17.2262 8.01703 17.1604 8.06598C16.7593 8.36218 16.3925 8.6069 16.3591 8.62378C14.9751 9.55205 13.3668 10.2052 11.6535 10.5301C11.5417 10.552 11.4317 10.4938 11.375 10.3942C10.8947 9.56217 9.99763 9.0204 8.99713 9.0204C8.0033 9.0204 7.09785 9.55627 6.60344 10.3892C6.54591 10.4871 6.43752 10.5436 6.32663 10.5225C4.62745 10.1968 3.01914 9.54445 1.64346 8.63222L0.843056 8.07526C0.776356 8.03307 0.734669 7.95712 0.734669 7.87273C0.709656 7.44235 0.667969 6.05839 0.667969 6.05839C0.667969 4.28625 2.09368 2.84322 3.84456 2.84322H5.48704C5.64546 1.6196 6.67097 0.666016 7.9216 0.666016H10.0727C11.3233 0.666016 12.3488 1.6196 12.5072 2.84322ZM17.0512 9.67871L17.0178 9.69559C15.3336 10.8264 13.3076 11.5774 11.1816 11.8897C10.8814 11.9319 10.5813 11.7378 10.4979 11.434C10.3145 10.742 9.7225 10.2863 9.01381 10.2863H9.00547H8.9888C8.28011 10.2863 7.68815 10.742 7.50472 11.434C7.42134 11.7378 7.1212 11.9319 6.82104 11.8897C4.69498 11.5774 2.66897 10.8264 0.984794 9.69559C0.976456 9.68715 0.893081 9.63652 0.826381 9.67871C0.751344 9.72091 0.751344 9.82217 0.751344 9.82217L0.809706 14.1259C0.809706 15.8981 2.22708 17.3327 3.97796 17.3327H14.0163C15.7672 17.3327 17.1846 15.8981 17.1846 14.1259L17.2513 9.82217C17.2513 9.82217 17.2513 9.72091 17.1762 9.67871C17.1345 9.6534 17.0845 9.66183 17.0512 9.67871ZM9.62245 13.2146C9.62245 13.569 9.34731 13.8475 8.99713 13.8475C8.6553 13.8475 8.37182 13.569 8.37182 13.2146V12.126C8.37182 11.78 8.6553 11.493 8.99713 11.493C9.34731 11.493 9.62245 11.78 9.62245 12.126V13.2146Z"
        fill={color}
      />
    </svg>
  );
}
