export enum AssetType {
  IMAGE = 'IMAGE',
  GALLERY = 'GALLERY',
  DOCUMENT = 'DOCUMENT',
}

export enum UserAssetType {
  AVATAR = 'AVATAR',
  RESUME = 'RESUME',
  JOB_DESCRIPTION = 'JOB_DESCRIPTION',
  CUSTOM = 'CUSTOM',
}

export enum AssetProvider {
  AFFINDA = 'AFFINDA',
  OTHERS = 'OTHERS',
  DEFAULT = 'DEFAULT',
}
