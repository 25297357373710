import { BackButton, UIBox } from '@/components/UI';
import { appRoutes } from '@/constants';
import { RoleNames } from '@/enums';
import { useAuth } from '@/hooks/useAuth';

import {
  ProfileAbout,
  ProfileCertifications,
  ProfileEducation,
  ProfileExperience,
  ProfileSkills,
  PublicUserInfo,
} from '@/modules/Profile';
import { usePublicUserByIdMutation } from '@/redux/apis';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PublicProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { auth, user } = useAuth();
  const userId = id ? id : user?.id;
  const [loadUserInfo, { isLoading, data: userInfo }] =
    usePublicUserByIdMutation();
  useEffect(() => {
    if (userId) loadUserInfo({ userId });
  }, [userId]);
  const isWorker = auth?.roleName === RoleNames.WORKER;
  return (
    <UIBox display="flex" flexDirection="column" gap={20} pt={1}>
      {isWorker && (
        <BackButton to={appRoutes.workerProfile}>Back to My Profile</BackButton>
      )}
      <PublicUserInfo user={userInfo} loading={isLoading} />
      {userInfo?.summary && (
        <ProfileAbout user={userInfo} publicView loading={isLoading} />
      )}
      {/* <ProfileProjects publicView userId={userId} /> */}
      {userInfo?.userExperiences?.length && (
        <ProfileExperience
          publicView
          experiences={userInfo?.userExperiences}
          isLoading={isLoading}
        />
      )}
      {userInfo?.userEducations?.length && (
        <ProfileEducation
          publicView
          educations={userInfo?.userEducations}
          isLoading={isLoading}
        />
      )}
      {userInfo?.userSkills?.length && (
        <ProfileSkills
          publicView
          skills={userInfo?.userSkills}
          isLoading={isLoading}
        />
      )}
      {userInfo?.userCertifications?.length && (
        <ProfileCertifications
          publicView
          certifications={userInfo?.userCertifications}
          isLoading={isLoading}
        />
      )}
      {/* <ProfileAssessment />
      <ProfileMentor /> */}
    </UIBox>
  );
};

export default PublicProfile;
