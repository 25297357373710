import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconUser({
  width = 8,
  height = 10,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 10"
      fill="none"
      className="fill-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.23132 2.76342C6.23132 4.15853 5.12729 5.27684 3.75 5.27684C2.37271 5.27684 1.26868 4.15853 1.26868 2.76342C1.26868 1.36831 2.37271 0.25 3.75 0.25C5.12729 0.25 6.23132 1.36831 6.23132 2.76342ZM0 8.12322C0 6.83103 1.72711 6.5076 3.75 6.5076C5.7834 6.5076 7.5 6.84218 7.5 8.13437C7.5 9.42657 5.77239 9.75 3.75 9.75C1.7166 9.75 0 9.41491 0 8.12322Z"
        fill={color}
      />
    </svg>
  );
}
