import { AppConfirmModal } from '@/components/App';
import { ButtonVariant } from '@/components/UI/Button';
import React, { createContext, ReactNode, useState } from 'react';

type ConfirmModalParams = {
  onClose?: () => void;
  onConfirm?: () => void;
  question?: string;
  confirmButton?: string;
  variant?: ButtonVariant;
};
const initialValues: ConfirmModalParams = {
  onClose: undefined,
  onConfirm: undefined,
  question: 'Do you want to proceed this action?',
  confirmButton: 'Confirm',
  variant: 'danger',
};
interface ConfirmModalContextType {
  showConfirmModal: (props?: ConfirmModalParams) => void;
  hideConfirmModal: () => void;
}

export const AppConfirmModalContext = createContext<
  ConfirmModalContextType | undefined
>(undefined);

// AuthProvider component that will wrap your app's component tree
export const AppConfirmModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [processing, setProcessing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [params, setParams] = useState<ConfirmModalParams>(initialValues);
  function handleShowConfirmModal(props?: ConfirmModalParams) {
    if (props) {
      setParams({
        ...params,
        ...props,
      });
    }
    setShowConfirmModal(true);
  }
  function init() {
    setProcessing(false);
    setShowConfirmModal(false);
    setParams({ ...initialValues });
  }
  function handleClose() {
    params.onClose?.();
    init();
  }
  async function handleConfirm() {
    setProcessing(true);
    try {
      await params.onConfirm?.();
    } catch (err) {}
    setProcessing(false);
  }
  return (
    <AppConfirmModalContext.Provider
      value={{
        showConfirmModal: handleShowConfirmModal,
        hideConfirmModal: handleClose,
      }}
    >
      {children}
      <AppConfirmModal
        processing={processing}
        show={showConfirmModal}
        question={params.question}
        confirmButton={params.confirmButton}
        variant={params.variant}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </AppConfirmModalContext.Provider>
  );
};
