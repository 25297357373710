import { AdditionalTextProps } from './Typography';
import { useTheme } from 'styled-components';

type LabelProps = AdditionalTextProps &
  React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > & {
    variant?: 'primary' | 'secondary';
  };
const Label: React.FC<LabelProps> = ({
  color,
  variant = 'primary',
  fontSize,
  textAlign,
  textTransform,
  fontWeight = 500,
  lineHeight,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  opacity,
  style,
  ...props
}) => {
  const theme = useTheme();
  const styles: React.CSSProperties = {
    display: 'block',
    color:
      color ||
      (variant === 'primary'
        ? theme.colors.textPrimary
        : theme.colors.textDescription),
    marginBottom: marginBottom === undefined ? 5 : marginBottom,
    ...(style || {}),
    fontSize,
    textAlign,
    textTransform,
    fontWeight,
    lineHeight,
    marginTop,
    marginLeft,
    marginRight,
    opacity,
  };
  return <label style={styles} {...props} />;
};

export default Label;
