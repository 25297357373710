import { Button, Image, UIContainer, Typography, UIBox } from '@/components/UI';
import { appRoutes } from '@/constants';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

export default function Welcome() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <UIBox id="welcome" position="relative">
      <Image
        src="/images/img_landing_bg.png"
        alt="Home background"
        width="100%"
        height="auto"
      />
      <StyledContentSection>
        <UIContainer>
          <StyledContentContainer>
            <Typography color={theme.colors.white} fontSize={18}>
              Welcome to
            </Typography>
            <Typography variant="h1">Non-Linear Careers</Typography>
            <Typography color={theme.colors.gray700} fontSize={18}>
              Where Individuals from non-traditional backgrounds with a wide
              range of employment opportunities and employers seeking a more
              diverse candidate pool.
            </Typography>
            <UIBox mt={2} />
            <UIBox width={195}>
              <Button
                variant="transparent"
                rounded
                fullWidth
                onClick={() => navigate(appRoutes.register)}
              >
                Get started
              </Button>
            </UIBox>
          </StyledContentContainer>
        </UIContainer>
      </StyledContentSection>
    </UIBox>
  );
}

const StyledContentSection = styled.section(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}));

const StyledContentContainer = styled.div(({ theme }) => ({
  color: theme.colors.white,
  maxWidth: 446,
}));
