import {
  IconBuildings1,
  IconUsers2,
  IconUsers3,
  IconWork,
} from '@/assets/icons';
import { UIGrid, Typography, UIBox } from '@/components/UI';
import { theme } from '@/constants';
import {
  AdminNewJobs,
  DailyTrafficWidget,
  DashboardWidget,
  HireStatusWidget,
  ProjectStatusWidget,
  TopOrganizations,
} from '@/modules/Dashboard';

const AdminDashboardPage: React.FC = () => {
  return (
    <UIBox display="flex" flexDirection="column" gap={20}>
      <Typography variant="h5">Dashboard</Typography>
      <UIGrid columns={4} gap={20}>
        <DashboardWidget
          icon={
            <IconUsers3 color={theme.colors.primary} width={22} height={16} />
          }
          value={1236}
          label="New employees"
        />
        <DashboardWidget
          icon={
            <IconBuildings1
              color={theme.colors.primary}
              width={22}
              height={22}
            />
          }
          value={
            <>
              42
              <sup
                style={{
                  fontSize: 12,
                  color: theme.colors.error,
                  paddingLeft: 5,
                }}
              >
                -3%
              </sup>
            </>
          }
          label="New organizations"
        />
        <DashboardWidget
          icon={
            <IconUsers2 color={theme.colors.primary} width={18} height={16} />
          }
          value={
            <>
              327
              <sup
                style={{
                  fontSize: 12,
                  color: theme.colors.success,
                  paddingLeft: 5,
                }}
              >
                +23%
              </sup>
            </>
          }
          label="New sponsors"
        />
        <DashboardWidget
          icon={
            <IconWork color={theme.colors.primary} width={18} height={17} />
          }
          value={2935}
          label="Hires"
        />
      </UIGrid>
      <UIBox display="flex" gap={20}>
        <UIBox width={360}>
          <DailyTrafficWidget />
        </UIBox>
        <UIBox flexGrow={1}>
          <AdminNewJobs />
        </UIBox>
      </UIBox>
      <UIGrid columns={4} gap={20}>
        <UIBox gridColumn={3}>
          <HireStatusWidget />
        </UIBox>
        <ProjectStatusWidget title="Projects" />
      </UIGrid>
      <TopOrganizations />
    </UIBox>
  );
};

export default AdminDashboardPage;
