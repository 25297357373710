import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconArrowUpCircle({
  width = 18,
  height = 18,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00033 0.667969C13.6003 0.667969 17.3337 4.40964 17.3337 9.0013L17.3305 9.23444C17.2069 13.7268 13.5224 17.3346 9.00033 17.3346C4.40866 17.3346 0.666992 13.6013 0.666992 9.0013C0.666992 4.40964 4.40866 0.667969 9.00033 0.667969ZM5.66681 10.6521C5.91681 10.8938 6.30848 10.8938 6.55015 10.6438L9.00015 8.18542L11.4501 10.6438C11.6918 10.8938 12.0918 10.8938 12.3335 10.6521C12.5835 10.4021 12.5835 10.0104 12.3335 9.76875L9.44181 6.86042C9.32515 6.74375 9.16681 6.67708 9.00015 6.67708C8.83348 6.67708 8.67515 6.74375 8.55848 6.86042L5.66681 9.76875C5.54181 9.88542 5.48348 10.0438 5.48348 10.2021C5.48348 10.3687 5.54181 10.5271 5.66681 10.6521Z"
        fill={color}
      />
    </svg>
  );
}
