import { appRoutes } from '@/constants';
import { RoleNames } from '@/enums';

export const allowedUsersMock = [
  {
    email: 'worker@nl-careers.com',
    password: 'Welcome2024!',
    role: RoleNames.WORKER,
    nextPath: appRoutes.workerFindWork(),
  },
  {
    email: 'mentor@nl-careers.com',
    password: 'Welcome2024!',
    role: RoleNames.MENTOR,
    nextPath: appRoutes.workerFindWork(),
  },
  {
    email: 'sponsor@nl-careers.com',
    password: 'Welcome2024!',
    role: RoleNames.SPONSOR,
    nextPath: appRoutes.sponsorMyJobs,
  },
  {
    email: 'org_admin@nl-careers.com',
    password: 'Welcome2024!',
    role: RoleNames.ORG_ADMIN,
    nextPath: appRoutes.orgDashboard,
  },
  {
    email: 'super_admin@nl-careers.com',
    password: 'Welcome2024!',
    role: RoleNames.SUPER_ADMIN,
    nextPath: appRoutes.adminDashboard,
  },
];
