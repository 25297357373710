import { IconInfoCircle } from '@/assets/icons';
import { UIBox, UICard, Image, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { ProjectBudgetType } from '@/enums';
import JobApplyButton from './JobApplyButton';
import { ProjectJson } from '@/types';
import { formatCurrency } from '@/utils';

interface WorkerRecommendJobCardProps {
  job: ProjectJson.Project;
  onClick: () => void;
  onClickApply: () => void;
  onClickBookmark: () => void;
}

const WorkerRecommendJobCard: React.FC<WorkerRecommendJobCardProps> = ({
  job,
  onClick,
  onClickApply,
  onClickBookmark,
}) => {
  return (
    <UICard
      borderRadius={10}
      pt={2}
      px={1.5}
      pb={1.5}
      display="flex"
      flexDirection="column"
      gap={20}
      position="relative"
      borderWidth={0}
      cursor="pointer"
      onClick={onClick}
    >
      <UIBox display="flex" justifyContent="space-between">
        <UIBox>
          <Typography
            fontSize={18}
            fontWeight={600}
            lineHeight="24px"
            color={theme.colors.textPrimary}
            marginBottom={2}
          >
            {job.title}
          </Typography>
          <UIBox display="flex" alignItems="center" gap={10}>
            <Image src={'job.companyData?.logo'} width={12} height={15} />
            <Typography
              fontSize={14}
              lineHeight="19px"
              color={theme.colors.textPrimary}
            >
              {job.companyData?.name}
            </Typography>
            <Typography fontSize={12}>
              ({job.companyData?.numEmployee} employees)
            </Typography>
          </UIBox>
        </UIBox>
        <UIBox display="flex" flexDirection="column" alignItems="flex-end">
          <Typography color={theme.colors.textPrimary}>
            {job.budget === ProjectBudgetType.HOURLY
              ? `${formatCurrency(job.minRate)} - ${formatCurrency(job.maxRate)}`
              : `${formatCurrency(job.minSalary)} - ${formatCurrency(job.maxSalary)}`}
          </Typography>
          <UIBox display="flex" alignItems="center" gap={10} transform="low">
            <IconInfoCircle />
            <Typography
              textTransform="lowercase"
              fontSize={14}
              fontWeight={500}
              lineHeight="17px"
            >
              est. {job.budget}
            </Typography>
          </UIBox>
        </UIBox>
      </UIBox>
      <Typography
        style={{
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
        }}
      >
        {job.description}
      </Typography>
      <JobApplyButton
        onClickApply={onClickApply}
        onClickBookmark={onClickBookmark}
      />
    </UICard>
  );
};

export default WorkerRecommendJobCard;
