import { createApi } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { AppTypes, UserJson } from '@/types';
import { authorizeQueryWithRefreshToken } from '@/utils';

const getUserId = (state: any) => state.authState.user?.id || '';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: authorizeQueryWithRefreshToken,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    myInfo: builder.mutation<UserJson.User, void>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/me`,
            method: 'POST',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.User,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    changePassword: builder.mutation<
      UserJson.User,
      UserJson.ChangePasswordInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/change-password`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.User,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    mySkills: builder.query<UserJson.Skill[], void>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/skills`,
            method: 'GET',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Skill[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    myEducations: builder.query<
      UserJson.Education[],
      UserJson.EducationInput[]
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/educations`,
            method: 'GET',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Education[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    myExperiences: builder.query<
      UserJson.Experience[],
      UserJson.ExperienceInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/experiences`,
            method: 'GET',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Experience[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    myAssets: builder.query<UserJson.Asset[], void>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/assets`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Asset[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    updateUserPassword: builder.mutation<
      AppTypes.Message,
      { currentPassword: string; newPassword: string }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/password`,
            method: 'PATCH',
            body: arg,
          });
          if (result?.data) {
            return { data: result.data as AppTypes.Message };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    updateUser: builder.mutation<UserJson.User, UserJson.UserInfoInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/info`,
            method: 'PATCH',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.User,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    deleteUser: builder.mutation<AppTypes.Message, { userId: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${arg.userId}`,
            method: 'DELETE',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as AppTypes.Message,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    userById: builder.query<UserJson.User, { userId: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${arg.userId}`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.User,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),

    // Experience Apis
    createUserExperience: builder.mutation<
      UserJson.Experience,
      UserJson.ExperienceInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/experiences`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Experience,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    updateUserExperience: builder.mutation<
      UserJson.Experience,
      UserJson.ExperienceInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/experiences/${arg.id}`,
            method: 'PATCH',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Experience,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    deleteUserExperience: builder.mutation<AppTypes.Message, { expId: string }>(
      {
        async queryFn(arg, queryApi, extraOptions, baseQuery) {
          try {
            const result = await baseQuery({
              url: `/users/${getUserId(queryApi.getState())}/experiences/${arg.expId}`,
              method: 'DELETE',
              body: arg,
            });
            if (result?.data) {
              return {
                data: result.data as AppTypes.Message,
              };
            } else {
              return {
                error: result.error as FetchBaseQueryError,
              };
            }
          } catch (err) {
            return { error: err as FetchBaseQueryError };
          }
        },
      }
    ),
    userExperienceByUserId: builder.query<
      UserJson.Experience[],
      { userId: string }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${arg.userId}/experiences`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Experience[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),

    // Education Apis
    createUserEducation: builder.mutation<
      UserJson.Education,
      UserJson.EducationInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/educations`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Education,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    updateUserEducation: builder.mutation<
      UserJson.Education,
      UserJson.EducationInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/educations/${arg.id}`,
            method: 'PATCH',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Education,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    deleteUserEducation: builder.mutation<AppTypes.Message, { eduId: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/educations/${arg.eduId}`,
            method: 'DELETE',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as AppTypes.Message,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    userEducationByUserId: builder.query<
      UserJson.Education[],
      { userId: string }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${arg.userId}/educations`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Education[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),

    // Skill Apis
    updateUserSkill: builder.mutation<UserJson.Skill, UserJson.SkillInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/skills`,
            method: 'PATCH',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Skill,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    createUserSkill: builder.mutation<UserJson.Skill, UserJson.SkillInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/skills`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Skill,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    deleteUserSkill: builder.mutation<UserJson.Skill, { id: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/skills/${arg.id}`,
            method: 'DELETE',
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Skill,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    userSkillsByUserId: builder.query<UserJson.Skill[], { userId: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${arg.userId}/skills`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Skill[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),

    // Certification Apis
    updateUserCertification: builder.mutation<
      UserJson.Certification,
      UserJson.CertificationInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/certifications/${arg.id}`,
            method: 'PATCH',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Certification,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    createUserCertification: builder.mutation<
      UserJson.Certification,
      UserJson.CertificationInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/certifications`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Certification,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    deleteUserCertification: builder.mutation<
      UserJson.Certification,
      { id: string }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${getUserId(queryApi.getState())}/certifications/${arg.id}`,
            method: 'DELETE',
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Certification,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    userCertificationsByUserId: builder.query<
      UserJson.Certification[],
      { userId: string }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${arg.userId}/certifications`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Certification[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),

    getUserProjectsByUserId: builder.query<
      UserJson.Project[],
      UserJson.ProjectSearchType
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${arg.userId}/projects`,
            method: 'GET',
            params: { ...arg, status: arg?.status ?? 'all' },
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Project[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    getUserProjectsByProjectId: builder.query<
      UserJson.Project[],
      UserJson.ProjectParam
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${arg.userId}/projects/${arg.projectId}`,
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Project[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    getUsers: builder.query<UserJson.PagedUsers, UserJson.UserPagination>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users`,
            method: 'GET',
            params: arg,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.PagedUsers,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    createUserAsset: builder.mutation<UserJson.Asset, UserJson.AssetInput>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/users/${arg.userId}/assets`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as UserJson.Asset,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const {
  useMyInfoMutation,
  useMyEducationsQuery,
  useMyExperiencesQuery,
  useMySkillsQuery,
  useMyAssetsQuery,
  useLazyMyAssetsQuery,
  useUpdateUserPasswordMutation,

  useUpdateUserMutation,
  useDeleteUserMutation,

  useUserByIdQuery,
  useGetUsersQuery,

  useUserExperienceByUserIdQuery,
  useUpdateUserExperienceMutation,
  useDeleteUserExperienceMutation,
  useCreateUserExperienceMutation,

  useUserEducationByUserIdQuery,
  useCreateUserEducationMutation,
  useDeleteUserEducationMutation,
  useUpdateUserEducationMutation,

  useUserSkillsByUserIdQuery,
  useUpdateUserSkillMutation,
  useCreateUserSkillMutation,
  useDeleteUserSkillMutation,

  useUserCertificationsByUserIdQuery,
  useUpdateUserCertificationMutation,
  useCreateUserCertificationMutation,
  useDeleteUserCertificationMutation,

  useGetUserProjectsByUserIdQuery,
  useGetUserProjectsByProjectIdQuery,

  useCreateUserAssetMutation,
  useChangePasswordMutation,
} = userApi;
