import { ProfileEditButton } from '@/modules/Shared';
import {
  UIBox,
  UICard,
  Typography,
  Spinner,
  RichEditor,
} from '@/components/UI';
import { UserJson } from '@/types';
import { useEffect, useState } from 'react';
import { useUpdateUserMutation } from '@/redux/apis';
import { AppEmptyItems } from '@/components/App';

interface ProfileAboutProps {
  user?: UserJson.User;
  publicView?: boolean;
  loading?: boolean;
  refetch?: () => void;
}
const ProfileAbout: React.FC<ProfileAboutProps> = ({
  user,
  publicView,
  loading,
  refetch,
}) => {
  const [editing, setEditing] = useState(false);
  const [summary, setSummary] = useState(user?.summary);
  const [updateUserInfo, { isLoading: saving, isSuccess }] =
    useUpdateUserMutation();
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnSave = async () => {
    await updateUserInfo({
      summary,
    });
  };
  const handleOnCancel = () => {
    setEditing(false);
  };
  useEffect(() => {
    setSummary(user?.summary);
  }, [user]);
  useEffect(() => {
    if (isSuccess) {
      refetch?.();
      setEditing(false);
    }
  }, [isSuccess]);
  return (
    <UICard borderRadius={10} id="profile-about-section">
      <UIBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="h5" marginBottom={0}>
          About
        </Typography>
        {!publicView && (
          <ProfileEditButton
            editing={editing}
            saving={saving}
            onClickSave={handleOnSave}
            onClickCancel={handleOnCancel}
            onClickEdit={handleOnEdit}
          />
        )}
      </UIBox>
      {editing ? (
        <RichEditor defaultValue={summary} onChange={setSummary} />
      ) : loading ? (
        <UIBox display="flex" justifyContent="center" p={2}>
          <Spinner size="xl" />
        </UIBox>
      ) : (
        <>
          {user?.summary ? (
            user?.summary
              .split('\n')
              .map((text, id) => <Typography key={id}>{text}</Typography>)
          ) : (
            <AppEmptyItems
              title="Please add your summary"
              description="Your profile does not have summary."
              buttonLabel="Add Summary"
              onClickButton={handleOnEdit}
            />
          )}
        </>
      )}
    </UICard>
  );
};

export default ProfileAbout;
