import { IconPlus } from '@/assets/icons';
import {
  UIBox,
  UICard,
  IconButton,
  Typography,
  Spinner,
} from '@/components/UI';
import { useDeleteUserEducationMutation } from '@/redux/apis';
import EducationCard from './EducationCard';
import EducationEditModal from './EducationEditModal';
import { useEffect, useMemo, useState } from 'react';
import { UserJson } from '@/types';
import { useConfirmModal } from '@/hooks';
import { AppEmptyItems } from '@/components/App';

interface ProfileEducationProps {
  educations?: UserJson.Education[];
  isLoading?: boolean;
  publicView?: boolean;
  refetch?: () => void;
}
const ProfileEducation: React.FC<ProfileEducationProps> = ({
  publicView,
  isLoading,
  refetch,
  educations = [],
}) => {
  const [showModal, setShowModal] = useState(false);
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();
  const [deleteEducation, { isSuccess: isDeleted }] =
    useDeleteUserEducationMutation();

  async function handleDeleteExperience(education: UserJson.Education) {
    await deleteEducation({ eduId: education.id });
  }

  useEffect(() => {
    if (isDeleted) {
      refetch?.();
      hideConfirmModal();
    }
  }, [isDeleted]);

  const sortedEducations = useMemo(
    () =>
      [...educations].sort(
        (a, b) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime()
      ),
    [educations]
  );
  return (
    <UICard borderRadius={10} id="profile-education-section">
      <UIBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="h5" marginBottom={0}>
          Education
        </Typography>
        {!publicView && (
          <IconButton onClick={() => setShowModal(true)}>
            <IconPlus />
          </IconButton>
        )}
      </UIBox>
      {isLoading && (
        <UIBox display="flex" justifyContent="center" p={2}>
          <Spinner size="xl" />
        </UIBox>
      )}
      {!isLoading && educations.length === 0 && (
        <AppEmptyItems
          description="Try add a new education"
          buttonLabel="Add Education"
          onClickButton={() => setShowModal(true)}
        />
      )}
      <UIBox display="flex" flexDirection="column" gap={20}>
        {sortedEducations.map((education, id) => (
          <EducationCard
            key={id}
            education={education}
            editable={!publicView}
            onClickDelete={() => {
              showConfirmModal({
                question: 'Do you want to delete this?',
                confirmButton: 'Yes, Delete',
                onClose: hideConfirmModal,
                onConfirm: () => handleDeleteExperience(education),
              });
            }}
            onClickSave={() => refetch?.()}
          />
        ))}
      </UIBox>

      <EducationEditModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onAdded={() => {
          refetch?.();
          setShowModal(false);
        }}
      />
    </UICard>
  );
};

export default ProfileEducation;
