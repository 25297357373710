import { ResumeType } from '@/enums';
import { UserJson } from '@/types';
import { faker } from '@faker-js/faker';

function createRandomResume(): UserJson.Resume {
  return {
    title: faker.helpers.arrayElement([
      'Full Stack JS Engineer',
      'Team lead Developer',
      'Java Engineer',
    ]),
    url: faker.internet.url(),
    resumeType: faker.helpers.arrayElement([
      ResumeType.AFFINDA,
      ResumeType.LINKEDIN,
    ]),
  };
}
export const resumesMock: UserJson.Resume[] = faker.helpers.multiple(
  createRandomResume,
  { count: 3 }
);
