import { UIBox, Button, UICard, Image, Typography } from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { RoleNames } from '@/enums';
import { useAuth } from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export default function AuthAccountCreatedPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  function handleGotoHome() {
    if (auth) {
      if (auth.roleName === RoleNames.SUPER_ADMIN) {
        navigate(appRoutes.adminProfile);
      } else if (auth.roleName === RoleNames.SITE_ADMIN) {
        navigate(appRoutes.adminProfile);
      } else if (auth.roleName === RoleNames.ORG_ADMIN) {
        navigate(appRoutes.orgProfile);
      } else if (auth.roleName === RoleNames.SPONSOR) {
        navigate(appRoutes.sponsorProfile);
      } else if (auth.roleName === RoleNames.WORKER) {
        navigate(appRoutes.workerProfile);
      }
    }
  }
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={520}
        px={3}
        py={2}
        borderRadius={32}
        boxShadow="0px 4px 35px 0px #00000014"
      >
        <Typography
          variant="h3"
          textAlign="center"
          fontSize={26}
          lineHeight="35px"
          marginBottom={0}
        >
          Your account successfully created
        </Typography>
        <Typography
          textAlign="center"
          fontWeight={400}
          marginBottom={40}
          color={theme.colors.primary}
        >
          Provide more information about your organization
        </Typography>
        <Image src="/images/account-created.svg" />
        <UIBox
          mt={3}
          width={300}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Button fullWidth rounded onClick={() => handleGotoHome()}>
            Go to Home
          </Button>
        </UIBox>
      </UICard>
    </UIBox>
  );
}
