import {
  IconArrowDown,
  IconEarth,
  IconPaperclip,
  IconShieldDone,
  IconUsers3,
} from '@/assets/icons';
import {
  UIBox,
  UICard,
  UIDivider,
  IconButton,
  Image,
  ReadMoreButton,
  RichEditor,
  Tag,
  Typography,
} from '@/components/UI';
import { theme } from '@/constants';
import JobApplyButton from './JobApplyButton';
import { ProjectJson } from '@/types';

interface JobDetailCardProps {
  job?: ProjectJson.Project;
  skillsMatching: number;
  onClickApply: () => void;
  onClickBookmark: () => void;
  hideCompanyInfo?: boolean;
}
const JobDetailCard: React.FC<JobDetailCardProps> = ({
  job,
  skillsMatching,
  hideCompanyInfo,
  onClickApply,
  onClickBookmark,
}) => {
  return (
    <UICard
      borderRadius={10}
      p={2}
      display="flex"
      flexDirection="column"
      gap={21}
      px={1.5}
      py={2}
    >
      <UIBox display="flex" justifyContent="space-between">
        <UIBox>
          <Typography
            fontSize={18}
            fontWeight={600}
            lineHeight="24px"
            color={theme.colors.textPrimary}
            marginBottom={2}
          >
            {job?.title}
          </Typography>
          <UIBox display="flex" alignItems="center" gap={10}>
            <Image src={'job?.companyData?.logo'} width={12} height={15} />
            <Typography
              fontSize={14}
              lineHeight="19px"
              color={theme.colors.textPrimary}
            >
              {job?.companyData?.name}
            </Typography>
            <Typography fontSize={12}>
              ({job?.companyData?.numEmployee} employees)
            </Typography>
            <Typography fontSize={12}>Posted 23 minutes ago</Typography>
          </UIBox>
        </UIBox>
        <UIBox>
          <UIBox
            px={1}
            borderRadius={10}
            width={'fit-content'}
            background={
              skillsMatching > 80
                ? theme.colors.green600
                : theme.colors.yellow500
            }
            mb={0.5}
          >
            <Typography color={theme.colors.textPrimary}>
              {`${skillsMatching}% skills matching`}
            </Typography>
          </UIBox>
        </UIBox>
      </UIBox>
      <UIBox>
        <Typography
          color={theme.colors.textPrimary}
          fontSize={18}
          fontWeight={600}
          marginBottom={5}
        >
          Job Description
        </Typography>
        {job?.description
          ?.split('\n')
          .map((d, i) => <Typography key={i}>{d}</Typography>)}
        <UIBox mb={1} />
        <ReadMoreButton onClick={() => null} color={theme.colors.secondary}>
          Read More
          <IconArrowDown color={theme.colors.secondary} />
        </ReadMoreButton>
      </UIBox>
      <UIBox>
        <Typography
          color={theme.colors.textPrimary}
          fontSize={18}
          fontWeight={600}
          marginBottom={5}
        >
          Job budget
        </Typography>
        <Typography>
          ***$1,000 Sign-On Bonus Available!*** <br />
          Starting Pay Rate: $30.13 - $33.14 / hour
        </Typography>
      </UIBox>
      <UIBox>
        <Typography
          color={theme.colors.textPrimary}
          fontSize={18}
          fontWeight={600}
          marginBottom={5}
        >
          Skills
        </Typography>
        <UIBox display="flex" flexWrap="wrap" gap={10} mb={1} mt={1}>
          {job?.projectSkills?.map((skill, id) => (
            <Tag key={id}>{skill.name}</Tag>
          ))}
        </UIBox>
      </UIBox>

      {!hideCompanyInfo && (
        <UIBox>
          <Typography
            color={theme.colors.textPrimary}
            fontSize={18}
            fontWeight={600}
            marginBottom={10}
          >
            Company Info
          </Typography>
          <UIBox display="flex" justifyContent="space-between" gap={30}>
            <UIBox>
              <Typography marginBottom={15}>{job?.companyData?.bio}</Typography>
              <ReadMoreButton
                onClick={() => null}
                color={theme.colors.secondary}
              >
                Read More
                <IconArrowDown color={theme.colors.secondary} />
              </ReadMoreButton>
            </UIBox>
            <UIBox minWidth={150} flexGrow={1}>
              <UIBox
                p={1}
                borderStyle="solid"
                borderWidth={1}
                borderColor="#AEAEBA33"
                borderRadius={1}
                mb={1}
              >
                <Image src={'job?.companyData?.logo'} />
              </UIBox>
              <UIBox display="flex" gap={10}>
                <IconShieldDone />
                <Typography fontSize={14}>Technology</Typography>
              </UIBox>
              <UIBox display="flex" gap={10}>
                <IconUsers3 />
                <Typography fontSize={14}>
                  ({job?.companyData?.numEmployee} employees)
                </Typography>
              </UIBox>
              <UIBox display="flex" gap={10}>
                <IconEarth />
                <Typography fontSize={14}>
                  {job?.companyData?.website}
                </Typography>
              </UIBox>
            </UIBox>
          </UIBox>
        </UIBox>
      )}
      <UIDivider />
      <UIBox>
        <Typography
          color={theme.colors.textPrimary}
          fontSize={18}
          fontWeight={600}
          marginBottom={16}
        >
          +Add a cover letter
        </Typography>
        <UIBox position="relative">
          <RichEditor />
          <UIBox position="absolute" bottom={10} right={10}>
            <IconButton>
              <IconPaperclip />
            </IconButton>
          </UIBox>
        </UIBox>
      </UIBox>
      <UIBox maxWidth={280}>
        <JobApplyButton
          onClickApply={onClickApply}
          onClickBookmark={onClickBookmark}
        />
      </UIBox>
    </UICard>
  );
};

export default JobDetailCard;
