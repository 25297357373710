import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconSmile({
  width = 16,
  height = 16,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.67212 15.1758C11.6094 15.1758 14.8013 12.0418 14.8013 8.17578C14.8013 4.30979 11.6094 1.17578 7.67212 1.17578C3.7348 1.17578 0.542969 4.30979 0.542969 8.17578C0.542969 12.0418 3.7348 15.1758 7.67212 15.1758Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.82031 9.57617C4.82031 9.57617 5.88969 10.9762 7.67197 10.9762C9.45426 10.9762 10.5236 9.57617 10.5236 9.57617"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.53516 6.07617H5.54216"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.8125 6.07617H9.8195"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
