import { orgSizes } from '@/constants';
import { OrganizationJson } from '@/types';
import { faker } from '@faker-js/faker';

export function createRandomCompany(): OrganizationJson.Organization {
  return {
    id: faker.string.uuid(),
    name: faker.helpers.arrayElement([
      'Phoenix',
      'Linkedin',
      'Slack',
      'Facebook',
    ]),
    bio: faker.lorem.paragraphs(),
    website: faker.internet.url(),
    vision: faker.lorem.paragraphs(),
    numEmployee: faker.helpers.arrayElement(orgSizes),
    createdAt: faker.date.past({ years: 1 }).toDateString(),
  };
}
const organizationsMock: OrganizationJson.Organization[] =
  faker.helpers.multiple(createRandomCompany, { count: 10 });

export default organizationsMock;
