import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconTimeCircle({
  width = 12,
  height = 12,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99984 11.8327C2.77984 11.8327 0.166504 9.22518 0.166504 5.99935C0.166504 2.77935 2.77984 0.166016 5.99984 0.166016C9.22567 0.166016 11.8332 2.77935 11.8332 5.99935C11.8332 9.22518 9.22567 11.8327 5.99984 11.8327ZM7.86067 8.16352C7.93067 8.20435 8.0065 8.22768 8.08817 8.22768C8.234 8.22768 8.37984 8.15185 8.4615 8.01185C8.584 7.80768 8.51984 7.53935 8.30984 7.41102L6.23317 6.17435V3.47935C6.23317 3.23435 6.03484 3.04185 5.79567 3.04185C5.5565 3.04185 5.35817 3.23435 5.35817 3.47935V6.42518C5.35817 6.57685 5.43984 6.71685 5.574 6.79852L7.86067 8.16352Z"
        fill={color}
      />
    </svg>
  );
}
