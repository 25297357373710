import { FileDropzone } from '@/modules/Shared';
import { UIBox, Button, UICard, Typography, Spinner } from '@/components/UI';
import { appRoutes } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { useUploadFile } from '@/hooks/useUploadFile';
import { UserAssetType } from '@/enums';
import { useAuth } from '@/hooks';

export default function AuthCompanyLogoPage() {
  const { refetchUser, org, user } = useAuth();
  const {
    setAcceptedFiles,
    uploading,
    progress,
    loading: isUploading,
    error,
    uploadFile,
  } = useUploadFile({
    onUploadedFile: () => {
      refetchUser();
      navigate(appRoutes.authCompanyInfo);
    },
  });
  const navigate = useNavigate();
  const loading = isUploading;
  function handleAddPhoto() {
    uploadFile({
      contentType: UserAssetType.AVATAR,
      orgId: org?.id,
      userId: user?.id || '',
    });
  }
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        flexGrow={1}
        maxWidth={580}
        px={4}
        py={2}
        borderRadius={32}
        boxShadow="0px 4px 35px 0px #00000014"
      >
        <Typography
          variant="h3"
          textAlign="center"
          marginBottom={30}
          fontSize={26}
        >
          Add Company logo
        </Typography>
        <UIBox mb={2} width="100%">
          <FileDropzone
            label="Click or drag to upload organizations photo"
            description="*.svg / *.jpg (2 MB)"
            options={{ accept: { 'image/*': [] } }}
            uploading={uploading}
            progress={progress}
            error={error}
            onChangeFiles={(files) => setAcceptedFiles(files)}
          />
        </UIBox>
        <UIBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={'100%'}
        >
          <Button fullWidth rounded onClick={handleAddPhoto} disabled={loading}>
            {loading && <Spinner size="sm" />}
            Add photo
          </Button>
          <Typography
            role="button"
            marginBottom={0}
            marginTop={20}
            onClick={() => navigate(appRoutes.authCompanyInfo)}
          >
            Skip
          </Typography>
        </UIBox>
      </UICard>
    </UIBox>
  );
}
