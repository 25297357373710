import { ProfileEditButton } from '@/modules/Shared';
import {
  Autocomplete,
  UIBox,
  UICard,
  UIGrid,
  Tag,
  Typography,
  Spinner,
} from '@/components/UI';
import { useEffect, useState } from 'react';
import {
  useCreateUserSkillMutation,
  useDeleteUserSkillMutation,
  useIndustryChildrenQuery,
  useIndustryParentsQuery,
  useIndustrySkillsQuery,
} from '@/redux/apis';
import { AppTypes, UserJson } from '@/types';
import { IconSearch } from '@/assets/icons';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Ability } from '@/enums';
import { useConfirmModal } from '@/hooks';
import { AppEmptyItems } from '@/components/App';
import { inputStatus } from '@/utils';

const abilities: AppTypes.Option[] = [
  {
    label: 'Beginner',
    value: Ability.BEGINNER,
  },
  {
    label: 'Intermediate',
    value: Ability.INTERMEDIATE,
  },
  {
    label: 'Advanced',
    value: Ability.ADVANCED,
  },
  {
    label: 'Expert',
    value: Ability.EXPERT,
  },
];
type SkillInputData = UserJson.SkillInput & {
  parentIndustryId?: string;
  childIndustryId?: string;
};

const initialValues: SkillInputData = {
  parentIndustryId: '',
  childIndustryId: '',
  skillId: '',
};

const validationSchema = yup.object({
  parentIndustryId: yup.string().required('Required'),
  childIndustryId: yup.string().required('Required'),
  skillId: yup.string().required('Required'),
  proficiency: yup.string().required('Required'),
  interestLevel: yup.string().required('Required'),
});
interface ProfileSkillsProps {
  skills?: UserJson.Skill[];
  isLoading?: boolean;
  publicView?: boolean;
  refetch?: () => void;
}
const ProfileSkills: React.FC<ProfileSkillsProps> = ({
  publicView,
  isLoading,
  skills = [],
  refetch,
}) => {
  const [editing, setEditing] = useState(false);
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();
  const [deleteUserSkill, { isSuccess: isDeleted }] =
    useDeleteUserSkillMutation();
  const [createUserSkill, { isLoading: saving, isSuccess }] =
    useCreateUserSkillMutation();
  const { data: parentIndustries = [], isLoading: loadingParentIndustries } =
    useIndustryParentsQuery();
  useEffect(() => {
    if (isDeleted) {
      refetch?.();
      hideConfirmModal();
    }
  }, [isDeleted]);
  const formik = useFormik<SkillInputData>({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      const { parentIndustryId, childIndustryId, ...saveToValue } = v;
      await createUserSkill(saveToValue);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      setEditing(false);
      formik.resetForm();
      refetch?.();
    }
  }, [isSuccess]);
  const {
    data: childrenIndustries = [],
    isLoading: loadingChildrenIndustries,
  } = useIndustryChildrenQuery(
    {
      id: formik.values.parentIndustryId || '',
    },
    { skip: !formik.values.parentIndustryId }
  );
  const { data: allSkills = [], isLoading: loadingAllSkills } =
    useIndustrySkillsQuery(
      {
        id: formik.values.childIndustryId || '',
      },
      { skip: !formik.values.childIndustryId }
    );
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnSave = () => {
    formik.handleSubmit();
  };
  const handleOnCancel = () => {
    formik.resetForm();
    setEditing(false);
  };
  const handleDeleteSkill = (skill?: UserJson.Skill) => {
    if (!skill) return;
    showConfirmModal({
      question: 'Do you want to delete this skill?',
      onConfirm: async () => {
        await deleteUserSkill({ id: skill.id });
      },
    });
  };
  return (
    <UICard borderRadius={10} id="profile-skills-section">
      <UIBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="h5" marginBottom={0}>
          Skills
        </Typography>
        {!publicView && (
          <ProfileEditButton
            editing={editing}
            saving={saving}
            onClickSave={handleOnSave}
            onClickCancel={handleOnCancel}
            onClickEdit={handleOnEdit}
          />
        )}
      </UIBox>
      {editing && (
        <UIBox display="flex" flexDirection="column" gap={20} mb={2}>
          <UIGrid columns={2} gap={20}>
            <Autocomplete
              label="Category"
              fullWidth
              isClearable
              isLoading={loadingParentIndustries}
              options={parentIndustries}
              value={parentIndustries.find(
                (item) => item.id === formik.values.parentIndustryId
              )}
              onChange={(v) => formik.setFieldValue('parentIndustryId', v?.id)}
              getOptionLabel={(v) => v.name}
              getOptionValue={(v) => v.id}
              onBlur={() => formik.setFieldTouched('parentIndustryId')}
              {...inputStatus(formik, 'parentIndustryId')}
            />
            <Autocomplete
              label="Sector"
              fullWidth
              isClearable
              isLoading={loadingChildrenIndustries}
              options={childrenIndustries}
              value={childrenIndustries.find(
                (item) => item.id === formik.values.childIndustryId
              )}
              onChange={(v) => formik.setFieldValue('childIndustryId', v?.id)}
              getOptionLabel={(v) => v.name}
              getOptionValue={(v) => v.id}
              onBlur={() => formik.setFieldTouched('childIndustryId')}
              {...inputStatus(formik, 'childIndustryId')}
            />
          </UIGrid>

          <Autocomplete
            label="Skill"
            placeholder={
              <>
                <IconSearch /> Search
              </>
            }
            isClearable
            fullWidth
            isLoading={loadingAllSkills}
            options={allSkills}
            value={allSkills.find((item) => item.id === formik.values.skillId)}
            onChange={(v) => formik.setFieldValue('skillId', v?.id)}
            getOptionLabel={(v) => v.name}
            getOptionValue={(v) => v.id}
            onBlur={() => formik.setFieldTouched('skillId')}
            {...inputStatus(formik, 'skillId')}
          />
          <UIGrid columns={2} gap={20}>
            <Autocomplete
              label="Proficiency"
              fullWidth
              isClearable
              options={abilities}
              value={abilities.find(
                (item) => item.value === formik.values.proficiency
              )}
              onChange={(v) => formik.setFieldValue('proficiency', v?.value)}
              onBlur={() => formik.setFieldTouched('proficiency')}
              {...inputStatus(formik, 'proficiency')}
            />
            <Autocomplete
              label="Interest Level"
              fullWidth
              isClearable
              options={abilities}
              value={abilities.find(
                (item) => item.value === formik.values.interestLevel
              )}
              onChange={(v) => formik.setFieldValue('interestLevel', v?.value)}
              onBlur={() => formik.setFieldTouched('interestLevel')}
              {...inputStatus(formik, 'interestLevel')}
            />
          </UIGrid>
        </UIBox>
      )}
      {isLoading && (
        <UIBox display="flex" justifyContent="center" p={2}>
          <Spinner size="xl" />
        </UIBox>
      )}
      {!isLoading && skills.length === 0 && (
        <AppEmptyItems
          description="Try add a new skill"
          buttonLabel="Add Skill"
          onClickButton={() => handleOnEdit()}
        />
      )}
      <UIBox display="flex" flexWrap="wrap" gap={10}>
        {skills.map((item, id) => (
          <Tag
            key={id}
            closable={editing}
            onClose={() => handleDeleteSkill(item)}
          >
            {item.skill?.name}
          </Tag>
        ))}
      </UIBox>
    </UICard>
  );
};

export default ProfileSkills;
