import { AppModal } from '@/components/App';
import { Button, UIBox } from '@/components/UI';
import { UserJson } from '@/types';
import { useEffect, useRef } from 'react';
import EducationEditForm, {
  EducationEditFormHandle,
} from './EducationEditForm';
import { useCreateUserEducationMutation } from '@/redux/apis';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  onAdded: () => void;
}
const EducationEditModal: React.FC<ModalProps> = ({
  show,
  onClose,
  onAdded,
}) => {
  const [createUserEducation, { isLoading, isSuccess }] =
    useCreateUserEducationMutation();
  const formRef = useRef<EducationEditFormHandle>(null);
  const handleSubmit = async (v: UserJson.EducationInput) => {
    await createUserEducation(v);
  };
  const handleAddClick = () => {
    formRef.current?.submitForm();
  };
  useEffect(() => {
    if (isSuccess) {
      onAdded();
    }
  }, [isLoading, isSuccess]);
  return (
    <AppModal
      isOpen={show}
      header={
        <UIBox display="flex" alignItems="center" gap={8}>
          Education
        </UIBox>
      }
      onClose={onClose}
      width={800}
      actions={
        <UIBox display="flex" justifyContent="flex-end" gap={20}>
          <Button size="md" variant="outlined-secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button size="md" variant="primary" onClick={handleAddClick}>
            Add
          </Button>
        </UIBox>
      }
    >
      <UIBox mt={-1.5}>
        <EducationEditForm ref={formRef} onSubmit={handleSubmit} />
      </UIBox>
    </AppModal>
  );
};

export default EducationEditModal;
