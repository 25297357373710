import {
  IconDelete,
  IconEdit1,
  IconEllipsis,
  IconSearch,
  IconSwap,
} from '@/assets/icons';
import {
  UIBox,
  Dropdown,
  DropdownItem,
  IconButton,
  Image,
  Input,
  Pagination,
  UITable,
  UITh,
  UIThead,
  UITr,
  Typography,
} from '@/components/UI';
import { DropdownButton } from '@/components/UI/Dropdown';
import { appRoutes, theme } from '@/constants';
import { projectsMock } from '@/mock';
import { UserStatus } from '@/enums';
import { AppUserStatusTag } from '@/components/App';
import { Link } from 'react-router-dom';

const AdminProjectsPage: React.FC = () => {
  return (
    <>
      <UIBox display="flex" justifyContent="space-between">
        <Typography variant="h5">Posted jobs</Typography>
        <UIBox display="flex" gap={20}>
          <Input
            placeholder={
              <>
                <IconSearch color={theme.colors.icon} />
                Search
              </>
            }
            height={40}
          />
          <Dropdown
            button={
              <UIBox display="flex" alignItems="center" width={200} gap={10}>
                <IconSwap /> Status
              </UIBox>
            }
            height={40}
          >
            <DropdownItem>Status</DropdownItem>
          </Dropdown>
        </UIBox>
      </UIBox>
      <UIBox>
        <UITable width="100%">
          <UIThead>
            <tr>
              <UITh align="left">ID</UITh>
              <UITh></UITh>
              <UITh align="left">Poster</UITh>
              <UITh align="left">Title</UITh>
              <UITh align="left">Type</UITh>
              <UITh align="left">Employment type</UITh>
              <UITh align="center">Status</UITh>
              <UITh align="right">Joined at</UITh>
              <UITh></UITh>
            </tr>
          </UIThead>
          <tbody>
            {projectsMock.map((item, id) => (
              <UITr key={id}>
                <td width={30}>
                  <Typography
                    color={theme.colors.textPrimary}
                    fontWeight={500}
                    fontSize={14}
                  >
                    {id + 1}
                  </Typography>
                </td>
                <td width={30}>
                  <Image src={''} width={30} height={30} />
                </td>
                <td>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {item.companyData?.name}
                  </Typography>
                </td>
                <td>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {item.title}
                  </Typography>
                </td>
                <td>
                  <Typography
                    color={theme.colors.textPrimary}
                    fontWeight={500}
                    fontSize={14}
                  >
                    {item.type}
                  </Typography>
                </td>
                <td>
                  <Typography
                    color={theme.colors.textPrimary}
                    fontWeight={500}
                    fontSize={14}
                  >
                    {item.employmentType}
                  </Typography>
                </td>
                <td align="center" width={82}>
                  <AppUserStatusTag
                    status={UserStatus.DEFAULT}
                    label="Posted"
                  />
                </td>
                <td align="right">
                  <Typography
                    color={theme.colors.textPrimary}
                    fontWeight={500}
                    fontSize={14}
                  >
                    {new Date('2024/01/15').toLocaleDateString()}
                  </Typography>
                </td>
                <td align="right">
                  <Dropdown
                    button={
                      <IconButton>
                        <IconEllipsis />
                      </IconButton>
                    }
                    disableDefaultToggleStyle
                  >
                    <Link to={appRoutes.adminProjectDetail(item.id)}>
                      <DropdownButton variant="primary">
                        <IconEdit1 />
                        Edit
                      </DropdownButton>
                    </Link>
                    <DropdownButton variant="danger">
                      <IconDelete width={8} height={9} />
                      Delete
                    </DropdownButton>
                  </Dropdown>
                </td>
              </UITr>
            ))}
          </tbody>
        </UITable>
        <UIBox display="flex" justifyContent="flex-end" mt={1}>
          <Pagination total={10} offset={1} />
        </UIBox>
      </UIBox>
    </>
  );
};

export default AdminProjectsPage;
