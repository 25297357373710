import { AppModal } from '@/components/App';
import { Button, Spinner, Typography, UIBox } from '@/components/UI';
import { ButtonVariant } from '@/components/UI/Button';
import { theme } from '@/constants';

interface AppConfirmModalProps {
  show: boolean;
  processing?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  question?: string;
  confirmButton?: string;
  variant?: ButtonVariant;
}
const AppConfirmModal: React.FC<AppConfirmModalProps> = ({
  show,
  onClose,
  onConfirm,
  question = 'Do you want to delete this?',
  confirmButton = 'Yes, Delete',
  variant = 'danger',
  processing,
}) => {
  return (
    <AppModal isOpen={show} headerAlign="center" onClose={onClose} width={350}>
      <UIBox display="flex" flexDirection="column" alignItems="center" gap={20}>
        <UIBox display="flex" flexDirection="column" alignItems="center">
          <Typography
            textAlign="center"
            color={theme.colors.textPrimary}
            fontWeight={600}
          >
            {question}
          </Typography>
        </UIBox>
        <Button
          variant={variant}
          height={40}
          onClick={onConfirm}
          width={170}
          size="sm"
          disabled={processing}
        >
          {processing && <Spinner />}
          {confirmButton}
        </Button>
      </UIBox>
    </AppModal>
  );
};

export default AppConfirmModal;
