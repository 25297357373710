import { Editor, EditorState, ContentState } from 'draft-js';
import styled from 'styled-components';
import { theme } from '@/constants';
import { useEffect, useRef, useState } from 'react';
import { StatusAlert } from './Typography';
import { UIBox } from './Box';
import Label from './Label';

const StyledEditorContainer = styled.div<{
  $status?: 'error' | 'info' | 'valid';
  $variant?: 'primary' | 'secondary' | 'transparent';
}>`
  font-family: Gantari;
  position: relative;
  cursor: text;
  padding: 16px;
  font-size: 14px;
  line-height: 22px;
  background: ${({ $status, $variant }) =>
    $variant === 'transparent'
      ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07))'
      : $status
        ? theme.colors.white
        : theme.colors.gray980};
  border-radius: 10px;
  border: solid 1px
    ${({ $status, $variant }) =>
      $status === 'error'
        ? theme.colors.red500
        : $status === 'info'
          ? theme.colors.blue300
          : $status === 'valid'
            ? theme.colors.gray800
            : $variant === 'transparent'
              ? '#FFFFFF12'
              : 'transparent'};
  color: ${({ $variant }) =>
    $variant === 'transparent' ? theme.colors.white : theme.colors.textPrimary};
  &:focus-within {
    border: solid 1px
      ${({ $variant }) =>
        $variant === 'transparent' ? theme.colors.white : theme.colors.blue};
  }
`;

const StyledPlaceholderContainer = styled.div<{
  $variant?: 'primary' | 'secondary' | 'transparent';
}>`
  font-family: Gantari;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: ${({ $variant }) =>
    $variant === 'transparent' ? theme.colors.gray700 : theme.colors.icon};
`;
interface RichEditorProps {
  height?: number;
  placeholder?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'transparent';
  labelSize?: number;
  label?: string;
  status?: 'error' | 'info' | 'valid';
  statusLabel?: string;
  defaultValue?: string;
  onChange?: (v: string) => void;
  onBlur?: () => void;
}
const RichEditor: React.FC<RichEditorProps> = ({
  height = 200,
  variant,
  label,
  labelSize,
  status,
  statusLabel,
  defaultValue,
  placeholder,
  onChange,
  onBlur,
}) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(defaultValue || '')
    )
  );
  const editorRef = useRef<Editor>(null);
  const value = editorState.getCurrentContent().getPlainText();
  useEffect(() => {
    const value = editorState.getCurrentContent().getPlainText();
    onChange?.(value);
  }, [editorState]);
  return (
    <UIBox>
      {label && (
        <Label
          fontSize={labelSize}
          variant={variant === 'primary' ? 'primary' : 'secondary'}
        >
          {label}
        </Label>
      )}
      <StyledEditorContainer
        $status={status ? status : value ? 'valid' : undefined}
        $variant={variant}
        onClick={() => editorRef.current?.focus()}
        style={{
          minHeight: height,
        }}
      >
        {placeholder && !value && (
          <StyledPlaceholderContainer $variant={variant}>
            {placeholder}
          </StyledPlaceholderContainer>
        )}
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={setEditorState}
          onBlur={onBlur}
        />
      </StyledEditorContainer>
      {statusLabel && <StatusAlert status={status}>{statusLabel}</StatusAlert>}
    </UIBox>
  );
};

export default RichEditor;
