import { AppConfirmModalContext } from '@/providers';
import { useContext } from 'react';

export const useConfirmModal = () => {
  const context = useContext(AppConfirmModalContext);
  if (context === undefined) {
    throw new Error(
      'useConfirmModal must be used within an ConfirmModalProvider'
    );
  }
  return context;
};
