import { useScrollTrigger } from '@/hooks';
import { UIFade } from '@/components/UI';
import styled from 'styled-components';
import { IconUpArrow } from '@/assets/icons';

export default function AppScrollTop() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <UIFade fade={trigger}>
      <StyledScrollTopContainer onClick={handleClick} role="presentation">
        <IconUpArrow />
      </StyledScrollTopContainer>
    </UIFade>
  );
}

const StyledScrollTopContainer = styled.div(({ theme }) => ({
  cursor: 'pointer',
  width: 60,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.primary,
  position: 'fixed',
  bottom: 16,
  right: 16,
  borderRadius: 60,
}));
