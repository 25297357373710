import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconUsers2({
  width = 18,
  height = 16,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.845 4.46868C10.845 6.67181 9.04211 8.43735 6.79243 8.43735C4.54357 8.43735 2.73991 6.67181 2.73991 4.46868C2.73991 2.26554 4.54357 0.5 6.79243 0.5C9.04211 0.5 10.845 2.26554 10.845 4.46868ZM0.667969 12.9309C0.667969 10.8914 3.48924 10.3812 6.79241 10.3812C10.1135 10.3812 12.9169 10.909 12.9169 12.95C12.9169 14.9895 10.0956 15.4997 6.79241 15.4997C3.4713 15.4997 0.667969 14.9719 0.667969 12.9309ZM12.4786 4.5402C12.4786 5.66213 12.1345 6.709 11.5311 7.57859C11.4684 7.66803 11.5238 7.78861 11.6331 7.80777C11.7847 7.83253 11.9405 7.8477 12.0995 7.85089C13.6813 7.89162 15.1009 6.89426 15.4932 5.39223C16.0745 3.16354 14.3687 1.16243 12.1957 1.16243C11.96 1.16243 11.7342 1.18639 11.514 1.23031C11.4838 1.2367 11.4512 1.25107 11.4349 1.27662C11.4137 1.30936 11.4292 1.35169 11.4504 1.37963C12.1035 2.27638 12.4786 3.36796 12.4786 4.5402ZM15.0985 9.4189C16.1618 9.62253 16.8606 10.037 17.15 10.6414C17.3955 11.1365 17.3955 11.7115 17.15 12.2066C16.7073 13.1424 15.2787 13.4435 14.7234 13.5209C14.6084 13.5369 14.5163 13.4411 14.5285 13.3277C14.8123 10.7341 12.5561 9.50435 11.9723 9.22167C11.9478 9.20809 11.9421 9.18893 11.9445 9.17615C11.9462 9.16817 11.9568 9.15539 11.9755 9.153C13.2386 9.12904 14.597 9.29913 15.0985 9.4189Z"
        fill={color}
      />
    </svg>
  );
}
