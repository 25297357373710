import { UIBox } from '@/components/UI';
import { useSearchParams } from 'react-router-dom';

import WorkerJobCard from './JobCard';
import { jobsMock } from '@/mock';

const WorkerJobs: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickJobCard = (jobId: number) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.jobId = `${jobId}`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <UIBox display="flex" flexDirection="column" gap={15}>
      {jobsMock.map((job, id) => (
        <WorkerJobCard
          key={id}
          job={job}
          onClick={() => handleOnClickJobCard(job.id)}
        />
      ))}
    </UIBox>
  );
};

export default WorkerJobs;
