import { AppSideModal } from '@/components/App';
import {
  Autocomplete,
  UIBox,
  Button,
  Input,
  RichEditor,
  Typography,
  Spinner,
  IconButton,
  SectionSpinner,
} from '@/components/UI';
import { theme } from '@/constants';
import { useEffect, useState } from 'react';
import NewSkillPublishedModal from './NewSkillPublishedModal';
import { SkillJson } from '@/types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useCreateSkillMutation,
  useIndustryChildrenQuery,
  useIndustryParentsQuery,
  useSkillQuery,
  useUpdateSkillMutation,
} from '@/redux/apis';
import { useSearchParams } from 'react-router-dom';
import { IconEdit } from '@/assets/icons';
import CancelSaveConfirmModal from './CancelSaveConfirmModal';
import { inputStatus } from '@/utils';

type FormikData = SkillJson.SkillInput & {
  parentIndustryId: string;
};

const validationSchema = yup.object({
  name: yup.string().required('Skill name is required.'),
  // parentIndustryId: yup.string().required('Category is required.'),
  // industryId: yup.string().required('Sector is required.'),
});
const initialValues: FormikData = {
  name: '',
  description: '',
  industryId: '',
  parentIndustryId: '',
  isDefault: false,
};
const SidebarAddNewSkills: React.FC<{
  show: boolean;
  setShow: (show: boolean) => void;
}> = ({ show, setShow }) => {
  const [submitted, setSubmitted] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPublishedModal, setShowPublishedModal] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const skillId = searchParams.get('skillId') || '';
  const isCreateMode = !skillId || skillId === 'new';
  const {
    data: skill,
    isLoading,
    refetch,
  } = useSkillQuery(
    {
      id: skillId,
    },
    { skip: !skillId || skillId === 'new' }
  );
  const [
    createSkill,
    { isLoading: isCreating, data: createdSkill, isSuccess: isCreated },
  ] = useCreateSkillMutation();
  const [
    updateSkill,
    { isLoading: isUpdating, data: updatedSkill, isSuccess: isUpdated },
  ] = useUpdateSkillMutation();
  const formik = useFormik<FormikData>({
    initialValues,
    validationSchema,
    onSubmit: (v) => {
      setSubmitted(true);
      const { parentIndustryId, industryId = null, ...formData } = v;
      const dataToSave: SkillJson.SkillInput = { ...formData, industryId };
      if (!isCreateMode) {
        updateSkill(dataToSave);
      } else {
        createSkill(dataToSave);
      }
    },
  });
  const { data: parentIndustries = [], isLoading: loadingParentIndustries } =
    useIndustryParentsQuery();
  const {
    data: childrenIndustries = [],
    isLoading: loadingChildrenIndustries,
  } = useIndustryChildrenQuery(
    {
      id: formik.values.parentIndustryId || '',
    },
    { skip: !formik.values.parentIndustryId }
  );
  useEffect(() => {
    if (!skillId) {
      setEditMode(true);
    } else {
      setShow(true);
      if (skillId === 'new') setEditMode(true);
      else {
        setEditMode(false);
        refetch();
      }
    }
  }, [skillId]);
  useEffect(() => {
    if (show) {
      const init = {
        id: skill?.id,
        name: skill?.name || '',
        description: skill?.description || '',
        industryId: skill?.industryId || '',
        parentIndustryId: skill?.industry?.parentId || '',
        isDefault: !!skill?.isDefault,
      };
      formik.setValues(!skillId || skillId === 'new' ? initialValues : init);
    }
  }, [skill, show]);
  const handleCloseSideModal = () => {
    setSubmitted(false);
    setShowConfirmModal(false);
    setShowPublishedModal(false);
    setShow(false);
    formik.resetForm();
    const { skillId, ...currentSearchParams } =
      Object.fromEntries(searchParams);
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  useEffect(() => {
    if (isCreated && submitted) {
      setShowPublishedModal(true);
    }
  }, [isCreated, submitted]);
  useEffect(() => {
    if (isUpdated && submitted) {
      setShowPublishedModal(true);
    }
  }, [isUpdated, submitted]);
  return (
    <AppSideModal
      isOpen={show}
      width={414}
      onClose={() =>
        editMode && formik.dirty
          ? setShowConfirmModal(true)
          : handleCloseSideModal()
      }
      customTools={
        !editMode && (
          <IconButton onClick={() => setEditMode(true)}>
            <IconEdit />
          </IconButton>
        )
      }
    >
      {isLoading ? (
        <SectionSpinner />
      ) : (
        <>
          {!editMode ? (
            <UIBox
              display="flex"
              flexDirection="column"
              gap={30}
              px={1.5}
              py={2}
            >
              <UIBox>
                <Typography variant="h6" fontSize={20} marginBottom={0}>
                  {skill?.name}
                </Typography>
                <Typography>{skill?.industry?.name}</Typography>
              </UIBox>

              <UIBox>
                <Typography variant="h6" marginBottom={10}>
                  Description
                </Typography>
                {skill?.description?.split('\n').map((desc, id) => (
                  <Typography key={id} marginBottom={10}>
                    {desc}
                  </Typography>
                ))}
              </UIBox>
            </UIBox>
          ) : (
            <>
              <UIBox
                display="flex"
                flexDirection="column"
                gap={30}
                px={1.5}
                py={2}
              >
                <Typography variant="h6" fontSize={20} marginBottom={0}>
                  {isCreateMode ? '+Add new skill' : skill?.name}
                </Typography>
                <Input
                  label="Write skills name"
                  variant="secondary"
                  fullWidth
                  {...formik.getFieldProps('name')}
                  {...inputStatus(formik, 'name')}
                />
                <Autocomplete
                  label="Category"
                  fullWidth
                  isClearable
                  isLoading={loadingParentIndustries}
                  options={parentIndustries}
                  value={parentIndustries.find(
                    (item) => item.id === formik.values.parentIndustryId
                  )}
                  onChange={(v) =>
                    formik.setFieldValue('parentIndustryId', v?.id)
                  }
                  getOptionLabel={(v) => v.name}
                  getOptionValue={(v) => v.id}
                  onBlur={() => formik.setFieldTouched('parentIndustryId')}
                  {...inputStatus(formik, 'parentIndustryId')}
                />
                <Autocomplete
                  label="Sector"
                  fullWidth
                  isClearable
                  isLoading={loadingChildrenIndustries}
                  options={childrenIndustries}
                  value={childrenIndustries.find(
                    (item) => item.id === formik.values.industryId
                  )}
                  onChange={(v) => formik.setFieldValue('industryId', v?.id)}
                  getOptionLabel={(v) => v.name}
                  getOptionValue={(v) => v.id}
                  onBlur={() => formik.setFieldTouched('industryId')}
                  {...inputStatus(formik, 'industryId')}
                />
                <RichEditor
                  label="Description"
                  defaultValue={formik.values.description}
                  onChange={(v) => formik.setFieldValue('description', v)}
                />
              </UIBox>
              <UIBox
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                background={theme.colors.white}
                boxShadow="10px -1px 30px 0px #7B7D9633"
                py={1}
                px={1.5}
                display="flex"
                gap={10}
              >
                <UIBox width={'50%'}>
                  <Button
                    fullWidth
                    variant="primary"
                    size="md"
                    onClick={() => formik.handleSubmit()}
                    disabled={isCreating || isUpdating}
                  >
                    {(isCreating || isUpdating) && <Spinner size="sm" />}
                    {isCreateMode ? 'Add skill' : 'Update skill'}
                  </Button>
                </UIBox>
                <UIBox width={'50%'}>
                  <Button
                    fullWidth
                    variant="outlined-primary"
                    size="md"
                    onClick={() => {
                      formik.resetForm();
                      if (isCreateMode) setShow(false);
                      else setEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                </UIBox>
              </UIBox>
            </>
          )}
        </>
      )}
      <NewSkillPublishedModal
        show={showPublishedModal}
        createdSkill={isCreateMode ? createdSkill : updatedSkill}
        onClose={() => {
          // setSubmitted(false);
          // setShowPublishedModal(false);
          handleCloseSideModal();
        }}
        onLookSkills={() => {
          handleCloseSideModal();
        }}
      />
      <CancelSaveConfirmModal
        show={showConfirmModal}
        onCancel={handleCloseSideModal}
        onClose={() => setShowConfirmModal(false)}
      />
    </AppSideModal>
  );
};

export default SidebarAddNewSkills;
