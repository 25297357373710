import ReactModal from 'react-modal';
import { UIBox, IconButton } from '@/components/UI';
import { IconClose } from '@/assets/icons';
import { theme } from '@/constants';

interface AppSideModalProps extends ReactModal.Props {
  hideClose?: boolean;
  customTools?: React.ReactNode;
  width?: number;
  onClose?: () => void;
}
const AppSideModal: React.FC<AppSideModalProps> = ({
  style,
  customTools,
  children,
  hideClose,
  onClose,
  width = 400,
  ...props
}) => {
  const handleOnClose = () => {
    onClose?.();
  };
  return (
    <ReactModal
      onRequestClose={handleOnClose}
      closeTimeoutMS={500}
      style={{
        overlay: {
          zIndex: 90,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: `rgba(0,0,0,0.2)`,
        },
        content: {
          padding: 0,
          width: 'fit-content',
          minWidth: width,
          maxWidth: width,
          position: 'absolute',
          bottom: '0',
          right: '0',
          top: 58,
          left: 'unset',
        },
        ...style,
      }}
      {...props}
    >
      <UIBox
        zIndex={10}
        position="absolute"
        display="flex"
        gap={10}
        right={15}
        top={15}
      >
        {customTools}
        {!hideClose && (
          <IconButton onClick={handleOnClose}>
            <IconClose color={theme.colors.primary} />
          </IconButton>
        )}
      </UIBox>
      <UIBox maxHeight={'calc(100vh - 60px)'} overflow="auto">
        {children}
      </UIBox>
    </ReactModal>
  );
};
export default AppSideModal;
