import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconCopy({
  width = 17,
  height = 17,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1562 11.1562H14.3438V2.65625H5.84375V5.84375"
        stroke={color}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1562 5.84375H2.65625V14.3438H11.1562V5.84375Z"
        stroke={color}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
