import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconSearch({
  width = 18,
  height = 18,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4284 7.71422C13.4284 10.8701 10.8701 13.4284 7.71422 13.4284C4.55834 13.4284 2 10.8701 2 7.71422C2 4.55834 4.55834 2 7.71422 2C10.8701 2 13.4284 4.55834 13.4284 7.71422ZM15.4284 7.71422C15.4284 11.9747 11.9747 15.4284 7.71422 15.4284C3.45377 15.4284 0 11.9747 0 7.71422C0 3.45377 3.45377 0 7.71422 0C11.9747 0 15.4284 3.45377 15.4284 7.71422ZM15.4402 13.4155L17.6299 15.183H17.6679C18.1109 15.6308 18.1109 16.3567 17.6679 16.8045C17.2249 17.2523 16.5067 17.2523 16.0637 16.8045L14.2465 14.7219C14.0747 14.5489 13.9782 14.3138 13.9782 14.0687C13.9782 13.8236 14.0747 13.5885 14.2465 13.4155C14.5778 13.0864 15.1089 13.0864 15.4402 13.4155Z"
        fill={color}
      />
    </svg>
  );
}
