import { theme } from '@/constants';
import { UIBox } from './Box';
import Label from './Label';
import styled from 'styled-components';

const StyledCheckbox = styled.input`
  cursor: pointer;
  margin: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: block;
  position: relative;
  &::before {
    position: absolute;
    top: -1px;
    left: -1px;
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background: ${theme.colors.gray810};
  }
  &:checked {
    &::before {
      background: ${theme.colors.primary};
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      left: 5px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
`;

type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: React.ReactNode;
};
const Checkbox: React.FC<CheckboxProps> = ({ id, label, ...props }) => {
  return (
    <UIBox display="flex" alignItems="center" cursor="pointer">
      <StyledCheckbox id={id} type="checkbox" {...props} />
      {label && (
        <Label
          color={theme.colors.gray500}
          htmlFor={id}
          fontSize={14}
          fontWeight={400}
          marginBottom={0}
          marginLeft={10}
          style={{ cursor: 'pointer' }}
        >
          {label}
        </Label>
      )}
    </UIBox>
  );
};
export default Checkbox;
