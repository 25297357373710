import { UIBox, Button, Image, Typography } from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { useNavigate } from 'react-router-dom';

const Error404: React.FC = () => {
  const navigate = useNavigate();
  return (
    <UIBox
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <UIBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={20}
        maxWidth={340}
      >
        <Image src="/images/page-not-found.png" />
        <UIBox display="flex" flexDirection="column" alignItems="center">
          <Typography
            fontSize={45}
            fontWeight={400}
            lineHeight="60px"
            color={theme.colors.textPrimary}
          >
            Page Not Found
          </Typography>
          <Typography
            fontSize={18}
            lineHeight="24px"
            textAlign="center"
            color={theme.colors.primary}
          >
            This page doesn’t exist or was removed! We suggest you go back to
            home.
          </Typography>
        </UIBox>
        <UIBox width={230}>
          <Button onClick={() => navigate(appRoutes.home)} rounded fullWidth>
            Go back Home
          </Button>
        </UIBox>
      </UIBox>
    </UIBox>
  );
};
export default Error404;
