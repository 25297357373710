import { theme } from '@/constants';
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from 'react';
import styled from 'styled-components';
import Link from './Link';
import { UIBox } from './Box';
import { IconCaretLeft } from '@/assets/icons';
import { Typography } from '.';
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'success'
  | 'danger'
  | 'outlined-primary'
  | 'outlined-secondary'
  | 'transparent';

interface ButtonProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: ButtonVariant;
  height?: number | string;
  width?: number | string;
  fullWidth?: boolean;
  fontWeight?: number;
  rounded?: boolean;
}
const Button = styled(
  ({
    size = 'md',
    variant,
    height,
    width,
    fullWidth,
    fontWeight,
    rounded,
    ...rest
  }: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    ButtonProps) => <button {...rest} />
)<ButtonProps>(
  ({
    variant = 'primary',
    size,
    rounded,
    height,
    width,
    fullWidth,
    fontWeight,
    theme,
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Gantari',
    gap: 10,
    borderRadius: rounded ? 30 : size === 'xs' ? 5 : 10,
    height: height
      ? height
      : size === 'xs'
        ? 27
        : size === 'sm'
          ? 35
          : size === 'md'
            ? 44
            : 50,
    paddingLeft: size === 'xs' ? 12 : 20,
    paddingRight: size === 'xs' ? 12 : 20,
    fontSize: size === 'xs' ? 12 : size === 'sm' ? 14 : 16,
    lineHeight: size === 'xs' ? '14px' : size === 'sm' ? '17px' : '20px',
    fontWeight: fontWeight ? fontWeight : 600,
    cursor: 'pointer',
    border: 'none',
    ...(width && { width }),
    ...(fullWidth && { width: '100%' }),
    ...(variant === 'primary' && {
      background: theme.colors.primary,
      color: theme.colors.white,
      '&:disabled': {
        background: theme.colors.gray200,
        cursor: 'none',
      },
    }),
    ...(variant === 'secondary' && {
      background: theme.colors.secondaryBackground,
      color: theme.colors.primary,
    }),
    ...(variant === 'gray' && {
      background: '#ecedf1',
      color: '#494965',
    }),
    ...(variant === 'success' && {
      background: theme.colors.secondary,
      color: theme.colors.white,
    }),
    ...(variant === 'danger' && {
      background: theme.colors.dangerBackground,
      color: theme.colors.red500,
    }),
    ...(variant === 'outlined-primary' && {
      background: theme.colors.white,
      color: theme.colors.primary,
      border: `1px solid #c6cce1`,
    }),
    ...(variant === 'outlined-secondary' && {
      background: theme.colors.white,
      color: theme.colors.primary,
      border: `1px solid ${theme.colors.secondaryBackground}`,
    }),
    ...(variant === 'transparent' && {
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
      border: `solid 1px ${theme.colors.gray400}`,
      color: theme.colors.green,
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '23px',
    }),
  })
);

export default Button;

export const SocialButton = styled.button`
  font-family: Gantari;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  border-radius: 8px;
  gap: 10px;
  padding: 10px;
  color: ${theme.colors.blue};
  font-size: 14px;
  font-weight: 500;
  border: solid 1px ${theme.colors.gray600};
  background: ${theme.colors.white};
  &:hover {
    background: ${theme.colors.buttonHoverBg};
  }
  &:disabled {
    background: ${theme.colors.gray980};
    color: ${theme.colors.gray700};
    cursor: default;
  }
`;

interface IconButtonProps {
  rounded?: boolean;
}
export const IconButton = styled(
  ({
    rounded,
    ...rest
  }: IconButtonProps &
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >) => <button {...rest} />
)<IconButtonProps>`
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ rounded }) => (rounded ? '36px' : '24px')};
  height: ${({ rounded }) => (rounded ? '36px' : '24px')};
  min-width: ${({ rounded }) => (rounded ? '36px' : '24px')};
  min-height: ${({ rounded }) => (rounded ? '36px' : '24px')};
  border: none;
  border-radius: 36px;
  background: ${({ rounded }) =>
    rounded ? theme.colors.gray840 : 'transparent'};
  opacity: 0.5;
  transition: opacity linear 0.3s;
  &:hover {
    opacity: 1;
  }
`;

const StyledReadMoreButton = styled.button<{ color?: string }>(
  ({ theme, color }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Gantari',
    background: 'transparent',
    padding: 0,
    border: 'none',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '23px',
    color: color || theme.colors.greenLight,
    '> svg': {
      marginLeft: 10,
    },
  })
);

interface ReadMoreButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: string;
}
export const ReadMoreButton: React.FC<ReadMoreButtonProps> = ({
  children,
  color,
}) => <StyledReadMoreButton color={color}>{children}</StyledReadMoreButton>;

interface BackButtonProps extends PropsWithChildren {
  to: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ to, children }) => {
  return (
    <Link to={to} color={theme.colors.gray500} style={{ width: 'fit-content' }}>
      <UIBox display="flex" alignItems="center" gap={15}>
        <IconCaretLeft />
        <Typography fontSize={14} lineHeight="17px" fontWeight={500}>
          {children}
        </Typography>
      </UIBox>
    </Link>
  );
};
