import { IconFacebook, IconGoogle, IconLinkedin } from '@/assets/icons';
import {
  UIBox,
  Button,
  UICard,
  Checkbox,
  UIGrid,
  Link,
  UIOrDivider,
  SocialButton,
  Input,
  Typography,
  StatusAlert,
  Spinner,
} from '@/components/UI';
import { appConfig, appRoutes, theme } from '@/constants';
import { Provider, RoleNames } from '@/enums';
import { useToast } from '@/hooks';
import { useRegisterMutation } from '@/redux/apis';
import { setCredentials } from '@/redux/reducers';
import { AppTypes, AuthJson } from '@/types';
import { getInputError, inputStatus } from '@/utils';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

type RegisterInputForm = AuthJson.RegisterInput & {
  agreeTerms: boolean;
  sendMeEmails: boolean;
};

interface RegisterFormProps {
  roleName: RoleNames;
}
const RegisterForm: React.FC<RegisterFormProps> = ({ roleName }) => {
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [register, { isLoading, error, data }] = useRegisterMutation();
  const isCompanyRegister = roleName === RoleNames.ORG_ADMIN;

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required first name'),
    lastName: Yup.string().required('Required last name'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Required email'),
    ...(isCompanyRegister
      ? { companyName: Yup.string().required('Required last name') }
      : {}),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Required password'),
    agreeTerms: Yup.boolean()
      .required('You must agree to the terms')
      .oneOf([true], 'You must agree to the terms of service'),
  });
  const initialValues: RegisterInputForm = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    sendMeEmails: false,
    agreeTerms: false,
    roleName,
    provider: Provider.WEB_FORM,
  };

  const formik = useFormik<RegisterInputForm>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const { agreeTerms, sendMeEmails, ...dataToSave } = values;
      dispatch(
        setCredentials({
          email: dataToSave.email,
          password: dataToSave.password,
        })
      );
      await register(dataToSave);
    },
  });
  useEffect(() => {
    if (!isLoading && data) {
      const searchParams = new URLSearchParams();
      searchParams.set('userId', data.id.toString());
      navigate({
        pathname: appRoutes.authVerifyCode,
        search: `?` + searchParams.toString(),
      });
    }
    if (error) {
      const err = error as AppTypes.ResponseError;
      const msg = err.data?.message || 'Cannot create account';
      showToast(msg, { variant: 'error' });
      setErrMsg(msg);
    }
  }, [isLoading, error, data]);
  return (
    <UICard borderRadius={32} boxShadow="0px 4px 35px 0px #00000014">
      <Typography variant="h3" marginBottom={30}>
        Welcome to join us!
      </Typography>
      <UIGrid gap={20} columns={isCompanyRegister ? 2 : 3}>
        <SocialButton
          onClick={() =>
            (window.location.href =
              appConfig.API_URL +
              `/auth/google?roleName=${roleName}&frontendDomain=${encodeURIComponent(window.location.origin)}`)
          }
        >
          <IconGoogle /> Sign in with Google
        </SocialButton>
        <SocialButton disabled>
          <IconFacebook disabled />
          {isCompanyRegister ? 'Sign up with Facebook' : 'Facebook'}
        </SocialButton>
        {!isCompanyRegister && (
          <SocialButton disabled>
            <IconLinkedin disabled /> Linkedin
          </SocialButton>
        )}
      </UIGrid>
      <UIOrDivider>or</UIOrDivider>
      <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
        <UIGrid gap={20} columns={2}>
          <UIBox mb={1.5}>
            <Input
              placeholder="First Name"
              label="Enter your First Name*"
              fullWidth
              autoComplete="off"
              {...formik.getFieldProps('firstName')}
              {...inputStatus(formik, 'firstName')}
            />
          </UIBox>
          <UIBox>
            <Input
              placeholder="Last Name"
              label="Enter your Last name*"
              fullWidth
              autoComplete="off"
              {...formik.getFieldProps('lastName')}
              {...inputStatus(formik, 'lastName')}
            />
          </UIBox>
        </UIGrid>

        {isCompanyRegister && (
          <UIBox mb={1.5}>
            <Input
              label="Enter Company Name*"
              placeholder="Company Name"
              fullWidth
              {...formik.getFieldProps('companyName')}
              {...inputStatus(formik, 'companyName')}
            />
          </UIBox>
        )}
        <UIBox mb={1.5}>
          <Input
            placeholder="Email"
            type="email"
            label="Enter your Email*"
            fullWidth
            autoComplete="off"
            {...formik.getFieldProps('email')}
            {...inputStatus(formik, 'email')}
          />
        </UIBox>
        <UIBox mb={1.5}>
          <Input
            placeholder="Password"
            type="password"
            label="Password*"
            fullWidth
            autoComplete="new-password"
            {...formik.getFieldProps('password')}
            {...inputStatus(formik, 'password')}
          />
        </UIBox>
        <UIBox mb={0.5}>
          <Checkbox
            id="sendMeEmail"
            label="Send me emails with tips on how to find talent that fits my needs."
            {...formik.getFieldProps('sendMeEmail')}
          />
        </UIBox>
        <UIBox mb={2}>
          <Checkbox
            id="agreeTerm"
            label={
              <>
                I agree to the{' '}
                <Link color={theme.colors.primary} to="">
                  Terms of Service
                </Link>
                , including the{' '}
                <Link color={theme.colors.primary} to="">
                  User Agreement
                </Link>{' '}
                and{' '}
                <Link color={theme.colors.primary} to="">
                  Privacy Policy.
                </Link>
              </>
            }
            {...formik.getFieldProps('agreeTerms')}
          />
          {getInputError(formik, 'agreeTerms') && (
            <StatusAlert status="error">{formik.errors.agreeTerms}</StatusAlert>
          )}
        </UIBox>
        {error && (
          <UIBox mb={1}>
            <StatusAlert status="error">{errMsg}</StatusAlert>
          </UIBox>
        )}
        <Button type="submit" fullWidth rounded size="lg" disabled={isLoading}>
          {isLoading && <Spinner />}
          Create Account
        </Button>
        <Typography textAlign="center" fontSize={16} marginTop={20}>
          If you have an account you can{' '}
          <Link to={appRoutes.login}>login here</Link>
        </Typography>
      </form>
    </UICard>
  );
};

export default RegisterForm;
