import {
  UIBox,
  Button,
  Dropdown,
  DropdownItem,
  Typography,
} from '@/components/UI';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IconSwap } from '@/assets/icons';
import { WorkerJobSearchFilter } from '@/modules/Job/WorkerJobs';
import WorkerSummaryCard from '@/modules/Job/WorkerJobs/WorkerSummaryCard';
import JobFilter from '@/modules/Job/WorkerJobs/JobFilter';
import SidebarJobDetail from '@/modules/Job/SidebarJobDetail';
import { WorkerJobRouteCategory } from '@/enums';
import { appRoutes, theme } from '@/constants';

const WorkerJobsPage: React.FC = () => {
  const navigate = useNavigate();
  const handleOnClickJobType = (jobType: WorkerJobRouteCategory) => {
    navigate(appRoutes.workerFindWork(jobType));
  };
  const { pathname } = useLocation();
  const [, , , category] = pathname.split('/');
  const jobCategories = [
    {
      label: 'All Jobs',
      route: WorkerJobRouteCategory.ALL,
    },
    {
      label: 'New Jobs',
      route: WorkerJobRouteCategory.NEW,
    },
    {
      label: 'Most Recent',
      route: WorkerJobRouteCategory.MOST_RECENT,
    },
    {
      label: 'Saved Jobs(10)',
      route: WorkerJobRouteCategory.SAVED,
    },
  ];
  return (
    <UIBox display="flex" gap={30}>
      <UIBox flexGrow={1} display="flex" flexDirection="column" gap={30}>
        <WorkerJobSearchFilter />
        <UIBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" marginBottom={0}>
            Jobs List
          </Typography>
          <Typography>5645 jobs</Typography>
        </UIBox>
        <UIBox display="flex" justifyContent="space-between" gap={20}>
          {jobCategories.map((item, id) => (
            <UIBox flexGrow={1} key={id}>
              <Button
                variant={category === item.route ? 'primary' : 'gray'}
                fullWidth
                onClick={() => handleOnClickJobType(item.route)}
                size="sm"
                height={40}
              >
                {item.label}
              </Button>
            </UIBox>
          ))}
          <UIBox flexGrow={1} minWidth={200}>
            <Dropdown
              button={
                <UIBox display="flex" gap={10}>
                  <IconSwap />
                  <Typography
                    variant="span"
                    fontSize={14}
                    fontWeight={600}
                    color={theme.colors.textPurpleLight}
                  >
                    Last 30 days
                  </Typography>
                </UIBox>
              }
              fullWidth
            >
              <DropdownItem>
                <Typography
                  variant="span"
                  fontSize={14}
                  fontWeight={600}
                  color={theme.colors.textPurpleLight}
                >
                  for % matching skills
                </Typography>
              </DropdownItem>
              <DropdownItem>
                <Typography
                  variant="span"
                  fontSize={14}
                  fontWeight={600}
                  color={theme.colors.textPurpleLight}
                >
                  by salary
                </Typography>
              </DropdownItem>
            </Dropdown>
          </UIBox>
        </UIBox>
        <Outlet />
      </UIBox>
      <UIBox
        maxWidth={390}
        minWidth={390}
        display="flex"
        flexDirection="column"
        gap={30}
      >
        <WorkerSummaryCard />
        <JobFilter />
      </UIBox>
      <SidebarJobDetail />
    </UIBox>
  );
};

export default WorkerJobsPage;
