import { UIBox, Typography, UICard, Image, IconButton } from '@/components/UI';
import { appRoutes, theme } from '@/constants';

import { OrganizationJson } from '@/types';
import {
  IconArrowSquareOut,
  IconEarth,
  IconShieldDone,
  IconUsers3,
} from '@/assets/icons';
import { Link } from 'react-router-dom';

interface WorkerCompanySummaryCardProps {
  company?: OrganizationJson.Organization;
  hideDescription?: boolean;
}

const WorkerCompanySummaryCard: React.FC<WorkerCompanySummaryCardProps> = ({
  company,
  hideDescription,
}) => {
  return (
    <UICard boxShadow="0px 4px 30px 0px #7B7D961A" position="relative">
      <UIBox position="absolute" right={20} top={20}>
        <Link to={appRoutes.workerCompanyDetail(company?.id || 29387)}>
          <IconButton>
            <IconArrowSquareOut />
          </IconButton>
        </Link>
      </UIBox>
      <UIBox display="flex" gap={20}>
        <UIBox
          width={100}
          height={100}
          borderRadius={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          background={theme.colors.buttonHoverBg}
        >
          <Image src={'company?.logo'} width={80} />
        </UIBox>
        <UIBox display="flex" flexDirection="column" gap={5}>
          <Typography fontWeight={500} color={theme.colors.textPrimary}>
            {company?.name}
          </Typography>
          <UIBox display="flex" alignItems="center" gap={10}>
            <IconShieldDone />
            <Typography
              variant="span"
              fontSize={14}
              lineHeight="19px"
              color={theme.colors.textDescription}
            >
              {'company?.companyType'}
            </Typography>
          </UIBox>
          <UIBox display="flex" alignItems="center" gap={10}>
            <IconUsers3 />
            <Typography
              variant="span"
              fontSize={14}
              lineHeight="19px"
              color={theme.colors.textDescription}
            >
              ({company?.numEmployee} employees)
            </Typography>
          </UIBox>
          <UIBox display="flex" alignItems="center" gap={10}>
            <IconEarth />
            <Typography
              variant="span"
              fontSize={14}
              lineHeight="19px"
              color={theme.colors.textDescription}
            >
              {company?.website}
            </Typography>
          </UIBox>
        </UIBox>
      </UIBox>
      {!hideDescription && (
        <Typography marginTop={10}>{company?.bio}</Typography>
      )}
    </UICard>
  );
};

export default WorkerCompanySummaryCard;
