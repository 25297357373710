import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconPrivateOrg({
  width = 14,
  height = 14,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50397 5.11958C5.41064 5.12891 5.34375 5.21291 5.34375 5.3078V5.67491C5.34375 5.76824 5.40908 5.8538 5.50397 5.86313C5.61753 5.87247 5.71086 5.78847 5.71086 5.67491V5.3078C5.71086 5.19424 5.61597 5.10091 5.50397 5.11958Z"
        fill={color}
      />
      <path
        d="M5.50397 7.23478C5.41064 7.24411 5.34375 7.32811 5.34375 7.423V7.78078C5.34375 7.87411 5.40908 7.95967 5.50397 7.969C5.61753 7.97834 5.71086 7.89434 5.71086 7.78078V7.423C5.71086 7.311 5.61597 7.21611 5.50397 7.23478Z"
        fill={color}
      />
      <path
        d="M5.50397 6.18208C5.41064 6.19141 5.34375 6.27541 5.34375 6.3703V6.72808C5.34375 6.82141 5.40908 6.90697 5.50397 6.9163C5.61753 6.92563 5.71086 6.84163 5.71086 6.72808V6.3703C5.71086 6.25674 5.61597 6.16341 5.50397 6.18208Z"
        fill={color}
      />
      <path
        d="M5.50397 4.075C5.41064 4.08433 5.34375 4.16833 5.34375 4.26322V4.621C5.34375 4.71433 5.40908 4.79989 5.50397 4.80922C5.61753 4.81855 5.71086 4.73455 5.71086 4.621V4.26322C5.71086 4.14967 5.61597 4.05478 5.50397 4.075Z"
        fill={color}
      />
      <path
        d="M6.49225 8.28751C6.39892 8.29685 6.33203 8.38085 6.33203 8.47574V8.83351C6.33203 8.92685 6.39736 9.0124 6.49225 9.02174C6.60581 9.03107 6.69914 8.94707 6.69914 8.83351V8.47574C6.7007 8.36374 6.60581 8.26885 6.49225 8.28751Z"
        fill={color}
      />
      <path
        d="M6.49225 7.23478C6.39892 7.24411 6.33203 7.32811 6.33203 7.423V7.78078C6.33203 7.87411 6.39736 7.95967 6.49225 7.969C6.60581 7.97834 6.69914 7.89434 6.69914 7.78078V7.423C6.7007 7.311 6.60581 7.21611 6.49225 7.23478Z"
        fill={color}
      />
      <path
        d="M4.56257 7.23478C4.46923 7.24411 4.40234 7.32811 4.40234 7.423V7.78078C4.40234 7.87411 4.46768 7.95967 4.56257 7.969C4.67612 7.97834 4.76945 7.89434 4.76945 7.78078V7.423C4.76945 7.311 4.67612 7.21611 4.56257 7.23478Z"
        fill={color}
      />
      <path
        d="M5.50397 8.28751C5.41064 8.29685 5.34375 8.38085 5.34375 8.47574V8.83351C5.34375 8.92685 5.40908 9.0124 5.50397 9.02174C5.61753 9.03107 5.71086 8.94707 5.71086 8.83351V8.47574C5.71086 8.36374 5.61597 8.26885 5.50397 8.28751Z"
        fill={color}
      />
      <path
        d="M4.56257 8.28751C4.46923 8.29685 4.40234 8.38085 4.40234 8.47574V8.83351C4.40234 8.92685 4.46768 9.0124 4.56257 9.02174C4.67612 9.03107 4.76945 8.94707 4.76945 8.83351V8.47574C4.76945 8.36374 4.67612 8.26885 4.56257 8.28751Z"
        fill={color}
      />
      <path
        d="M4.56257 5.11958C4.46923 5.12891 4.40234 5.21291 4.40234 5.3078V5.67491C4.40234 5.76824 4.46768 5.8538 4.56257 5.86313C4.67612 5.87247 4.76945 5.78847 4.76945 5.67491V5.3078C4.76945 5.19424 4.67612 5.10091 4.56257 5.11958Z"
        fill={color}
      />
      <path
        d="M4.56257 6.18208C4.46923 6.19141 4.40234 6.27541 4.40234 6.3703V6.72808C4.40234 6.82141 4.46768 6.90697 4.56257 6.9163C4.67612 6.92563 4.76945 6.84163 4.76945 6.72808V6.3703C4.76945 6.25674 4.67612 6.16341 4.56257 6.18208Z"
        fill={color}
      />
      <path
        d="M9.60553 7.45158C9.5122 7.46091 9.44531 7.54491 9.44531 7.6398V8.03491C9.44531 8.14847 9.53865 8.2418 9.6522 8.22313C9.74554 8.2138 9.81242 8.1298 9.81242 8.03491V7.6398C9.81242 7.5278 9.71753 7.43291 9.60553 7.45158Z"
        fill={color}
      />
      <path
        d="M8.74225 7.45158C8.64892 7.46091 8.58203 7.54491 8.58203 7.6398V8.03491C8.58203 8.14847 8.67536 8.2418 8.78892 8.22313C8.88225 8.2138 8.94914 8.1298 8.94914 8.03491V7.6398C8.94914 7.5278 8.85425 7.43291 8.74225 7.45158Z"
        fill={color}
      />
      <path
        d="M9.6522 9.72727C9.53865 9.71794 9.44531 9.80194 9.44531 9.91549V10.3199C9.44531 10.4335 9.53865 10.5268 9.6522 10.5082C9.74554 10.4988 9.81242 10.4148 9.81242 10.3199V9.91549C9.81242 9.82216 9.74709 9.73816 9.6522 9.72727Z"
        fill={color}
      />
      <path
        d="M9.6522 8.59836C9.53865 8.58903 9.44531 8.67303 9.44531 8.78659V9.1817C9.44531 9.29525 9.53865 9.38859 9.6522 9.36992C9.74554 9.36059 9.81242 9.27659 9.81242 9.1817V8.78659C9.81242 8.6917 9.74709 8.6077 9.6522 8.59836Z"
        fill={color}
      />
      <path
        d="M8.78892 8.59836C8.67536 8.58903 8.58203 8.67303 8.58203 8.78659V9.1817C8.58203 9.29525 8.67536 9.38859 8.78892 9.36992C8.88225 9.36059 8.94914 9.27659 8.94914 9.1817V8.78659C8.94914 8.6917 8.88225 8.6077 8.78892 8.59836Z"
        fill={color}
      />
      <path
        d="M8.78892 9.72727C8.67536 9.71794 8.58203 9.80194 8.58203 9.91549V10.3199C8.58203 10.4335 8.67536 10.5268 8.78892 10.5082C8.88225 10.4988 8.94914 10.4148 8.94914 10.3199V9.91549C8.94914 9.82216 8.88225 9.73816 8.78892 9.72727Z"
        fill={color}
      />
      <path
        d="M7 0C3.13444 0 0 3.13444 0 7C0 10.8656 3.13444 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13444 10.8656 0 7 0ZM6.40733 11.2327H4.77089V9.81244H6.40733V11.2327ZM7.658 11.2327H7.63H6.73711V11.2047V11.186V9.67245C6.73711 9.56822 6.65311 9.48422 6.54889 9.48422H4.62933C4.52511 9.48422 4.44111 9.56822 4.44111 9.67245V11.1969V11.2156V11.2342H3.54822H3.50156V4.31978C3.50156 4.28244 3.52022 4.24511 3.54822 4.21556C3.68044 4.08333 3.99933 3.80178 4.508 3.52956C5.11933 3.19978 6.13511 2.80467 7.56467 2.76733C7.62067 2.76733 7.658 2.80467 7.658 2.87156V11.2327ZM10.4984 11.2327H7.94111V7.01867C7.94111 6.93467 8.00644 6.86778 8.092 6.86778H10.3491C10.4331 6.86778 10.5 6.93311 10.5 7.01867V11.2327H10.4984Z"
        fill={color}
      />
      <path
        d="M6.49225 4.075C6.39892 4.08433 6.33203 4.16833 6.33203 4.26322V4.621C6.33203 4.71433 6.39736 4.79989 6.49225 4.80922C6.60581 4.81855 6.69914 4.73455 6.69914 4.621V4.26322C6.7007 4.14967 6.60581 4.05478 6.49225 4.075Z"
        fill={color}
      />
      <path
        d="M6.49225 5.11958C6.39892 5.12891 6.33203 5.21291 6.33203 5.3078V5.67491C6.33203 5.76824 6.39736 5.8538 6.49225 5.86313C6.60581 5.87247 6.69914 5.78847 6.69914 5.67491V5.3078C6.7007 5.19424 6.60581 5.10091 6.49225 5.11958Z"
        fill={color}
      />
      <path
        d="M6.49225 6.18208C6.39892 6.19141 6.33203 6.27541 6.33203 6.3703V6.72808C6.33203 6.82141 6.39736 6.90697 6.49225 6.9163C6.60581 6.92563 6.69914 6.84163 6.69914 6.72808V6.3703C6.7007 6.25674 6.60581 6.16341 6.49225 6.18208Z"
        fill={color}
      />
    </svg>
  );
}
