import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconDone({
  width = 16,
  height = 16,
  color = theme.colors.success,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M6.8213 9.74969L5.21714 8.14552C5.03839 7.96677 4.75422 7.96677 4.57547 8.14552C4.39672 8.32427 4.39672 8.60844 4.57547 8.78719L6.49589 10.7076C6.67464 10.8864 6.96339 10.8864 7.14214 10.7076L12.0005 5.85385C12.1792 5.6751 12.1792 5.39094 12.0005 5.21219C11.8217 5.03344 11.5376 5.03344 11.3588 5.21219L6.8213 9.74969Z"
        fill="white"
      />
    </svg>
  );
}
