import { UserJson } from '@/types';
import { createRandomAssessment } from './assessments.mock';
import { createRandomUserProfile } from './user.mock';
import { faker } from '@faker-js/faker';
import { createRandomProject } from './jobs.mock';

function createRandomWorkerProfile(): UserJson.Worker {
  return {
    ...createRandomUserProfile(),
    projects: faker.helpers.multiple(createRandomProject, { count: 2 }),
    assessments: faker.helpers.multiple(createRandomAssessment, { count: 2 }),
    coverLetter: faker.lorem.paragraphs(),
  };
}

export const workersMock: UserJson.Worker[] = faker.helpers.multiple(
  createRandomWorkerProfile,
  { count: 10 }
);
