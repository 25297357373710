import React, {
  useState,
  ReactNode,
  useEffect,
  Children,
  isValidElement,
  ReactElement,
} from 'react';
import styled from 'styled-components';
import UICard from './Card';
import { theme } from '@/constants';

type TabProps = {
  title: string;
  eventKey: string; // Unique key for each tab
  children: ReactNode;
};

type TabsProps = {
  children: ReactNode;
  defaultActiveKey?: string; // Key of the tab to be active initially
};
const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabList = styled.div`
  display: flex;
`;

const TabButton = styled.button<{ $isActive: boolean }>`
  font-family: Gantari;
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 500)};
  color: ${({ $isActive }) =>
    $isActive ? theme.colors.textPrimary : theme.colors.textSecondary};
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: ${({ $isActive }) =>
    $isActive ? `2px solid ${theme.colors.primary}` : 'none'};
  background-color: ${({ $isActive }) => ($isActive ? 'white' : 'transparent')};
  outline: none;
`;

const TabContent = styled(UICard)`
  border-top-left-radius: 0;
`;
export const Tab: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>; // Just render children, logic is in Tabs
};

export const Tabs: React.FC<TabsProps> = ({ children, defaultActiveKey }) => {
  // Normalize children to an array
  const childArray: ReactElement<TabProps>[] = Children.toArray(
    children
  ).filter(isValidElement) as ReactElement<TabProps>[];

  const findDefaultActiveTab = () => {
    const defaultIndex = childArray.findIndex(
      (tab) => tab.props.eventKey === defaultActiveKey
    );
    return defaultIndex >= 0 ? defaultIndex : 0;
  };

  const [activeTab, setActiveTab] = useState(findDefaultActiveTab());

  useEffect(() => {
    setActiveTab(findDefaultActiveTab());
  }, [defaultActiveKey, childArray.length]); // Monitor changes in defaultActiveKey or child count

  return (
    <TabsContainer>
      <TabList>
        {childArray.map((child, index) => (
          <TabButton
            key={child.props.eventKey}
            $isActive={index === activeTab}
            onClick={() => setActiveTab(index)}
          >
            {child.props.title}
          </TabButton>
        ))}
      </TabList>
      <TabContent>{childArray[activeTab]}</TabContent>
    </TabsContainer>
  );
};
