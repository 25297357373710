import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconEarth({
  width = 19,
  height = 19,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 16.625C13.435 16.625 16.625 13.435 16.625 9.5C16.625 5.56497 13.435 2.375 9.5 2.375C5.56497 2.375 2.375 5.56497 2.375 9.5C2.375 13.435 5.56497 16.625 9.5 16.625Z"
        stroke={color}
        strokeWidth="1.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8961 12.6459L11.867 10.1679C11.7964 10.1244 11.7175 10.0963 11.6353 10.0852L9.94173 9.85689C9.81456 9.83974 9.68525 9.86426 9.57319 9.92677C9.46112 9.98928 9.37231 10.0864 9.32008 10.2036L8.30337 12.485C8.25609 12.5911 8.24097 12.7087 8.25987 12.8233C8.27877 12.9379 8.33088 13.0445 8.40973 13.1297L9.80504 14.639C9.86829 14.7074 9.91456 14.7897 9.94009 14.8793C9.96562 14.9689 9.9697 15.0633 9.95201 15.1548L9.66779 16.6241"
        stroke={color}
        strokeWidth="1.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.8201 4.12695L4.15627 5.69666C4.09708 5.83662 4.0938 5.99395 4.14712 6.13625L4.99996 8.41241C5.03488 8.50562 5.09268 8.58855 5.16803 8.65358C5.24339 8.71861 5.33388 8.76366 5.4312 8.78457L7.0217 9.12642C7.10995 9.14539 7.19272 9.18423 7.26371 9.23999C7.3347 9.29575 7.39204 9.36697 7.43137 9.44822L7.71382 10.0317C7.76242 10.1321 7.83834 10.2168 7.93286 10.2761C8.02738 10.3353 8.13668 10.3668 8.24825 10.3668H9.24519"
        stroke={color}
        strokeWidth="1.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.315 2.60938L12.0077 3.85019C12.0732 3.96749 12.097 4.10353 12.0752 4.23609C12.0533 4.36865 11.9872 4.48989 11.8876 4.58L9.89026 6.3863C9.85636 6.41695 9.81904 6.44359 9.77903 6.46569L8.87046 6.96743C8.78258 7.01597 8.68382 7.04142 8.58343 7.04142H6.9992C6.88238 7.04142 6.76814 7.07589 6.67081 7.1405C6.57348 7.20512 6.49737 7.29701 6.45202 7.40468L5.83398 8.87186"
        stroke={color}
        strokeWidth="1.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
