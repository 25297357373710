import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconRanking({
  width = 20,
  height = 20,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className="fill-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.55964 11.666H3.33464C2.41797 11.666 1.66797 12.416 1.66797 13.3327V17.4993C1.66797 17.9577 2.04297 18.3327 2.5013 18.3327H5.55964C6.01797 18.3327 6.39297 17.9577 6.39297 17.4993V12.4993C6.39297 12.041 6.01797 11.666 5.55964 11.666Z"
        fill={color}
      />
      <path
        d="M11.1065 8.33398H8.88151C7.96484 8.33398 7.21484 9.08398 7.21484 10.0007V17.5007C7.21484 17.959 7.58984 18.334 8.04818 18.334H11.9398C12.3982 18.334 12.7732 17.959 12.7732 17.5007V10.0007C12.7732 9.08398 12.0315 8.33398 11.1065 8.33398Z"
        fill={color}
      />
      <path
        d="M16.6677 14.166H14.4427C13.9844 14.166 13.6094 14.541 13.6094 14.9993V17.4993C13.6094 17.9577 13.9844 18.3327 14.4427 18.3327H17.501C17.9594 18.3327 18.3344 17.9577 18.3344 17.4993V15.8327C18.3344 14.916 17.5844 14.166 16.6677 14.166Z"
        fill={color}
      />
      <path
        d="M12.5079 4.04076C12.7662 3.78242 12.8662 3.47409 12.7829 3.20742C12.6995 2.94076 12.4412 2.74909 12.0745 2.69076L11.2745 2.55742C11.2412 2.55742 11.1662 2.49909 11.1495 2.46576L10.7079 1.58242C10.3745 0.907422 9.61621 0.907422 9.28288 1.58242L8.84121 2.46576C8.83288 2.49909 8.75788 2.55742 8.72454 2.55742L7.92454 2.69076C7.55788 2.74909 7.30788 2.94076 7.21621 3.20742C7.13288 3.47409 7.23288 3.78242 7.49121 4.04076L8.10788 4.66576C8.14121 4.69076 8.16621 4.79076 8.15788 4.82409L7.98288 5.59076C7.84954 6.16576 8.06621 6.42409 8.20788 6.52409C8.34954 6.62409 8.65788 6.75742 9.16621 6.45742L9.91621 6.01576C9.94954 5.99076 10.0579 5.99076 10.0912 6.01576L10.8329 6.45742C11.0662 6.59909 11.2579 6.64076 11.4079 6.64076C11.5829 6.64076 11.7079 6.57409 11.7829 6.52409C11.9245 6.42409 12.1412 6.16576 12.0079 5.59076L11.8329 4.82409C11.8245 4.78242 11.8495 4.69076 11.8829 4.66576L12.5079 4.04076Z"
        fill={color}
      />
    </svg>
  );
}
