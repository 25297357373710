import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconProfile({
  width = 11,
  height = 13,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80808 3.55691C8.80808 5.39255 7.33628 6.86444 5.49934 6.86444C3.66303 6.86444 2.1906 5.39255 2.1906 3.55691C2.1906 1.72126 3.66303 0.25 5.49934 0.25C7.33628 0.25 8.80808 1.72126 8.80808 3.55691ZM5.5 12.7496C2.78898 12.7496 0.5 12.3089 0.5 10.6089C0.5 8.90829 2.80336 8.48328 5.5 8.48328C8.21164 8.48328 10.5 8.92391 10.5 10.6239C10.5 12.3246 8.19664 12.7496 5.5 12.7496Z"
        fill={color}
      />
    </svg>
  );
}
