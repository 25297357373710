import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconDashboard({
  width = 18,
  height = 18,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.78464 0.666016H5.6013C6.7763 0.666016 7.71797 1.62435 7.71797 2.80018V5.64102C7.71797 6.82435 6.7763 7.77435 5.6013 7.77435H2.78464C1.61797 7.77435 0.667969 6.82435 0.667969 5.64102V2.80018C0.667969 1.62435 1.61797 0.666016 2.78464 0.666016ZM2.78464 10.2241H5.6013C6.7763 10.2241 7.71797 11.1749 7.71797 12.3583V15.1991C7.71797 16.3741 6.7763 17.3324 5.6013 17.3324H2.78464C1.61797 17.3324 0.667969 16.3741 0.667969 15.1991V12.3583C0.667969 11.1749 1.61797 10.2241 2.78464 10.2241ZM15.2181 0.666016H12.4014C11.2264 0.666016 10.2847 1.62435 10.2847 2.80018V5.64102C10.2847 6.82435 11.2264 7.77435 12.4014 7.77435H15.2181C16.3847 7.77435 17.3347 6.82435 17.3347 5.64102V2.80018C17.3347 1.62435 16.3847 0.666016 15.2181 0.666016ZM12.4014 10.2241H15.2181C16.3847 10.2241 17.3347 11.1749 17.3347 12.3583V15.1991C17.3347 16.3741 16.3847 17.3324 15.2181 17.3324H12.4014C11.2264 17.3324 10.2847 16.3741 10.2847 15.1991V12.3583C10.2847 11.1749 11.2264 10.2241 12.4014 10.2241Z"
        fill={color}
      />
    </svg>
  );
}
