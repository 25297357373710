import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconTwitter({
  width = 14,
  height = 14,
  color = theme.colors.white,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_449_2326)">
        <path
          d="M13.4336 2.96919C12.9502 3.18125 12.435 3.32181 11.898 3.39006C12.4505 3.06019 12.8722 2.54181 13.0704 1.917C12.5553 2.22412 11.9865 2.44106 11.3804 2.56213C10.8913 2.04131 10.1942 1.71875 9.43366 1.71875C7.95816 1.71875 6.77028 2.91638 6.77028 4.38456C6.77028 4.59581 6.78816 4.79894 6.83203 4.99231C4.61634 4.88425 2.65578 3.82231 1.33872 2.20463C1.10878 2.60356 0.973906 3.06019 0.973906 3.55175C0.973906 4.47475 1.44922 5.29294 2.15772 5.76662C1.72953 5.7585 1.30947 5.63419 0.953594 5.43837C0.953594 5.4465 0.953594 5.45706 0.953594 5.46762C0.953594 6.76275 1.87741 7.8385 3.08884 8.08631C2.87191 8.14563 2.63547 8.17406 2.39009 8.17406C2.21947 8.17406 2.04722 8.16431 1.88553 8.12856C2.23084 9.184 3.21072 9.95994 4.37584 9.98512C3.46909 10.6944 2.31778 11.1218 1.07141 11.1218C0.852844 11.1218 0.643219 11.1121 0.433594 11.0852C1.61416 11.8466 3.01328 12.2812 4.52209 12.2812C9.42634 12.2812 12.1076 8.21875 12.1076 4.69737C12.1076 4.57956 12.1035 4.46581 12.0978 4.35287C12.6268 3.9775 13.0712 3.50869 13.4336 2.96919Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_449_2326">
          <rect
            width="13"
            height="13"
            fill={color}
            transform="translate(0.433594 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
