import { AppModal } from '@/components/App';
import { Button, Typography, UIBox } from '@/components/UI';
import { theme } from '@/constants';

interface ResumeConfirmDeleteModalProps {
  resumeTitle?: string;
  show: boolean;
  onClose: () => void;
}
const ResumeConfirmDeleteModal: React.FC<ResumeConfirmDeleteModalProps> = ({
  resumeTitle,
  show,
  onClose,
}) => {
  return (
    <AppModal isOpen={show} headerAlign="center" onClose={onClose}>
      <UIBox display="flex" flexDirection="column" alignItems="center" gap={20}>
        <UIBox display="flex" flexDirection="column" alignItems="center">
          <Typography
            textAlign="center"
            color={theme.colors.textPrimary}
            fontWeight={600}
          >
            Do you want to delete{' '}
            <Typography
              variant="span"
              color={theme.colors.textPrimary}
              style={{ textDecoration: 'underline' }}
            >
              {resumeTitle}
            </Typography>
            <br />
            resume?
          </Typography>
        </UIBox>
        <Button variant="danger" height={40} onClick={onClose} width={170}>
          Yes, Delete
        </Button>
      </UIBox>
    </AppModal>
  );
};

export default ResumeConfirmDeleteModal;
