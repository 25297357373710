import { AppModal } from '@/components/App';
import { IconSend } from '@/assets/icons';
import { UIBox, Button, Typography, UIContainer } from '@/components/UI';
import { theme } from '@/constants';
import { WorkerNavbar } from '@/components/App/Layout';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const StyledMain = styled.main(({ theme }) => ({
  paddingTop: 80,
  paddingBottom: 20,
  backgroundColor: theme.colors.gray960,
  minHeight: '100vh',
}));
export type WorkerLayoutContextType = {
  applyToJob?: (jobId?: number | string) => void;
};
const WorkerLayout: React.FC = () => {
  const [show, setShow] = useState(false);
  const applyToJob = (jobId?: number | string) => {
    setShow(true);
  };
  return (
    <>
      <WorkerNavbar />
      <StyledMain>
        <UIContainer>
          <Outlet context={{ applyToJob }} />
        </UIContainer>
        <AppModal
          header={
            <UIBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={10}
            >
              <Typography variant="h6" marginBottom={0}>
                Application sent!
              </Typography>
              <IconSend />
            </UIBox>
          }
          headerAlign="center"
          isOpen={show}
          onClose={() => setShow(false)}
        >
          <UIBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={20}
          >
            <UIBox display="flex" flexDirection="column" alignItems="center">
              <Typography>
                You’re all set! We’ve sent your application to
              </Typography>
              <Typography color={theme.colors.textPrimary}>
                TypeScript Developer
              </Typography>
            </UIBox>
            <Button variant="secondary">Look my Applied jobs</Button>
          </UIBox>
        </AppModal>
      </StyledMain>
    </>
  );
};

export default WorkerLayout;
