import { PropsWithChildren } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import { theme } from '@/constants';

const AppThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;

const GlobalStyle = createGlobalStyle``;
