import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconFacebook({
  width = 13,
  height = 13,
  color = theme.colors.white,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_449_2314)">
        <path
          d="M8.43209 2.4925H9.52759V0.5845C9.33859 0.5585 8.68859 0.5 7.93159 0.5C6.35209 0.5 5.27009 1.4935 5.27009 3.3195V5H3.52709V7.133H5.27009V12.5H7.40709V7.1335H9.07959L9.34509 5.0005H7.40659V3.531C7.40709 2.9145 7.57309 2.4925 8.43209 2.4925Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_449_2314">
          <rect
            width="12"
            height="12"
            fill={color}
            transform="translate(0.433594 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
