import { UserAssessmentStatus } from '@/enums';
import { UserJson } from '@/types';
import { faker } from '@faker-js/faker';

export function createRandomAssessment(): UserJson.Assessment {
  return {
    id: faker.number.int(),
    userId: faker.number.int(),
    assessmentId: faker.number.int(),
    title: faker.helpers.arrayElement([
      'Mathematics',
      'Science',
      'History',
      'English',
      'Geography',
      'Art',
      'Music',
      'Computer Science',
    ]),
    description: faker.lorem.paragraph(),
    status: UserAssessmentStatus.PASSED,
    score: 100,
    startAt: faker.date.past({ years: 1 }).toLocaleDateString(),
    endAt: faker.date.past({ years: 1 }).toLocaleDateString(),
    createdAt: faker.date.past({ years: 1 }).toLocaleDateString(),
    updatedAt: faker.date.past({ years: 1 }).toLocaleDateString(),
  };
}
export const assessmentsMock: UserJson.Assessment[] = faker.helpers.multiple(
  createRandomAssessment,
  { count: 2 }
);
