import { IconLogo } from '@/assets/icons';
import { appRoutes } from '@/constants';
import { RoleNames } from '@/enums';
import { useAuth } from '@/hooks/useAuth';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

const AppLogo: React.FC = () => {
  const { auth } = useAuth();
  const homeRoute = useMemo(() => {
    if (auth) {
      if (auth.roleName === RoleNames.SUPER_ADMIN) {
        return appRoutes.adminDashboard;
      } else if (auth.roleName === RoleNames.SITE_ADMIN) {
        return appRoutes.adminDashboard;
      } else if (auth.roleName === RoleNames.ORG_ADMIN) {
        return appRoutes.orgDashboard;
      } else if (auth.roleName === RoleNames.SPONSOR) {
        return appRoutes.sponsorMyJobs;
      } else {
        return appRoutes.workerFindWork();
      }
    } else return appRoutes.home;
  }, [auth]);
  return (
    <Link to={homeRoute}>
      <IconLogo />
    </Link>
  );
};
export default AppLogo;
