import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#40549C',
    secondary: '#39B5D8',
    success: '#6CBC6E',
    error: '#E53E3E',
    warning: '#FF9110',
    secondaryBackground: '#E1EFF6',
    dangerBackground: '#ED99991A',
    text: '#0C0C0C',

    white: '#FFFFFF',
    black: '#000000',
    gray: '#D8DADD',
    gray100: '',
    gray200: '#9fa9cc',
    gray300: '#828282',
    gray400: '#FFFFFF40',
    gray500: '#7B7D96',
    gray540: '#A3AED0',
    gray550: '#C4C4C4',
    gray600: '#D6DFE6',
    gray700: '#D1DAE2',
    gray710: '#D2D2D2',
    gray800: '#E6E6F6',
    gray810: '#E7E7E9',
    gray840: '#efeff1',
    gray950: '#F0FAFF',
    gray960: '#F5F5F5',
    gray970: '#F6FCFE',
    gray980: '#F7F7F8',
    gray990: '#FCFCFC',

    red: '#D11A2A',
    red300: '#811212',
    red500: '#E53E3E',
    red600: '#E71D36',
    red800: '#FF3363',

    green: '#82EBF7',
    green100: '#39B5D81A',
    green200: '#D5F2EE',
    green300: '#357045',
    green400: '#2CC84A',
    green500: '#47C844',
    green600: '#D0F8D8',
    green700: '#6CBC6E',
    green800: '#7EE488',
    green900: '',
    greenLight: '#1FDFC8',

    blue: '#4285F4',
    blue300: '#5B93FF',
    blue400: '#7CA8FF',
    blue500: '#ebf8fb',

    yellow500: '#FFEBE1',

    icon: '#AEAEBA',
    companyBrand: '#AEC0CE',
    surface: '#F3F5F9',
    surfaceTwo: '#EAECF0',
    buttonHoverBg: '#E9F1FF',
    textPurple: '#030229',
    textPurpleLight: '#3A3C6B',
    textSecondary: '#4F4F4F',
    secondaryDark: '#777777',
    lightBoxShadow: '0px 4px 10px 0px #7B7D960D',
    heavyBoxShadow: '0px 4px 10px 0px #0000000D',
    boxShadow: '0px 4px 20px 0px #7B7D961A',
    textCaption: '#7C8684',
    textBackground: '#E2E8F5',
    textBrand: '#7E7E7E',
    grayHover: '#404040',
    textTertiary: '#7C8684',
    textPrimary: '#0A1049',
    textDescription: '#7B7D96',
    textTitle1: '#1FA9D1',
    stroke: 'rgba(225, 225, 225, 1)',
    accent: 'rgba(37, 131, 176, 1)',
    ui: 'rgba(194, 204, 202, 1)',
    boxDark: 'rgba(0 , 0, 13, 0.1)',
  },
  breakpoints: {
    sm: '576px',
    md: '7680px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
  breakpointsMinWidth: {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
    xxl: '1320px',
  },
};

export default theme;
