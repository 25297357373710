import { BackButton, UIBox, Typography } from '@/components/UI';
import { appRoutes } from '@/constants';

import {
  WorkerCompanySummaryCard,
  WorkerJobApplyButton,
  WorkerJobDetailCard,
  WorkerJobProperties,
  WorkerRecommendJobs,
} from '@/modules/Job/WorkerJobs';
import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { WorkerLayoutContextType } from '../layout';
import { jobsMock } from '@/mock';
import { ProjectJson } from '@/types';

const WorkerJobDetailPage: React.FC = () => {
  const { applyToJob } = useOutletContext<WorkerLayoutContextType>();
  const { id: jobId } = useParams();
  const [job, setJob] = useState<ProjectJson.Project | undefined>(undefined);
  useEffect(() => {
    if (jobId) {
      setJob(jobsMock[0]);
    } else {
      setJob(undefined);
    }
  }, [jobId]);
  const skillsMatching = 89;
  const handleClickApply = () => {
    applyToJob?.();
  };
  const handleClickBookmark = () => {};
  return (
    <UIBox display="flex" gap={30}>
      <UIBox flexGrow={1} display="flex" flexDirection="column" gap={21}>
        <UIBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <BackButton to={appRoutes.workerFindWork()}>Back to List</BackButton>
          <UIBox width={280}>
            <WorkerJobApplyButton
              onClickApply={handleClickApply}
              onClickBookmark={handleClickBookmark}
            />
          </UIBox>
        </UIBox>
        <WorkerJobDetailCard
          job={job}
          skillsMatching={skillsMatching}
          onClickApply={handleClickApply}
          onClickBookmark={handleClickBookmark}
          hideCompanyInfo
        />
      </UIBox>
      <UIBox
        maxWidth={390}
        minWidth={390}
        display="flex"
        flexDirection="column"
        gap={30}
      >
        <UIBox mt={5} mb={1}>
          <Typography variant="h6">At a glance</Typography>
          <WorkerJobProperties job={job} skillsMatching={skillsMatching} />
        </UIBox>
        <UIBox mb={1}>
          <Typography variant="h6">Company info</Typography>
          <WorkerCompanySummaryCard company={job?.companyData} />
        </UIBox>
        <WorkerRecommendJobs />
      </UIBox>
    </UIBox>
  );
};

export default WorkerJobDetailPage;
