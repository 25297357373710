import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconInfoCircle({
  width = 14,
  height = 14,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9612 13.168C3.53844 13.168 0.76419 10.4066 0.76419 7.0013C0.76419 3.59597 3.53844 0.834636 6.9612 0.834636C10.3833 0.834636 13.1582 3.59597 13.1582 7.0013C13.1582 10.4066 10.3833 13.168 6.9612 13.168Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96483 9.5332V6.5872"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96427 4.4694H6.95758"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
