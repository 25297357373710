import {
  Ability,
  EmploymentType,
  ProjectBudgetType,
  ProjectStatus,
  ProjectType,
} from '@/enums';
import { workerProfileMock } from './user.mock';
import { skillsMock } from './skills.mock';
import { faker } from '@faker-js/faker';
import { createRandomCompany } from './organizations.mock';
import { ProjectJson } from '@/types';

export function createRandomProject(): ProjectJson.Project {
  return {
    id: faker.number.int({ max: 10000 }),
    poster: workerProfileMock,
    title: faker.helpers.arrayElement([
      'Java Script Developer',
      'Full stack developer',
      '.Net Core developer',
    ]),
    description: faker.lorem.paragraphs(),
    type: faker.helpers.arrayElement([
      ProjectType.REMOTE,
      ProjectType.HYBRID,
      ProjectType.ONSITE,
    ]),
    minSalary: faker.number.int({ min: 10000, max: 40000 }),
    maxSalary: faker.number.int({ min: 50000, max: 150000 }),
    minRate: faker.number.int({ min: 5, max: 40 }),
    maxRate: faker.number.int({ min: 40, max: 200 }),
    location: {
      country: faker.location.countryCode('alpha-2'),
      city: faker.location.city(),
      state: faker.location.state(),
      address1: faker.location.streetAddress(),
      zipcode: faker.location.zipCode(),
    },
    companyData: createRandomCompany(),
    projectSkills: skillsMock,
    budget: faker.helpers.arrayElement([
      ProjectBudgetType.HOURLY,
      ProjectBudgetType.FIXED,
    ]),
    employmentType: faker.helpers.arrayElement([
      EmploymentType.CONTRACT,
      EmploymentType.FULL_TIME,
      EmploymentType.PART_TIME,
    ]),
    minProficiency: faker.helpers.arrayElement([
      Ability.EXPERT,
      Ability.BEGINNER,
      Ability.ADVANCED,
      Ability.INTERMEDIATE,
    ]),
    status: faker.helpers.arrayElement([
      ProjectStatus.COMPLETED,
      ProjectStatus.CLOSED,
      ProjectStatus.DRAFT,
      ProjectStatus.POSTED,
      ProjectStatus.REJECTED,
    ]),
    createdAt: faker.date.past({ years: 1 }).toLocaleDateString(),
    postedAt: faker.date.past({ years: 1 }).toLocaleDateString(),
  };
}
export const jobsMock: ProjectJson.Project[] = faker.helpers.multiple(
  createRandomProject,
  { count: 10 }
);
