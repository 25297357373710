import { UIBox, Button, UICard, Tag, Typography } from '@/components/UI';
import { UserJson } from '@/types';
import SponsorWorkerStatus from './SponsorWorkerStatus';

interface SponsorHiredWorkerCardProps {
  worker: UserJson.Worker;
  onClickViewContract: () => void;
  onClickMessage: () => void;
}

const SponsorHiredWorkerCard: React.FC<SponsorHiredWorkerCardProps> = ({
  worker,
  onClickViewContract,
  onClickMessage,
}) => {
  return (
    <UICard borderRadius={10} display="flex" flexDirection="column">
      <UIBox display="flex" justifyContent="space-between">
        <SponsorWorkerStatus worker={worker} />
        <UIBox display="flex" gap={10}>
          <Button
            variant="outlined-primary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onClickMessage();
            }}
          >
            Message
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onClickViewContract();
            }}
          >
            View Contract
          </Button>
        </UIBox>
      </UIBox>
      <UIBox my={1}>
        <Typography
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
          }}
        >
          {worker.coverLetter}
        </Typography>
      </UIBox>
      <UIBox display="flex" gap={10} flexWrap="wrap">
        {worker.userSkills?.map((skill, id) => (
          <Tag key={id}>{skill.skill?.name}</Tag>
        ))}
      </UIBox>
    </UICard>
  );
};

export default SponsorHiredWorkerCard;
