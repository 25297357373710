import { theme } from '@/constants';
import styled from 'styled-components';
import { UIBox } from './Box';
import Pagination from './Pagination';
import Spinner from './Spinner';
import { AppEmptyItems } from '../App';
import { IconSort } from '@/assets/icons';
import { AppTypes } from '@/types';
import { SortDirection } from '@/enums';

export const UITh = styled.th<{ $borderBottom?: boolean }>`
  font-size: 12px;
  line-height: 14px;
  color: ${theme.colors.textDescription};
  border-bottom: ${(props) =>
    props.$borderBottom ? `solid 1px #E9EDF7` : 'none'};
`;
export const UITr = styled.tr`
  box-shadow: 1px 17px 44px 0px #03022912;
  > td {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.colors.textDescription};
    background: ${theme.colors.white};
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-right: 20px;
    }
  }
`;
export const UIThead = styled.thead`
  height: 50px;
  th {
    padding-left: 20px;
    &:last-child {
      padding-right: 20px;
    }
  }
`;

export const UITable = styled.table`
  border-spacing: 0;
  -webkit-border-vertical-spacing: 10px;
`;

export interface DataTableColumn<T extends Record<string, any>> {
  field: string;
  label?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'char';
  sortable?: boolean;
  width?: string | number;
  headerRenderer?: () => React.ReactNode;
  renderer?: (d: T, id: number) => React.ReactNode;
}
interface DataTableProps<T extends Record<string, any>> {
  columns: DataTableColumn<T>[];
  data: T[];
  loading?: boolean;
  hidePagination?: boolean;
  onClickRow?: (d: T) => void;
  pagination?: AppTypes.PaginationParam;
  total?: number;
  onUpdatePagination?: (p: Partial<AppTypes.PaginationParam>) => void;
}
export function DataTable<T extends Record<string, any>>({
  columns,
  data,
  loading,
  pagination,
  onUpdatePagination,
  hidePagination,
  total = 0,
  onClickRow,
}: DataTableProps<T>) {
  return (
    <UIBox>
      <UITable width="100%">
        <UIThead>
          <tr>
            {columns.map((col, colId) => (
              <UITh key={colId} align={col.align || 'left'}>
                <UIBox
                  display="flex"
                  alignItems="center"
                  justifyContent={
                    col.align === 'right'
                      ? 'flex-end'
                      : col.align === 'justify'
                        ? 'space-between'
                        : 'flex-start'
                  }
                  gap={10}
                  cursor={col.sortable ? 'pointer' : 'default'}
                  onClick={() => {
                    if (col.sortable) {
                      onUpdatePagination?.({
                        sortField: col.field,
                        sortBy:
                          pagination?.sortBy === SortDirection.DESC
                            ? SortDirection.ASC
                            : SortDirection.DESC,
                      });
                    }
                  }}
                >
                  {col.headerRenderer?.() || col.label}
                  {col.sortable && (
                    <IconSort
                      sort={
                        pagination?.sortField === col.field
                          ? pagination?.sortBy
                          : undefined
                      }
                    />
                  )}
                </UIBox>
              </UITh>
            ))}
          </tr>
        </UIThead>
        <tbody>
          {!loading &&
            data.map((row, rowId) => (
              <UITr
                key={rowId}
                onClick={() => onClickRow?.(row)}
                style={{ cursor: 'pointer' }}
              >
                {columns.map((col, colId) => (
                  <td
                    key={colId}
                    width={col.width}
                    align={col.align || 'left'}
                    style={{
                      color: theme.colors.textPrimary,
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                  >
                    {col.renderer
                      ? col.renderer(row, rowId + (pagination?.offset || 0))
                      : col.field && row[col.field] !== undefined
                        ? row[col.field]
                        : ''}
                  </td>
                ))}
              </UITr>
            ))}
        </tbody>
      </UITable>
      {loading && (
        <UIBox p={3} display="flex" alignItems="center" justifyContent="center">
          <Spinner size="xl" />
        </UIBox>
      )}
      {!loading && data.length === 0 && <AppEmptyItems />}
      {!hidePagination &&
        !loading &&
        data.length >= 1 &&
        total > (pagination?.limit || 10) && (
          <UIBox mt={1}>
            <Pagination
              total={total}
              offset={pagination?.offset}
              limit={pagination?.limit}
              onChangeOffset={(offset) => onUpdatePagination?.({ offset })}
            />
          </UIBox>
        )}
    </UIBox>
  );
}
