import { IconCaretRight, IconClose } from '@/assets/icons';
import { UIBox, UICard, IconButton, Typography } from '@/components/UI';
import { theme } from '@/constants';

interface SummaryWidgetProps {
  icon: React.ReactNode;
  value: number;
  label: string;
  link?: string;
  status?: 'normal' | 'selected' | 'deselected';
  onClick?: () => void;
}
const SummaryWidget: React.FC<SummaryWidgetProps> = ({
  icon,
  value,
  label,
  status = 'normal',
  onClick,
}) => {
  return (
    <UICard
      borderRadius={10}
      display="flex"
      alignItems="center"
      gap={20}
      style={{
        boxShadow:
          status === 'selected' ? '0px 4px 20px 0px #7B7D961A' : 'none',
        border:
          status === 'selected' ? `solid 1px ${theme.colors.gray810}` : 'none',
        opacity: status === 'deselected' ? 0.5 : 1,
      }}
    >
      <UIBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        background={theme.colors.green100}
        width={50}
        height={50}
        borderRadius={50}
      >
        {icon}
      </UIBox>
      <UIBox flexGrow={1}>
        <Typography variant="h5" marginBottom={0}>
          {value}
        </Typography>
        <Typography
          color={theme.colors.textPrimary}
          fontSize={14}
          fontWeight={600}
        >
          {label}
        </Typography>
      </UIBox>
      <IconButton onClick={onClick}>
        {status === 'selected' ? (
          <IconClose color={theme.colors.primary} width={12} height={12} />
        ) : (
          <IconCaretRight color={theme.colors.primary} />
        )}
      </IconButton>
    </UICard>
  );
};

export default SummaryWidget;
