import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconVector({
  width = 23,
  height = 17,
  color = theme.colors.success,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.14815 16.836C1.273 16.9123 1.40946 16.9531 1.54597 16.9613C1.85525 16.98 2.16442 16.8313 2.33707 16.5485L6.88237 9.1023C7.12692 8.70161 7.61378 8.50901 8.06616 8.63408L12.8439 9.95358C13.9186 10.2503 15.0631 9.88009 15.7602 9.01049L20.3058 3.34082L20.8461 8.08271C20.8944 8.50636 21.241 8.82347 21.6524 8.84824C21.7019 8.85123 21.7525 8.85 21.8034 8.84417C22.278 8.7901 22.619 8.36148 22.5649 7.88688L21.7753 0.957473C21.7494 0.729555 21.6339 0.521271 21.4543 0.378485C21.2748 0.235656 21.0459 0.169997 20.818 0.196012L14.1537 0.955433C13.6791 1.0095 13.3381 1.43812 13.3922 1.91272C13.4463 2.38736 13.875 2.72816 14.3495 2.67418L19.0527 2.13827L14.4106 7.92838C14.1461 8.25826 13.712 8.39873 13.3044 8.28613L8.52665 6.96663C7.33418 6.63723 6.05059 7.14478 5.40587 8.20096L0.860566 15.6472C0.611686 16.0549 0.740405 16.5872 1.14815 16.836Z"
        fill={color}
      />
    </svg>
  );
}
