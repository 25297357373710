import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconPaginationPrev({
  width = 16,
  height = 16,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.06 12L11 11.06L7.94667 8L11 4.94L10.06 4L6.06 8L10.06 12Z"
        fill={color}
      />
    </svg>
  );
}
