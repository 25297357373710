import { createApi } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { AppTypes, CertificationJson } from '@/types';
import { authorizeQueryWithRefreshToken } from '@/utils';

export const certificationApi = createApi({
  reducerPath: 'certificationApi',
  baseQuery: authorizeQueryWithRefreshToken,
  tagTypes: ['Certification'],
  endpoints: (builder) => ({
    certifications: builder.query<
      CertificationJson.PagedCertification,
      AppTypes.PaginationParam
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const queryString = new URLSearchParams(arg as any).toString();
          const result = await baseQuery({
            url: `/certifications?${queryString}`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as CertificationJson.PagedCertification,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const { useCertificationsQuery } = certificationApi;
