import {
  UIBox,
  Button,
  UICard,
  Image,
  Input,
  Typography,
  Spinner,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { useResetPasswordMutation } from '@/redux/apis';
import { inputStatus } from '@/utils';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
});
export default function AuthChangePasswordPage() {
  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId') || '';
  const navigate = useNavigate();
  const formik = useFormik<{ password: string; confirmPassword: string }>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (v) => {
      resetPassword({
        userId,
        password: v.password,
      });
    },
  });
  useEffect(() => {
    if (isSuccess) {
      navigate(appRoutes.authPasswordChanged);
    }
  }, [isSuccess]);
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={520}
        px={5}
        py={2}
        borderRadius={32}
        boxShadow="0px 4px 35px 0px #00000014"
      >
        <Typography
          variant="h3"
          textAlign="center"
          fontSize={26}
          lineHeight="35px"
          marginBottom={10}
        >
          Change Password
        </Typography>

        <Typography textAlign="center" fontWeight={400} marginBottom={0}>
          Input your new desired password in the input fields below to create a
          new password
        </Typography>
        <Typography
          textAlign="center"
          fontWeight={400}
          marginBottom={30}
          color={theme.colors.primary}
        >
          We strongly advise you to store it safely.
        </Typography>
        <Image src="/images/change-password.png" />
        {!userId ? (
          <Typography
            textAlign="center"
            color={theme.colors.error}
            fontWeight={400}
            marginTop={10}
          >
            Invalid Url. Please contact to support team
          </Typography>
        ) : (
          <>
            <UIBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={20}
              width={300}
              mt={1}
            >
              <Input
                type="password"
                placeholder="Password"
                label="Password"
                fullWidth
                {...formik.getFieldProps('password')}
                {...inputStatus(formik, 'password')}
              />
              <Input
                type="password"
                placeholder="Confirm new password"
                label="Confirm new password"
                fullWidth
                {...formik.getFieldProps('confirmPassword')}
                {...inputStatus(formik, 'confirmPassword')}
              />
            </UIBox>
            <UIBox
              mt={2}
              width={300}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Button
                fullWidth
                rounded
                onClick={() => formik.handleSubmit()}
                disabled={isLoading}
              >
                {isLoading && <Spinner size="sm" />}
                Change password
              </Button>
            </UIBox>
          </>
        )}
      </UICard>
    </UIBox>
  );
}
