import { Libraries } from '@react-google-maps/api';

const appConfig = {
  S3_URL: process.env.REACT_APP_S3_URL,
  PARSE_API_URL: process.env.REACT_APP_PARSE_API,
  API_URL: process.env.REACT_APP_API_URL,
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
  libraries: ['places'] as Libraries,
  cookieName: 'tokenData',
};

export default appConfig;
