import { IconLogo } from '@/assets/icons';
import {
  UIBox,
  UIContainer,
  UIFlexColumnBox,
  UIFlexSpaceBox,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavbarContainer = styled.header(({ theme }) => ({
  flexGrow: 1,
  position: 'fixed',
  zIndex: 99,
  left: 0,
  right: 0,
  paddingTop: '30px',
  paddingBottom: '10px',
}));

type StyledMainProps = {
  needBefore?: boolean;
  needAfter?: boolean;
};
const StyledMain = styled(
  ({
    needAfter,
    needBefore,
    ...rest
  }: StyledMainProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLElement>,
      HTMLElement
    >) => <main {...rest} />
)<StyledMainProps>`
  height: 100vh;
  padding-top: 100px;
  padding-bottom: 80px;
  position: relative;
  background: ${({ needAfter, needBefore }) =>
    !needAfter && !needBefore && theme.colors.gray970};
  ${(props) =>
    props.needBefore &&
    `
    &::before {
      z-index: -1;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url(/images/img_ideal_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      filter: opacity(0.5);
    }
  `}
  ${(props) =>
    props.needAfter &&
    `
    &::after {
      z-index: -1;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 45%;
      background: ${theme.colors.gray970};
    }
  `}
`;
interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const [, , authType, subType] = pathname.split('/');
  let needBefore = false,
    needAfter = false;
  if (authType === 'login' || authType === 'register') {
    needBefore = true;
    needAfter = true;
  }
  if (subType === 'worker' || subType === 'organization') {
    needAfter = false;
    needBefore = true;
  }

  return (
    <>
      <StyledNavbarContainer>
        <UIContainer>
          <UIFlexSpaceBox>
            <UIFlexColumnBox>
              <Link to={appRoutes.home}>
                <IconLogo />
              </Link>
            </UIFlexColumnBox>
          </UIFlexSpaceBox>
        </UIContainer>
      </StyledNavbarContainer>
      <StyledMain needAfter={needAfter} needBefore={needBefore}>
        <UIContainer height="100%">
          <UIBox height="100%" display="flex" alignItems="center">
            {children}
          </UIBox>
        </UIContainer>
      </StyledMain>
    </>
  );
};

export default AuthLayout;
