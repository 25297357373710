import { UIBox, UIContainer } from '@/components/UI';
import { PublicProfile } from '@/modules/Profile';

const SharedProfile: React.FC = () => {
  return (
    <UIBox>
      <UIContainer>
        <PublicProfile />
      </UIContainer>
    </UIBox>
  );
};

export default SharedProfile;
