import {
  LinkProps as ReactLinkProps,
  Link as ReactLink,
} from 'react-router-dom';
import { AdditionalTextProps } from './Typography';
import { useTheme } from 'styled-components';

interface LinkProps extends AdditionalTextProps, ReactLinkProps {}
const Link: React.FC<LinkProps> = ({
  color,
  fontSize,
  textAlign,
  fontWeight,
  lineHeight,
  marginBottom,
  marginTop,
  style,
  ...props
}) => {
  const theme = useTheme();
  const styles = {
    color: color || theme.colors.secondary,
    textDecorationColor: color || theme.colors.secondary,
    ...(style || {}),
    ...(fontSize && { fontSize }),
    ...(textAlign && { textAlign }),
    ...(fontWeight && { fontWeight }),
    ...(lineHeight && { lineHeight }),
    ...(marginBottom && { marginBottom }),
    ...(marginTop && { marginTop }),
  };
  return <ReactLink style={styles} {...props} />;
};

export default Link;
