import { UIBox, Input, Button, Spinner } from '@/components/UI';
import { useToast } from '@/hooks';
import { useChangePasswordMutation } from '@/redux/apis';
import { UserJson } from '@/types';
import { inputStatus } from '@/utils';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

type FormikData = UserJson.ChangePasswordInput;
const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Current Password is required'),
  newPassword: Yup.string()
    .required('New Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
});

const initialValues: FormikData = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};
const AccountChangePasswordForm: React.FC = () => {
  const { showToast } = useToast();
  const [changePassword, { isLoading, isSuccess, error }] =
    useChangePasswordMutation();
  const formik = useFormik<FormikData>({
    initialValues: { ...initialValues },
    validationSchema,
    onSubmit: async (values) => {
      changePassword(values);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      showToast('Password has been changed, successfully', {
        variant: 'success',
      });
      formik.resetForm();
    }
  }, [isSuccess]);
  useEffect(() => {
    if (error) {
      showToast('Old password does not match', {
        variant: 'error',
      });
    }
  }, [error]);
  return (
    <form noValidate autoComplete="off">
      <UIBox display="flex" flexDirection="column" gap={20} maxWidth={400}>
        <Input
          label="Current Password"
          type="password"
          fullWidth
          variant="secondary"
          labelSize={14}
          {...formik.getFieldProps('oldPassword')}
          {...inputStatus(formik, 'oldPassword')}
        />
        <Input
          label="New Password"
          type="password"
          fullWidth
          variant="secondary"
          labelSize={14}
          {...formik.getFieldProps('newPassword')}
          {...inputStatus(formik, 'newPassword')}
        />
        <Input
          label="Confirm Password"
          type="password"
          fullWidth
          variant="secondary"
          labelSize={14}
          {...formik.getFieldProps('confirmPassword')}
          {...inputStatus(formik, 'confirmPassword')}
        />
        <Button
          type="button"
          size="md"
          onClick={() => formik.handleSubmit()}
          disabled={isLoading}
        >
          {isLoading && <Spinner />}
          Change Password
        </Button>
      </UIBox>
    </form>
  );
};

export default AccountChangePasswordForm;
