import { UIBox, Typography } from '@/components/UI';
import { theme } from '@/constants';

type Status = 'default' | 'danger' | 'success';

interface StatusProps {
  status?: Status;
  label?: string;
}
const StatusTag: React.FC<StatusProps> = ({
  status = 'default',
  label = 'Default',
}) => {
  const colors: Record<Status, string> = {
    default: theme.colors.primary,
    success: theme.colors.green300,
    danger: theme.colors.red500,
  };
  const bgColors: Record<Status, string> = {
    default: '#ecf5fc',
    success: '#efffdb',
    danger: '#fef5f5',
  };
  return (
    <UIBox
      height={28}
      px={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      background={bgColors[status]}
      borderRadius={33}
    >
      <Typography
        variant="span"
        color={colors[status]}
        textTransform="capitalize"
      >
        {label}
      </Typography>
    </UIBox>
  );
};

export default StatusTag;
