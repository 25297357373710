import { FileDropzone } from '@/modules/Shared';
import { UIBox, Button, UICard, Typography, Spinner } from '@/components/UI';
import { appRoutes } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  useGetParsedAffindaResumeMutation,
  useGetParsedLinkedInResumeMutation,
} from '@/redux/apis';
import { UserAssetType, ResumeType } from '@/enums';
import { useAuth } from '@/hooks/useAuth';
import { useUploadFile } from '@/hooks/useUploadFile';

export default function UploadResume() {
  const [errMsg, setErrMsg] = useState('');
  const {
    acceptedFiles,
    setAcceptedFiles,
    uploading,
    progress,
    loading: isUploading,
    error,
    uploadFile,
  } = useUploadFile({
    onUploadedFile: async (uploadedUrl) => {
      const fileName = acceptedFiles[0].name.replaceAll(' ', '+');
      const resumeType =
        fileName === 'Profile.pdf' ? ResumeType.LINKEDIN : ResumeType.AFFINDA;
      setErrMsg('');
      if (resumeType === ResumeType.LINKEDIN) {
        await getParsedLinkedInResume({
          file_link: uploadedUrl,
          user_id: user?.id || '',
          access_token: auth?.accessToken || '',
          resume_type: 'linkedin',
        });
      } else {
        await getParsedAffindaResume({
          file_link: uploadedUrl,
          user_id: user?.id || '',
          access_token: auth?.accessToken || '',
          resume_type: 'affinda',
        });
      }
    },
  });
  const { user, auth } = useAuth();
  const [
    getParsedLinkedInResume,
    {
      data: parsedLinkedInResume,
      isSuccess: isSuccessParsedLinkedInResume,
      isLoading: isLoadingParsedLinkedInResume,
      error: linkedinParsingError,
    },
  ] = useGetParsedLinkedInResumeMutation();

  const [
    getParsedAffindaResume,
    {
      data: parsedAffindaResume,
      isSuccess: isSuccessParsedAffindaResume,
      isLoading: isLoadingParsedAffindaResume,
      error: affindaParsingError,
    },
  ] = useGetParsedAffindaResumeMutation();
  const navigate = useNavigate();
  async function handleUploadResume() {
    setErrMsg('');
    await uploadFile({
      contentType: UserAssetType.RESUME,
      userId: user?.id || '',
    });
  }

  useEffect(() => {
    if (
      (parsedAffindaResume && isSuccessParsedAffindaResume) ||
      (parsedLinkedInResume && isSuccessParsedLinkedInResume)
    ) {
      navigate(appRoutes.authAccountCreated);
    }
  }, [
    parsedLinkedInResume,
    isSuccessParsedLinkedInResume,
    parsedAffindaResume,
    isSuccessParsedAffindaResume,
  ]);
  const loading =
    isUploading ||
    isLoadingParsedAffindaResume ||
    isLoadingParsedLinkedInResume;
  useEffect(() => {
    setErrMsg(
      error ||
        (affindaParsingError || linkedinParsingError
          ? 'Failed to parse resume. Try to upload another template'
          : '')
    );
  }, [error, affindaParsingError, linkedinParsingError]);

  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={580}
        px={4}
        py={2}
        borderRadius={32}
        boxShadow="0px 4px 35px 0px #00000014"
      >
        <Typography variant="h3" textAlign="center" marginBottom={30}>
          Ready to upload your resume?
        </Typography>
        <UIBox mb={2} width="100%">
          <FileDropzone
            onChangeFiles={(files) => {
              setErrMsg('');
              setAcceptedFiles(files);
            }}
            label="Click or drag to upload your resume"
            uploading={uploading}
            progress={progress}
            error={errMsg}
          />
        </UIBox>
        <UIBox
          width={300}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Button
            fullWidth
            rounded
            onClick={handleUploadResume}
            disabled={loading}
          >
            {loading && <Spinner size="sm" />}
            {isUploading
              ? 'Uploading file...'
              : isLoadingParsedAffindaResume || isLoadingParsedLinkedInResume
                ? 'Parsing resume...'
                : 'Add Resume'}
          </Button>
          <Typography
            role="button"
            marginBottom={0}
            marginTop={20}
            onClick={() => navigate(appRoutes.authAccountCreated)}
          >
            Skip
          </Typography>
        </UIBox>
      </UICard>
    </UIBox>
  );
}
