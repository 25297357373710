import { IconCaretLeft, IconCaretRight, IconLogo } from '@/assets/icons';
import { UIBox, UIDivider, IconButton, Typography } from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PropsWithChildren } from 'react';

const StyledAside = styled.aside<{ $expanded: boolean }>`
  transition: all 0.3s ease;
  width: ${({ $expanded }) => `${$expanded ? 210 : 80}px`};
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 10px 0px #0000000d;
  .nav-link-label {
    display: ${({ $expanded }) => ($expanded ? 'block' : 'none')};
  }
`;

interface AsideBarProps extends PropsWithChildren {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}
const AsideBar: React.FC<AsideBarProps> = ({
  children,
  expanded,
  setExpanded,
}) => {
  return (
    <StyledAside $expanded={expanded}>
      <UIBox
        display="flex"
        justifyContent="center"
        pt={1.5}
        position="relative"
        height={90}
      >
        <UIBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={5}
        >
          <Link to={appRoutes.home}>
            <IconLogo width={expanded ? 90 : 45} height={expanded ? 55 : 32} />
          </Link>
          {expanded && (
            <Typography
              fontSize={8}
              lineHeight="2.5px"
              fontWeight={900}
              color={theme.colors.primary}
              style={{ letterSpacing: 0.02 }}
            >
              NON-LINEAR CAREERS
            </Typography>
          )}
        </UIBox>
        <UIBox
          position="absolute"
          bottom={0}
          right={expanded ? 0 : -28}
          width={28}
          height={26}
          boxShadow="1px 1px 20px 0px #7B7D9626"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderTopLeftRadius={expanded ? 14 : 0}
          borderBottomLeftRadius={expanded ? 14 : 0}
          borderTopRightRadius={expanded ? 0 : 14}
          borderBottomRightRadius={expanded ? 0 : 14}
          backgroundColor="white"
        >
          <IconButton onClick={() => setExpanded(!expanded)}>
            {expanded ? <IconCaretLeft /> : <IconCaretRight />}
          </IconButton>
        </UIBox>
      </UIBox>
      <UIDivider
        background="linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 49.52%, rgba(224, 225, 226, 0.15625) 99.04%)"
        mt={1.5}
      />
      <UIBox display="flex" flexDirection="column" gap={10}>
        {children}
      </UIBox>
    </StyledAside>
  );
};

export default AsideBar;
