import { AppTypes } from '@/types';

export const individuals: AppTypes.Team[] = [
  {
    id: 1,
    name: 'Jim Cracraft',
    summary: 'Platform Architect',
    desc: 'Jim is our lead platform architect having worked in a variety of engineering and investment roles over his 35 year career.  He’s had a very non-linear career moving from aerospace to entertainment to finance.',
    degree: [
      'M.S. Aerospace Engineering University of Southern California',
      'B.S. Aerospace Engineering University of Colorado',
    ],
    more: 'Jim recently retired from Fidelity Investments after heading a Portfolio Engineering investment team.  Prior to Fidelity, he served as a Director of Quantitative Research at Citigroup Asset Management.  Jim began his career as an Aerospace Engineer working on rocket and spacecraft simulations as well as hyperspectral sensors and 3D modeling.  He spent time at DreamWorks Animation and has credits in several feature films.',
    src: '/images/img_team3.png',
  },
  {
    id: 2,
    name: 'Braiden Miller',
    summary: 'Principle Software Engineer',
    desc: 'Braiden leads our technology development and is responsible for the overall platform design and development.',
    degree: ['B.S. Western Governors University'],
    more: `Specialized full-stack engineer who has spent over 10 years refining skillsets and
    cultivating extensive arise of experience. The unwavering passion for creating top-
    performing applications motivates me to deliver high-quality results for every project I
    undertake. With proficiency in software engineering, web development, and database
    design, I draw on my years of professional experience to provide comprehensive and
    tailored solutions that address the specific requirements of each client.`,
    src: '/images/img_team4.png',
  },
];

export const employers: AppTypes.Team[] = [
  {
    id: 5,
    name: 'Michael Even, CFA',
    summary: 'CEO',
    desc: 'Michael is our chief executive and is the innovator behind Non-Linear Careers.  He is a strategic thinker who has advised and lead dozens of organizations, large and small.  Mike spent years incubating the idea of helping people transition to new careers based on the skills they developed throughout their life.',
    degree: [
      'M.S. Finance Massachusetts Institute of Technology',
      'B.A. Economics & Operations Research at Cornell University',
    ],
    more: 'Michael is a Board Member of Geode Capital Management, LLC. and serves in advisory and director roles with the Whitehead Institute, KingsCrowd, Lumint Corporation, FaithInvest, Osmosis Investment Management and Massachusetts Pension Reserves Management Board. Previously, he served as the Chairman and CEO of Man Numeric. Prior to Man Numeric, Mr. Even spent 22 years at Citigroup and Independence Investment Associates in Boston.',
    src: '/images/img_team1.png',
  },
  {
    id: 6,
    name: 'Paul Goldwhite',
    summary: 'Lead Researcher',
    desc: 'Paul is our lead for skills-based topologies and skill extraction algorithms.',
    degree: ['B.S. Mechanical Engineering from Yale University'],
    more: `Paul has worked in the investment industry for more than thirty years at Morgan Stanley, Citigroup and First Quadrant. Paul has held leadership roles in portfolio management with global experience.  He has also served as an advisor to FaithInvest, an investment advisor to faith-based institutional investors.`,
    src: '/images/img_team2.png',
  },
];
