import { AppUserAvatar, AppUserStatusTag } from '@/components/App';
import {
  IconDelete,
  IconEdit1,
  IconEllipsis,
  IconSearch,
  IconSwap,
} from '@/assets/icons';
import {
  UIBox,
  Button,
  Dropdown,
  DropdownItem,
  IconButton,
  Input,
  Pagination,
  UITable,
  UITh,
  UIThead,
  UITr,
  Typography,
} from '@/components/UI';
import { DropdownButton } from '@/components/UI/Dropdown';
import { theme } from '@/constants';
import { SidebarInviteUser } from '@/modules/Job';
import { useState } from 'react';
import { workersMock } from '@/mock';

const AdminInviteesPage: React.FC = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <UIBox display="flex" justifyContent="space-between">
        <Typography variant="h5">Invitations</Typography>
        <UIBox display="flex" gap={20}>
          <Input
            placeholder={
              <>
                <IconSearch color={theme.colors.icon} />
                Search
              </>
            }
            height={40}
          />
          <Dropdown
            button={
              <UIBox display="flex" alignItems="center" width={200} gap={10}>
                <IconSwap /> Status
              </UIBox>
            }
            height={40}
          >
            <DropdownItem>Status</DropdownItem>
          </Dropdown>
          <Button
            size="sm"
            height={40}
            width={110}
            onClick={() => setShow(true)}
          >
            Invite
          </Button>
        </UIBox>
      </UIBox>
      <UIBox>
        <UITable width="100%">
          <UIThead>
            <tr>
              <UITh align="left">ID</UITh>
              <UITh></UITh>
              <UITh align="left">Name</UITh>
              <UITh align="left">Email</UITh>
              <UITh align="left">Role</UITh>
              <UITh align="center">Status</UITh>
              <UITh align="right">Joined at</UITh>
              <UITh></UITh>
            </tr>
          </UIThead>
          <tbody>
            {workersMock.map((item, id) => (
              <UITr key={id}>
                <td width={30}>
                  <Typography
                    color={theme.colors.textPrimary}
                    fontWeight={500}
                    fontSize={14}
                  >
                    {id + 1}
                  </Typography>
                </td>
                <td width={30}>
                  <AppUserAvatar user={item} size={30} />
                </td>
                <td>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {item.firstName} {item.lastName}
                  </Typography>
                </td>
                <td>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {item.email}
                  </Typography>
                </td>
                <td>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    Mentors
                  </Typography>
                </td>
                <td align="center" width={82}>
                  <AppUserStatusTag status={item.status} />
                </td>
                <td align="right">
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {item.createdAt &&
                      new Date(item.createdAt).toLocaleDateString()}
                  </Typography>
                </td>
                <td align="right">
                  <Dropdown
                    button={
                      <IconButton>
                        <IconEllipsis />
                      </IconButton>
                    }
                    disableDefaultToggleStyle
                  >
                    <DropdownButton variant="primary">
                      <IconEdit1 />
                      Edit
                    </DropdownButton>
                    <DropdownButton variant="danger">
                      <IconDelete width={8} height={9} />
                      Delete
                    </DropdownButton>
                  </Dropdown>
                </td>
              </UITr>
            ))}
          </tbody>
        </UITable>
        <UIBox display="flex" justifyContent="flex-end" mt={1}>
          <Pagination total={10} offset={1} />
        </UIBox>
        <SidebarInviteUser show={show} setShow={setShow} />
      </UIBox>
    </>
  );
};

export default AdminInviteesPage;
