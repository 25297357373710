export enum WorkerJobRouteCategory {
  ALL = 'all',
  NEW = 'new',
  MOST_RECENT = 'most-recent',
  SAVED = 'saved',
}

export enum WorkerMyJobRouteCategory {
  APPLIED = 'applied',
  INVITED = 'invited',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  ARCHIVE = 'archive',
  INTERVIEWS = 'interviews',
  APPLICATIONS = 'applications',
  SAVED_JOBS = 'saved-jobs',
  RECENTLY_VIEWED = 'recently-viewed',
}

export enum SponsorJobRouteCategory {
  VIEW_JOB_POST = 'view-job-post',
  INVITE_WORKERS = 'invite-workers',
  REVIEW_PROPOSALS = 'review-proposals',
  CONTACTED_WORKERS = 'contacted-workers',
}
