import { UIBox, SectionSpinner } from '@/components/UI';
import { appRoutes } from '@/constants';
import { RoleNames } from '@/enums';
import { useAuth } from '@/hooks/useAuth';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

function AuthCallbackPage() {
  const [searchParams] = useSearchParams();
  const { setAuth, loadingUserInfo, user, org } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  useEffect(() => {
    const accessToken = searchParams.get('accessToken');
    const authType = searchParams.get('authType');
    const roleName = searchParams.get('roleName') as RoleNames;
    setError('');
    if (accessToken && authType && roleName) {
      setAuth({
        accessToken,
        authType,
        roleName,
      });
    } else {
      setError('Invalid url');
    }
  }, [searchParams]);
  useEffect(() => {
    if (!loadingUserInfo && user) {
      const roleName = user.role?.name;
      if (roleName === RoleNames.SUPER_ADMIN) {
        navigate(appRoutes.adminDashboard);
      } else if (roleName === RoleNames.SITE_ADMIN) {
        navigate(appRoutes.adminDashboard);
      } else if (roleName === RoleNames.ORG_ADMIN) {
        navigate(
          !!user.organizations?.length
            ? appRoutes.orgDashboard
            : appRoutes.registerOrgGoogle
        );
      } else if (
        roleName === RoleNames.WORKER ||
        roleName === RoleNames.SPONSOR
      ) {
        if (
          !user.summary &&
          !user.profession &&
          !user.userSkills?.length &&
          !user.userEducations?.length &&
          !user.userExperiences?.length
        ) {
          navigate(appRoutes.authWorkerUploadResume);
        } else {
          navigate(
            roleName === RoleNames.SPONSOR
              ? appRoutes.sponsorMyJobs
              : appRoutes.workerFindWork()
          );
        }
      }
    }
  }, [loadingUserInfo, user, org]);
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      {loadingUserInfo && <SectionSpinner />}
      {error && <>{error}</>}
    </UIBox>
  );
}

export default AuthCallbackPage;
