import { UIBox, Button, Typography } from '@/components/UI';

const NothingsApplications: React.FC = () => {
  return (
    <UIBox display="flex" flexDirection="column" alignItems="center" py={3}>
      <Typography variant="h5">There's nothing there</Typography>
      <Typography marginBottom={20}>
        Try viewing all applications, or browse all jobs near you.
      </Typography>
      <UIBox display="flex" flexDirection="column" width={190} gap={20}>
        <Button size="sm" variant="secondary">
          View all applications
        </Button>
        <Button
          variant="outlined-secondary"
          style={{ background: 'transparent', border: 'none' }}
          size="sm"
        >
          Browse jobs
        </Button>
      </UIBox>
    </UIBox>
  );
};

export default NothingsApplications;
