import { UserJson } from '@/types';
import { faker } from '@faker-js/faker';

export function createRandomExperience(): UserJson.Experience {
  return {
    id: faker.string.uuid(),
    title: faker.helpers.arrayElement([
      'Senior Vice President, Head of Portfolio Engineering',
      'Software Engineer',
      'Full stack developer',
    ]),
    company: faker.helpers.arrayElement([
      'Fidelity',
      'Phoenix',
      'Facebook',
      'Slack',
    ]),
    description: faker.lorem.paragraphs(),
    startAt: faker.date.past({ years: 3 }).toLocaleDateString(),
    endAt: faker.date.past({ years: 2 }).toLocaleDateString(),
    isProgress: false,
    country: faker.location.country(),
    city: faker.location.city(),
    state: faker.location.state(),
    createdAt: faker.date.past({ years: 1 }).toLocaleDateString(),
  };
}
export const experiencesMock: UserJson.Experience[] = faker.helpers.multiple(
  createRandomExperience,
  { count: 3 }
);
