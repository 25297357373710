import { UIBox, Button, Pagination, Typography } from '@/components/UI';
import { theme } from '@/constants';
import Notification from './Notification';
import { IconInfoCircle } from '@/assets/icons';
import { useState } from 'react';

const notificationTypes = ['All', 'New', 'Archive'];
const NotificationsPage: React.FC = () => {
  const [notificationType, setNotificationType] = useState('All');
  const [allowedPushNotification, setAllowedPushNotification] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  return (
    <UIBox display="flex" flexDirection="column" gap={30}>
      {!dismissed && !allowedPushNotification && (
        <UIBox
          borderRadius={10}
          backgroundColor={theme.colors.green200}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height={68}
          px={2}
          boxShadow={theme.colors.lightBoxShadow}
        >
          <Typography color={theme.colors.textPrimary}>
            <strong>Turn on push notifications</strong> to know when your video
            has been watched and interacted with.
          </Typography>
          <UIBox display="flex" gap={10}>
            <Button
              variant="outlined-primary"
              size="sm"
              style={{
                background: 'transparent',
                color: theme.colors.textPrimary,
              }}
              onClick={() => setAllowedPushNotification(true)}
            >
              Allow push notifications
            </Button>
            <Button
              variant="secondary"
              size="sm"
              style={{
                background: 'transparent',
                color: theme.colors.textDescription,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              onClick={() => setDismissed(true)}
            >
              Dismiss
            </Button>
          </UIBox>
        </UIBox>
      )}
      {!dismissed && allowedPushNotification && (
        <UIBox
          borderRadius={10}
          backgroundColor={'#D0E5F8'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height={68}
          px={2}
        >
          <Typography color={theme.colors.textPrimary} fontWeight={600}>
            Push notification allowed
          </Typography>
          <Button
            variant="secondary"
            size="sm"
            style={{
              background: 'transparent',
              color: theme.colors.textDescription,
              paddingLeft: 10,
              paddingRight: 10,
            }}
            onClick={() => setDismissed(true)}
          >
            Dismiss
          </Button>
        </UIBox>
      )}
      <Typography variant="h3" marginTop={0} marginBottom={0}>
        Notifications
      </Typography>
      <UIBox display="flex" gap={10}>
        {notificationTypes.map((item) => (
          <UIBox width={140} key={item}>
            <Button
              fullWidth
              height={40}
              variant={item === notificationType ? 'primary' : 'gray'}
              onClick={() => setNotificationType(item)}
            >
              {item}
            </Button>
          </UIBox>
        ))}
      </UIBox>
      {notificationType === 'All' && (
        <>
          <UIBox display="flex" flexDirection="column" gap={10}>
            <Notification category="note">
              <UIBox display="flex" gap={20}>
                <UIBox width={20} pt={0.2}>
                  <IconInfoCircle
                    width={20}
                    height={20}
                    color={theme.colors.blue400}
                  />
                </UIBox>
                <UIBox display="flex" flexDirection="column" gap={10}>
                  <Typography
                    fontSize={14}
                    lineHeight="17px"
                    fontWeight={600}
                    color={theme.colors.textPrimary}
                  >
                    Note
                  </Typography>
                  <Typography
                    fontSize={14}
                    lineHeight="17px"
                    fontWeight={500}
                    color={theme.colors.textDescription}
                  >
                    This product is under active development and is available in
                    a Private Beta, please contact NL support for access.
                  </Typography>
                  <UIBox width={100}>
                    <Button size="sm" variant="outlined-secondary" fullWidth>
                      Accept
                    </Button>
                  </UIBox>
                </UIBox>
              </UIBox>
            </Notification>
            <Notification category="success">
              <Typography
                fontSize={14}
                lineHeight="17px"
                fontWeight={500}
                color={theme.colors.textDescription}
              >
                <Typography color={theme.colors.green700} variant="span">
                  New job added!
                </Typography>{' '}
                Create stunning content with our latest designs.
              </Typography>
            </Notification>
            <Notification category="danger">
              <UIBox display="flex" gap={20}>
                <UIBox width={20} pt={0.2}>
                  <IconInfoCircle
                    width={20}
                    height={20}
                    color={theme.colors.blue400}
                  />
                </UIBox>
                <Typography
                  fontSize={14}
                  lineHeight="17px"
                  fontWeight={500}
                  color={theme.colors.textDescription}
                >
                  <Typography color={theme.colors.red800} variant="span">
                    Account Security Alert:
                  </Typography>
                  Suspicious login detected. Verify recent activity to secure
                  your account.
                </Typography>
              </UIBox>
            </Notification>
            <Notification category="success">
              <Typography
                fontSize={14}
                lineHeight="17px"
                fontWeight={500}
                color={theme.colors.textDescription}
              >
                <Typography color={theme.colors.green700} variant="span">
                  New job added!
                </Typography>{' '}
                Create stunning content with our latest designs.
              </Typography>
            </Notification>
          </UIBox>
          <UIBox display="flex" justifyContent="flex-end">
            <Pagination total={10} offset={1} />
          </UIBox>
        </>
      )}
      {notificationType !== 'All' && (
        <UIBox
          height={400}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          borderRadius={10}
          backgroundColor="white"
        >
          <Typography variant="h4" marginBottom={10}>
            There's nothing here
          </Typography>
          <Typography>Try viewing archive</Typography>
        </UIBox>
      )}
    </UIBox>
  );
};

export default NotificationsPage;
