import { HomeNavbar } from '@/modules/Home';

interface HomeLayoutProps {
  children: React.ReactNode;
}

const HomeLayout: React.FC<HomeLayoutProps> = ({ children }) => {
  return (
    <div>
      <HomeNavbar />
      {children}
    </div>
  );
};

export default HomeLayout;
