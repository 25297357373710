import { UIBox, Button, Typography, Tabs, Tab } from '@/components/UI';
import { useAuth, useConfirmModal } from '@/hooks';
import { useDeleteUserMutation } from '@/redux/apis';
import { useEffect } from 'react';
import AccountChangeInfoForm from './AccountChangeInfoForm';
import AccountChangePasswordForm from './AccountChangePasswordForm';

const AccountSettings: React.FC = () => {
  const { user, logout } = useAuth();
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();
  const [deleteUser, { isSuccess: isDeleted }] = useDeleteUserMutation();
  const handleDeleteProfile = async () => {
    await deleteUser({ userId: user?.id || '' });
  };
  useEffect(() => {
    if (isDeleted) {
      hideConfirmModal();
      logout();
    }
  }, [isDeleted]);
  return (
    <UIBox>
      <UIBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1.5}
      >
        <Typography
          variant="h3"
          fontWeight={600}
          marginTop={0}
          marginBottom={0}
        >
          Account Settings
        </Typography>
      </UIBox>
      <Tabs defaultActiveKey="accountInfo">
        <Tab eventKey="accountInfo" title="Account Info">
          <AccountChangeInfoForm />
        </Tab>
        <Tab eventKey="changePassword" title="Change Password">
          <AccountChangePasswordForm />
        </Tab>
      </Tabs>
      <UIBox mt={2} />
      <Button
        variant="danger"
        size="sm"
        width={140}
        onClick={() =>
          showConfirmModal({
            question: 'Do you want to close your account?',
            confirmButton: 'Yes, Close My Account',
            onClose: () => hideConfirmModal(),
            onConfirm: handleDeleteProfile,
          })
        }
      >
        Close Account
      </Button>
    </UIBox>
  );
};

export default AccountSettings;
