import { UIBox, Typography } from '@/components/UI';

import { useOutletContext, useSearchParams } from 'react-router-dom';
import WorkerRecommendJobCard from './RecommendJobCard';
import { WorkerLayoutContextType } from '@/pages/worker/layout';
import SidebarJobDetail from '../SidebarJobDetail';
import { jobsMock } from '@/mock';

const WorkerRecommendJobs: React.FC = () => {
  const { applyToJob } = useOutletContext<WorkerLayoutContextType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickJobCard = (jobId: number) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.jobId = `${jobId}`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  const handleClickApply = () => {
    applyToJob?.();
  };
  const handleClickBookmark = () => {};
  return (
    <UIBox>
      <Typography variant="h6">You might also like</Typography>
      <UIBox display="flex" flexDirection="column" gap={20}>
        {jobsMock.map((job, id) => (
          <WorkerRecommendJobCard
            key={id}
            job={job}
            onClick={() => handleOnClickJobCard(job.id)}
            onClickApply={handleClickApply}
            onClickBookmark={handleClickBookmark}
          />
        ))}
      </UIBox>
      <SidebarJobDetail />
    </UIBox>
  );
};

export default WorkerRecommendJobs;
