import { theme } from '@/constants';
import { UIBox } from './Box';
import { Typography } from '.';

type ProgressVariant = 'primary' | 'secondary' | 'success' | 'danger';
interface ProgressBarProps {
  value: number; // 0~100
  variant?: ProgressVariant;
  barWidth?: number;
  label?: string;
}
const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  variant = 'primary',
  barWidth = 10,
  label,
}) => {
  const colors: Record<ProgressVariant, string> = {
    primary: theme.colors.primary,
    secondary: theme.colors.secondary,
    success: theme.colors.success,
    danger: theme.colors.error,
  };
  return (
    <UIBox>
      <UIBox
        backgroundColor={theme.colors.gray800}
        borderRadius={barWidth}
        height={barWidth}
      >
        <UIBox
          width={`${value}%`}
          backgroundColor={colors[variant]}
          borderRadius={barWidth}
          height={barWidth}
        />
      </UIBox>

      {label && (
        <Typography fontSize={12} textAlign="center">
          {label}
        </Typography>
      )}
    </UIBox>
  );
};

export default ProgressBar;
