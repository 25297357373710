import { IconBookmark } from '@/assets/icons';
import { UIBox, Button } from '@/components/UI';
import { theme } from '@/constants';

interface JobApplyButtonProps {
  onClickApply?: () => void;
  onClickBookmark?: () => void;
}
const JobApplyButton: React.FC<JobApplyButtonProps> = ({
  onClickApply,
  onClickBookmark,
}) => {
  return (
    <UIBox display="flex" alignItems="center" gap={10}>
      <UIBox flexGrow={1}>
        <Button
          height={44}
          fullWidth
          onClick={(e) => {
            e.stopPropagation();
            onClickApply?.();
          }}
        >
          Apply Now
        </Button>
      </UIBox>
      <Button
        variant="outlined-primary"
        height={44}
        width={44}
        style={{ padding: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          onClickBookmark?.();
        }}
      >
        <IconBookmark width={15} height={18} color={theme.colors.textPrimary} />
      </Button>
    </UIBox>
  );
};

export default JobApplyButton;
