import React, { forwardRef, useMemo } from 'react';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { UIBox } from './Box';
import { Typography } from '.';
import { theme } from '@/constants';

interface StyledThumbProps {
  valueNow: number;
  index: number;
  value: number | readonly number[];
  min: number;
  max: number;
}

const StyledThumb = styled.div<{ color: string }>`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  background: ${theme.colors.white};
  box-shadow: 0px 3px 6px 0px #0000001a;
  transform: translateY(-5px);
  border-radius: 50%;
  cursor: grab;
  .react-tooltip__show {
    background-color: ${({ color }) => color};
    color: ${theme.colors.primary};
    font-size: 14px;
    padding: 5px;
    border-radius: 10px;
  }
`;

const Thumb = forwardRef<HTMLDivElement, StyledThumbProps>(
  ({ index, value, valueNow, min, max, ...props }, ref) => {
    const mixColors = (color1: string, color2: string, ratio: number) => {
      const hex = (color: string) => parseInt(color, 16);
      const r = Math.round(
        (1 - ratio) * hex(color1.slice(1, 3)) + ratio * hex(color2.slice(1, 3))
      );
      const g = Math.round(
        (1 - ratio) * hex(color1.slice(3, 5)) + ratio * hex(color2.slice(3, 5))
      );
      const b = Math.round(
        (1 - ratio) * hex(color1.slice(5, 7)) + ratio * hex(color2.slice(5, 7))
      );
      return `rgb(${r}, ${g}, ${b})`;
    };

    const getColor = (value: number, min: number, max: number) => {
      const percent = (value - min) / (max - min);
      const startColor = '#ffe588';
      const endColor = '#43ff33';
      return mixColors(startColor, endColor, percent);
    };
    return (
      <StyledThumb
        {...props}
        ref={ref as React.MutableRefObject<HTMLDivElement>}
        data-tooltip-id={`slider-thumb-${index}`}
        color={getColor(valueNow, min, max)}
      >
        <Tooltip
          place="top"
          content={`${valueNow}%`}
          isOpen
          id={`slider-thumb-${index}`}
        />
      </StyledThumb>
    );
  }
);

interface StyledTrackProps {
  index: number;
  value: string | number | readonly number[] | readonly string[];
}

const StyledTrack = styled(({ index, value, ...props }: StyledTrackProps) => (
  <div {...props} />
))<StyledTrackProps>`
  top: 0;
  bottom: 0;
  border-radius: 999px;
`;

const Track: React.FC<StyledTrackProps> = (props) => <StyledTrack {...props} />;

const StyledSlider = styled(ReactSlider)<ReactSliderProps<number[]>>`
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, #ffe588 0%, #43ff33 100%);
  margin-top: 60px;
  border-radius: 6px;
`;

type RangeSliderProps = ReactSliderProps<number[]> & {
  rulerStep?: number;
};

const RangeSlider: React.FC<RangeSliderProps> = ({
  renderThumb,
  renderTrack,
  ...rest
}) => {
  const { min = 0, max = 100, rulerStep = 5 } = rest;
  const [rulerTicks, rulerLabels] = useMemo(() => {
    const ticks = [];
    const labels = [];
    for (let i = min; i <= max; i += rulerStep) {
      ticks.push(
        <UIBox
          key={i}
          height={6}
          borderLeftColor="#ccc"
          borderLeftStyle="solid"
          borderLeftWidth={2}
        />
      );
    }
    for (let i = min; i <= max; i += rulerStep * 2) {
      labels.push(
        <Typography
          key={i}
          color="#666"
          fontSize={12}
          lineHeight="14px"
          style={{ width: 22 }}
          textAlign="center"
        >
          {i}
        </Typography>
      );
    }
    return [ticks, labels];
  }, [min, max, rulerStep]);

  return (
    <UIBox>
      <StyledSlider
        renderTrack={(props, state) => (
          <Track index={state.index} value={state.value} {...props} />
        )}
        renderThumb={(props, state) => (
          <Thumb
            {...props}
            index={state.index}
            valueNow={state.valueNow}
            value={state.value}
            min={min}
            max={max}
          />
        )}
        {...rest}
      />
      <UIBox>
        <UIBox display="flex" justifyContent="space-between" mt={1}>
          {rulerTicks}
        </UIBox>
        <UIBox
          display="flex"
          justifyContent="space-between"
          ml={'-10px'}
          mr={'-10px'}
        >
          {rulerLabels}
        </UIBox>
      </UIBox>
    </UIBox>
  );
};

export default RangeSlider;
