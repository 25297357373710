import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconCurrencyCircleDollar({
  width = 15,
  height = 15,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 4.00781V4.89844"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9.35156V10.2422"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 12.4688C10.6066 12.4688 13.125 10.0763 13.125 7.125C13.125 4.17373 10.6066 1.78125 7.5 1.78125C4.3934 1.78125 1.875 4.17373 1.875 7.125C1.875 10.0763 4.3934 12.4688 7.5 12.4688Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.09375 9.35156H8.20312C8.51393 9.35156 8.812 9.23427 9.03177 9.02549C9.25153 8.81671 9.375 8.53354 9.375 8.23828C9.375 7.94302 9.25153 7.65985 9.03177 7.45107C8.812 7.24229 8.51393 7.125 8.20312 7.125H6.79688C6.48607 7.125 6.188 7.00771 5.96823 6.79893C5.74847 6.59015 5.625 6.30698 5.625 6.01172C5.625 5.71646 5.74847 5.43329 5.96823 5.22451C6.188 5.01573 6.48607 4.89844 6.79688 4.89844H8.90625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
