import { UIBox, UIGrid, Image, UIContainer, Typography } from '@/components/UI';
import { services } from '@/constants';
import styled from 'styled-components';

export default function IdealFor() {
  return (
    <StyledIdealForSection>
      <UIBox pt={6} pb={6} backgroundColor="#FFFFFFBF">
        <UIContainer
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h2" textAlign="center" marginBottom={40}>
            Ideal for
          </Typography>
          <UIGrid gap="30px" {...{ columns: 1, sm: 2, md: 3, lg: 4 }}>
            {services.map((item, index) => (
              <StyledServiceCard key={index}>
                <Image src={item.src} alt="" width={40} height={40} />
                <StyledServiceLabel textAlign="center" marginTop="16px">
                  {item.label}
                </StyledServiceLabel>
              </StyledServiceCard>
            ))}
          </UIGrid>
        </UIContainer>
      </UIBox>
    </StyledIdealForSection>
  );
}

const StyledServiceLabel = styled(Typography)`
  min-height: 48px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 18px;
`;

const StyledIdealForSection = styled.section({
  width: '100%',
  marginTop: '8rem',
  position: 'relative',
  background: 'url(/images/img_ideal_bg.png)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const StyledServiceCard = styled.div({
  height: 158,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: 24,
  backgroundColor: '#FFFFFF8C',
  border: '1px solid #FFFFFF8C',
  borderRadius: '24px',
  boxShadow: '0 4px 8px 0 #BDBDBD26, 0 6px 20px 0 #BDBDBD26',
});
