import { IconInfoCircle } from '@/assets/icons';
import { UIBox, UICard, UIDivider, Image, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { ProjectBudgetType } from '@/enums';
import { ProjectJson } from '@/types';
import { formatCurrency } from '@/utils';

interface WorkerInvitedJobCardProps {
  job: ProjectJson.Project;
  onClick: () => void;
}

const WorkerInvitedJobCard: React.FC<WorkerInvitedJobCardProps> = ({
  job,
  onClick,
}) => {
  return (
    <UICard
      borderRadius={10}
      pt={2}
      px={1.5}
      pb={1.5}
      position="relative"
      display="flex"
      flexDirection="column"
      cursor="pointer"
      onClick={onClick}
      borderWidth={1}
      borderStyle="solid"
      borderColor={theme.colors.gray810}
    >
      <UIBox
        position="absolute"
        right={0}
        top={0}
        height={20}
        px={1}
        borderBottomLeftRadius={10}
        borderTopRightRadius={10}
        background={`${theme.colors.secondary}33`}
      >
        <Typography
          fontSize={12}
          fontWeight={500}
          color={theme.colors.textPrimary}
          variant="span"
        >
          Applied
        </Typography>
      </UIBox>
      <UIBox display="flex" justifyContent="space-between" mb={1}>
        <UIBox>
          <Typography
            fontSize={18}
            fontWeight={600}
            lineHeight="24px"
            color={theme.colors.textPrimary}
            marginBottom={2}
          >
            {job.title}
          </Typography>
          <UIBox display="flex" alignItems="center" gap={10}>
            <Image src={'job.companyData?.logo'} width={12} height={15} />
            <Typography
              fontSize={14}
              lineHeight="19px"
              color={theme.colors.textPrimary}
            >
              {job.companyData?.name}
            </Typography>
            <Typography fontSize={12}>
              ({job.companyData?.numEmployee} employees)
            </Typography>
          </UIBox>
        </UIBox>
        <UIBox display="flex" flexDirection="column" alignItems="flex-end">
          <Typography color={theme.colors.textPrimary}>
            {job.budget === ProjectBudgetType.HOURLY
              ? `${formatCurrency(job.minRate)} - ${formatCurrency(job.maxRate)}`
              : `${formatCurrency(job.minSalary)} - ${formatCurrency(job.maxSalary)}`}
          </Typography>
          <UIBox display="flex" alignItems="center" gap={10} transform="low">
            <IconInfoCircle />
            <Typography
              textTransform="lowercase"
              fontSize={14}
              fontWeight={500}
              lineHeight="17px"
            >
              est. {job.budget}
            </Typography>
          </UIBox>
        </UIBox>
      </UIBox>
      <UIBox flexGrow={1} />
      <UIDivider mt={0} mb={1} />
      <UIBox display="flex" alignItems="center" gap={10}>
        <Typography color={theme.colors.textPrimary} fontWeight={500}>
          Interview:
        </Typography>
        <Typography>today at 2.p.m</Typography>
      </UIBox>
    </UICard>
  );
};

export default WorkerInvitedJobCard;
