import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconBuildings1({
  width = 22,
  height = 22,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      className="fill-icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.625 17.873H19.937V8.93555C19.9366 8.571 19.7916 8.22151 19.5338 7.96374C19.276 7.70596 18.9265 7.56096 18.562 7.56055H13.062V3.43555C13.0616 3.071 12.9166 2.72151 12.6588 2.46374C12.401 2.20596 12.0515 2.06096 11.687 2.06055H3.437C3.07245 2.06096 2.72296 2.20596 2.46519 2.46374C2.20741 2.72151 2.06241 3.071 2.062 3.43555V17.873H1.375C1.19266 17.873 1.0178 17.9455 0.888864 18.0744C0.759933 18.2033 0.6875 18.3782 0.6875 18.5605C0.6875 18.7429 0.759933 18.9178 0.888864 19.0467C1.0178 19.1756 1.19266 19.248 1.375 19.248H20.625C20.8073 19.248 20.9822 19.1756 21.1111 19.0467C21.2401 18.9178 21.3125 18.7429 21.3125 18.5605C21.3125 18.3782 21.2401 18.2033 21.1111 18.0744C20.9822 17.9455 20.8073 17.873 20.625 17.873ZM10.312 11.6855C10.312 11.8679 10.2396 12.0427 10.1106 12.1717C9.9817 12.3006 9.80683 12.373 9.6245 12.373H6.8745C6.69216 12.373 6.51729 12.3006 6.38836 12.1717C6.25943 12.0428 6.187 11.8679 6.187 11.6855C6.187 11.5032 6.25943 11.3283 6.38836 11.1994C6.51729 11.0705 6.69216 10.998 6.8745 10.998H9.6245C9.80683 10.998 9.9817 11.0705 10.1106 11.1994C10.2396 11.3283 10.312 11.5032 10.312 11.6855ZM5.4995 5.49805H8.2495C8.43183 5.49805 8.6067 5.57048 8.73563 5.69941C8.86456 5.82834 8.937 6.00321 8.937 6.18555C8.937 6.36788 8.86456 6.54275 8.73563 6.67168C8.6067 6.80061 8.43183 6.87305 8.2495 6.87305H5.4995C5.31716 6.87305 5.14229 6.80061 5.01336 6.67168C4.88443 6.54275 4.812 6.36788 4.812 6.18555C4.812 6.00321 4.88443 5.82834 5.01336 5.69941C5.14229 5.57048 5.31716 5.49805 5.4995 5.49805ZM5.4995 14.4355H8.2495C8.43183 14.4355 8.6067 14.508 8.73563 14.6369C8.86456 14.7658 8.937 14.9407 8.937 15.123C8.937 15.3054 8.86456 15.4803 8.73563 15.6092C8.6067 15.7381 8.43183 15.8105 8.2495 15.8105H5.4995C5.31716 15.8105 5.14229 15.7381 5.01336 15.6092C4.88443 15.4803 4.812 15.3054 4.812 15.123C4.812 14.9407 4.88443 14.7658 5.01336 14.6369C5.14229 14.508 5.31716 14.4355 5.4995 14.4355ZM13.062 8.93555H18.562V17.873H13.062V8.93555Z"
        fill={color}
      />
      <path
        d="M16.5 14.4355H15.125C14.9427 14.4355 14.7678 14.508 14.6389 14.6369C14.5099 14.7658 14.4375 14.9407 14.4375 15.123C14.4375 15.3054 14.5099 15.4803 14.6389 15.6092C14.7678 15.7381 14.9427 15.8105 15.125 15.8105H16.5C16.6823 15.8105 16.8572 15.7381 16.9861 15.6092C17.1151 15.4803 17.1875 15.3054 17.1875 15.123C17.1875 14.9407 17.1151 14.7658 16.9861 14.6369C16.8572 14.508 16.6823 14.4355 16.5 14.4355Z"
        fill={color}
      />
      <path
        d="M15.125 12.373H16.5C16.6823 12.373 16.8572 12.3006 16.9861 12.1717C17.1151 12.0428 17.1875 11.8679 17.1875 11.6855C17.1875 11.5032 17.1151 11.3283 16.9861 11.1994C16.8572 11.0705 16.6823 10.998 16.5 10.998H15.125C14.9427 10.998 14.7678 11.0705 14.6389 11.1994C14.5099 11.3283 14.4375 11.5032 14.4375 11.6855C14.4375 11.8679 14.5099 12.0428 14.6389 12.1717C14.7678 12.3006 14.9427 12.373 15.125 12.373Z"
        fill={color}
      />
    </svg>
  );
}
