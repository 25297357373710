import { AppModal, AppSideModal } from '@/components/App';
import { IconSend, IconUserPlus } from '@/assets/icons';
import {
  Autocomplete,
  UIBox,
  Button,
  Input,
  Label,
  RichEditor,
  Typography,
} from '@/components/UI';
import { theme } from '@/constants';
import { useState } from 'react';
const SidebarInviteUser: React.FC<{
  show: boolean;
  setShow: (show: boolean) => void;
}> = ({ show, setShow }) => {
  const [showSentModal, setShowSentModal] = useState(false);

  const handleCloseSideModal = () => {
    setShow(false);
  };
  return (
    <AppSideModal isOpen={show} width={414} onClose={handleCloseSideModal}>
      <UIBox display="flex" flexDirection="column" gap={30} px={1.5} py={2}>
        <UIBox display="flex" alignItems="center" gap={10}>
          <Typography variant="h6" fontSize={20} marginBottom={0}>
            Invite user
          </Typography>
          <IconUserPlus width={13} height={14} color={theme.colors.icon} />
        </UIBox>
        <Input
          label="Write users email"
          fullWidth
          variant="secondary"
          labelSize={14}
        />
        <Autocomplete
          label="Choose the role of invitee"
          fullWidth
          variant="secondary"
          labelSize={14}
        />
        <Autocomplete
          label="Choose the vacancy"
          fullWidth
          variant="secondary"
          labelSize={14}
        />
        <UIBox>
          <Label variant="secondary" fontSize={14}>
            + Add a cover letter
          </Label>
          <RichEditor />
        </UIBox>
      </UIBox>

      <UIBox
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        background={theme.colors.white}
        boxShadow="10px -1px 30px 0px #7B7D9633"
        py={1}
        px={1.5}
        display="flex"
        gap={10}
      >
        <UIBox width={'50%'}>
          <Button
            fullWidth
            variant="primary"
            size="md"
            onClick={() => setShowSentModal(true)}
          >
            Send to Invite
          </Button>
        </UIBox>
        <UIBox width={'50%'}>
          <Button
            fullWidth
            variant="outlined-primary"
            size="md"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </UIBox>
      </UIBox>
      <AppModal
        isOpen={showSentModal}
        header={
          <UIBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={10}
          >
            <Typography variant="h6" marginBottom={0}>
              Invitation sent!
            </Typography>
            <IconSend />
          </UIBox>
        }
        headerAlign="center"
        onClose={() => setShowSentModal(false)}
      >
        <UIBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={20}
        >
          <UIBox display="flex" flexDirection="column" alignItems="center">
            <Typography>
              You’re all set! We’ve sent your invitation to
            </Typography>
            <Typography
              color={theme.colors.textPrimary}
              style={{ textDecoration: 'underline' }}
            >
              alanmonder@gmail.com
            </Typography>
          </UIBox>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSentModal(false);
              setShow(false);
            }}
            size="sm"
            width={200}
            height={40}
          >
            Look my Invitations List
          </Button>
        </UIBox>
      </AppModal>
    </AppSideModal>
  );
};

export default SidebarInviteUser;
