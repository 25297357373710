import { AppModal } from '@/components/App';
import { Button, Spinner, Typography, UIBox } from '@/components/UI';
import { UserAssetType } from '@/enums';
import { useUploadFile } from '@/hooks/useUploadFile';
import { useRef, useState } from 'react';

interface LogoChangeModalProps {
  userId: string;
  orgId?: string;
  show: boolean;
  url?: string;
  onClose: () => void;
  onUploaded: () => void;
}
const LogoChangeModal: React.FC<LogoChangeModalProps> = ({
  userId,
  orgId,
  url,
  show,
  onClose,
  onUploaded,
}) => {
  const {
    acceptedFiles,
    setAcceptedFiles,
    loading: isUploading,
    uploadFile,
  } = useUploadFile({
    onUploadedFile: () => {
      setAcceptedFiles([]);
      setNewFileUrl('');
      onUploaded();
    },
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [newFileUrl, setNewFileUrl] = useState('');
  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      const f = event.target.files[0];
      setAcceptedFiles([f]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewFileUrl(reader.result as string);
      };
      reader.readAsDataURL(f);
    }
  }

  const previewUrl = newFileUrl || url;
  function handleClose() {
    setAcceptedFiles([]);
    setNewFileUrl('');
    onClose();
  }
  return (
    <AppModal
      isOpen={show}
      header={
        <UIBox display="flex" alignItems="center" gap={8}>
          Change image
        </UIBox>
      }
      onClose={handleClose}
      width={370}
      actions={
        <UIBox display="flex" justifyContent="flex-end" gap={20}>
          <Button
            disabled={isUploading || acceptedFiles.length === 0}
            onClick={() =>
              uploadFile({ contentType: UserAssetType.AVATAR, userId, orgId })
            }
          >
            {isUploading && <Spinner size="sm" />}
            Update Photo
          </Button>
        </UIBox>
      }
    >
      <UIBox
        onClick={() => fileInputRef.current?.click()}
        width={200}
        height={200}
        borderRadius={200}
        minWidth={200}
        background="#F5F8FF"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        cursor="pointer"
        mx="auto"
        style={{
          backgroundImage: `url("${previewUrl}")`,
          backgroundSize: 'contain',
          cursor: 'pointer',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          border: 'dashed 2px #0E0E0E',
        }}
      >
        {!previewUrl && (
          <UIBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="h2"
              marginBottom={0}
              marginTop={0}
              fontWeight={400}
            >
              +
            </Typography>
            <Typography>Add Image</Typography>
          </UIBox>
        )}
      </UIBox>
      <input
        ref={fileInputRef}
        type="file"
        hidden
        accept="image/*"
        onChange={handleFileChange}
      />
    </AppModal>
  );
};

export default LogoChangeModal;
