import { ProfileEditButton } from '@/modules/Shared';
import { IconEmail, IconLocation, IconPhone } from '@/assets/icons';
import {
  UIBox,
  Button,
  UICard,
  UIDivider,
  Input,
  Typography,
  Spinner,
  LocationAutocomplete,
} from '@/components/UI';
import { UserJson } from '@/types';
import {
  downloadFile,
  formatAddressFromLocation,
  formatPhoneNumber,
  getUserAvatarUrl,
  inputStatus,
} from '@/utils';
import { useEffect, useState } from 'react';
import { AppUserAvatar } from '@/components/App';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useDeleteUserMutation,
  useLazyMyAssetsQuery,
  useUpdateUserMutation,
} from '@/redux/apis';
import { useAuth, useConfirmModal } from '@/hooks';
import UpdateProfileFromFileModal from './UpdateProfileFromFileModal';
import LogoChangeModal from '../Shared/LogoChangeModal';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
});

interface UserInfoProps {
  user?: UserJson.User;
  loading?: boolean;
  refetch?: () => void;
  refetchAll?: () => void;
}
const UserInfo: React.FC<UserInfoProps> = ({
  user,
  loading,
  refetch,
  refetchAll,
}) => {
  const { logout } = useAuth();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showLogoChangeModal, setShowLogoChangeModal] = useState(false);
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();
  const [editing, setEditing] = useState(false);
  const [updateUserInfo, { isLoading: saving, isSuccess: isSaved }] =
    useUpdateUserMutation();
  const [deleteUser, { isSuccess: isDeleted }] = useDeleteUserMutation();
  const [getMyAssets, { isLoading: downloading }] = useLazyMyAssetsQuery();
  const formik = useFormik<UserJson.UserInfoInput>({
    initialValues: {
      // firstName: user?.firstName || '',
      // lastName: user?.lastName || '',
      profession: user?.profession || '',
      phone: user?.phone || '',
      address1: user?.address1,
      address2: user?.address2,
      zipcode: user?.zipcode,
      city: user?.city,
      state: user?.state,
      country: user?.country,
    },
    validationSchema,
    onSubmit: async (values) => {
      await updateUserInfo(values);
    },
  });
  useEffect(() => {
    if (isSaved) {
      refetch?.();
      setEditing(false);
    }
  }, [isSaved]);
  useEffect(() => {
    formik.setValues({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      profession: user?.profession || '',
      phone: user?.phone || '',
      address1: user?.address1,
      address2: user?.address2,
      zipcode: user?.zipcode,
      city: user?.city,
      state: user?.state,
      country: user?.country,
    });
  }, [user]);
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnCancel = () => {
    setEditing(false);
  };
  const handleDownload = async () => {
    try {
      const result = await getMyAssets();
      const assets = result.data || [];
      if (result.isSuccess && assets.length > 0) {
        const latestAsset = assets[assets.length - 1];
        if (latestAsset.asset?.url) {
          downloadFile(latestAsset.asset?.url, latestAsset.asset?.name);
        }
      }
    } catch (err) {}
  };
  const handleDeleteProfile = async () => {
    await deleteUser({ userId: user?.id || '' });
  };
  useEffect(() => {
    if (isDeleted) {
      hideConfirmModal();
      logout();
    }
  }, [isDeleted]);
  return (
    <UICard p={0} borderRadius={10} borderWidth={0} position="relative">
      <UIBox
        background="url(/images/profile-header-background.png)"
        height={120}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        borderTopLeftRadius={10}
        borderTopRightRadius={10}
        position="relative"
        mb={2}
      >
        <UIBox
          position="absolute"
          left={'50%'}
          bottom={'-30px'}
          transform="translateX(-50%)"
        >
          <UIBox position="relative" id="profile-photo">
            <AppUserAvatar
              user={user}
              size={112}
              editing={editing}
              onClickEdit={() => setShowLogoChangeModal(true)}
            />
          </UIBox>
        </UIBox>
      </UIBox>
      <UIBox
        p={1.5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={14}
        flexGrow={1}
        width={'100%'}
      >
        {editing ? (
          <UIBox
            width="100%"
            gap={14}
            display="flex"
            flexDirection="column"
            mb={1}
          >
            <Input
              label="First Name"
              fullWidth
              {...formik.getFieldProps('firstName')}
              {...inputStatus(formik, 'firstName')}
            />
            <Input
              label="Last Name"
              fullWidth
              {...formik.getFieldProps('lastName')}
              {...inputStatus(formik, 'lastName')}
            />
            <Input
              label="Position"
              fullWidth
              {...formik.getFieldProps('profession')}
            />
            <Input
              label="Email"
              fullWidth
              defaultValue={user?.email}
              readOnly
            />
            <Input
              type="phone"
              label="Phone Number"
              fullWidth
              {...formik.getFieldProps('phone')}
            />
            <LocationAutocomplete
              label="Location"
              fullWidth
              value={formatAddressFromLocation(user)}
              onChange={(a, p, l) => {
                formik.setValues({
                  ...formik.values,
                  ...l,
                });
              }}
            />
          </UIBox>
        ) : loading ? (
          <Spinner size="xl" />
        ) : (
          <>
            <UIBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={10}
              mb={0.5}
            >
              <Typography variant="h4">{`${user?.firstName} ${user?.lastName}`}</Typography>
              <Typography textAlign="center">{user?.profession}</Typography>
            </UIBox>
            <UIBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={10}
              id="profile-contact-information"
            >
              <UIBox display="flex" gap={10} alignItems="center">
                <IconEmail /> {user?.email}
              </UIBox>
              {user?.phone && (
                <UIBox display="flex" gap={10} alignItems="center">
                  <IconPhone />
                  {formatPhoneNumber(user?.phone || '')}
                </UIBox>
              )}
              {(user?.city || user?.state) && (
                <UIBox display="flex" gap={10} alignItems="flex-start">
                  <UIBox minWidth={14}>
                    <IconLocation />
                  </UIBox>
                  {formatAddressFromLocation(user)}
                </UIBox>
              )}
            </UIBox>
            <UIDivider mb={0} mt={0} />
            <UIBox
              display="flex"
              flexDirection="column"
              gap={10}
              width="100%"
              id="profile-action-buttons"
            >
              <Button
                variant="secondary"
                fontWeight={500}
                size="md"
                onClick={handleDownload}
                disabled={downloading}
              >
                {downloading && <Spinner />}
                Download profile
              </Button>
              <Button
                variant="secondary"
                fontWeight={500}
                size="md"
                onClick={() => setShowUpdateModal(true)}
              >
                Update profile from file
              </Button>
              <Button
                variant="danger"
                fontWeight={500}
                size="md"
                onClick={() =>
                  showConfirmModal({
                    question: 'Do you want to delete this profile?',
                    confirmButton: 'Delete Profile',
                    onClose: () => hideConfirmModal(),
                    onConfirm: handleDeleteProfile,
                  })
                }
              >
                Delete profile
              </Button>
            </UIBox>
            <UpdateProfileFromFileModal
              show={showUpdateModal}
              onClose={() => setShowUpdateModal(false)}
              onUpdated={() => {
                refetchAll?.();
                setShowUpdateModal(false);
              }}
            />
          </>
        )}

        <UIBox
          position={editing ? undefined : 'absolute'}
          top={20}
          right={20}
          width={editing ? '100%' : undefined}
        >
          <ProfileEditButton
            editing={editing}
            onClickSave={() => formik.handleSubmit()}
            onClickCancel={handleOnCancel}
            onClickEdit={handleOnEdit}
            fullWidth
            saving={saving}
          />
        </UIBox>
      </UIBox>

      <LogoChangeModal
        show={showLogoChangeModal}
        onClose={() => setShowLogoChangeModal(false)}
        onUploaded={() => {
          setShowLogoChangeModal(false);
          refetch?.();
        }}
        url={getUserAvatarUrl(user)}
        userId={user?.id || ''}
      />
    </UICard>
  );
};

export default UserInfo;
