import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconHide({
  width = 15,
  height = 13,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98467 8.80557C6.42918 9.10544 6.97093 9.28675 7.54741 9.28675C9.07541 9.28675 10.3187 8.03847 10.3187 6.50428C10.3187 5.92547 10.1381 5.38153 9.83942 4.93522L9.08236 5.69534C9.20738 5.93245 9.27683 6.21139 9.27683 6.50428C9.27683 7.45967 8.49894 8.24071 7.54741 8.24071C7.2557 8.24071 6.97788 8.17097 6.74173 8.04545L5.98467 8.80557ZM12.1244 2.64054C13.1315 3.56106 13.9858 4.80236 14.6248 6.29471C14.6803 6.42721 14.6803 6.58063 14.6248 6.70615C13.1384 10.179 10.4922 12.2571 7.54732 12.2571H7.54037C6.19989 12.2571 4.91498 11.8178 3.78286 11.0158L2.43544 12.3687C2.33126 12.4733 2.19929 12.5221 2.06733 12.5221C1.93537 12.5221 1.79646 12.4733 1.69922 12.3687C1.52558 12.1944 1.4978 11.9154 1.63671 11.7132L1.65755 11.6853L11.9299 1.37135L11.9299 1.37134C11.9438 1.3574 11.9577 1.34345 11.9646 1.3295C11.9785 1.31556 11.9924 1.30161 11.9994 1.28766L12.6522 0.632145C12.8606 0.42991 13.187 0.42991 13.3885 0.632145C13.5968 0.834379 13.5968 1.16911 13.3885 1.37135L12.1244 2.64054ZM4.7711 6.50558C4.7711 6.6869 4.79194 6.86821 4.81972 7.03557L2.24989 9.61581C1.55534 8.80687 0.951087 7.83057 0.471849 6.70782C0.416285 6.58229 0.416285 6.42887 0.471849 6.29637C1.95818 2.82352 4.60441 0.75236 7.54235 0.75236H7.5493C8.5425 0.75236 9.50792 0.989462 10.3969 1.43577L8.07715 3.76496C7.91046 3.73706 7.72988 3.71614 7.5493 3.71614C6.01435 3.71614 4.7711 4.96442 4.7711 6.50558Z"
        fill={color}
      />
    </svg>
  );
}
