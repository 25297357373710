import { AppLogo } from '@/components/App';
import {
  UIBox,
  UIContainer,
  UIFlexColumnBox,
  UIFlexSpaceBox,
} from '@/components/UI';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavbarContainer = styled.header(({ theme }) => ({
  flexGrow: 1,
  position: 'fixed',
  zIndex: 99,
  left: 0,
  right: 0,
  paddingTop: '30px',
  paddingBottom: '10px',
}));
const StyledMain = styled.main`
  height: 100vh;
  padding-bottom: 80px;
  position: relative;
  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(/images/error-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: opacity(0.5);
  }
`;

const ErrorPageLayout: React.FC = () => {
  return (
    <>
      <StyledNavbarContainer>
        <UIContainer>
          <UIFlexSpaceBox>
            <UIFlexColumnBox>
              <AppLogo />
            </UIFlexColumnBox>
          </UIFlexSpaceBox>
        </UIContainer>
      </StyledNavbarContainer>
      <StyledMain>
        <UIContainer height="100%">
          <UIBox height="100%" display="flex" alignItems="center">
            <Outlet />
          </UIBox>
        </UIContainer>
      </StyledMain>
    </>
  );
};

export default ErrorPageLayout;
