import { UIBox, Button, UIGrid, Typography } from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { WorkerMyJobRouteCategory } from '@/enums';
import {
  IconBookmark,
  IconChat,
  IconPaperUpload,
  IconShow,
} from '@/assets/icons';
import SidebarJobDetail from '@/modules/Job/SidebarJobDetail';
import { useState } from 'react';
import { SummaryWidget } from '@/modules/Shared';

const myJobCategories: WorkerMyJobRouteCategory[] = [
  WorkerMyJobRouteCategory.APPLIED,
  WorkerMyJobRouteCategory.INVITED,
  WorkerMyJobRouteCategory.IN_PROGRESS,
  WorkerMyJobRouteCategory.COMPLETED,
  WorkerMyJobRouteCategory.ARCHIVE,
];
const WorkerMyJobsPage: React.FC = () => {
  const { pathname } = useLocation();
  const [selectedWidget, setSelectedWidget] = useState('');
  const [, , , category] = pathname.split('/');
  const navigate = useNavigate();
  const widgets = [
    {
      icon: <IconChat />,
      label: 'Interviews',
      value: 6,
      link: WorkerMyJobRouteCategory.INTERVIEWS,
    },
    {
      icon: <IconPaperUpload />,
      label: 'Applications',
      value: 25,
      link: WorkerMyJobRouteCategory.APPLICATIONS,
    },
    {
      icon: (
        <IconBookmark
          marked
          color={theme.colors.secondary}
          width={14}
          height={17}
        />
      ),
      label: 'Saved jobs',
      value: 37,
      link: WorkerMyJobRouteCategory.SAVED_JOBS,
    },
    {
      icon: <IconShow />,
      label: 'Recently views',
      value: 124,
      link: WorkerMyJobRouteCategory.RECENTLY_VIEWED,
    },
  ];
  return (
    <UIBox display="flex" gap={30} flexDirection="column">
      <UIBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" marginTop={0} marginBottom={0}>
          Hi, Samuel
        </Typography>
      </UIBox>
      <UIGrid columns={4} gap={20}>
        {widgets.map((item, id) => (
          <SummaryWidget
            key={id}
            {...item}
            status={
              !selectedWidget
                ? 'normal'
                : selectedWidget === item.link
                  ? 'selected'
                  : 'deselected'
            }
            onClick={() => {
              if (selectedWidget === item.link) {
                setSelectedWidget('');
                navigate(appRoutes.workerMyJobs());
              } else {
                setSelectedWidget(item.link);
                navigate(appRoutes.workerMyJobs(item.link));
              }
            }}
          />
        ))}
      </UIGrid>
      {!selectedWidget && (
        <UIBox>
          <Typography variant="h5">My Jobs</Typography>
          <UIBox display="flex" justifyContent="space-between" gap={20}>
            {myJobCategories.map((ct) => (
              <UIBox flexGrow={1} key={ct}>
                <Button
                  variant={ct === category ? 'success' : 'secondary'}
                  fullWidth
                  style={{ textTransform: 'capitalize' }}
                  onClick={() => navigate(appRoutes.workerMyJobs(ct))}
                  size="sm"
                  height={40}
                >
                  {ct}
                </Button>
              </UIBox>
            ))}
          </UIBox>
        </UIBox>
      )}
      <Outlet />
      <SidebarJobDetail viewOnly />
    </UIBox>
  );
};

export default WorkerMyJobsPage;
