import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconEdit({
  width = 15,
  height = 15,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1308 5.62726L5.72696 14.056C5.28991 14.4806 4.7155 14.7179 4.11611 14.7179H1.18162C1.01929 14.7179 0.869445 14.6554 0.75706 14.543C0.644675 14.4307 0.594727 14.2808 0.594727 14.1185L0.66965 11.159C0.682137 10.5721 0.919393 10.0227 1.33147 9.61064L7.28785 3.6543C7.38775 3.5544 7.56257 3.5544 7.66247 3.6543L9.74783 5.72716C9.88519 5.86451 10.085 5.95192 10.2973 5.95192C10.7593 5.95192 11.1214 5.57731 11.1214 5.12778C11.1214 4.90301 11.034 4.70322 10.8967 4.55337C10.8592 4.50342 8.87373 2.53046 8.87373 2.53046C8.74886 2.40559 8.74886 2.19331 8.87373 2.06844L9.71037 1.21932C10.4846 0.445122 11.7333 0.445122 12.5075 1.21932L14.1308 2.84264C14.8925 3.60435 14.8925 4.85306 14.1308 5.62726"
        fill={color}
      />
    </svg>
  );
}
