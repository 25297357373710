import { AppModal } from '@/components/App';
import { IconTickSquare } from '@/assets/icons';
import { UIBox, Button, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { SkillJson } from '@/types';
const NewSkillPublishedModal: React.FC<{
  createdSkill?: SkillJson.Skill;
  show: boolean;
  onClose: () => void;
  onLookSkills: () => void;
}> = ({ show, createdSkill, onClose, onLookSkills }) => {
  return (
    <AppModal
      isOpen={show}
      header={
        <UIBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={10}
        >
          <Typography variant="h6" marginBottom={0}>
            New skills published
          </Typography>
          <IconTickSquare />
        </UIBox>
      }
      headerAlign="center"
      onClose={onClose}
    >
      <UIBox display="flex" flexDirection="column" alignItems="center" gap={20}>
        <UIBox display="flex" flexDirection="column" alignItems="center">
          <Typography textAlign="center">
            You’re all set! We’ve published
            <br />
            <Typography variant="span" color={theme.colors.textPrimary}>
              {createdSkill?.name}
            </Typography>{' '}
            skill.
          </Typography>
        </UIBox>
        <Button variant="secondary" onClick={onLookSkills}>
          Look my Skills List
        </Button>
      </UIBox>
    </AppModal>
  );
};

export default NewSkillPublishedModal;
