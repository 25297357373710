import { IconArrowDown, IconPlus } from '@/assets/icons';
import {
  UIBox,
  UICard,
  UIGrid,
  Typography,
  Spinner,
  IconButton,
} from '@/components/UI';
import { theme } from '@/constants';
import styled from 'styled-components';
import { useGetUserProjectsByUserIdQuery } from '@/redux/apis';
import ProjectCard from './ProjectCard';
import { AppEmptyItems } from '@/components/App';
import { forwardRef, useImperativeHandle } from 'react';
import { RefetchHandleRef } from './Profile';

const StyledShowAllButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.secondary};
  gap: 10px;
  font-size: 16px;
  line-height: 22px;
`;

type ProfileProjectsProps = {
  userId?: string;
  publicView?: boolean;
};
const ProfileProjects = forwardRef<RefetchHandleRef, ProfileProjectsProps>(
  function ({ userId, publicView }, ref) {
    const {
      data: projects = [],
      isLoading,
      refetch,
    } = useGetUserProjectsByUserIdQuery({ userId: userId }, { skip: !userId });

    useImperativeHandle(ref, () => ({
      refetch,
    }));
    return (
      <UICard borderRadius={10} id="profile-projects-section">
        <UIBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h5" marginBottom={0}>
            Projects
          </Typography>
          {!publicView && (
            <IconButton>
              <IconPlus />
            </IconButton>
          )}
        </UIBox>
        {isLoading && (
          <UIBox display="flex" justifyContent="center" p={2}>
            <Spinner size="xl" />
          </UIBox>
        )}
        <UIGrid columns={3} gap={18}>
          {projects.slice(0, 3).map((project, id) => (
            <ProjectCard
              key={id}
              project={project.project}
              onClickDelete={() => null}
              editable={true}
            />
          ))}
        </UIGrid>
        {projects.length === 0 && (
          <AppEmptyItems
            description="Try add a new project"
            buttonLabel="Add Project"
          />
        )}
        {projects.length > 3 && (
          <UIBox display="flex" justifyContent="center" mt={1}>
            <StyledShowAllButton>
              Show all <IconArrowDown />
            </StyledShowAllButton>
          </UIBox>
        )}
      </UICard>
    );
  }
);

export default ProfileProjects;
