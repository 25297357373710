import {
  IconBookmark,
  IconHide,
  IconInfoCircle,
  IconLocation,
} from '@/assets/icons';
import { UIBox, UICard, Image, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { ProjectBudgetType } from '@/enums';
import { ProjectJson } from '@/types';
import { formatCurrency } from '@/utils';
import { useMemo } from 'react';
import styled from 'styled-components';

const StyledFooterButton = styled.button`
  font-family: Gantari;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: none;
  color: ${theme.colors.textPrimary};
`;
interface WorkerJobCardProps {
  job: ProjectJson.Project;
  onClick: () => void;
  statusLabel?: string;
}

const WorkerJobCard: React.FC<WorkerJobCardProps> = ({
  job,
  onClick,
  statusLabel,
}) => {
  const matching = useMemo(() => Math.round(Math.random() * 100), []);
  return (
    <UICard
      borderRadius={10}
      pt={2}
      px={1.5}
      pb={1.5}
      display="flex"
      flexDirection="column"
      gap={20}
      position="relative"
      cursor="pointer"
      onClick={onClick}
      borderWidth={1}
      borderStyle="solid"
      borderColor={theme.colors.gray810}
    >
      {(matching > 50 || statusLabel) && (
        <UIBox
          position="absolute"
          right={0}
          top={0}
          height={20}
          px={1}
          borderBottomLeftRadius={10}
          borderTopRightRadius={10}
          background={
            statusLabel
              ? `${theme.colors.secondary}33`
              : matching > 80
                ? theme.colors.green600
                : theme.colors.yellow500
          }
        >
          <Typography
            fontSize={12}
            fontWeight={500}
            color={theme.colors.textPrimary}
            variant="span"
          >
            {statusLabel ? statusLabel : `${matching}% skills matching`}
          </Typography>
        </UIBox>
      )}
      <UIBox display="flex" justifyContent="space-between">
        <UIBox>
          <Typography
            fontSize={18}
            fontWeight={600}
            lineHeight="24px"
            color={theme.colors.textPrimary}
            marginBottom={2}
          >
            {job.title}
          </Typography>

          <UIBox display="flex" alignItems="center" gap={10}>
            {job.employmentType}
            {job.location && (
              <>
                <IconLocation />
                <Typography fontSize={14} lineHeight="17px" fontWeight={500}>
                  {job.location?.city}, {job.location?.state}
                </Typography>
              </>
            )}
          </UIBox>
        </UIBox>
        <UIBox display="flex" flexDirection="column" alignItems="flex-end">
          <Typography color={theme.colors.textPrimary}>
            {job.budget === ProjectBudgetType.HOURLY
              ? `${formatCurrency(job.minRate)} - ${formatCurrency(job.maxRate)}`
              : `${formatCurrency(job.minSalary)} - ${formatCurrency(job.maxSalary)}`}
          </Typography>
          <UIBox display="flex" alignItems="center" gap={10} transform="low">
            <IconInfoCircle />
            <Typography
              textTransform="lowercase"
              fontSize={14}
              fontWeight={500}
              lineHeight="17px"
            >
              est. {job.budget}
            </Typography>
          </UIBox>
        </UIBox>
      </UIBox>
      <Typography
        style={{
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
        }}
      >
        {job.description}
      </Typography>
      <UIBox display="flex" justifyContent="space-between" alignItems="center">
        <UIBox display="flex" alignItems="center" gap={10}>
          <UIBox display="flex" alignItems="center" gap={10}>
            <Image src={'job.companyData?.logo'} width={12} height={15} />
            <Typography
              fontSize={14}
              lineHeight="19px"
              color={theme.colors.textPrimary}
            >
              {job.companyData?.name}
            </Typography>
            <Typography fontSize={12}>
              ({job.companyData?.numEmployee} employees)
            </Typography>
          </UIBox>
          <Typography fontSize={12}>Posted 23 minutes ago</Typography>
        </UIBox>
        <UIBox display="flex" alignItems="center" gap={20}>
          <StyledFooterButton>
            <IconHide />
            Don't show
          </StyledFooterButton>
          <StyledFooterButton>
            <IconBookmark />
            Saved
          </StyledFooterButton>
        </UIBox>
      </UIBox>
    </UICard>
  );
};

export default WorkerJobCard;
