import { IconEdit } from '@/assets/icons';
import { UIBox, Button, IconButton, Image, Typography } from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import {
  Ability,
  EmploymentType,
  JobPostingSteps,
  ProjectBudgetType,
  ProjectStatus,
  ProjectType,
} from '@/enums';
import { jobsMock, skillsMock, workerProfileMock } from '@/mock';

import {
  NewJobStep1,
  NewJobStep2,
  NewJobStep3,
  NewJobStep4,
  JobReviewCard,
} from '@/modules/Job/SponsorJobs/NewJob';
import JobProperties from '@/modules/Job/WorkerJobs/JobProperties';
import { ProjectJson } from '@/types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const descriptions: Record<JobPostingSteps, string> = {
  [JobPostingSteps.STEP_1]:
    "To ensure your post captures the attention of the right audience, make your headline compelling. It's the first impression they'll get, so make it memorable!",
  [JobPostingSteps.STEP_2]:
    'Identify and list the essential skills necessary for the successful completion of your project.',
  [JobPostingSteps.STEP_3]:
    'Provide specific employment details for your project to help potential candidates understand the job type, industry, proficiency level, and budget. This will ensure that you find the most suitable professionals for your requirements.',
  [JobPostingSteps.STEP_4]:
    "Attach any relevant documents or files that will provide additional context to your job posting. This can include project guidelines, specifications, or any other resources that candidates might find useful. Ensure the files are clear and pertinent to the position you're advertising.",
  [JobPostingSteps.STEP_5]: '',
};

const initJob: ProjectJson.Project = {
  id: 1,
  poster: workerProfileMock,
  title: '',
  description: '',
  type: ProjectType.REMOTE,
  minSalary: 0,
  maxSalary: 0,
  minRate: 0,
  maxRate: 0,
  companyData: {
    name: '',
    bio: '',
    website: '',
    numEmployee: '1-10',
    createdAt: '',
  },
  projectSkills: skillsMock,
  budget: ProjectBudgetType.HOURLY,
  employmentType: EmploymentType.FULL_TIME,
  minProficiency: Ability.EXPERT,
  status: ProjectStatus.COMPLETED,
  createdAt: '',
};
const SponsorNewJob: React.FC = () => {
  const navigate = useNavigate();
  const [job] = useState<ProjectJson.Project>(initJob);
  const [step, setStep] = useState<JobPostingSteps>(JobPostingSteps.STEP_1);
  return (
    <>
      <UIBox display="flex" gap={step === JobPostingSteps.STEP_5 ? 30 : 100}>
        <UIBox flexGrow={1}>
          <UIBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" fontWeight={600}>
              {step === JobPostingSteps.STEP_5
                ? 'Double-check your work before publishing'
                : 'Add new job'}
            </Typography>
            {step === JobPostingSteps.STEP_5 && (
              <UIBox display="flex" gap={10}>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => setStep(JobPostingSteps.STEP_1)}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={() => navigate(appRoutes.sponsorMyJobs)}
                >
                  Publish
                </Button>
              </UIBox>
            )}
          </UIBox>
          {step === JobPostingSteps.STEP_1 && (
            <NewJobStep1
              onClickNext={() => setStep(JobPostingSteps.STEP_2)}
              job={job}
              updateJob={() => null}
            />
          )}
          {step === JobPostingSteps.STEP_2 && (
            <NewJobStep2
              job={job}
              onClickPrev={() => setStep(JobPostingSteps.STEP_1)}
              onClickNext={() => setStep(JobPostingSteps.STEP_3)}
              updateJob={() => null}
            />
          )}
          {step === JobPostingSteps.STEP_3 && (
            <NewJobStep3
              job={job}
              onClickPrev={() => setStep(JobPostingSteps.STEP_2)}
              onClickNext={() => setStep(JobPostingSteps.STEP_4)}
              updateJob={() => null}
            />
          )}
          {step === JobPostingSteps.STEP_4 && (
            <NewJobStep4
              job={job}
              updateJob={() => null}
              onClickPrev={() => setStep(JobPostingSteps.STEP_3)}
              onClickNext={() => setStep(JobPostingSteps.STEP_5)}
            />
          )}
          {step === JobPostingSteps.STEP_5 && (
            <JobReviewCard
              onClickGotoStep={(step) => setStep(step)}
              job={job}
            />
          )}
        </UIBox>

        <UIBox display="flex" flexGrow={1} justifyContent="center" mt={5}>
          {step === JobPostingSteps.STEP_5 ? (
            <UIBox width={390}>
              <UIBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <Typography
                  variant="h6"
                  marginBottom={0}
                  color={theme.colors.textPrimary}
                >
                  At a glance
                </Typography>
                <IconButton onClick={() => setStep(JobPostingSteps.STEP_3)}>
                  <IconEdit />
                </IconButton>
              </UIBox>
              <JobProperties job={jobsMock[0]} />
            </UIBox>
          ) : (
            <UIBox maxWidth={320}>
              <Image src="/images/woman-thinking.png" />
              <Typography marginTop={20}>{descriptions[step]}</Typography>
            </UIBox>
          )}
        </UIBox>
      </UIBox>
    </>
  );
};

export default SponsorNewJob;
