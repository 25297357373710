import { IconArrowUpCircle } from '@/assets/icons';
import {
  Autocomplete,
  UIBox,
  Checkbox,
  UIDivider,
  UIGrid,
  IconButton,
  RangeSlider,
  Typography,
} from '@/components/UI';
import { theme } from '@/constants';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.textPurple};
  margin-bottom: 1rem;
`;
const JobFilter: React.FC = () => {
  return (
    <UIBox position="sticky" top={80}>
      <UIBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          color={theme.colors.textPrimary}
          fontSize={18}
          fontWeight={600}
        >
          Filters
        </Typography>
        <IconButton>
          <IconArrowUpCircle />
        </IconButton>
      </UIBox>
      <UIDivider />
      <UIBox>
        <StyledLabel>Skills matching (%)</StyledLabel>
        <RangeSlider min={50} defaultValue={[55, 90]} />
      </UIBox>
      <UIDivider />
      <UIBox>
        <StyledLabel>Category</StyledLabel>
        <UIBox display="flex" flexDirection="column" gap={15}>
          <Checkbox id="sales-procurement" label="Sales & procurement (1655)" />
          <Checkbox
            id="chk-1"
            label="Logistics, warehouse & int-l commerce (20)"
          />
          <Checkbox id="chk-2" label="Transportation & auto industry (90)" />
          <Checkbox id="chk-3" label="IT (190)" />
          <Checkbox id="chk-4" label="Marketing, advertising & PR (113)" />
          <Checkbox id="chk-5" label="Telecommunications (113)" />
        </UIBox>
      </UIBox>

      <UIDivider />

      <UIBox>
        <StyledLabel>Schedule</StyledLabel>
        <UIGrid columns={2} gap={15}>
          <Checkbox id="chk-30" label="Full-time" />
          <Checkbox id="chk-31" label="Contract" />
          <Checkbox id="chk-32" label="Part-time" />
          <Checkbox id="chk-33" label="Freelance" />
        </UIGrid>
      </UIBox>
      <UIDivider />
      <UIBox>
        <StyledLabel>Salary</StyledLabel>
        <UIGrid columns={3} gap={15}>
          <Autocomplete label="From" fullWidth height={40} />
          <Autocomplete label="to" fullWidth height={40} />
        </UIGrid>
      </UIBox>
      <UIDivider />

      <UIBox>
        <StyledLabel>Industry</StyledLabel>
        <UIBox display="flex" flexDirection="column" gap={15}>
          <Checkbox id="chk-10" label="Customer Service" />
          <Checkbox id="chk-11" label="Education" />
          <Checkbox id="chk-12" label="Transportation & auto industry (90)" />
          <Checkbox id="chk-13" label="Real estate (190)" />
          <Checkbox id="chk-14" label="Marketing, advertising & PR (113)" />
          <Checkbox id="chk-15" label="Telecommunications (113)" />
        </UIBox>
      </UIBox>
      <UIDivider />
      <UIBox>
        <StyledLabel>Fits</StyledLabel>
        <UIBox display="flex" flexDirection="column" gap={15}>
          <Checkbox id="chk-20" label="Candidates with no experience" />
          <Checkbox id="chk-21" label="Students" />
          <Checkbox id="chk-22" label="People with disabilities" />
          <Checkbox id="chk-23" label="Veterans" />
        </UIBox>
      </UIBox>
    </UIBox>
  );
};

export default JobFilter;
