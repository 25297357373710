import { theme } from '@/constants';
import styled from 'styled-components';
import { UIBox } from './Box';
import { IconButton } from './Button';
import { IconCircleClose } from '@/assets/icons';

const StyledTag = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  fontSize: 14,
  color: theme.colors.primary,
  background: '#dfe6eb',
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 33,
  position: 'relative',
});

interface TagProps extends React.PropsWithChildren {
  closable?: boolean;
  onClose?: () => void;
}
const Tag: React.FC<TagProps> = ({ closable: editable, children, onClose }) => {
  return (
    <StyledTag>
      {editable && (
        <UIBox position="absolute" top={-10} right={-10}>
          <IconButton onClick={onClose}>
            <IconCircleClose color="#130F26" />
          </IconButton>
        </UIBox>
      )}
      {children}
    </StyledTag>
  );
};
export default Tag;
