export enum ProjectStatus {
  DRAFT = 'DRAFT ',
  POSTED = 'POSTED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
}

export enum ProjectType {
  REMOTE = 'REMOTE',
  HYBRID = 'HYBRID',
  ONSITE = 'ONSITE',
}

export enum EmploymentType {
  FULL_TIME = 'FILL_TIME',
  PART_TIME = 'PART_TIME',
  CONTRACT = 'CONTRACT',
}

export enum ProjectBudgetType {
  HOURLY = 'HOURLY',
  FIXED = 'FIXED',
}
export enum ProjectPostStep {
  TITLE = 'title',
  SKILL = 'skill',
  EMPLOYMENT = 'employment',
  DOCUMENT = 'document',
  REVIEW = 'review',
}

export enum JobDetailValue {
  JOB_POST = 'job_post',
  INVITE = 'invite',
  REVIEW = 'review',
  HIRE = 'hire',
  APPLIED = 'applied',
  INVITED = 'invited',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}

export enum CompanySize {
  MICRO = '1-10',
  SMALL = '11-50',
  MEDIUM_SMALL = '51-200',
  MEDIUM_LARGE = '201-500',
  LARGE = '501-1000',
  ENTERPRISE = '1000+',
}
