import { UIContainer } from '@/components/UI';
import { SponsorNavbar } from '@/components/App/Layout';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const StyledMain = styled.main(({ theme }) => ({
  paddingTop: 80,
  paddingBottom: 20,
  backgroundColor: theme.colors.gray960,
  minHeight: '100vh',
}));

const SponsorLayout: React.FC = () => {
  return (
    <>
      <SponsorNavbar />
      <StyledMain>
        <UIContainer>
          <Outlet />
        </UIContainer>
      </StyledMain>
    </>
  );
};

export default SponsorLayout;
