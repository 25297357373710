import { AppCopyToClipboard, AppModal } from '@/components/App';
import { AppUserAvatar } from '@/components/App';
import {
  IconEmail,
  IconLocation,
  IconPhone,
  IconProfile,
} from '@/assets/icons';
import { UIBox, UICard, Typography, Spinner } from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { UserJson } from '@/types';
import { formatPhoneNumber } from '@/utils';
import { useState } from 'react';
import styled from 'styled-components';

const StyledContactInfoButton = styled.button`
  padding: 0;
  width: fit-content;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${theme.colors.secondary};
  text-decoration: underline;
`;

interface PublicUserInfoProps {
  user?: UserJson.User;
  loading?: boolean;
}
const PublicUserInfo: React.FC<PublicUserInfoProps> = ({ user, loading }) => {
  const [show, setShow] = useState(false);
  const sharedUrl = window.location.origin + appRoutes.publicProfile(user?.id);
  return (
    <UICard p={0} borderRadius={10} borderWidth={0} position="relative">
      <UIBox
        background="url(/images/public-profile-header-background.png)"
        height={120}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        borderTopLeftRadius={10}
        borderTopRightRadius={10}
        position="relative"
        mb={8.5}
      />
      {loading ? (
        <UIBox display="flex" justifyContent="center" mt={-10} p={4}>
          <Spinner size="xl" />
        </UIBox>
      ) : (
        <UIBox
          display="flex"
          gap={30}
          px={2}
          py={1.5}
          zIndex={1}
          position="absolute"
          bottom={0}
          left={0}
          right={0}
        >
          <AppUserAvatar user={user} size={137} />
          <UIBox
            display="flex"
            flexDirection="column"
            gap={10}
            width={'100%'}
            justifyContent="flex-end"
          >
            <Typography variant="h4">{`${user?.firstName} ${user?.lastName}`}</Typography>
            <Typography>{user?.profession}</Typography>
            <StyledContactInfoButton onClick={() => setShow(true)}>
              Contact info
            </StyledContactInfoButton>
          </UIBox>
        </UIBox>
      )}
      <AppModal
        header="Contact Info"
        isOpen={show}
        onClose={() => setShow(false)}
      >
        <UIBox display="flex" flexDirection="column" gap={15}>
          <UIBox display="flex" gap={10} alignItems="center">
            <IconProfile />
            <Typography
              fontSize={14}
              fontWeight={500}
              lineHeight="18px"
              color={theme.colors.textPrimary}
              style={{
                textDecoration: 'underline',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 300,
                whiteSpace: 'nowrap',
              }}
            >
              {sharedUrl}
            </Typography>
            <AppCopyToClipboard value={sharedUrl} />
          </UIBox>
          {user?.phone && (
            <UIBox display="flex" gap={10} alignItems="center">
              <IconPhone />
              <Typography
                fontSize={14}
                fontWeight={500}
                lineHeight="18px"
                color={theme.colors.textPrimary}
              >
                {formatPhoneNumber(user.phone)}
              </Typography>
              <AppCopyToClipboard value={formatPhoneNumber(user.phone) || ''} />
            </UIBox>
          )}
          {(user?.city || user?.state) && (
            <UIBox display="flex" gap={10} alignItems="center">
              <IconLocation />
              <Typography
                fontSize={14}
                fontWeight={500}
                lineHeight="18px"
                color={theme.colors.textPrimary}
              >
                {user.city}, {user.state}
              </Typography>
            </UIBox>
          )}
          <UIBox display="flex" gap={10} alignItems="center">
            <IconEmail />
            <Typography
              fontSize={14}
              fontWeight={500}
              lineHeight="18px"
              color={theme.colors.textPrimary}
            >
              {user?.email}
            </Typography>
            <AppCopyToClipboard value={user?.email || ''} />
          </UIBox>
        </UIBox>
      </AppModal>
    </UICard>
  );
};

export default PublicUserInfo;
