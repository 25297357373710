import { AppUserAvatar } from '@/components/App';
import { UIBox, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { UserJson } from '@/types';

interface SponsorWorkerStatusProps {
  worker?: UserJson.User;
}

const SponsorWorkerStatus: React.FC<SponsorWorkerStatusProps> = ({
  worker,
}) => {
  return (
    <UIBox display="flex" gap={20}>
      <UIBox>
        <AppUserAvatar user={worker} size={78} />
        <UIBox
          mt={0.5}
          borderRadius={33}
          height={25}
          background={theme.colors.green600}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="span"
            color={theme.colors.green300}
            fontSize={12}
            fontWeight={500}
          >
            99% match
          </Typography>
        </UIBox>
      </UIBox>
      <UIBox display="flex" flexDirection="column" gap={10}>
        <UIBox display="flex" alignItems="center" gap={10}>
          <Typography color={theme.colors.textPrimary} fontWeight={500}>
            {worker?.firstName} {worker?.lastName}
          </Typography>
          <UIBox
            borderRadius={33}
            height={22}
            px={0.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={10}
            borderColor={theme.colors.secondary}
            borderWidth={1}
            borderStyle="solid"
          >
            <UIBox
              width={6}
              height={6}
              background={theme.colors.greenLight}
              borderRadius={6}
            />
            <Typography
              color={theme.colors.primary}
              variant="span"
              fontSize={12}
            >
              Available now
            </Typography>
          </UIBox>
        </UIBox>
        <Typography
          color={theme.colors.textPrimary}
          fontSize={14}
          fontWeight={500}
          lineHeight="17px"
        >
          {worker?.profession}
        </Typography>
        <Typography fontSize={12} lineHeight="15px">
          {worker?.city}, {worker?.state}
        </Typography>
        <Typography color={theme.colors.textPrimary} fontWeight={500}>
          ${worker?.hourlyRate}/hour
        </Typography>
      </UIBox>
    </UIBox>
  );
};

export default SponsorWorkerStatus;
