import ReactSelect, {
  ActionMeta,
  GroupBase,
  Props as ReactSelectProps,
} from 'react-select';
import { appConfig, theme } from '@/constants';
import styled from 'styled-components';
import Label from './Label';
import { UIBox } from './Box';
import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { AppTypes } from '@/types';
import { StatusAlert } from './Typography';
import { IconEye, IconEyeSlash } from '@/assets/icons';
type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'placeholder' | 'height'
>;
type CustomProps = {
  variant?: 'primary' | 'secondary' | 'transparent';
  fullWidth?: boolean;
  height?: number;
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
  status?: 'error' | 'info' | 'valid';
  statusLabel?: string;
  statusLabelPosition?: 'absolute';
  type?: HTMLInputTypeAttribute;
  labelSize?: number;
};

const StyledPlaceholderContainer = styled.div<{ $variant: string }>`
  position: absolute;
  pointer-events: none;
  left: 22px;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: ${({ $variant }) =>
    $variant === 'transparent' ? theme.colors.gray700 : theme.colors.icon};
`;
const sharedStyle = ({ fullWidth, variant, status }: CustomProps) => `
  font-family: Gantari;
  padding: 10px 16px;
  width: ${fullWidth ? '100%' : 'initial'};
  color: ${variant === 'transparent' ? theme.colors.white : theme.colors.textPrimary};
  border-radius: ${variant === 'transparent' ? '12px' : '8px'};
  border: solid 1px ${status === 'error' ? theme.colors.red500 : status === 'info' ? theme.colors.blue300 : status === 'valid' ? theme.colors.gray800 : variant === 'transparent' ? '#FFFFFF12' : 'transparent'};
  background: ${variant === 'transparent' ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07))' : status ? theme.colors.white : theme.colors.gray980};
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  &::placeholder {
    color: ${variant === 'transparent' ? theme.colors.gray700 : theme.colors.icon};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  &:focus {
    border: solid 1px ${variant === 'transparent' ? theme.colors.white : theme.colors.blue};
  }
  &:disabled {
    border: none;
    background: ${theme.colors.gray980};
    opacity: 0.6;
  }
`;
const StyledInput = styled(
  forwardRef<HTMLInputElement, CustomProps & InputProps>(
    ({ fullWidth, variant, placeholder, height, ...rest }, ref) => (
      <input ref={ref} {...rest} />
    )
  )
)<CustomProps>`
  ${(props) => sharedStyle(props)}
  height: ${(props) => props.height}px;
`;

const StyledEyeButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;
type CustomTextFieldProps = InputProps & CustomProps;

export const Input: React.FC<CustomTextFieldProps> = ({
  label,
  variant = 'primary',
  placeholder,
  value,
  status,
  statusLabel,
  statusLabelPosition,
  height = 44,
  labelSize = 16,
  type,
  onChange,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleOnChange = (
    event?: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    // @ts-ignore
    onChange?.(event);
  };
  const component = (
    <StyledInput
      type={showPassword ? 'text' : type}
      value={value}
      onChange={handleOnChange}
      variant={variant}
      status={status ? status : value ? 'valid' : undefined}
      height={height}
      {...(props as InputProps)}
    />
  );
  return (
    <UIBox width={props.fullWidth ? '100%' : undefined} position="relative">
      {label && (
        <Label
          fontSize={labelSize}
          variant={variant === 'primary' ? 'primary' : 'secondary'}
        >
          {label}
        </Label>
      )}
      <UIBox position="relative" width={props.fullWidth ? '100%' : undefined}>
        {component}
        {placeholder && !value && (
          <StyledPlaceholderContainer $variant={variant}>
            {placeholder}
          </StyledPlaceholderContainer>
        )}
        {type === 'password' && (
          <StyledEyeButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            tabIndex={-1}
          >
            {showPassword ? <IconEyeSlash /> : <IconEye />}
          </StyledEyeButton>
        )}
      </UIBox>
      {statusLabel && (
        <UIBox position={statusLabelPosition} bottom={-18}>
          <StatusAlert status={status}>{statusLabel}</StatusAlert>
        </UIBox>
      )}
    </UIBox>
  );
};

type SelectType = Omit<CustomProps, 'multiline'>;
type AutocompleteProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = SelectType &
  ReactSelectProps<Option, IsMulti, Group> & {
    status?: 'error' | 'info' | 'valid';
    statusLabel?: string;
  };

export function Autocomplete<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  variant,
  fullWidth,
  label,
  height = 44,
  value,
  labelSize = 16,
  onChange,
  status,
  statusLabel,
  ...rest
}: AutocompleteProps<Option, IsMulti, Group>) {
  const handleOnChange = (
    newValue: Option | readonly Option[] | null,
    actionMeta: ActionMeta<Option>
  ) => {
    // @ts-ignore
    onChange?.(newValue, actionMeta);
  };
  const component = (
    <ReactSelect
      value={value}
      onChange={handleOnChange}
      {...rest}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          height,
          borderRadius: 8,
          outline: 'none',
          background:
            variant === 'transparent'
              ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07))'
              : value
                ? theme.colors.white
                : theme.colors.gray980,
          border: `solid 1px ${
            status === 'error'
              ? theme.colors.red500
              : status === 'info'
                ? theme.colors.blue300
                : theme.colors.gray800
          }`,
          '&:hover': {
            borderWidth: 1,
          },
          '&:focus': {
            boxShadow: 'none',
            outline: 'none',
            border: `solid 1px ${variant === 'transparent' ? theme.colors.white : theme.colors.blue}`,
            background: theme.colors.white,
          },
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          paddingLeft: 20,
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          fontFamily: 'Gantari',
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '24px',
          color:
            variant === 'transparent'
              ? theme.colors.white
              : theme.colors.textPrimary,
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none',
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          color:
            variant === 'transparent'
              ? theme.colors.white
              : theme.colors.textPrimary,
          fontFamily: 'Gantari',
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '24px',
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          color:
            variant === 'transparent'
              ? theme.colors.gray700
              : theme.colors.icon,
          fontFamily: 'Gantari',
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '24px',
        }),
      }}
    />
  );
  return (
    <UIBox width={fullWidth ? '100%' : undefined}>
      {label && (
        <Label
          fontSize={labelSize}
          variant={variant === 'primary' ? 'primary' : 'secondary'}
        >
          {label}
        </Label>
      )}
      {component}
      {statusLabel && <StatusAlert status={status}>{statusLabel}</StatusAlert>}
    </UIBox>
  );
}

function convertAddressToLocation(
  addressComponents: google.maps.GeocoderAddressComponent[]
): AppTypes.Location {
  let location: Partial<AppTypes.Location> = {
    address1: '',
    address2: '',
    zipcode: '',
    city: '',
    state: '',
    country: '',
  };

  for (const component of addressComponents) {
    if (component.types.includes('street_number')) {
      location.address1 = `${component.long_name}`;
    } else if (component.types.includes('route')) {
      location.address1 = location.address1
        ? `${location.address1} ${component.long_name}`
        : component.long_name;
    } else if (component.types.includes('neighborhood')) {
      location.address2 = component.long_name;
    } else if (component.types.includes('locality')) {
      location.city = component.long_name;
    } else if (component.types.includes('administrative_area_level_1')) {
      location.state = component.long_name;
    } else if (component.types.includes('country')) {
      location.country = component.long_name;
    } else if (component.types.includes('postal_code')) {
      location.zipcode = component.long_name;
    }
  }

  return location as AppTypes.Location; // Cast to Location now that all required fields are expected to be present
}

interface LocationAutocompleteProps
  extends CustomProps,
    Omit<InputProps, 'onChange'> {
  value?: string;
  types?: string[];
  onChange?: (
    formattedAddress: string,
    position: AppTypes.Position,
    location: AppTypes.Location
  ) => void;
}

export const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({
  label,
  variant = 'primary',
  placeholder,
  value,
  status,
  statusLabel,
  height = 44,
  labelSize = 16,
  onChange,
  types,
  ...props
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: appConfig.GOOGLE_MAP_API_KEY,
    libraries: appConfig.libraries,
  });
  const autocompleteInput = useRef<HTMLInputElement>(null);
  const autocomplete = useRef<google.maps.places.Autocomplete>();
  useEffect(() => {
    if (!isLoaded || !autocompleteInput.current) return;
    autocomplete.current = new google.maps.places.Autocomplete(
      autocompleteInput.current,
      { types }
    );

    autocomplete.current.addListener('place_changed', () => {
      const place = autocomplete.current?.getPlace();
      const location = convertAddressToLocation(
        place?.address_components || []
      );
      const address = place?.formatted_address || '';
      onChange?.(
        address,
        {
          lat: place?.geometry?.location?.lat() || -3,
          lng: place?.geometry?.location?.lng() || -30,
        },
        location
      );
    });
  }, [isLoaded, autocompleteInput]);
  if (!isLoaded) return <>loading</>;
  return (
    <UIBox width={props.fullWidth ? '100%' : undefined}>
      {label && (
        <Label
          fontSize={labelSize}
          variant={variant === 'primary' ? 'primary' : 'secondary'}
        >
          {label}
        </Label>
      )}
      {!isLoaded && 'Loading...'}
      {isLoaded && (
        <UIBox position="relative" width={props.fullWidth ? '100%' : undefined}>
          <StyledInput
            ref={autocompleteInput}
            variant={variant}
            status={status ? status : value ? 'valid' : undefined}
            height={height}
            defaultValue={value}
            {...(props as InputProps)}
          />
          {placeholder && !value && (
            <StyledPlaceholderContainer $variant={variant}>
              {placeholder}
            </StyledPlaceholderContainer>
          )}
          {statusLabel && (
            <StatusAlert status={status}>{statusLabel}</StatusAlert>
          )}
        </UIBox>
      )}
    </UIBox>
  );
};
