export enum RoleType {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
}

export enum WebViewType {
  SUPER_ADMIN = 'SUPER_ADMIN',
  SITE_ADMIN = 'SITE_ADMIN',
  ORG_ADMIN = 'ORG_ADMIN',
  SPONSOR = 'SPONSOR',
  WORKER = 'WORKER',
  MENTOR = 'MENTOR',
}

export enum RoleNames {
  WORKER = 'WORKER',
  MENTOR = 'MENTOR',
  SPONSOR = 'SPONSOR',
  ORG_SPONSOR = 'ORG_SPONSOR',
  ORG_ADMIN = 'ORG_ADMIN',
  SITE_ADMIN = 'SITE_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum Provider {
  WEB_FORM = 'WEB_FORM',
  GOOGLE = 'GOOGLE',
  LINKEDIN = 'LINKEDIN',
  FACEBOOK = 'FACEBOOK',
}
