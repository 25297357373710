import { theme } from '@/constants';
import { AppTypes } from '@/types';

const IconRoundedArrowDown: React.FC<
  AppTypes.IconProps & { opacity?: number }
> = ({ width = 11, height = 8, color = theme.colors.gray500, opacity = 1 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity}
        d="M4.24531 7.36749C4.19727 7.3264 3.99186 7.17123 3.82289 7.0267C2.7602 6.17934 1.02081 3.96883 0.489877 2.81185C0.404564 2.63614 0.223998 2.19191 0.212402 1.95457C0.212402 1.72714 0.272039 1.51034 0.392968 1.30346C0.561938 1.04556 0.827816 0.838683 1.14174 0.725324C1.35957 0.652348 2.01143 0.538989 2.02303 0.538989C2.73618 0.425629 3.89495 0.363281 5.17547 0.363281C6.39553 0.363281 7.50709 0.425629 8.23101 0.518442C8.2426 0.52907 9.05267 0.642429 9.33014 0.766416C9.83705 0.993844 10.1518 1.43807 10.1518 1.91347V1.95457C10.1394 2.26418 9.82462 2.91529 9.81303 2.91529C9.28127 4.00992 7.62719 6.16942 6.52806 7.03733C6.52806 7.03733 6.24561 7.28176 6.06919 7.38803C5.81574 7.55382 5.50182 7.63601 5.1879 7.63601C4.83753 7.63601 4.51119 7.5432 4.24531 7.36749Z"
        fill={color}
      />
    </svg>
  );
};

export default IconRoundedArrowDown;
