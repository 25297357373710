import {
  WorkerMyJobRouteCategory,
  WorkerJobRouteCategory,
  SponsorJobRouteCategory,
} from '@/enums';

const appRoutes = {
  home: '/',

  error: '/error',
  error404: '/error/404',
  error500: '/error/500',
  error403: '/error/503',

  login: '/auth/login',
  register: '/auth/register',
  registerWorker: '/auth/register/worker',
  registerSponsor: '/auth/register/sponsor',
  registerOrganization: '/auth/register/organization',
  registerOrgGoogle: '/auth/register/org-google',
  forgotPassword: '/auth/forgot-password',
  authVerifyCode: '/auth/verify',
  authAccountCreated: '/auth/account-created',
  authResetPassword: '/auth/reset-password',
  authChangePassword: '/auth/change-password',
  authPasswordChanged: '/auth/password-changed',

  authWorkerUploadResume: '/auth/upload-resume',

  authCompanyLocation: '/auth/company-location',
  authCompanyLogo: '/auth/company-logo',
  authCompanyInfo: '/auth/company-info',

  workerProfile: '/w/profile',
  workerPublicProfile: '/w/public',
  workerSettings: '/w/settings',
  workerPreference: '/w/preference',
  workerFindWork: (type: WorkerJobRouteCategory = WorkerJobRouteCategory.ALL) =>
    `/w/jobs/${type}`,
  workerJobDetail: (id: number | string = ':id') => `/w/job/${id}`,
  workerResumes: `/w/resume`,
  workerResume: (id: number | string = ':id') => `/w/resume/${id}`,
  workerMyJobs: (
    type: WorkerMyJobRouteCategory = WorkerMyJobRouteCategory.APPLIED
  ) => `/w/my-jobs/${type}`,
  workerCompanyDetail: (id: number | string = ':id') => `/w/company/${id}`,
  workerNotifications: '/w/notifications',
  workerMessages: '/w/messages',

  sponsorNewJob: '/s/new-job',
  sponsorMyJobs: '/s/my-jobs',
  sponsorProfile: '/s/profile',
  sponsorPublicProfile: '/s/public',
  sponsorPreference: '/s/preference',
  sponsorSettings: '/s/settings',
  sponsorJobDetail: (
    id: number | string = ':id',
    category: SponsorJobRouteCategory | '' = ''
  ) => `/s/job/${id}/${category}`,
  sponsorWorkerProfile: (id: number | string = ':id') => `/s/workers/${id}`,
  sponsorNotifications: '/s/notifications',
  sponsorMessages: '/s/messages',

  orgDashboard: '/o/dashboard',
  orgMessages: '/o/messages',
  orgNotifications: '/o/notifications',
  orgSponsors: '/o/sponsors',
  orgWorkers: '/o/workers',
  orgWorkerProfile: (id: number | string = ':id') => `/o/workers/${id}`,
  orgProjects: '/o/projects',
  orgProjectDetail: (
    id: number | string = ':id',
    category: SponsorJobRouteCategory | '' = ''
  ) => `/o/projects/${id}/${category}`,
  orgProfile: '/o/profile',
  orgPreference: '/o/preference',
  orgSettings: '/o/settings',

  adminDashboard: '/admin/dashboard',
  adminMessages: '/admin/messages',
  adminNotifications: '/admin/notifications',
  adminWorkers: '/admin/workers',
  adminSponsors: '/admin/sponsors',
  adminMentors: '/admin/mentors',
  adminInvitees: '/admin/invitees',
  adminWorkerProfile: (id: number | string = ':id') => `/admin/workers/${id}`,
  adminOrganizations: '/admin/organizations',
  adminOrganizationProfile: (id: number | string) =>
    `/admin/organizations/${id}`,
  adminProjects: '/admin/projects',
  adminProjectDetail: (
    id: number | string = ':id',
    category: SponsorJobRouteCategory | '' = ''
  ) => `/admin/projects/${id}/${category}`,
  adminIndustries: '/admin/industries',
  adminSkills: '/admin/skills',
  adminAssessments: '/admin/assessments',
  adminPreference: '/admin/preference',
  adminProfile: '/admin/profile',
  adminSettings: '/admin/settings',

  publicProfile: (id = ':id') => `/public/profile/${id}`,
};

export default appRoutes;
