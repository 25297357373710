import {
  UIBox,
  Button,
  UICard,
  Typography,
  LocationAutocomplete,
  Spinner,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { useAuth } from '@/hooks';
import { useUpdateOrganizationMutation } from '@/redux/apis';
import { AppTypes } from '@/types';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const validationSchema = yup.object({
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
});
const initialValues: AppTypes.Location = {
  address1: '',
  address2: '',
  zipcode: '',
  city: '',
  state: '',
  country: '',
};
export default function AuthCompanyLocationPage() {
  const [updateOrg, { isLoading, isSuccess }] = useUpdateOrganizationMutation();
  const { org, refetchUser } = useAuth();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (v) => {
      const orgId = org?.id;
      if (!orgId) return;
      updateOrg({
        id: orgId,
        ...v,
      });
    },
  });
  useEffect(() => {
    if (!isLoading && isSuccess) {
      refetchUser();
      navigate(appRoutes.authCompanyLogo);
    }
  }, [isLoading, isSuccess]);
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={520}
        px={4}
        py={2}
        borderRadius={32}
        boxShadow="0px 4px 35px 0px #00000014"
      >
        <Typography
          variant="h3"
          textAlign="center"
          lineHeight="35px"
          marginBottom={0}
          fontSize={26}
        >
          Where is company location?
        </Typography>
        <Typography
          textAlign="center"
          fontWeight={400}
          marginBottom={30}
          color={theme.colors.primary}
        >
          We'll recommend people near you to help you excel.
        </Typography>
        <UIBox mb={2} width="100%">
          <LocationAutocomplete
            fullWidth
            label="Location"
            onChange={(formattedAddress, position, location) =>
              formik.setValues(location)
            }
            onBlur={() => formik.setFieldTouched('state')}
            status={
              formik.touched.state &&
              (formik.errors.country ||
                formik.errors.state ||
                formik.errors.city)
                ? 'error'
                : undefined
            }
            statusLabel={
              (formik.touched.state &&
                (formik.errors.country ||
                  formik.errors.state ||
                  formik.errors.city)) ||
              ''
            }
          />
        </UIBox>
        <Button
          fullWidth
          rounded
          onClick={() => formik.handleSubmit()}
          disabled={isLoading}
        >
          {isLoading && <Spinner size="sm" />}
          Next
        </Button>
      </UICard>
    </UIBox>
  );
}
