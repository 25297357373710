import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconUserPlus({
  width = 10,
  height = 10,
  color = theme.colors.blue300,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      className="fill-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 5.27684C5.12729 5.27684 6.23132 4.15853 6.23132 2.76342C6.23132 1.36831 5.12729 0.25 3.75 0.25C2.37271 0.25 1.26868 1.36831 1.26868 2.76342C1.26868 4.15853 2.37271 5.27684 3.75 5.27684ZM3.75 6.5076C1.72711 6.5076 0 6.83103 0 8.12322C0 9.41491 1.7166 9.75 3.75 9.75C5.77239 9.75 7.5 9.42657 7.5 8.13437C7.5 6.84218 5.7834 6.5076 3.75 6.5076ZM8.94897 3.79393H9.55051C9.79812 3.79393 10 3.99865 10 4.24974C10 4.50082 9.79812 4.70555 9.55051 4.70555H8.94897V5.29419C8.94897 5.54528 8.7476 5.75 8.49949 5.75C8.25188 5.75 8.05 5.54528 8.05 5.29419V4.70555H7.44949C7.20137 4.70555 7 4.50082 7 4.24974C7 3.99865 7.20137 3.79393 7.44949 3.79393H8.05V3.20581C8.05 2.95472 8.25188 2.75 8.49949 2.75C8.7476 2.75 8.94897 2.95472 8.94897 3.20581V3.79393Z"
        fill={color}
      />
    </svg>
  );
}
