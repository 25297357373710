import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconFilter({
  width = 13,
  height = 13,
  color = theme.colors.companyBrand,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58012 0.25H11.4199C12.1548 0.25 12.75 0.863158 12.75 1.62016V2.57735C12.75 2.94458 12.6065 3.29723 12.3517 3.55442L8.28604 7.66689C8.21589 7.73848 8.12066 7.77825 8.0222 7.77759L4.61808 7.76698C4.51448 7.76698 4.41602 7.72257 4.34524 7.64501L0.609073 3.53586C0.378057 3.28198 0.25 2.94657 0.25 2.59856V1.62082C0.25 0.863821 0.845238 0.25 1.58012 0.25ZM4.80006 8.89009L7.83417 8.89937C8.02336 8.90003 8.17651 9.05846 8.17651 9.25268V10.9596C8.17651 11.1545 8.06583 11.3308 7.89337 11.4123L5.13919 12.7056C5.07484 12.7354 5.00663 12.75 4.93842 12.75C4.84768 12.75 4.75695 12.7235 4.6778 12.6711C4.53945 12.5796 4.45579 12.4219 4.45579 12.2528V9.2434C4.45579 9.04785 4.61023 8.88943 4.80006 8.89009Z"
        fill={color}
      />
    </svg>
  );
}
