import { useState, useEffect } from 'react';

interface UseScrollTriggerOptions {
  threshold?: number;
  disableHysteresis?: boolean;
}

const useScrollTrigger = (options: UseScrollTriggerOptions = {}): boolean => {
  const { threshold = 100, disableHysteresis = false } = options;
  const [trigger, setTrigger] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = (): void => {
      // Get the current scroll position
      const currentScroll: number = window.pageYOffset;

      if (!disableHysteresis) {
        // Implement hysteresis (lag before triggering)
        if (currentScroll > threshold && !trigger) {
          setTrigger(true);
        } else if (currentScroll <= threshold && trigger) {
          setTrigger(false);
        }
      } else {
        // No hysteresis, trigger immediately when threshold is crossed
        setTrigger(currentScroll > threshold);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [trigger, threshold, disableHysteresis]);

  return trigger;
};

export default useScrollTrigger;
