import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconPhone({
  width = 13,
  height = 13,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20734 6.79524C8.70052 9.28773 9.26611 6.4042 10.8535 7.99051C12.3839 9.52047 13.2635 9.827 11.3245 11.7655C11.0816 11.9606 9.53851 14.3089 4.11538 8.88729C-1.30842 3.465 1.03849 1.92028 1.23373 1.67747C3.17742 -0.266346 3.47866 0.618364 5.00906 2.14833C6.59648 3.73531 3.71416 4.30276 6.20734 6.79524Z"
        fill={color}
      />
    </svg>
  );
}
