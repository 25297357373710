import { IconSearch } from '@/assets/icons';
import {
  UIBox,
  Button,
  UICard,
  UIGrid,
  Typography,
  Autocomplete,
  Tag,
} from '@/components/UI';
import { theme } from '@/constants';
import { JobPostingSteps } from '@/enums';
import { ProjectJson } from '@/types';

const skills = ['GitHub', 'SQL', 'REST API'];
interface NewJobStep2Props {
  job: ProjectJson.Project;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  updateJob: (updated: Partial<ProjectJson.Project>) => void;
}
const NewJobStep2: React.FC<NewJobStep2Props> = ({
  onClickNext,
  onClickPrev,
}) => {
  return (
    <UICard
      id={JobPostingSteps.STEP_2}
      borderRadius={10}
      display="flex"
      flexDirection="column"
      gap={20}
      p={2}
    >
      <Typography variant="h6" marginBottom={0}>
        Skills
      </Typography>
      <UIGrid columns={2} gap={20}>
        <Autocomplete label="Category" fullWidth />
        <Autocomplete label="Sector" fullWidth />
      </UIGrid>
      <Autocomplete
        label="Search Skills or add your own"
        fullWidth
        placeholder={
          <UIBox display="flex" gap={10}>
            <IconSearch /> Search
          </UIBox>
        }
      />
      <UIBox display="flex" flexWrap="wrap" gap={10}>
        {skills.map((skill, id) => (
          <Tag key={id} closable>
            {skill}
          </Tag>
        ))}
      </UIBox>
      {onClickNext && (
        <UIBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <UIBox display="flex" alignItems="center" gap={20}>
            <Button variant="secondary" onClick={onClickPrev} size="sm">
              Back
            </Button>
            <Typography>
              <Typography variant="span" color={theme.colors.textPrimary}>
                2
              </Typography>
              /5
            </Typography>
          </UIBox>
          <Button size="sm" onClick={onClickNext}>
            Next
          </Button>
        </UIBox>
      )}
    </UICard>
  );
};

export default NewJobStep2;
