import {
  IconFacebookGray,
  IconInstagram,
  IconLinkedinGray,
  IconLogo,
  IconTwitter,
} from '@/assets/icons';
import {
  UIGrid,
  UIContainer,
  UIFlexColumnBox,
  UIFlexSpaceBox,
} from '@/components/UI';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function HomeFooterNavbar() {
  return (
    <StyledNavbarContainer>
      <UIContainer>
        <UIFlexSpaceBox>
          <UIFlexColumnBox>
            <IconLogo />
          </UIFlexColumnBox>
          <StyledNavLinkContainer>
            <StyledNavLink to={'#welcome'}>Home</StyledNavLink>
            <StyledNavLink to={'#about_us'}>About us</StyledNavLink>
            <StyledNavLink to={'#services'}>Services</StyledNavLink>
            <StyledNavLink to={'#contact_us'}>Contact</StyledNavLink>
          </StyledNavLinkContainer>
          <UIGrid gap={10} columns={4}>
            <StyledSocialLink>
              <IconFacebookGray />
            </StyledSocialLink>
            <StyledSocialLink>
              <IconInstagram />
            </StyledSocialLink>
            <StyledSocialLink>
              <IconTwitter />
            </StyledSocialLink>
            <StyledSocialLink>
              <IconLinkedinGray />
            </StyledSocialLink>
          </UIGrid>
        </UIFlexSpaceBox>
      </UIContainer>
    </StyledNavbarContainer>
  );
}

const StyledNavbarContainer = styled.div({
  marginTop: 100,
  marginBottom: 30,
  paddingBottom: 20,
  backgroundColor: 'transparent',
  transitionDelay: '300ms',
  transitionProperty: 'all',
  transitionTimingFunction: 'linear',
});

const StyledNavLinkContainer = styled.div({
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
  border: '1px solid #FFFFFF26',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: 45,
  width: 620,
  borderRadius: 30,
  paddingLeft: 20,
  paddingRight: 20,
});

const StyledNavLink = styled(Link)(({ theme: { colors } }) => ({
  color: colors.white,
  fontSize: '1rem',
  textDecoration: 'none',
  fontWeight: 400,
}));

const StyledSocialLink = styled.a(({ theme: { colors } }) => ({
  color: colors.white,
  fontSize: '1rem',
  textDecoration: 'none',
  fontWeight: 400,
  width: 34,
  height: 34,
  borderRadius: 34,
  boxShadow: '0px 4px 12px 0px #BDBDBD26',
  background: '#FFFFFF26',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
