import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconRightArrow({
  width = 21,
  height = 8,
  color = theme.colors.white,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.87 7.25C16.17 6.61 16.46 6.05 16.74 5.57C17.04 5.09 17.33 4.69 17.61 4.37H0.12V3.11H17.61C17.33 2.77 17.04 2.36 16.74 1.88C16.46 1.4 16.17 0.849999 15.87 0.229999H16.92C18.18 1.69 19.5 2.77 20.88 3.47V4.01C19.5 4.69 18.18 5.77 16.92 7.25H15.87Z"
        fill={color}
      />
    </svg>
  );
}
