import {
  UIBox,
  Button,
  UICard,
  Image,
  Link,
  Typography,
  StatusAlert,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { useAuth } from '@/hooks/useAuth';
import {
  useAuthenticateMutation,
  useResendVerificationCodeMutation,
  useVerifyMutation,
} from '@/redux/apis';

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import { Provider, RoleNames } from '@/enums';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

export default function Verify() {
  const credentials = useSelector<RootState>(
    (state) => state.registerState
  ) as { email: string; password: string };
  const { setAuth } = useAuth();
  const [authenticate, { data: authData, isLoading: isAuthenticating }] =
    useAuthenticateMutation();
  const [verify, { data, error, isLoading }] = useVerifyMutation();
  const [
    resend,
    { data: resendData, error: resendError, isLoading: isResending },
  ] = useResendVerificationCodeMutation();
  const [resent, setResent] = useState(false);
  const [searchParam] = useSearchParams();
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  const handleVerify = async () => {
    const userId = `${searchParam.get('userId') || '0'}`;
    await verify({ code, userId });
  };
  const handleResend = async () => {
    const userId = parseInt(`${searchParam.get('userId') || '0'}`);
    await resend({ userId });
  };
  useEffect(() => {
    if (!isResending && !resendError && resendData) {
      setResent(true);
      setTimeout(() => setResent(false), 3000);
    }
  }, [resendData, resendError, isResending]);
  useEffect(() => {
    if (data && !isLoading) {
      const signIn = async () => {
        await authenticate({
          identifier: credentials.email,
          password: credentials.password,
          provider: Provider.WEB_FORM,
          rememberMe: false,
        });
      };
      signIn();
    }
  }, [data, error, isLoading, credentials]);
  useEffect(() => {
    if (!isAuthenticating && authData) {
      setAuth(authData);
      navigate(
        authData.roleName === RoleNames.ORG_ADMIN
          ? appRoutes.authCompanyLocation
          : appRoutes.authWorkerUploadResume
      );
    }
  }, [authData, isAuthenticating]);
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={580}
        p={3}
        borderRadius={32}
        boxShadow="0px 4px 35px 0px #00000014"
      >
        <Typography variant="h3" textAlign="center" marginBottom={10}>
          We sent you the verification code to your email address
        </Typography>
        <Image src="/images/img_woman_phone_check.png" />
        <Typography
          color={theme.colors.textPrimary}
          fontSize={16}
          marginBottom={10}
          marginTop={20}
        >
          Enter verification code
        </Typography>
        <VerificationInput
          value={code}
          length={6}
          onChange={(v) => setCode(v)}
          validChars="0-9"
          placeholder=""
          onComplete={(value) => {
            setCode(value);
          }}
          classNames={{
            container: 'verify-container',
            character: 'verify-character',
            characterInactive: 'verify-character--inactive',
            characterSelected: 'verify-character--selected',
            characterFilled: 'verify-character--filled',
          }}
        />
        {error && (
          <UIBox maxWidth={290}>
            <StatusAlert status="error">
              Sorry, we're currently unable to verify your account. Please
              double-check your details or contact support for assistance.
            </StatusAlert>
          </UIBox>
        )}
        <Typography
          fontSize={16}
          textAlign="center"
          marginBottom={30}
          marginTop={10}
        >
          Didn't receive a code?
          <br />
          <Link to={'#'} onClick={() => handleResend()}>
            {resent ? 'Resent' : isResending ? 'Resending' : 'Resend'}
          </Link>
        </Typography>

        <UIBox width={300}>
          <Button
            fullWidth
            rounded
            onClick={handleVerify}
            disabled={isLoading || isAuthenticating || code.length !== 6}
          >
            Verify
          </Button>
        </UIBox>
      </UICard>
    </UIBox>
  );
}
