import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconLock({
  width = 9,
  height = 11,
  color = theme.colors.blue300,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 11"
      fill="none"
      className="fill-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02864 3.39114V4.09392C7.81805 4.34032 8.39323 5.05495 8.39323 5.90849V8.17123C8.39323 9.22791 7.51719 10.0846 6.43716 10.0846H2.55809C1.4776 10.0846 0.601562 9.22791 0.601562 8.17123V5.90849C0.601562 5.05495 1.17721 4.34032 1.96615 4.09392V3.39114C1.97081 2.02475 3.10253 0.917969 4.49041 0.917969C5.89691 0.917969 7.02864 2.02475 7.02864 3.39114ZM4.49898 1.71468C5.44441 1.71468 6.21287 2.4662 6.21287 3.39079V3.99473H2.78044V3.38168C2.78509 2.46164 3.55355 1.71468 4.49898 1.71468ZM4.90422 7.54337C4.90422 7.76655 4.72259 7.94418 4.49438 7.94418C4.27083 7.94418 4.08919 7.76655 4.08919 7.54337V6.53224C4.08919 6.31362 4.27083 6.13599 4.49438 6.13599C4.72259 6.13599 4.90422 6.31362 4.90422 6.53224V7.54337Z"
        fill={color}
      />
    </svg>
  );
}
