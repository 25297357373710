import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconInstagram({
  width = 14,
  height = 14,
  color = theme.colors.white,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_449_2319)">
        <path
          d="M9.91459 0.5H3.95257C2.01217 0.5 0.433594 2.07858 0.433594 4.01898V9.9811C0.433594 11.9214 2.01217 13.5 3.95257 13.5H9.91469C11.855 13.5 13.4336 11.9214 13.4336 9.9811V4.01898C13.4336 2.07858 11.855 0.5 9.91459 0.5V0.5ZM12.6715 9.9811C12.6715 11.5012 11.4348 12.7379 9.91459 12.7379H3.95257C2.43241 12.7379 1.19571 11.5012 1.19571 9.9811V4.01898C1.19571 2.49881 2.43241 1.26211 3.95257 1.26211H9.91469C11.4348 1.26211 12.6715 2.49881 12.6715 4.01898V9.9811Z"
          fill={color}
        />
        <path
          d="M6.93358 3.44531C4.97355 3.44531 3.379 5.03986 3.379 6.99989C3.379 8.95993 4.97355 10.5545 6.93358 10.5545C8.89361 10.5545 10.4882 8.95993 10.4882 6.99989C10.4882 5.03986 8.89361 3.44531 6.93358 3.44531ZM6.93358 9.79236C5.39388 9.79236 4.14111 8.53969 4.14111 6.99989C4.14111 5.46019 5.39388 4.20743 6.93358 4.20743C8.47338 4.20743 9.72605 5.46019 9.72605 6.99989C9.72605 8.53969 8.47338 9.79236 6.93358 9.79236Z"
          fill={color}
        />
        <path
          d="M10.5732 2.18262C9.99394 2.18262 9.52283 2.65383 9.52283 3.23295C9.52283 3.81218 9.99394 4.28339 10.5732 4.28339C11.1524 4.28339 11.6236 3.81218 11.6236 3.23295C11.6236 2.65373 11.1524 2.18262 10.5732 2.18262ZM10.5732 3.52118C10.4143 3.52118 10.2849 3.39184 10.2849 3.23295C10.2849 3.07397 10.4143 2.94473 10.5732 2.94473C10.7322 2.94473 10.8615 3.07397 10.8615 3.23295C10.8615 3.39184 10.7322 3.52118 10.5732 3.52118Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_449_2319">
          <rect
            width="13"
            height="13"
            fill={color}
            transform="translate(0.433594 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
