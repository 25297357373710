import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconClose({
  width = 15,
  height = 15,
  color = theme.colors.companyBrand,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.87422 7.49989L14.7149 13.3409C15.095 13.7208 15.095 14.3351 14.7149 14.7151C14.335 15.095 13.7207 15.095 13.3408 14.7151L7.49991 8.87405L1.65921 14.7151C1.27911 15.095 0.665002 15.095 0.285077 14.7151C-0.0950257 14.3351 -0.0950257 13.7208 0.285077 13.3409L6.12578 7.49989L0.285077 1.65889C-0.0950257 1.27896 -0.0950257 0.66466 0.285077 0.284728C0.474417 0.0952063 0.72337 0 0.972145 0C1.22092 0 1.4697 0.0952063 1.65921 0.284728L7.49991 6.12572L13.3408 0.284728C13.5303 0.0952063 13.7791 0 14.0279 0C14.2766 0 14.5254 0.0952063 14.7149 0.284728C15.095 0.66466 15.095 1.27896 14.7149 1.65889L8.87422 7.49989Z"
        fill={color}
      />
    </svg>
  );
}
