export enum UserStatus {
  DEFAULT = 'DEFAULT',
  PENDING = 'PENDING',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
  REJECTED = 'REJECTED',
}
export const allUserStatuses: UserStatus[] = [
  UserStatus.DEFAULT,
  UserStatus.PENDING,
  UserStatus.BLOCKED,
  UserStatus.DELETED,
  UserStatus.REJECTED,
];
export enum Ability {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
  EXPERT = 'EXPERT',
}

export enum UserProjectStatus {
  APPLIED = 'APPLIED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
  INVITED = 'INVITED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum MentorStatus {
  DEFAULT = 'DEFAULT',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}
