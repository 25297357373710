import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconSend1({
  width = 24,
  height = 24,
  color = theme.colors.secondary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4281 5.68793L12.4219 21.5361L11.0073 12.7292L3.94684 7.12286L23.4281 5.68793Z"
        fill={color}
      />
    </svg>
  );
}
