import { AppSideModal, AppUserAvatar } from '@/components/App';
import {
  IconArrowSquareOut,
  IconCurrencyCircleDollar,
  IconDelete,
  IconTimeCircle,
  IconWork,
} from '@/assets/icons';
import {
  UIBox,
  Button,
  UICard,
  IconButton,
  Radio,
  Typography,
} from '@/components/UI';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { appRoutes, theme } from '@/constants';
import FileDropzone from '@/modules/Shared/FileDropzone';
import { resumesMock, workerProfileMock } from '@/mock';
import ResumePublishedModal from './ResumePublishedModal';
import ResumeConfirmDeleteModal from './ResumeDeleteConfirmModal';
import { CancelSaveConfirmModal } from '@/modules/Shared';

const WorkerSidebarMyResumes: React.FC = () => {
  const [show, setShow] = useState(false);
  const [showPublishedModal, setShowPublishedModal] = useState(false);
  const [selectedResume, setSelectedResume] = useState(-1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteResumeTitle, setDeleteResumeTitle] = useState('');
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  useEffect(() => {
    const openMyResumes = searchParams.get('openMyResume');
    setShow(!!openMyResumes);
  }, [searchParams]);

  const handleCloseSideModal = () => {
    const { openMyResume, ...currentSearchParams } =
      Object.fromEntries(searchParams);
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <AppSideModal
      width={500}
      isOpen={show}
      onClose={() => setShowCancelConfirmModal(true)}
      customTools={
        <Link to={appRoutes.workerResumes} target="_blank">
          <IconButton>
            <IconArrowSquareOut />
          </IconButton>
        </Link>
      }
    >
      <UIBox display="flex" flexDirection="column" gap={30} px={1.5} py={2}>
        <Typography variant="h6" marginBottom={0}>
          My resume
        </Typography>
        <UIBox display="flex" flexDirection="column" gap={10}>
          {resumesMock.map((resume, id) => (
            <UIBox key={id} position="relative" minHeight={100}>
              <UICard
                position="absolute"
                zIndex={1}
                top={0}
                left={0}
                right={0}
                display="flex"
                justifyContent="space-between"
                boxShadow="0px 4px 10px 0px #0000000D"
                cursor="pointer"
                onClick={() =>
                  setSelectedResume(id === selectedResume ? -1 : id)
                }
              >
                <UIBox display="flex" alignItems="center" gap={20}>
                  <Radio checked={id === 0} />
                  <AppUserAvatar user={workerProfileMock} />
                  <UIBox>
                    <UIBox display="flex" gap={10} alignItems="center">
                      <Typography color={theme.colors.textPrimary}>
                        Samuel Ramichk
                      </Typography>
                      {id === 0 && (
                        <UIBox
                          borderRadius={33}
                          background="#39B5D822"
                          height={17}
                          display="flex"
                          alignItems="center"
                          px={0.5}
                        >
                          <Typography
                            variant="span"
                            fontSize={10}
                            lineHeight="12px"
                            color={theme.colors.textPrimary}
                          >
                            Default
                          </Typography>
                        </UIBox>
                      )}
                    </UIBox>
                    <Typography fontSize={14}>{resume.title}</Typography>
                  </UIBox>
                </UIBox>

                <UIBox display="flex" gap={8}>
                  <IconButton rounded>
                    <IconArrowSquareOut
                      width={14}
                      height={14}
                      color={theme.colors.icon}
                    />
                  </IconButton>
                  <IconButton
                    rounded
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteResumeTitle(resume.title);
                      setShowDeleteConfirmModal(true);
                    }}
                  >
                    <IconDelete
                      width={14}
                      height={14}
                      color={theme.colors.icon}
                    />
                  </IconButton>
                </UIBox>
              </UICard>
              {id === selectedResume && (
                <UICard
                  display="flex"
                  flexDirection="column"
                  gap={10}
                  boxShadow="0px 4px 10px 0px #0000000D"
                  mt={5.5}
                >
                  <Typography
                    fontSize={14}
                    lineHeight="24px"
                    color={theme.colors.textPrimary}
                  >
                    Info:
                  </Typography>
                  <UIBox display="flex" gap={10} alignItems="center">
                    <IconTimeCircle />
                    <Typography variant="span" fontSize={14} lineHeight="19px">
                      Full Time
                    </Typography>
                  </UIBox>
                  <UIBox display="flex" gap={10} alignItems="center">
                    <IconCurrencyCircleDollar />
                    <Typography variant="span" fontSize={14} lineHeight="19px">
                      $40/hour
                    </Typography>
                  </UIBox>
                  <UIBox display="flex" gap={10} alignItems="center">
                    <IconWork
                      width={12}
                      height={12}
                      color={theme.colors.icon}
                    />
                    <Typography variant="span" fontSize={14} lineHeight="19px">
                      Relocate/Remote work/Office/Part-time/Freelance
                    </Typography>
                  </UIBox>
                </UICard>
              )}
            </UIBox>
          ))}
        </UIBox>
        <UIBox>
          <Typography variant="h6">Add new resume</Typography>
          <FileDropzone label="Click or drag to upload your resume" />
          <UIBox display="flex" gap={40} mt={1}>
            <Radio label="Resume File" id="resumeFile" />
            <Radio label="Linkedin profile" id="linkedinProfile" />
          </UIBox>
        </UIBox>
        <Button fullWidth onClick={() => setShowPublishedModal(true)}>
          Add resume
        </Button>
        <ResumePublishedModal
          show={showPublishedModal}
          onClose={() => setShowPublishedModal(false)}
        />
      </UIBox>
      <ResumeConfirmDeleteModal
        show={showDeleteConfirmModal}
        resumeTitle={deleteResumeTitle}
        onClose={() => setShowDeleteConfirmModal(false)}
      />
      <CancelSaveConfirmModal
        show={showCancelConfirmModal}
        onClose={() => setShowCancelConfirmModal(false)}
        onCancel={() => {
          setShowCancelConfirmModal(false);
          handleCloseSideModal();
        }}
      />
    </AppSideModal>
  );
};

export default WorkerSidebarMyResumes;
