import {
  IconDelete,
  IconEdit1,
  IconEllipsis,
  IconSearch,
  IconSwap,
} from '@/assets/icons';
import {
  UIBox,
  Dropdown,
  DropdownItem,
  IconButton,
  Image,
  Input,
  Pagination,
  UITable,
  UITh,
  UIThead,
  UITr,
  Typography,
} from '@/components/UI';
import { DropdownButton } from '@/components/UI/Dropdown';
import { theme } from '@/constants';
import { RoleNames } from '@/enums';
import { jobsMock } from '@/mock';

import { SidebarJobDetail } from '@/modules/Job';
import { formatCurrency } from '@/utils';
import { useSearchParams } from 'react-router-dom';
const OrgProjectsPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickJobCard = (jobId: number) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.jobId = `${jobId}`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <>
      <UIBox display="flex" justifyContent="space-between">
        <Typography variant="h5">Projects</Typography>
        <UIBox display="flex" gap={20}>
          <Input
            placeholder={
              <>
                <IconSearch color={theme.colors.icon} />
                Search
              </>
            }
            height={40}
          />
          <Dropdown
            button={
              <UIBox display="flex" alignItems="center" width={200} gap={10}>
                <IconSwap /> Status
              </UIBox>
            }
            height={40}
          >
            <DropdownItem>Status</DropdownItem>
          </Dropdown>
        </UIBox>
      </UIBox>
      <UIBox>
        <UITable width="100%">
          <UIThead>
            <tr>
              <UITh align="left">Logo</UITh>
              <UITh align="left">Name</UITh>
              <UITh align="left">Description</UITh>
              <UITh align="left">Posted</UITh>
              <UITh align="left">Salary</UITh>
              <UITh>Candidate</UITh>
              <UITh align="right">Posted</UITh>
              <UITh></UITh>
            </tr>
          </UIThead>
          <tbody>
            {jobsMock.map((item, id) => (
              <UITr
                key={id}
                onClick={() => handleOnClickJobCard(item.id)}
                style={{ cursor: 'pointer' }}
              >
                <td width={30} align="center">
                  <Image src={''} />
                </td>
                <td width={200}>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {item.title}
                  </Typography>
                  <Typography
                    color={theme.colors.textDescription}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {item.type} - {item.employmentType}
                  </Typography>
                </td>
                <td>
                  <Typography
                    color={theme.colors.textDescription}
                    fontSize={14}
                    lineHeight="17px"
                    style={{
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 5,
                      WebkitBoxOrient: 'vertical',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.description}
                  </Typography>
                </td>
                <td align="center" width={130}>
                  <Typography color={theme.colors.textPrimary} fontWeight={400}>
                    {`${item.poster.firstName} ${item.poster.lastName}`}
                  </Typography>
                </td>
                <td align="center" width={170}>
                  <Typography color={theme.colors.textPrimary} fontWeight={500}>
                    {`${formatCurrency(item.minSalary)} - ${formatCurrency(item.maxSalary)}`}
                  </Typography>
                </td>
                <td align="center" width={100}>
                  <Typography variant="span">23</Typography>
                </td>
                <td align="right">
                  {new Date(item.createdAt).toLocaleDateString()}
                </td>
                <td align="right">
                  <Dropdown
                    button={
                      <IconButton>
                        <IconEllipsis />
                      </IconButton>
                    }
                    disableDefaultToggleStyle
                  >
                    <DropdownButton variant="primary">
                      <IconEdit1 />
                      Edit
                    </DropdownButton>
                    <DropdownButton variant="danger">
                      <IconDelete width={8} height={9} />
                      Delete
                    </DropdownButton>
                  </Dropdown>
                </td>
              </UITr>
            ))}
          </tbody>
        </UITable>
        <UIBox display="flex" justifyContent="flex-end" mt={1}>
          <Pagination total={10} offset={1} />
        </UIBox>
      </UIBox>

      <SidebarJobDetail viewOnly role={RoleNames.ORG_ADMIN} />
    </>
  );
};

export default OrgProjectsPage;
