import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconDelete({
  width = 15,
  height = 19,
  color = theme.colors.red500,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45297 2.18522C7.61506 2.18522 7.75 2.3198 7.75 2.49105V2.64938C7.75 2.81646 7.61506 2.95521 7.45297 2.95521H0.547439C0.384942 2.95521 0.25 2.81646 0.25 2.64938V2.49105C0.25 2.3198 0.384942 2.18522 0.547439 2.18522H1.76232C2.0091 2.18522 2.22388 2.00981 2.27939 1.76231L2.34301 1.47814C2.44189 1.09107 2.76729 0.833984 3.1397 0.833984H4.8603C5.22866 0.833984 5.5577 1.09107 5.65293 1.45773L5.72101 1.76189C5.77612 2.00981 5.9909 2.18522 6.23809 2.18522H7.45297ZM6.83576 7.97316C6.9626 6.79108 7.18466 3.98278 7.18466 3.95445C7.19277 3.86861 7.16481 3.78737 7.10929 3.72195C7.04972 3.6607 6.97435 3.62445 6.89128 3.62445H1.11189C1.02841 3.62445 0.948982 3.6607 0.893871 3.72195C0.837949 3.78737 0.810394 3.86861 0.814446 3.95445C0.815191 3.95965 0.823159 4.05857 0.83648 4.22395C0.89566 4.95863 1.06049 7.00488 1.167 7.97316C1.24237 8.68648 1.71041 9.13481 2.38836 9.15106C2.91151 9.16314 3.45047 9.16731 4.00158 9.16731C4.52068 9.16731 5.04789 9.16314 5.58725 9.15106C6.2887 9.13898 6.75634 8.69857 6.83576 7.97316Z"
        fill={color}
      />
    </svg>
  );
}
