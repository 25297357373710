import { IconRightArrow } from '@/assets/icons';
import { AppLogo } from '@/components/App';
import {
  Button,
  UIContainer,
  UIFlexCenterBox,
  UIFlexColumnBox,
  UIFlexSpaceBox,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { useAuth } from '@/hooks';
import { Link, Outlet } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavbarContainer = styled(
  ({
    scrolled,
    ...rest
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
    scrolled: boolean;
  }) => <header {...rest} />
)<{ scrolled: boolean }>(({ theme }) => ({
  flexGrow: 1,
  position: 'fixed',
  zIndex: 99,
  left: 0,
  right: 0,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: theme.colors.white,
  boxShadow: theme.colors.heavyBoxShadow,
}));

const StyledNavLink = styled(Link)(({ theme: { colors } }) => ({
  color: colors.black,
  fontSize: '1rem',
  textDecoration: 'none',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

const StyledMain = styled.main(({ theme }) => ({
  backgroundColor: theme.colors.gray960,
  minHeight: '100vh',
  padding: 24,
  paddingTop: 90,
  transition: 'all 0.3s ease',
}));

export default function PublicLayout() {
  const { auth } = useAuth();
  return (
    <div>
      <StyledNavbarContainer scrolled>
        <UIContainer>
          <UIFlexSpaceBox>
            <UIFlexColumnBox>
              <AppLogo />
            </UIFlexColumnBox>
            {!auth && (
              <UIFlexCenterBox gap={30}>
                <Button variant="outlined-primary" rounded size="sm">
                  Get started
                </Button>
                <StyledNavLink to={appRoutes.login}>
                  Login
                  <IconRightArrow color={theme.colors.black} />
                </StyledNavLink>
              </UIFlexCenterBox>
            )}
          </UIFlexSpaceBox>
        </UIContainer>
      </StyledNavbarContainer>
      <StyledMain style={{ backgroundColor: '#F5F5F5' }}>
        <Outlet />
      </StyledMain>
    </div>
  );
}
