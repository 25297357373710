import { AppTypes } from '@/types';

export default function IconLogo({
  width = 56,
  height = 39,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23.6842" cy="35.187" r="3.26316" fill="#39B5D8" />
      <path
        d="M4.10529 38.2406V13.6562C4.10529 10.8394 5.34976 8.16654 7.50541 6.35338C10.8859 3.51001 15.776 3.35833 19.3262 5.98674L19.6274 6.2098C22.1792 8.09899 23.6842 11.086 23.6842 14.2609V30.1354M36.4211 2.45117V26.4512C36.4211 31.3927 40.4269 35.3985 45.3684 35.3985H54.8948"
        stroke="#40549C"
        strokeWidth="6.31579"
      />
    </svg>
  );
}
