import { configureStore } from '@reduxjs/toolkit';
import {
  assetApi,
  authApi,
  certificationApi,
  defaultPublicApi,
  industryApi,
  organizationApi,
  resumeApi,
  skillApi,
  userApi,
} from './apis';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authSlice, registerSlice } from './reducers';
export const store = configureStore({
  reducer: {
    [assetApi.reducerPath]: assetApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [resumeApi.reducerPath]: resumeApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [industryApi.reducerPath]: industryApi.reducer,
    [skillApi.reducerPath]: skillApi.reducer,
    [certificationApi.reducerPath]: certificationApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [defaultPublicApi.reducerPath]: defaultPublicApi.reducer,
    authState: authSlice.reducer,
    registerState: registerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      assetApi.middleware,
      authApi.middleware,
      resumeApi.middleware,
      userApi.middleware,
      industryApi.middleware,
      skillApi.middleware,
      certificationApi.middleware,
      organizationApi.middleware,
      defaultPublicApi.middleware
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
