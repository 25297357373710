import { theme } from '@/constants';
import { UIBox } from './Box';
import Label from './Label';
import styled from 'styled-components';

const StyledRadio = styled.input`
  cursor: pointer;
  margin: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: block;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border: solid 2px ${theme.colors.primary};
    background: ${theme.colors.white};
  }
  &:checked {
    &::after {
      content: '';
      position: absolute;
      display: block;
      left: 4px;
      top: 4px;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background: ${theme.colors.primary};
    }
  }
`;

type RadioProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: React.ReactNode;
};
const Radio: React.FC<RadioProps> = ({ id, label, ...props }) => {
  return (
    <UIBox display="flex" alignItems="center">
      <StyledRadio id={id} type="radio" {...props} />
      {label && (
        <Label
          color={theme.colors.textPurple}
          htmlFor={id}
          fontSize={16}
          marginBottom={0}
          lineHeight="22px"
          marginLeft={5}
          style={{ cursor: 'pointer' }}
        >
          {label}
        </Label>
      )}
    </UIBox>
  );
};
export default Radio;
