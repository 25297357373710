import styled from 'styled-components';
import AsideBar from './AsideBar';
import { PropsWithChildren, useState } from 'react';

const StyledMain = styled.main<{ $expanded: boolean }>(
  ({ theme, $expanded }) => ({
    backgroundColor: theme.colors.gray960,
    minHeight: '100vh',
    marginLeft: $expanded ? 210 : 80,
    padding: 24,
    paddingTop: 90,
    transition: 'all 0.3s ease',
  })
);

const StyledNavbarContainer = styled.header(({ theme }) => ({
  paddingLeft: 30,
  paddingRight: 30,
  flexGrow: 1,
  position: 'fixed',
  zIndex: 99,
  left: 0,
  right: 0,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: theme.colors.white,
  boxShadow: theme.colors.heavyBoxShadow,
}));

interface DashboardLayoutProps extends PropsWithChildren {
  asideBar: React.ReactNode;
  navBar: React.ReactNode;
  setGlobalExpanded?: (status: boolean) => void;
}
const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  asideBar,
  navBar,
  children,
  setGlobalExpanded,
}) => {
  const [expanded, setExpanded] = useState(true);
  const handleChangeExpanded = (status: boolean) => {
    setExpanded(status);
    setGlobalExpanded?.(status);
  };
  return (
    <div>
      <AsideBar expanded={expanded} setExpanded={handleChangeExpanded}>
        {asideBar}
      </AsideBar>
      <StyledNavbarContainer>{navBar}</StyledNavbarContainer>
      <StyledMain $expanded={expanded}>{children}</StyledMain>
    </div>
  );
};

export default DashboardLayout;
