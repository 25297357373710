import { ErrorType } from '@/enums';
import { Error404, Error500, Error503 } from '@/modules/Errors';
import { useSearchParams } from 'react-router-dom';

interface ErrorProps {
  error?: ErrorType;
}
const Error: React.FC<ErrorProps> = ({ error: errorProps }) => {
  const [searchParam] = useSearchParams();
  const error = errorProps || searchParam.get('error') || ErrorType.ERROR_404;
  if (error === ErrorType.ERROR_500) return <Error500 />;
  else if (error === ErrorType.ERROR_503) return <Error503 />;
  return <Error404 />;
};

export default Error;
