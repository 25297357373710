import { theme } from '@/constants';
import { useState } from 'react';
import styled from 'styled-components';

const StyledSwitchContainer = styled.div<{ $checked: boolean }>`
  position: relative;
  width: 23px;
  height: 10px;
  border-radius: 5px;
  background: ${({ $checked }) =>
    $checked ? '#ADB8DF' : theme.colors.gray810};
  transition: all ease 0.3s;
  cursor: pointer;
`;

const StyledSwitchToggler = styled.div<{ $checked: boolean }>`
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 8px;
  left: ${({ $checked }) => ($checked ? '10px' : '-2px')};
  background: ${({ $checked }) =>
    $checked ? theme.colors.primary : theme.colors.white};
  transition: all ease 0.3s;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 0px 4px 0px #27272759;
`;
interface SwitchProps {}
const Switch: React.FC<SwitchProps> = () => {
  const [value, setValue] = useState(false);
  return (
    <StyledSwitchContainer onClick={() => setValue(!value)} $checked={value}>
      <StyledSwitchToggler $checked={value} />
    </StyledSwitchContainer>
  );
};

export default Switch;
