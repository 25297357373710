import { theme } from '@/constants';
import styled from 'styled-components';

export type Position = 'absolute' | 'relative' | 'fixed' | 'sticky';
export type BorderStyle = 'solid' | 'dotted' | 'dashed';
export type FlexPosition =
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'stretch';
export type FlexDirection = 'row' | 'column' | 'column-reverse' | 'row-reverse';
export type Overflow = 'hidden' | 'scroll' | 'visible' | 'auto' | 'clip';
export type Display =
  | 'flex'
  | 'block'
  | 'inline-block'
  | 'inline-flex'
  | 'contents'
  | 'grid';
export type Cursor = 'pointer' | 'default';
export type PointerEvents = 'none' | 'all';
export type WillChange = 'position' | 'opacity' | 'width' | string;
export type BoxSizing = 'border-box';
export type FlexWrap = 'nowrap' | 'wrap';
export type AnimationPlayState = 'running' | 'paused';
export type TextAlign =
  | 'left'
  | 'center'
  | 'right'
  | 'justify'
  | 'start'
  | 'end';
export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  p?: number | string | false;
  px?: number | string | false;
  py?: number | string | false;
  pt?: number | string | false;
  pb?: number | string | false;
  pr?: number | string | false;
  pl?: number | string | false;
  m?: number | string | false;
  mr?: number | string | false;
  ml?: number | string | false;
  mt?: number | string | false;
  mb?: number | string | false;
  mx?: number | string | false;
  my?: number | string | false;
  height?: number | string;
  maxHeight?: number | string;
  minHeight?: number | string;
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  flex?: number | false;
  flexDirection?: FlexDirection;
  flexGrow?: number | false;
  flexWrap?: FlexWrap;
  justifyContent?: FlexPosition;
  alignItems?: FlexPosition;
  flexCenter?: boolean;
  background?: string;
  backgroundSize?: 'cover' | 'contain' | 'auto';
  backgroundRepeat?: 'no-repeat' | 'repeat';
  backgroundPosition?: 'center' | 'right' | 'left' | 'top' | 'bottom';

  gap?: number | string | false;
  textAlign?: TextAlign;

  backgroundColor?: string;
  backgroundImage?: string;
  position?: Position;
  right?: number | string;
  left?: number | string;
  top?: number | string;
  bottom?: number | string;
  zIndex?: number | false;
  borderRadius?: number | string;
  borderBottomLeftRadius?: number | string;
  borderBottomRightRadius?: number | string;
  borderTopLeftRadius?: number | string;
  borderTopRightRadius?: number | string;
  borderColor?: string;
  borderWidth?: number | false;
  borderTopWidth?: number;
  borderTopColor?: string;
  borderTopStyle?: BorderStyle;
  borderBottomWidth?: number;
  borderBottomColor?: string;
  borderBottomStyle?: BorderStyle;
  borderRightWidth?: number;
  borderRightColor?: string;
  borderRightStyle?: BorderStyle;
  borderLeftWidth?: number;
  borderLeftColor?: string;
  borderLeftStyle?: BorderStyle;
  borderStyle?: BorderStyle;
  opacity?: number;
  shadowOffset?: string;
  shadowOpacity?: number;
  shadowRadius?: string;
  shadowBlur?: string;
  shadowSpread?: string;
  boxShadow?: string;
  elevation?: string;
  overflow?: Overflow;
  overflowY?: Overflow;
  overflowX?: Overflow;
  display?: Display;

  cursor?: Cursor;
  pointerEvents?: PointerEvents;
  transition?: string;
  transform?: string;
  gridTemplateColumns?: string;
  columnGap?: string | number;
  willChange?: WillChange;
  boxSizing?: BoxSizing;
  animationPlayState?: AnimationPlayState;

  gridColumn?: number;
}
const valueToUnit = (
  v?: number | string,
  unit: 'rem' | 'px' = 'rem',
  defaultValue: number | string = 0
) =>
  v === 0 || !!v ? (typeof v === 'number' ? `${v}${unit}` : v) : defaultValue;

const BoxCssHandlers = {
  p: (style: React.CSSProperties, value: string | number) =>
    (style.padding = valueToUnit(value)),
  py: (style: React.CSSProperties, value: string | number) => {
    style.paddingTop = valueToUnit(value);
    style.paddingBottom = valueToUnit(value);
  },
  px: (style: React.CSSProperties, value: string | number) => {
    style.paddingLeft = valueToUnit(value);
    style.paddingRight = valueToUnit(value);
  },
  pt: (style: React.CSSProperties, value: string | number) =>
    (style.paddingTop = valueToUnit(value)),
  pb: (style: React.CSSProperties, value: string) =>
    (style.paddingBottom = valueToUnit(value)),
  pr: (style: React.CSSProperties, value: string | number) =>
    (style.paddingRight = valueToUnit(value)),
  pl: (style: React.CSSProperties, value: string | number) =>
    (style.paddingLeft = valueToUnit(value)),
  m: (style: React.CSSProperties, value: string | number) =>
    (style.margin = valueToUnit(value)),

  my: (style: React.CSSProperties, value: string | number) => {
    style.marginTop = valueToUnit(value);
    style.marginBottom = valueToUnit(value);
  },
  mx: (style: React.CSSProperties, value: string | number) => {
    style.marginLeft = valueToUnit(value);
    style.marginRight = valueToUnit(value);
  },
  mr: (style: React.CSSProperties, value: string | number) =>
    (style.marginRight = valueToUnit(value)),
  ml: (style: React.CSSProperties, value: string | number) =>
    (style.marginLeft = valueToUnit(value)),
  mb: (style: React.CSSProperties, value: string | number) =>
    (style.marginBottom = valueToUnit(value)),
  mt: (style: React.CSSProperties, value: string | number) =>
    (style.marginTop = valueToUnit(value)),
  height: (style: React.CSSProperties, value: string | number) =>
    (style.height = valueToUnit(value, 'px', 'unset')),
  maxHeight: (style: React.CSSProperties, value: string | number) =>
    (style.maxHeight = valueToUnit(value, 'px', 'unset')),
  minHeight: (style: React.CSSProperties, value: string | number) =>
    (style.minHeight = valueToUnit(value, 'px', 'unset')),
  width: (style: React.CSSProperties, value: string | number) =>
    (style.width = valueToUnit(value, 'px', 'unset')),
  minWidth: (style: React.CSSProperties, value: string | number) =>
    (style.minWidth = valueToUnit(value, 'px', 'unset')),
  maxWidth: (style: React.CSSProperties, value: string | number) =>
    (style.maxWidth = valueToUnit(value, 'px', 'unset')),
  flexDirection: (style: React.CSSProperties, value: FlexDirection) =>
    (style.flexDirection = value),
  flex: (style: React.CSSProperties, value: string) => (style.flex = value),
  flexGrow: (style: React.CSSProperties, value: string) =>
    (style.flexGrow = value),
  flexWrap: (style: React.CSSProperties, value: FlexWrap) =>
    (style.flexWrap = value),
  flexCenter: (style: React.CSSProperties, value: string) => {
    if (!value) return;
    style.display = 'flex';
    style.alignItems = 'center';
    style.justifyContent = 'center';
  },
  justifyContent: (style: React.CSSProperties, value: string) =>
    (style.justifyContent = value),
  alignItems: (style: React.CSSProperties, value: string) =>
    (style.alignItems = value),
  backgroundColor: (style: React.CSSProperties, value: string) =>
    (style.backgroundColor = value),
  background: (style: React.CSSProperties, value: string) =>
    (style.background = value),
  backgroundImage: (style: React.CSSProperties, value: string) => {
    style.backgroundImage = value;
    style.backgroundPosition = 'center';
    style.backgroundRepeat = 'no-repeat';
    style.backgroundSize = 'cover';
  },
  backgroundSize: (style: React.CSSProperties, value: string) =>
    (style.backgroundSize = value),
  backgroundRepeat: (style: React.CSSProperties, value: string) =>
    (style.backgroundRepeat = value),
  backgroundPosition: (style: React.CSSProperties, value: string) =>
    (style.backgroundPosition = value),

  position: (style: React.CSSProperties, value: Position) =>
    (style.position = value),
  bottom: (style: React.CSSProperties, value: Position) =>
    (style.bottom = value),
  right: (style: React.CSSProperties, value: Position) => (style.right = value),
  left: (style: React.CSSProperties, value: Position) => (style.left = value),
  top: (style: React.CSSProperties, value: Position) => (style.top = value),
  zIndex: (style: React.CSSProperties, value: number) => (style.zIndex = value),
  opacity: (style: React.CSSProperties, value: number) =>
    (style.opacity = value),
  borderRadius: (style: React.CSSProperties, value: string | number) =>
    (style.borderRadius = value),
  borderBottomRightRadius: (
    style: React.CSSProperties,
    value: string | number
  ) => (style.borderBottomRightRadius = value),
  borderTopRightRadius: (style: React.CSSProperties, value: string | number) =>
    (style.borderTopRightRadius = value),
  borderBottomLeftRadius: (
    style: React.CSSProperties,
    value: string | number
  ) => (style.borderBottomLeftRadius = value),
  borderTopLeftRadius: (style: React.CSSProperties, value: string | number) =>
    (style.borderTopLeftRadius = value),
  borderColor: (style: React.CSSProperties, value: string) =>
    (style.borderColor = value),
  borderWidth: (style: React.CSSProperties, value: string | number) =>
    (style.borderWidth = value),
  borderTopWidth: (style: React.CSSProperties, value: string | number) =>
    (style.borderTopWidth = value),
  borderBottomWidth: (style: React.CSSProperties, value: string | number) =>
    (style.borderBottomWidth = value),
  borderRightWidth: (style: React.CSSProperties, value: string | number) =>
    (style.borderRightWidth = value),
  borderLeftWidth: (style: React.CSSProperties, value: string | number) =>
    (style.borderLeftWidth = value),
  borderTopColor: (style: React.CSSProperties, value: string) =>
    (style.borderTopColor = value),
  borderBottomColor: (style: React.CSSProperties, value: string) =>
    (style.borderBottomColor = value),
  borderRightColor: (style: React.CSSProperties, value: string) =>
    (style.borderRightColor = value),
  borderLeftColor: (style: React.CSSProperties, value: string) =>
    (style.borderLeftColor = value),
  borderStyle: (style: React.CSSProperties, value: BorderStyle) =>
    (style.borderStyle = value),
  borderTopStyle: (style: React.CSSProperties, value: BorderStyle) =>
    (style.borderTopStyle = value),
  borderBottomStyle: (style: React.CSSProperties, value: BorderStyle) =>
    (style.borderBottomStyle = value),
  borderRightStyle: (style: React.CSSProperties, value: BorderStyle) =>
    (style.borderRightStyle = value),
  borderLeftStyle: (style: React.CSSProperties, value: BorderStyle) =>
    (style.borderLeftStyle = value),
  boxShadow: (style: React.CSSProperties, value: string) =>
    (style.boxShadow = value),
  overflow: (style: React.CSSProperties, value: Overflow) =>
    (style.overflow = value),
  overflowY: (style: React.CSSProperties, value: Overflow) =>
    (style.overflowY = value),
  overflowX: (style: React.CSSProperties, value: Overflow) =>
    (style.overflowX = value),
  display: (style: React.CSSProperties, value: Display) =>
    (style.display = value),
  cursor: (style: React.CSSProperties, value: Cursor) => (style.cursor = value),
  pointerEvents: (style: React.CSSProperties, value: PointerEvents) =>
    (style.pointerEvents = value),
  transition: (style: React.CSSProperties, value: string) =>
    (style.transition = value),
  transform: (style: React.CSSProperties, value: string) =>
    (style.transform = value),
  gridTemplateColumns: (style: React.CSSProperties, value: string) =>
    (style.gridTemplateColumns = value),
  columnGap: (style: React.CSSProperties, value: string) =>
    (style.columnGap = value),
  willChange: (style: React.CSSProperties, value: WillChange) =>
    (style.willChange = value),
  boxSizing: (style: React.CSSProperties, value: BoxSizing) =>
    (style.boxSizing = value),

  animationPlayState: (style: React.CSSProperties, value: AnimationPlayState) =>
    (style.animationPlayState = value),

  gap: (style: React.CSSProperties, value: string) => (style.gap = value),
  textAlign: (style: React.CSSProperties, value: TextAlign) =>
    (style.textAlign = value),
  gridColumn: (style: React.CSSProperties, value: string) =>
    (style.gridColumn = `span ${value}`),
};

export const UIBox = (props: BoxProps) => {
  const passedProps = Object.keys(props);
  const propsToPass = {};
  const style = {};

  for (let i = 0; i < passedProps.length; i++) {
    const passedProp = passedProps[i];

    if (passedProp in BoxCssHandlers)
      // @ts-ignore
      BoxCssHandlers[passedProp](style, props[passedProp]);
    // @ts-ignore
    else propsToPass[passedProp] = props[passedProp];
  }

  return (
    <div
      {...propsToPass}
      style={{ ...style, ...(props.style || {}), fontFamily: 'Gantari' }}
    />
  );
};

export const UIContainer = styled(UIBox)(
  ({ theme }) => `
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: ${theme.breakpointsMinWidth.sm};
  }
  @media (min-width: ${theme.breakpoints.md}) {
    max-width: ${theme.breakpointsMinWidth.md};
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    max-width: ${theme.breakpointsMinWidth.lg};
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    max-width: ${theme.breakpointsMinWidth.xl};
  }
  @media (min-width: ${theme.breakpoints.xxl}) {
    max-width: ${theme.breakpointsMinWidth.xxl};
  }
`
);

type GridProps = {
  gap?: number | string;
  columns?: number;
  sm?: number;
  lg?: number;
  md?: number;
  xl?: number;
};
// Grid Container
export const UIGrid = styled(
  ({
    gap,
    columns,
    sm,
    lg,
    md,
    xl,
    ...rest
  }: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    GridProps) => <div {...rest} />
)<GridProps>(
  ({ columns: xs = 4, gap, sm, md, lg, xl, theme }) => `
  display: grid;
  grid-template-columns: repeat(${xs}, 1fr);
  gap: ${valueToUnit(gap, 'px')};

  @media (min-width: ${theme.breakpoints.sm}) { // sm breakpoint
    grid-template-columns: repeat(${sm || xs}, 1fr);
  }

  @media (min-width: ${theme.breakpoints.md}) { // md breakpoint
    grid-template-columns: repeat(${md || sm || xs}, 1fr);
  }

  @media (min-width: ${theme.breakpoints.lg}) { // lg breakpoint
    grid-template-columns: repeat(${lg || md || sm || xs}, 1fr);
  }

  @media (min-width: ${theme.breakpoints.xl}) { // xl breakpoint
    grid-template-columns: repeat(${xl || lg || md || sm || xs}, 1fr);
  }
`
);

export const UIFlexCenterBox = styled(UIBox)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const UIFlexWrapBox = styled(UIBox)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));

export const UIFlexColumnBox = styled(UIBox)(() => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

export const UIFlexColumnCenterBox = styled(UIBox)(() => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const UIFlexSpaceBox = styled(UIBox)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const UIBorderBox = styled(UIBox)(({ theme }) => ({
  border: `1px solid`,
  padding: '1rem',
  borderColor: theme.colors.gray200,
  borderRadius: 8,
  background: theme.colors.white,
}));

interface DividerProps {
  vertical?: boolean;
}
export const UIDivider = styled(
  ({ vertical, ...rest }: DividerProps & BoxProps) => <UIBox {...rest} />
)<DividerProps>`
  ${({ vertical }) =>
    vertical
      ? `
  margin: 0 20px;
  background: ${theme.colors.gray810};
  width: 1px;
  height: 100%;
  `
      : `
  margin: 20px 0;
  background: ${theme.colors.gray810};
  height: 1px;
  width: 100%;
  `}
`;

export const UIOrDivider = styled(UIBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.gray500};
  &::before,
  &::after {
    content: '';
    width: 40%;
    border-top: solid 1px ${theme.colors.companyBrand};
  }
`;

type FadeProps = {
  fade: boolean;
};
export const UIFade = styled(
  ({
    fade,
    ...rest
  }: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    FadeProps) => <div {...rest} />
)<FadeProps>`
  transition: opacity 300ms ease-in-out;
  opacity: ${(props) => (props.fade ? 1 : 0)};
`;
