import { UIGrid } from '@/components/UI';
import { useSearchParams } from 'react-router-dom';

import WorkerInvitedJobCard from './InvitedJobCard';
import { jobsMock } from '@/mock';
const WorkerInvitedJobs: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickJobCard = (jobId: number) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.jobId = `${jobId}`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <UIGrid columns={3} gap={20}>
      {jobsMock.map((job, id) => (
        <WorkerInvitedJobCard
          key={id}
          job={job}
          onClick={() => handleOnClickJobCard(job.id)}
        />
      ))}
    </UIGrid>
  );
};

export default WorkerInvitedJobs;
