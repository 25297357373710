import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconArrowUp({
  width = 12,
  height = 7,
  color = theme.colors.gray810,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9781 6.27691C11.2417 6.0133 11.2417 5.58592 10.9781 5.32231L6.00078 0.345015L1.02348 5.32231C0.75988 5.58592 0.75988 6.0133 1.02348 6.27691C1.28709 6.54051 1.71447 6.54051 1.97808 6.27691L6.00078 2.2542L10.0235 6.27691C10.2871 6.54051 10.7145 6.54051 10.9781 6.27691Z"
        fill={color}
      />
    </svg>
  );
}
