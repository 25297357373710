import { AppModal } from '@/components/App';
import { Button, Typography, UIBox } from '@/components/UI';
import { theme } from '@/constants';

interface CancelSaveConfirmModalProps {
  show: boolean;
  onClose: () => void;
  onCancel: () => void;
}
const CancelSaveConfirmModal: React.FC<CancelSaveConfirmModalProps> = ({
  show,
  onClose,
  onCancel,
}) => {
  return (
    <AppModal isOpen={show} headerAlign="center" onClose={onClose}>
      <UIBox display="flex" flexDirection="column" alignItems="center" gap={20}>
        <UIBox display="flex" flexDirection="column" alignItems="center">
          <Typography textAlign="center" fontWeight={500}>
            Are you sure you want to leave the page?
            <br />
            Then your changes{' '}
            <Typography
              variant="span"
              color={theme.colors.textPrimary}
              fontWeight={600}
            >
              will not be saved
            </Typography>
          </Typography>
        </UIBox>
        <Button variant="secondary" height={40} onClick={onCancel} width={170}>
          Don't save
        </Button>
      </UIBox>
    </AppModal>
  );
};

export default CancelSaveConfirmModal;
