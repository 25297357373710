import { theme } from '@/constants';
import { AppTypes } from '@/types';

const IconRoundedArrowUp: React.FC<AppTypes.IconProps> = ({
  width = 9,
  height = 6,
  color = theme.colors.gray500,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.71103 0.223483C5.7497 0.257384 5.91503 0.385392 6.05103 0.504632C6.90636 1.20371 8.30636 3.02738 8.7337 3.98188C8.80236 4.12684 8.9477 4.49333 8.95703 4.68914C8.95703 4.87677 8.90903 5.05563 8.8117 5.22631C8.6757 5.43907 8.4617 5.60975 8.20903 5.70327C8.0337 5.76347 7.50903 5.85699 7.4997 5.85699C6.9257 5.95052 5.99303 6.00195 4.96236 6.00195C3.98036 6.00195 3.0857 5.95052 2.50303 5.87395C2.4937 5.86518 1.8417 5.77166 1.61836 5.66937C1.21036 5.48174 0.957031 5.11525 0.957031 4.72304V4.68914C0.967031 4.43371 1.22036 3.89655 1.2297 3.89655C1.6577 2.99348 2.98903 1.21189 3.8737 0.495864C3.8737 0.495864 4.10103 0.294208 4.24303 0.206532C4.44703 0.069756 4.6997 0.00195312 4.95236 0.00195312C5.23436 0.00195312 5.49703 0.0785236 5.71103 0.223483Z"
        fill={color}
      />
    </svg>
  );
};

export default IconRoundedArrowUp;
