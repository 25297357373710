import { UIBox } from '@/components/UI';
import { Outlet } from 'react-router-dom';

function AuthRegisterPage() {
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <Outlet />
    </UIBox>
  );
}

export default AuthRegisterPage;
