import { IconPlus } from '@/assets/icons';
import {
  UIBox,
  UICard,
  IconButton,
  Typography,
  Spinner,
} from '@/components/UI';
import { useDeleteUserCertificationMutation } from '@/redux/apis';
import CertificationCard from './CertificationCard';
import { AppEmptyItems } from '@/components/App';
import CertificationEditModal from './CertificationEditModal';
import { useEffect, useState } from 'react';
import { useConfirmModal } from '@/hooks';
import { UserJson } from '@/types';

interface ProfileCertificationsProps {
  certifications?: UserJson.Certification[];
  isLoading?: boolean;
  publicView?: boolean;
  refetch?: () => void;
}

const ProfileCertifications: React.FC<ProfileCertificationsProps> = ({
  certifications = [],
  isLoading,
  refetch,
  publicView,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();
  const [deleteCertification, { isSuccess: isDeleted }] =
    useDeleteUserCertificationMutation();

  async function handleDeleteExperience(certification: UserJson.Certification) {
    await deleteCertification({ id: certification.id });
  }

  useEffect(() => {
    if (isDeleted) {
      refetch?.();
      hideConfirmModal();
    }
  }, [isDeleted]);
  return (
    <UICard borderRadius={10} id="profile-certifications-section">
      <UIBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="h5" marginBottom={0}>
          Certifications
        </Typography>
        {!publicView && (
          <IconButton onClick={() => setShowModal(true)}>
            <IconPlus />
          </IconButton>
        )}
      </UIBox>
      {isLoading && (
        <UIBox display="flex" justifyContent="center" p={2}>
          <Spinner size="xl" />
        </UIBox>
      )}
      {!isLoading && certifications.length === 0 && (
        <AppEmptyItems
          description="Try add a new certification"
          buttonLabel="Add Certification"
          onClickButton={() => setShowModal(true)}
        />
      )}
      <UIBox display="flex" flexDirection="column" gap={20}>
        {certifications.map((cert, id) => (
          <CertificationCard
            key={id}
            certification={cert}
            editable={!publicView}
            onClickDelete={() => {
              showConfirmModal({
                question: 'Do you want to delete this?',
                confirmButton: 'Yes, Delete',
                onClose: hideConfirmModal,
                onConfirm: () => handleDeleteExperience(cert),
              });
            }}
            onClickSave={() => refetch?.()}
          />
        ))}
      </UIBox>

      <CertificationEditModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onAdded={() => {
          refetch?.();
          setShowModal(false);
        }}
      />
    </UICard>
  );
};

export default ProfileCertifications;
