import ReactModal from 'react-modal';
import { UIBox, UIDivider, IconButton, Typography } from '@/components/UI';
import { IconClose } from '@/assets/icons';
import { theme } from '@/constants';

interface AppModalProps extends ReactModal.Props {
  header?: React.ReactNode;
  headerAlign?: 'flex-start' | 'center' | 'flex-end';
  hideClose?: boolean;
  onClose?: () => void;
  width?: number;
  actions?: React.ReactNode;
}
const AppModal: React.FC<AppModalProps> = ({
  header,
  headerAlign = 'flex-start',
  style,
  children,
  hideClose,
  width = 400,
  actions,
  onClose,
  ...props
}) => {
  const handleOnClose = () => {
    onClose?.();
  };
  return (
    <ReactModal
      onRequestClose={handleOnClose}
      closeTimeoutMS={500}
      style={{
        overlay: {
          zIndex: 9999,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: `rgba(0,0,0,0.2)`,
        },
        content: {
          padding: 0,
          width: 'fit-content',
          minWidth: width,
          position: 'absolute',
          left: '50%',
          bottom: 'unset',
          right: 'unset',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 10,
        },
        ...style,
      }}
      {...props}
    >
      <UIBox
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent={headerAlign}
        px={1.5}
        py={1}
      >
        {header && (
          <Typography
            variant="h5"
            fontSize={22}
            lineHeight="24px"
            fontWeight={600}
            marginBottom={0}
          >
            {header}
          </Typography>
        )}

        {!hideClose && (
          <UIBox
            position="absolute"
            right={15}
            top={15}
            onClick={handleOnClose}
          >
            <IconButton>
              <IconClose color={theme.colors.primary} />
            </IconButton>
          </UIBox>
        )}
      </UIBox>
      {header && <UIDivider mt={0} mb={0} />}
      <UIBox px={1.5} py={1} maxHeight={'calc(100vh - 200px)'} overflow="auto">
        {children}
      </UIBox>
      {actions && (
        <UIBox>
          <UIDivider mt={0} mb={0} />
          <UIBox px={1.5} py={0.5}>
            {actions}
          </UIBox>
        </UIBox>
      )}
    </ReactModal>
  );
};
export default AppModal;
