import { IconClose } from '@/assets/icons';
import { UIBox, IconButton } from '@/components/UI';
import { theme } from '@/constants';
import { PropsWithChildren } from 'react';

type NotificationCategory = 'success' | 'danger' | 'note';
export const notificationColors: Record<NotificationCategory, string> = {
  danger: theme.colors.red800,
  note: theme.colors.blue400,
  success: theme.colors.green700,
};
interface NotificationProps extends PropsWithChildren {
  category?: NotificationCategory;
  onClose?: () => void;
}
const Notification: React.FC<NotificationProps> = ({
  children,
  onClose,
  category,
}) => {
  return (
    <UIBox
      p={1}
      position="relative"
      background={theme.colors.white}
      boxShadow="0px 5px 20px 0px #7B7D961A"
      borderLeftStyle="solid"
      borderLeftWidth={4}
      borderLeftColor={category ? notificationColors[category] : 'transparent'}
    >
      <UIBox position="absolute" right={10} top={10}>
        <IconButton onClick={onClose}>
          <IconClose width={10} height={10} color={theme.colors.primary} />
        </IconButton>
      </UIBox>
      {children}
    </UIBox>
  );
};

export default Notification;
