import { UIBox } from '@/components/UI';
import { PublicProfile } from '@/modules/Profile';
const WorkerPublicProfile: React.FC = () => {
  return (
    <UIBox pt={1}>
      <PublicProfile />
    </UIBox>
  );
};

export default WorkerPublicProfile;
