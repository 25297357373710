import {
  Image,
  UIContainer,
  UIFlexCenterBox,
  UIFlexColumnBox,
} from '@/components/UI';
import { useState } from 'react';
import Individuals from './Individuals';
import Employers from './Employers';
import styled from 'styled-components';

export default function IndividualsAndEmployers() {
  const [tab, setTab] = useState<string>('employers');

  const handleTab = (value: string) => {
    setTab(value);
  };

  return (
    <UIFlexColumnBox
      id="individuals_and_employers"
      pb={10}
      background="linear-gradient(109.03deg, rgba(110, 0, 250, 0.069) 9.72%, rgba(31, 223, 200, 0.066) 84.16%)"
    >
      <UIContainer minHeight="500px" textAlign="center">
        <UIFlexCenterBox>
          <StyledSwitchContainer>
          <StyledSwitchButton
              selected={tab === 'employers'}
              onClick={() => {
                handleTab('employers');
              }}
            >
              Employers
            </StyledSwitchButton>
            <StyledSwitchButton
              selected={tab === 'individuals'}
              onClick={() => {
                handleTab('individuals');
              }}
            >
              Individuals
            </StyledSwitchButton>
            <Image
              src="/images/ic_sign.svg"
              alt=""
              width={50}
              height={50}
              position="absolute"
              top="-40px"
              right="-40px"
            />
          </StyledSwitchContainer>
        </UIFlexCenterBox>
        {tab === 'individuals' && <Individuals />}
        {tab === 'employers' && <Employers />}
      </UIContainer>
    </UIFlexColumnBox>
  );
}

const StyledSwitchContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: 80,
  marginBottom: 60,
  position: 'relative',
  width: '70%',
  height: '70px',
  background:
    'linear-gradient(0deg, rgba(148, 217, 255, 0.15), rgba(148, 217, 255, 0.15)), linear-gradient(0deg, rgba(158, 211, 241, 0.1), rgba(158, 211, 241, 0.1))',
  border: '1px solid #94D9FF26',
  borderRadius: '30px',
  paddingLeft: 8,
  paddingRight: 8,
  gap: 8,
});

const StyledSwitchButton = styled.button<{ selected: boolean }>(
  ({ selected }) => ({
    fontFamily: 'Gantari',
    flex: 1,
    fontWeight: 500,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '55px',
    background: `${
      selected
        ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.55)), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))'
        : 'transparent'
    }`,
    border: `${selected ? '1px solid #FFFFFF26' : 'none'}`,
    borderRadius: '30px',
    color: `${selected ? '#0A1049' : '#7B7D96'}`,
    fontSize: '26px',
  })
);
