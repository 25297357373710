import { appConfig } from '@/constants';
import { AuthJson } from '@/types';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
export const writeCookie = (name: string, value: any, hours?: number): void => {
  const options = hours
    ? { maxAge: hours * 60 * 60, path: '/' }
    : { path: '/' };
  cookies.set(name, value, options);
};

export const readCookie = (name: string): any | undefined => {
  return cookies.get(name);
};

export const removeCookie = (name: string): void => {
  cookies.remove(name, { path: '/' });
};

export const getAccessToken = () => {
  const token = cookies.get(appConfig.cookieName);
  if (token) {
    const accessToken = (token as AuthJson.Response).accessToken;
    return accessToken;
  }
  return '';
};
