import {
  IconArrowDown,
  IconEarth,
  IconEdit,
  IconShieldDone,
  IconUsers3,
} from '@/assets/icons';
import {
  UIBox,
  UICard,
  IconButton,
  Image,
  ReadMoreButton,
  Tag,
  Typography,
} from '@/components/UI';
import { theme } from '@/constants';
import { JobPostingSteps } from '@/enums';
import { ProjectJson } from '@/types';

interface JobReviewCardProps {
  job: ProjectJson.Project;
  onClickGotoStep: (step: JobPostingSteps) => void;
}

const JobReviewCard: React.FC<JobReviewCardProps> = ({
  onClickGotoStep,
  job,
}) => {
  return (
    <UICard
      borderRadius={10}
      p={2}
      display="flex"
      flexDirection="column"
      gap={30}
      px={1.5}
      py={2}
    >
      <UIBox>
        <UIBox display="flex" justifyContent="space-between">
          <Typography
            fontSize={18}
            fontWeight={600}
            lineHeight="24px"
            color={theme.colors.textPrimary}
            marginBottom={2}
          >
            {job?.title}
          </Typography>

          <IconButton onClick={() => onClickGotoStep(JobPostingSteps.STEP_1)}>
            <IconEdit />
          </IconButton>
        </UIBox>
        <UIBox display="flex" alignItems="center" gap={10}>
          <Image src={'job?.companyData?.logo'} width={12} height={15} />
          <Typography
            fontSize={14}
            lineHeight="19px"
            color={theme.colors.textPrimary}
          >
            {job?.companyData?.name}
          </Typography>
          <Typography fontSize={12}>
            ({job?.companyData?.numEmployee} employees)
          </Typography>
        </UIBox>
      </UIBox>
      <UIBox>
        <UIBox display="flex" justifyContent="space-between">
          <Typography
            color={theme.colors.textPrimary}
            fontSize={18}
            fontWeight={600}
            marginBottom={5}
          >
            Job Description
          </Typography>

          <IconButton onClick={() => onClickGotoStep(JobPostingSteps.STEP_1)}>
            <IconEdit />
          </IconButton>
        </UIBox>
        {job?.description
          ?.split('\n')
          .map((d, i) => <Typography key={i}>{d}</Typography>)}
        <UIBox mb={1} />
        <ReadMoreButton onClick={() => null} color={theme.colors.secondary}>
          Read More
          <IconArrowDown color={theme.colors.secondary} />
        </ReadMoreButton>
      </UIBox>
      <UIBox>
        <UIBox display="flex" justifyContent="space-between">
          <Typography
            color={theme.colors.textPrimary}
            fontSize={18}
            fontWeight={600}
            marginBottom={5}
          >
            Job budget
          </Typography>
          <IconButton onClick={() => onClickGotoStep(JobPostingSteps.STEP_3)}>
            <IconEdit />
          </IconButton>
        </UIBox>
        <Typography>
          ***$1,000 Sign-On Bonus Available!*** <br />
          Starting Pay Rate: $30.13 - $33.14 / hour
        </Typography>
      </UIBox>

      <UIBox>
        <UIBox display="flex" justifyContent="space-between">
          <Typography
            color={theme.colors.textPrimary}
            fontSize={18}
            fontWeight={600}
            marginBottom={5}
          >
            Skills
          </Typography>

          <IconButton onClick={() => onClickGotoStep(JobPostingSteps.STEP_2)}>
            <IconEdit />
          </IconButton>
        </UIBox>
        <UIBox display="flex" flexWrap="wrap" gap={10} mb={1} mt={1}>
          {job?.projectSkills?.map((skill, id) => (
            <Tag key={id}>{skill.name}</Tag>
          ))}
        </UIBox>
      </UIBox>

      <UIBox>
        <UIBox display="flex" justifyContent="space-between">
          <Typography
            color={theme.colors.textPrimary}
            fontSize={18}
            fontWeight={600}
            marginBottom={10}
          >
            Company Info
          </Typography>

          <IconButton onClick={() => onClickGotoStep(JobPostingSteps.STEP_1)}>
            <IconEdit />
          </IconButton>
        </UIBox>
        <UIBox display="flex" justifyContent="space-between" gap={30}>
          <UIBox flexGrow={1}>
            <Typography marginBottom={15}>{job?.companyData?.bio}</Typography>
            <ReadMoreButton onClick={() => null} color={theme.colors.secondary}>
              Read More
              <IconArrowDown color={theme.colors.secondary} />
            </ReadMoreButton>
          </UIBox>
          <UIBox minWidth={150} flexGrow={1}>
            <UIBox
              p={1}
              borderStyle="solid"
              borderWidth={1}
              borderColor="#AEAEBA33"
              borderRadius={1}
              mb={1}
            >
              <Image src={'job.companyData?.logo'} />
            </UIBox>
            <UIBox display="flex" gap={10}>
              <IconShieldDone />
              <Typography fontSize={14}>Technology</Typography>
            </UIBox>
            <UIBox display="flex" gap={10}>
              <IconUsers3 />
              <Typography fontSize={14}>
                ({job.companyData?.numEmployee} employees)
              </Typography>
            </UIBox>
            <UIBox display="flex" gap={10}>
              <IconEarth />
              <Typography fontSize={14}>{job.companyData?.website}</Typography>
            </UIBox>
          </UIBox>
        </UIBox>
      </UIBox>
    </UICard>
  );
};

export default JobReviewCard;
