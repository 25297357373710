import {
  UIBox,
  Image,
  UIContainer,
  UIFlexCenterBox,
  UIFlexColumnBox,
  UIFlexSpaceBox,
  Typography,
} from '@/components/UI';
import IdealFor from './IdealFor';

const helpOptions: Array<{
  question: string;
  bgColor: string;
  iconUrl: string;
}> = [
  {
    question: 'What jobs would be a good fit for me?',
    bgColor: '#E2F3FF',
    iconUrl: '/images/ic_rocket_no_head.svg',
  },
  {
    question: 'What skills would I need to get a specific job?',
    bgColor: '#FFF2F2',
    iconUrl: '/images/ic_circle_rect.svg',
  },
  {
    question: 'What other careers should I consider?',
    bgColor: '#FFE7FB',
    iconUrl: '/images/ic_cloud.svg',
  },
];

export default function About() {
  return (
    <UIFlexColumnBox id="services" justifyContent="center" minHeight="1500px">
      <UIBox position="relative" width="100%">
        <UIContainer
          position="relative"
          pt="100px"
          pl="0px !important"
          pr="0px !important"
        >
          <Image
            src="/images/img_about_details_bg.png"
            alt=""
            width={1276}
            height={1071}
          />
          <Image
            src="/images/ic_pin.svg"
            alt=""
            width={44}
            height={44}
            position="absolute"
            bottom="20px"
            left="96px"
          />
        </UIContainer>
        <UIBox
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'100%'}
        >
          <UIContainer>
            <UIFlexSpaceBox
              position="relative"
              backgroundColor="#6DBEC30D"
              borderRadius="30px"
              pt="50px"
              pl="130px"
              pb="70px"
              boxShadow="0px 0px 20px 0px #BDBDBD26"
            >
              <Image
                src="/images/ic_rocket.svg"
                alt=""
                width={61}
                height={67}
                position="absolute"
                top="30px"
                left="60px"
              />
              <UIFlexColumnBox flex={1}>
                <Typography fontSize={18}>Individuals</Typography>
                <Typography variant="h2">We Specialize</Typography>
                <Typography fontSize={18}>
                  in connecting individuals from non-traditional backgrounds
                  with a wide range of employment opportunities, including both
                  permanent and project-based positions. Our mission is to
                  empower under served workers who may have been left behind by
                  the conventional hiring processes, guiding them through the
                  often rigid and exclusionary hiring procedures.
                </Typography>
              </UIFlexColumnBox>
              <UIFlexCenterBox flex={1}>
                <Image
                  src="/images/img_individuals.png"
                  alt="individuals"
                  width={177}
                  height={244}
                />
              </UIFlexCenterBox>
            </UIFlexSpaceBox>
            <UIFlexSpaceBox
              position="relative"
              backgroundColor="#6DC37B0D"
              borderRadius="30px"
              p="90px"
              mt="150px"
              boxShadow="0px 0px 20px 0px #BDBDBD26"
            >
              <Image
                src="/images/ic_key.svg"
                alt=""
                width={58}
                height={55}
                position="absolute"
                top="36px"
                right="45px"
              />
              <UIFlexCenterBox flex={1}>
                <Image
                  src="/images/img_employers.png"
                  alt="individuals"
                  width={288}
                  height={224}
                />
              </UIFlexCenterBox>
              <UIFlexColumnBox flex={1}>
                <Typography fontSize={18}>Employers</Typography>
                <Typography variant="h2">We assist employers</Typography>
                <Typography fontSize={18}>
                  seeking a more diverse candidate pool in overcoming the
                  perceived challenges and complexities associated with hiring
                  non-traditional candidates. By fostering a workplace culture
                  that values diversity and inclusion, we empower organizations
                  to harness the unique perspectives and experiences that
                  non-traditional candidates bring, ultimately driving
                  innovation and success.
                </Typography>
              </UIFlexColumnBox>
            </UIFlexSpaceBox>
          </UIContainer>
        </UIBox>
      </UIBox>
      <UIContainer>
        <UIFlexSpaceBox borderRadius="30px" px="90px">
          <UIFlexColumnBox flex={1}>
            <Typography variant="h2">
              We help you identify your skills that can lead to new career
              opportunities
            </Typography>
          </UIFlexColumnBox>
          <UIFlexColumnBox flex={1}>
            {helpOptions.map((option, id) => (
              <UIBox display="flex" alignItems="center" key={id} mb={1}>
                <UIFlexCenterBox
                  width="60px"
                  height="60px"
                  backgroundColor={option.bgColor}
                  borderRadius="12px"
                  mr="36px"
                >
                  <Image
                    src={option.iconUrl}
                    alt="rocket_no_head"
                    width={28}
                    height={28}
                  />
                </UIFlexCenterBox>
                <Typography fontSize={18}>{option.question}</Typography>
              </UIBox>
            ))}
          </UIFlexColumnBox>
        </UIFlexSpaceBox>
      </UIContainer>
      <IdealFor />
    </UIFlexColumnBox>
  );
}
