import { IndustryJson } from '@/types';
import { faker } from '@faker-js/faker';
import { createRandomSkill } from './skills.mock';

export function createRandomIndustry(): IndustryJson.Industry {
  return {
    id: faker.string.uuid(),
    name: faker.helpers.arrayElement([
      'Elements, compound',
      'Software engineering',
      'Recruiting',
    ]),
    description: faker.lorem.paragraphs(),
    createdAt: faker.date.past({ years: 1 }).toLocaleDateString(),
    isDefault: false,
    skills: faker.helpers.multiple(createRandomSkill, {
      count: Math.round(Math.random() * 10),
    }),
    parent: {
      id: faker.string.uuid(),
      name: faker.helpers.arrayElement([
        'Science and Research',
        'Technology',
        'Blockchain',
      ]),
      createdAt: faker.date.past({ years: 1 }).toLocaleDateString(),
      isDefault: false,
      skills: [],
    },
  };
}

export const industriesMock: IndustryJson.Industry[] = faker.helpers.multiple(
  createRandomIndustry,
  { count: 10 }
);
