import { UIBox, UIDivider, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { ProjectBudgetType } from '@/enums';
import { ProjectJson } from '@/types';
import { formatCurrency } from '@/utils';

interface JobPropertiesProps {
  job?: ProjectJson.Project;
  skillsMatching?: number;
}
const JobProperties: React.FC<JobPropertiesProps> = ({
  job,
  skillsMatching,
}) => {
  return (
    <UIBox background={theme.colors.gray950} borderRadius={10} p={1}>
      <UIBox display="flex" alignItems="center" justifyContent="space-between">
        <Typography color={`${theme.colors.primary}CC`}>Hours</Typography>
        <Typography color={theme.colors.textPrimary}>
          {job?.employmentType}
        </Typography>
      </UIBox>
      <UIDivider mt={0.5} mb={0.5} background={`${theme.colors.primary}18`} />
      <UIBox display="flex" alignItems="center" justifyContent="space-between">
        <Typography color={`${theme.colors.primary}CC`}>Location</Typography>
        <Typography color={theme.colors.textPrimary}>
          {job?.location?.city}, {job?.location?.state}
        </Typography>
      </UIBox>
      <UIDivider mt={0.5} mb={0.5} background={`${theme.colors.primary}18`} />
      <UIBox display="flex" alignItems="center" justifyContent="space-between">
        <Typography color={`${theme.colors.primary}CC`}>Salary</Typography>
        <Typography color={theme.colors.textPrimary}>
          {job?.budget === ProjectBudgetType.HOURLY
            ? `${formatCurrency(job?.minRate)} - ${formatCurrency(job?.maxRate)}`
            : `${formatCurrency(job?.minSalary)} - ${formatCurrency(job?.maxSalary)}`}
        </Typography>
      </UIBox>
      {skillsMatching && (
        <>
          <UIDivider
            mt={0.5}
            mb={0.5}
            background={`${theme.colors.primary}18`}
          />
          <UIBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography color={`${theme.colors.primary}CC`}>
              Skills matching
            </Typography>
            <Typography color={theme.colors.textPrimary}>
              {skillsMatching}%
            </Typography>
          </UIBox>
        </>
      )}
    </UIBox>
  );
};

export default JobProperties;
