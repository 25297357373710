import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconServer({
  width = 18,
  height = 18,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      className="stroke-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V6C1.5 6.82843 2.17157 7.5 3 7.5H15C15.8284 7.5 16.5 6.82843 16.5 6V3C16.5 2.17157 15.8284 1.5 15 1.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 10.5H3C2.17157 10.5 1.5 11.1716 1.5 12V15C1.5 15.8284 2.17157 16.5 3 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V12C16.5 11.1716 15.8284 10.5 15 10.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 4.5H4.50833"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 13.5H4.50833"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
