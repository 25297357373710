import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconFacebook({
  width = 12,
  height = 12,
  color = theme.colors.white,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_449_2333)">
        <path
          d="M11.4308 11.5003V11.4998H11.4336V7.46555C11.4336 5.49197 11.0087 3.97168 8.70147 3.97168C7.5923 3.97168 6.84797 4.58035 6.5441 5.15739H6.51201V4.15593H4.32439V11.4998H6.6023V7.86339C6.6023 6.90593 6.7838 5.9801 7.96951 5.9801C9.1378 5.9801 9.15522 7.07276 9.15522 7.92481V11.5003H11.4308Z"
          fill={color}
        />
        <path
          d="M0.615097 4.15625H2.89576V11.5001H0.615097V4.15625Z"
          fill={color}
        />
        <path
          d="M1.75451 0.5C1.0253 0.5 0.433594 1.09171 0.433594 1.82092C0.433594 2.55013 1.0253 3.15421 1.75451 3.15421C2.48372 3.15421 3.07543 2.55013 3.07543 1.82092C3.07497 1.09171 2.48326 0.5 1.75451 0.5V0.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_449_2333">
          <rect
            width="11"
            height="11"
            fill={color}
            transform="translate(0.433594 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
