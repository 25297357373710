import {
  AdminAsideBar,
  AdminNavbar,
  DashboardLayout,
} from '@/components/App/Layout';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

const AdminLayout: React.FC = () => {
  const [expandedAsidebar, setExpandedAsidebar] = useState(true);
  return (
    <DashboardLayout
      setGlobalExpanded={setExpandedAsidebar}
      asideBar={<AdminAsideBar expanded={expandedAsidebar} />}
      navBar={<AdminNavbar />}
    >
      <Outlet context={{}} />
    </DashboardLayout>
  );
};

export default AdminLayout;
