import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconCircleClose({
  width = 16,
  height = 16,
  color = theme.colors.companyBrand,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="8" cy="8" r="8" fill={color} />
      <path
        d="M10.8061 9.87063L8.93557 8.00025L10.8061 6.12988C11.0638 5.87187 11.0638 5.45307 10.8068 5.19435C10.5485 4.935 10.1302 4.93566 9.87188 5.19368L8 7.06539L6.12812 5.19368C5.86977 4.93566 5.45152 4.935 5.19318 5.19435C4.9355 5.45303 4.9355 5.87183 5.19384 6.12988L7.06442 8.00025L5.19384 9.87063C4.9355 10.1286 4.9355 10.5474 5.19318 10.8062C5.32203 10.9358 5.49183 11 5.661 11C5.83016 11 5.99929 10.9352 6.12815 10.8068L8.00003 8.93509L9.87191 10.8068C10.0008 10.9358 10.1699 11 10.3391 11C10.5082 11 10.678 10.9352 10.8069 10.8062C11.0645 10.5474 11.0645 10.1286 10.8061 9.87063Z"
        fill="white"
      />
    </svg>
  );
}
