import {
  DashboardLayout,
  OrgAsideBar,
  OrgNavbar,
} from '@/components/App/Layout';
import { Outlet } from 'react-router-dom';
const OrgLayout: React.FC = () => {
  return (
    <DashboardLayout asideBar={<OrgAsideBar />} navBar={<OrgNavbar />}>
      <Outlet context={{}} />
    </DashboardLayout>
  );
};

export default OrgLayout;
