import {
  AppAnchorScrollingProvider,
  AppConfirmModalProvider,
  AppReduxProvider,
  AppThemeProvider,
  AuthProvider,
  ToastProvider,
} from '@/providers';
import AppRoutes from '@/pages';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <AppReduxProvider>
      <SnackbarProvider>
        <ToastProvider>
          <AuthProvider>
            <AppThemeProvider>
              <AppConfirmModalProvider>
                <AppAnchorScrollingProvider>
                  <AppRoutes />
                </AppAnchorScrollingProvider>
              </AppConfirmModalProvider>
            </AppThemeProvider>
          </AuthProvider>
        </ToastProvider>
      </SnackbarProvider>
    </AppReduxProvider>
  );
}

export default App;
