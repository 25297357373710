import {
  Autocomplete,
  UIBox,
  Button,
  UICard,
  Input,
  Typography,
  Spinner,
} from '@/components/UI';
import { appRoutes, orgSizeOptions, theme } from '@/constants';
import { useAuth } from '@/hooks';
import {
  useIndustriesQuery,
  useUpdateOrganizationMutation,
} from '@/redux/apis';
import { OrganizationJson } from '@/types';
import { formatIndustriesToGrouped, inputStatus } from '@/utils';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  website: Yup.string().required('Website is required'),
  industryId: Yup.string().required('Industry is required'),
  numEmployee: Yup.string().required('Number of employees is required'),
});
type FormikData = Pick<
  OrganizationJson.OrganizationInput,
  'id' | 'website' | 'industryId' | 'numEmployee'
>;
export default function AuthCompanyInfoPage() {
  const { org, refetchUser } = useAuth();
  const { data: { data: industries = [] } = {}, isFetching } =
    useIndustriesQuery({ limit: 1000 });
  const [updateOrg, { isLoading: isUpdatingOrg, isSuccess: isUpdatedOrg }] =
    useUpdateOrganizationMutation();
  const navigate = useNavigate();
  const formik = useFormik<FormikData>({
    initialValues: {
      id: org?.id,
      website: org?.website,
      industryId: org?.industryId,
      numEmployee: org?.numEmployee,
    },
    validationSchema,
    onSubmit: (v) => {
      const orgId = org?.id;
      if (!orgId) return;
      updateOrg({
        ...v,
        id: orgId,
      });
    },
  });
  const groupedIndustries = useMemo(
    () => formatIndustriesToGrouped(industries),
    [industries]
  );
  useEffect(() => {
    if (!isUpdatingOrg && isUpdatedOrg) {
      refetchUser();
      navigate(appRoutes.authAccountCreated);
    }
  }, [isUpdatingOrg, isUpdatedOrg]);
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={520}
        px={4}
        py={2}
        borderRadius={32}
        boxShadow="0px 4px 35px 0px #00000014"
      >
        <Typography
          variant="h3"
          textAlign="center"
          lineHeight="35px"
          marginBottom={0}
          fontSize={26}
        >
          Company information
        </Typography>
        <Typography
          textAlign="center"
          fontWeight={400}
          marginBottom={30}
          color={theme.colors.primary}
        >
          Provide more information about your organization
        </Typography>
        <UIBox
          display="flex"
          flexDirection="column"
          gap={15}
          width={'100%'}
          mb={2}
        >
          <Autocomplete
            variant="primary"
            label="Company industry"
            placeholder="Company industry"
            fullWidth
            isLoading={isFetching}
            options={groupedIndustries}
            getOptionLabel={(industry) => industry.name}
            getOptionValue={(industry) => industry.id}
            value={industries.find((m) => formik.values.industryId === m.id)}
            onChange={(v) => formik.setFieldValue('industryId', v?.id)}
            isClearable
            onBlur={() => formik.setFieldTouched('industryId')}
            {...inputStatus(formik, 'industryId')}
          />
          <Input
            label="Company website url"
            placeholder="Company website url"
            fullWidth
            {...formik.getFieldProps('website')}
            {...inputStatus(formik, 'website')}
          />
          <Autocomplete
            variant="primary"
            label="Your company's number of employees"
            placeholder="Company Size"
            fullWidth
            options={orgSizeOptions}
            value={orgSizeOptions.find(
              (m) => formik.values.numEmployee === m.value
            )}
            onChange={(v) => formik.setFieldValue('numEmployee', v?.value)}
            isClearable
            onBlur={() => formik.setFieldTouched('numEmployee')}
            {...inputStatus(formik, 'numEmployee')}
          />
        </UIBox>
        <Button
          fullWidth
          rounded
          onClick={() => formik.handleSubmit()}
          disabled={isUpdatingOrg}
        >
          {isUpdatingOrg && <Spinner size="sm" />}
          Next
        </Button>
      </UICard>
    </UIBox>
  );
}
