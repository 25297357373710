import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconEllipsisVertical({
  width = 4,
  height = 15,
  color = theme.colors.primary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 4 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8C1.58579 8 1.25 7.66421 1.25 7.25C1.25 6.83579 1.58579 6.5 2 6.5C2.41421 6.5 2.75 6.83579 2.75 7.25C2.75 7.66421 2.41421 8 2 8Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2.5C1.58579 2.5 1.25 2.16421 1.25 1.75C1.25 1.33579 1.58579 1 2 1C2.41421 1 2.75 1.33579 2.75 1.75C2.75 2.16421 2.41421 2.5 2 2.5Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 13.5C1.58579 13.5 1.25 13.1642 1.25 12.75C1.25 12.3358 1.58579 12 2 12C2.41421 12 2.75 12.3358 2.75 12.75C2.75 13.1642 2.41421 13.5 2 13.5Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
