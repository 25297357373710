import { AppModal } from '@/components/App';
import { IconTickSquare } from '@/assets/icons';
import { UIBox, Button, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { IndustryJson } from '@/types';
const NewIndustryPublishedModal: React.FC<{
  createdIndustry?: IndustryJson.Industry;
  show: boolean;
  onClose: () => void;
  onLookIndustries: () => void;
}> = ({ show, createdIndustry, onClose, onLookIndustries }) => {
  return (
    <AppModal
      isOpen={show}
      header={
        <UIBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={10}
        >
          <Typography variant="h6" marginBottom={0}>
            New industry published
          </Typography>
          <IconTickSquare />
        </UIBox>
      }
      headerAlign="center"
      onClose={onClose}
    >
      <UIBox display="flex" flexDirection="column" alignItems="center" gap={20}>
        <UIBox display="flex" flexDirection="column" alignItems="center">
          <Typography textAlign="center">
            You’re all set! We’ve published
            <br />
            <Typography
              variant="span"
              color={theme.colors.textPrimary}
              style={{ textDecoration: 'underline' }}
            >
              {createdIndustry?.name}
            </Typography>{' '}
            industry.
          </Typography>
        </UIBox>
        <Button variant="secondary" onClick={onLookIndustries}>
          Look my Industries
        </Button>
      </UIBox>
    </AppModal>
  );
};

export default NewIndustryPublishedModal;
