import { createApi } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { parseBaseQueryWithErrorHandler } from '@/utils/data.util';
import { ResumeJson } from '@/types';

export const resumeApi = createApi({
  reducerPath: 'resumeApi',
  baseQuery: parseBaseQueryWithErrorHandler,
  tagTypes: ['Resume'],
  endpoints: (builder) => ({
    getParsedLinkedInResume: builder.mutation<
      ResumeJson.LinkedinResume,
      {
        file_link: string;
        access_token: string;
        user_id: string;
        resume_type: 'linkedin';
      }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/linkedin`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as ResumeJson.LinkedinResume,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    getParsedAffindaResume: builder.mutation<
      ResumeJson.AffindaResume,
      {
        file_link: string;
        access_token: string;
        user_id: string;
        resume_type: 'affinda';
      }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/affinda`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as ResumeJson.AffindaResume,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const {
  useGetParsedLinkedInResumeMutation,
  useGetParsedAffindaResumeMutation,
} = resumeApi;
