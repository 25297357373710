import { IconArrowUpCircle } from '@/assets/icons';
import {
  Autocomplete,
  UIBox,
  Checkbox,
  UIDivider,
  UIGrid,
  IconButton,
  Typography,
} from '@/components/UI';
import { theme } from '@/constants';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.textPurple};
  margin-bottom: 1rem;
`;
const SponsorJobFilter: React.FC = () => {
  return (
    <UIBox>
      <UIBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          color={theme.colors.textPrimary}
          fontSize={18}
          fontWeight={600}
        >
          Filters
        </Typography>
        <IconButton>
          <IconArrowUpCircle />
        </IconButton>
      </UIBox>
      <UIDivider />

      <UIBox>
        <StyledLabel>Job Types</StyledLabel>
        <UIGrid columns={2} gap={15}>
          <Checkbox id="chk-0" label="Remote" />
          <Checkbox id="chk-1" label="Onsite" />
          <Checkbox id="chk-2" label="Hybrid" />
        </UIGrid>
      </UIBox>
      <UIDivider />

      <UIBox>
        <StyledLabel>Employment Types</StyledLabel>
        <UIGrid columns={2} gap={15}>
          <Checkbox id="chk-10" label="Full-time" />
          <Checkbox id="chk-11" label="Contract" />
          <Checkbox id="chk-12" label="Part-time" />
          <Checkbox id="chk-13" label="Freelance" />
        </UIGrid>
      </UIBox>
      <UIDivider />

      <UIBox>
        <StyledLabel>Last Activity</StyledLabel>
        <UIGrid columns={2} gap={15}>
          <Checkbox id="chk-20" label="Any time" />
          <Checkbox id="chk-21" label="Within 1 month" />
          <Checkbox id="chk-22" label="Within 2 weeks" />
          <Checkbox id="chk-23" label="within 2 months" />
        </UIGrid>
      </UIBox>
      <UIDivider />

      <UIBox>
        <StyledLabel>Salary</StyledLabel>
        <UIGrid columns={2} gap={15}>
          <Autocomplete label="From" fullWidth height={40} />
          <Autocomplete label="to" fullWidth height={40} />
        </UIGrid>
      </UIBox>
      <UIDivider />

      <UIBox>
        <StyledLabel>Hourly Rate</StyledLabel>
        <UIGrid columns={2} gap={15}>
          <Autocomplete label="From" fullWidth height={40} />
          <Autocomplete label="to" fullWidth height={40} />
        </UIGrid>
      </UIBox>
    </UIBox>
  );
};

export default SponsorJobFilter;
