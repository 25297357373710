import { AppUserAvatar } from '@/components/App';
import { UIBox, UIGrid, Input, Typography } from '@/components/UI';
import * as Yup from 'yup';
import { theme } from '@/constants';
import { useAuth } from '@/hooks';
import { useUpdateUserMutation } from '@/redux/apis';
import { UserJson } from '@/types';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import ProfileEditButton from './ProfileEditButton';
import LogoChangeModal from './LogoChangeModal';
import { getUserAvatarUrl, inputStatus } from '@/utils';

type FormikData = Omit<UserJson.UserInfoInput, 'profession' | 'summary'>;
const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
});

const OrgAccountSettingsForm: React.FC = () => {
  const [showLogoChangeModal, setShowLogoChangeModal] = useState(false);
  const { user, refetchUser } = useAuth();
  const [editing, setEditing] = useState(false);
  const [updateUserInfo, { isLoading: saving, isSuccess: isSaved }] =
    useUpdateUserMutation();
  const initialValues: FormikData = {
    id: user?.id || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    userName: user?.userName || '',
    phone: user?.phone || '',
    address1: user?.address1,
    address2: user?.address2,
    zipcode: user?.zipcode,
    city: user?.city,
    state: user?.state,
    country: user?.country,
  };
  const formik = useFormik<FormikData>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await updateUserInfo(values);
    },
  });
  useEffect(() => {
    if (isSaved) {
      refetchUser();
      setEditing(false);
    }
  }, [isSaved]);
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnCancel = () => {
    setEditing(false);
  };
  return (
    <UIBox display="flex" gap={30}>
      <AppUserAvatar
        user={user}
        size={132}
        editing={editing}
        onClickEdit={() => setShowLogoChangeModal(true)}
      />
      <UIBox display="flex" gap={20} flexGrow={1} flexDirection="column">
        <UIBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
        >
          <Typography color={theme.colors.textPrimary} fontWeight={600}>
            Info
          </Typography>

          <ProfileEditButton
            editing={editing}
            onClickSave={() => formik.handleSubmit()}
            onClickCancel={handleOnCancel}
            onClickEdit={handleOnEdit}
            fullWidth
            saving={saving}
          />
        </UIBox>
        <UIGrid columns={2} gap={20}>
          <Input
            label="First name"
            variant="secondary"
            fullWidth
            {...formik.getFieldProps('firstName')}
            {...inputStatus(formik, 'firstName')}
            disabled={!editing}
          />
          <Input
            label="Last name"
            variant="secondary"
            fullWidth
            {...formik.getFieldProps('lastName')}
            {...inputStatus(formik, 'lastName')}
            disabled={!editing}
          />
          <Input
            label="Email"
            variant="secondary"
            fullWidth
            defaultValue={user?.email}
            readOnly
          />
          <Input
            label="Phone"
            variant="secondary"
            fullWidth
            {...formik.getFieldProps('phone')}
            disabled={!editing}
          />
          <Input
            label="Username"
            variant="secondary"
            fullWidth
            {...formik.getFieldProps('userName')}
            disabled={!editing}
          />
          <Input
            label="Company"
            variant="secondary"
            fullWidth
            defaultValue={user?.organizations?.[0]?.name}
            readOnly
          />
        </UIGrid>
      </UIBox>

      <LogoChangeModal
        show={showLogoChangeModal}
        onClose={() => setShowLogoChangeModal(false)}
        onUploaded={() => {
          setShowLogoChangeModal(false);
          refetchUser();
        }}
        url={getUserAvatarUrl(user)}
        userId={user?.id || ''}
      />
    </UIBox>
  );
};

export default OrgAccountSettingsForm;
