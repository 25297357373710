import {
  Autocomplete,
  UIBox,
  Checkbox,
  UIGrid,
  Input,
  RichEditor,
} from '@/components/UI';
import { monthOptions, pastYears } from '@/constants';
import { UserJson } from '@/types';
import { forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getFormatISODate, getMonthYearFromString, inputStatus } from '@/utils';
import { useCertificationsQuery } from '@/redux/apis';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  issuedMonth: Yup.string().required('issued month is required'),
  issuedYear: Yup.number().required('Issued year is required'),
});

export interface CertificationEditFormHandle {
  submitForm: () => void;
}

type FormikData = Omit<UserJson.CertificationInput, 'issuedAt' | 'expireAt'> & {
  issuedMonth?: string;
  issuedYear?: number;
  expireMonth?: string;
  expireYear?: number;
};

interface CertificationEditFormProps {
  certification?: UserJson.Certification;
  onSubmit: (updatedCertification: UserJson.CertificationInput) => void;
}
const CertificationEditForm = forwardRef<
  CertificationEditFormHandle,
  CertificationEditFormProps
>(function ({ certification, onSubmit }, ref) {
  const { data: { data: certifications = [] } = {}, isFetching } =
    useCertificationsQuery({ limit: 1000 });
  const formik = useFormik<FormikData>({
    initialValues: {
      id: certification?.id,
      name: certification?.name || '',
      providerName: certification?.providerName || '',
      description: certification?.description,
      credentialId: certification?.credentialId,
      credentialUrl: certification?.credentialUrl,
      isVerified: certification?.isVerified,
      issuedMonth: getMonthYearFromString(certification?.issuedAt).month,
      issuedYear: getMonthYearFromString(certification?.issuedAt).year,
      expireMonth: getMonthYearFromString(certification?.expireAt).month,
      expireYear: getMonthYearFromString(certification?.expireAt).year,
    },
    validationSchema,
    onSubmit: (values) => {
      const { issuedMonth, issuedYear, expireMonth, expireYear, ...v } = values;
      const saveToValue: UserJson.CertificationInput = {
        ...v,
        issuedAt: getFormatISODate(`${issuedMonth} ${issuedYear}`),
        expireAt:
          expireMonth && expireYear
            ? getFormatISODate(`${expireMonth} ${expireYear}`)
            : undefined,
      };
      onSubmit(saveToValue);
    },
  });

  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
  }));
  return (
    <UIBox display="flex" flexDirection="column" gap={20} mt={1.5}>
      <UIGrid columns={2} gap={20}>
        <Input
          label="Name"
          fullWidth
          variant="secondary"
          {...formik.getFieldProps('name')}
          {...inputStatus(formik, 'name')}
        />
        <Input
          label="Provider Name"
          fullWidth
          variant="secondary"
          {...formik.getFieldProps('providerName')}
        />
        <Input
          label="Credential Id"
          fullWidth
          variant="secondary"
          {...formik.getFieldProps('credentialId')}
          {...inputStatus(formik, 'credentialId')}
        />
        <Input
          label="Credential Url"
          fullWidth
          variant="secondary"
          {...formik.getFieldProps('credentialUrl')}
          {...inputStatus(formik, 'credentialUrl')}
        />

        <Autocomplete
          label="Issued (month)"
          fullWidth
          isClearable
          options={monthOptions}
          value={monthOptions.find(
            (m) => formik.values.issuedMonth === m.value
          )}
          onChange={(v) => formik.setFieldValue('issuedMonth', v?.value)}
          onBlur={() => formik.setFieldTouched('issuedMonth')}
          {...inputStatus(formik, 'issuedMonth')}
        />
        <Autocomplete
          label="Issued (or expected), year"
          fullWidth
          isClearable
          options={pastYears}
          value={pastYears.find((m) => formik.values.issuedYear === m.value)}
          onChange={(v) => formik.setFieldValue('issuedYear', v?.value)}
          onBlur={() => formik.setFieldTouched('issuedYear')}
          {...inputStatus(formik, 'issuedYear')}
        />
        <Autocomplete
          label="Expire (month)"
          fullWidth
          isClearable
          options={monthOptions}
          value={monthOptions.find(
            (m) => formik.values.expireMonth === m.value
          )}
          onChange={(v) => formik.setFieldValue('expireMonth', v?.value)}
        />
        <Autocomplete
          label="Expire (or expected), year"
          fullWidth
          isClearable
          options={pastYears}
          value={pastYears.find((m) => formik.values.expireYear === m.value)}
          onChange={(v) => formik.setFieldValue('expireYear', v?.value)}
        />
      </UIGrid>
      <Checkbox
        id={`work-here-${certification?.id || '0'}`}
        label="Is verified?"
        checked={formik.values.isVerified}
        {...formik.getFieldProps('isVerified')}
        onChange={(v) => formik.setFieldValue('isVerified', v.target.checked)}
      />
      <Autocomplete
        label="Certification (Optional)"
        fullWidth
        isLoading={isFetching}
        options={certifications}
        getOptionLabel={(cert) => cert.name}
        getOptionValue={(cert) => cert.id}
        value={certifications.find(
          (m) => formik.values.certificationId === m.id
        )}
        onChange={(v) => formik.setFieldValue('certificationId', v?.id)}
        isClearable
      />
      <UIBox>
        <RichEditor
          label="Description (Optional)"
          defaultValue={formik.values.description}
          onChange={(v) => formik.setFieldValue('description', v)}
        />
      </UIBox>
    </UIBox>
  );
});

export default CertificationEditForm;
