import { UIBox, Button, IconButton, Spinner } from '@/components/UI';
import { IconDelete, IconEdit } from '@/assets/icons';
import { useTheme } from 'styled-components';

interface ProfileEditButtonProps {
  editing?: boolean;
  saving?: boolean;
  hasDelete?: boolean;
  onClickEdit?: () => void;
  onClickSave?: () => void;
  onClickCancel?: () => void;
  onClickDelete?: () => void;
  fullWidth?: boolean;
}
const ProfileEditButton: React.FC<ProfileEditButtonProps> = ({
  editing,
  saving,
  onClickCancel,
  onClickEdit,
  onClickSave,
  onClickDelete,
  hasDelete,
  fullWidth,
}) => {
  const theme = useTheme();
  return !editing ? (
    <UIBox display="flex" alignItems="center" gap={10}>
      <IconButton
        type="button"
        onClick={() => {
          onClickEdit?.();
        }}
      >
        <IconEdit color={theme.colors.primary} />
      </IconButton>
      {hasDelete && (
        <IconButton type="button" onClick={() => onClickDelete?.()}>
          <IconDelete />
        </IconButton>
      )}
    </UIBox>
  ) : (
    <UIBox display="flex" alignItems="center" gap={10}>
      <Button
        type="button"
        variant="outlined-secondary"
        onClick={() => {
          onClickCancel?.();
        }}
        size="sm"
        fullWidth={fullWidth}
      >
        Cancel
      </Button>
      <Button
        type="button"
        onClick={() => {
          onClickSave?.();
        }}
        size="sm"
        fullWidth={fullWidth}
        disabled={saving}
      >
        {saving && <Spinner size="sm" />}
        Save
      </Button>
    </UIBox>
  );
};

export default ProfileEditButton;
