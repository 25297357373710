import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconSetting({
  width = 11,
  height = 11,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      className="fill-icon"
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0516 6.35648C10.2453 6.4594 10.3948 6.6219 10.5 6.7844C10.7048 7.12023 10.6882 7.5319 10.4889 7.89482L10.1014 8.54482C9.8966 8.89148 9.51464 9.10815 9.12162 9.10815C8.92787 9.10815 8.71198 9.05398 8.53484 8.94565C8.39092 8.85357 8.22485 8.82107 8.04771 8.82107C7.49968 8.82107 7.04023 9.27065 7.02362 9.8069C7.02362 10.4298 6.51435 10.9173 5.87775 10.9173H5.12491C4.48278 10.9173 3.9735 10.4298 3.9735 9.8069C3.96243 9.27065 3.50298 8.82107 2.95495 8.82107C2.77228 8.82107 2.60621 8.85357 2.46782 8.94565C2.29068 9.05398 2.06926 9.10815 1.88105 9.10815C1.48248 9.10815 1.10053 8.89148 0.89571 8.54482L0.513753 7.89482C0.308936 7.54273 0.297864 7.12023 0.502682 6.7844C0.591251 6.6219 0.757319 6.4594 0.94553 6.35648C1.10053 6.28065 1.20017 6.15607 1.29427 6.00982C1.57105 5.54398 1.40498 4.9319 0.934459 4.65565C0.386434 4.3469 0.209295 3.65898 0.524824 3.12273L0.89571 2.48357C1.21677 1.94732 1.90319 1.75773 2.45675 2.0719C2.93835 2.3319 3.56387 2.15857 3.84619 1.69815C3.93476 1.54648 3.98458 1.38398 3.9735 1.22148C3.96243 1.01023 4.02332 0.809818 4.1285 0.647318C4.33332 0.311484 4.7042 0.0948177 5.1083 0.0839844H5.88882C6.29846 0.0839844 6.66934 0.311484 6.87416 0.647318C6.9738 0.809818 7.04023 1.01023 7.02362 1.22148C7.01255 1.38398 7.06237 1.54648 7.15094 1.69815C7.43326 2.15857 8.05878 2.3319 8.54591 2.0719C9.09394 1.75773 9.78589 1.94732 10.1014 2.48357L10.4723 3.12273C10.7934 3.65898 10.6162 4.3469 10.0627 4.65565C9.59214 4.9319 9.42607 5.54398 9.70839 6.00982C9.79696 6.15607 9.8966 6.28065 10.0516 6.35648ZM3.93476 5.50607C3.93476 6.35648 4.63778 7.03357 5.50687 7.03357C6.37596 7.03357 7.06237 6.35648 7.06237 5.50607C7.06237 4.65565 6.37596 3.97315 5.50687 3.97315C4.63778 3.97315 3.93476 4.65565 3.93476 5.50607Z"
        fill={color}
      />
    </svg>
  );
}
