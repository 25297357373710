import { UIBox, Button, Typography } from '@/components/UI';
import { appRoutes } from '@/constants';
import { useAuth } from '@/hooks/useAuth';
import {
  ProfileAbout,
  ProfileCertifications,
  ProfileEducation,
  ProfileExperience,
  // ProfileProjects,
  ProfileSkills,
  UserInfo,
} from '@/modules/Profile';
import { useUserByIdQuery } from '@/redux/apis';
import { Link } from 'react-router-dom';
import ReactJoyride, { Step } from 'react-joyride';
import { useRef } from 'react';

const steps: Step[] = [
  {
    target: '#profile-photo',
    content:
      'This is your profile photo and name. You can update your photo by clicking here.',
    placement: 'right',
  },
  {
    target: '#profile-contact-information',
    content:
      'Here is your contact information. You can edit these details by clicking the pencil icon.',
    placement: 'right',
  },
  {
    target: '#profile-action-buttons',
    content:
      'Use these buttons to download your profile, update it from a file, or delete your profile.',
    placement: 'right',
  },
  {
    target: '#profile-public-view-button',
    content: 'Click here to see how your profile looks to the public.',
    placement: 'bottom',
  },
  {
    target: '#profile-about-section',
    content:
      'In the About section, you can tell more about yourself. Click the edit button to add more information about you.',
  },
  {
    target: '#profile-projects-section',
    content:
      'This section lists your projects. Each project shows the role you had and your rate. Add more projects by clicking the plus icon.',
  },
  {
    target: '#profile-experience-section',
    content:
      'Your professional experience is listed here. You can add more experiences as your career progresses.',
    placement: 'top',
  },
  {
    target: '#profile-education-section',
    content:
      'This section shows your academic background. You can add new entries or edit existing ones using the plus and edit icons.',
    placement: 'top',
  },
  {
    target: '#profile-skills-section',
    content:
      'Here are your professional skills. Click on a skill to see more details or use the edit button to modify your skills list.',
    placement: 'top',
  },
  {
    target: '#profile-certifications-section',
    content:
      'Your certifications are listed here. Add any new certificates you acquire to enhance your profile.',
    placement: 'top',
  },
];
export interface RefetchHandleRef {
  refetch: () => void;
}

const Profile: React.FC = () => {
  const { user, refetchUser } = useAuth();
  const projectRef = useRef<RefetchHandleRef>(null);
  const {
    data: userInfo,
    isLoading,
    refetch,
  } = useUserByIdQuery(
    { userId: user?.id || '' },
    {
      skip: !user,
    }
  );
  const handleRefetch = () => {
    refetchUser();
    refetch();
  };
  const handleRefetchAll = () => {
    handleRefetch();
    projectRef.current?.refetch();
  };

  return (
    <>
      <UIBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1.5}
      >
        <Typography
          variant="h3"
          fontWeight={600}
          marginTop={0}
          marginBottom={0}
        >
          My Profile
        </Typography>
        <Link
          to={appRoutes.workerPublicProfile}
          id="profile-public-view-button"
        >
          <Button variant="secondary" fontWeight={500} size="md">
            See public view
          </Button>
        </Link>
      </UIBox>
      <UIBox display="flex" gap={20}>
        <UIBox width={300} minWidth={300}>
          <UIBox position="sticky" top={80}>
            <UserInfo
              user={userInfo}
              loading={isLoading}
              refetch={handleRefetch}
              refetchAll={handleRefetchAll}
            />
          </UIBox>
        </UIBox>
        <UIBox display="flex" flexDirection="column" gap={20} flexGrow={1}>
          <ProfileAbout
            user={userInfo}
            loading={isLoading}
            refetch={handleRefetch}
          />
          {/* <ProfileProjects userId={user?.id} ref={projectRef} /> */}
          <ProfileExperience
            experiences={user?.userExperiences}
            isLoading={isLoading}
            refetch={handleRefetch}
          />
          <ProfileEducation
            educations={user?.userEducations}
            isLoading={isLoading}
            refetch={handleRefetch}
          />
          <ProfileSkills
            skills={user?.userSkills}
            isLoading={isLoading}
            refetch={handleRefetch}
          />
          <ProfileCertifications
            certifications={user?.userCertifications}
            isLoading={isLoading}
            refetch={handleRefetch}
          />
          {/* <ProfileAssessment />
          <ProfileMentor /> */}
        </UIBox>
      </UIBox>
      <ReactJoyride
        steps={steps}
        scrollOffset={80}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
      />
    </>
  );
};

export default Profile;
