import { IconPaperclip, IconSend } from '@/assets/icons';
import { AppModal } from '@/components/App';
import {
  Autocomplete,
  Button,
  IconButton,
  Label,
  RichEditor,
  Typography,
  UIBox,
} from '@/components/UI';
import { theme } from '@/constants';
import { useState } from 'react';

interface InviteToJobModalProps {
  show: boolean;
  onClose: () => void;
}
const InviteToJobModal: React.FC<InviteToJobModalProps> = ({
  show,
  onClose,
}) => {
  const [showSentModal, setShowSentModal] = useState(false);
  const handleSendInvite = () => {
    setShowSentModal(true);
  };
  return (
    <UIBox>
      <AppModal
        isOpen={show && !showSentModal}
        headerAlign="center"
        onClose={onClose}
        header="Invite to Job"
        width={440}
      >
        <UIBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={20}
        >
          <Autocomplete
            label="Choose job"
            height={40}
            labelSize={14}
            variant="secondary"
            fullWidth
          />

          <UIBox width={'100%'}>
            <Label variant="secondary" fontSize={14} marginBottom={5}>
              +Add a cover letter
            </Label>
            <UIBox position="relative">
              <RichEditor height={140} />
              <UIBox position="absolute" bottom={10} right={0}>
                <IconButton>
                  <IconPaperclip />
                </IconButton>
              </UIBox>
            </UIBox>
          </UIBox>
          <Button height={40} onClick={handleSendInvite} fullWidth size="sm">
            Send Invite
          </Button>
        </UIBox>
      </AppModal>

      <AppModal
        isOpen={showSentModal}
        header={
          <UIBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={10}
          >
            <Typography variant="h6" marginBottom={0}>
              Invite send!
            </Typography>
            <IconSend />
          </UIBox>
        }
        headerAlign="center"
        onClose={() => {
          setShowSentModal(false);
          onClose();
        }}
      >
        <UIBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={20}
        >
          <UIBox display="flex" flexDirection="column" alignItems="center">
            <Typography>
              You’re all set! We’ve sent your invitation to <br />
              <Typography
                color={theme.colors.textPrimary}
                style={{ textDecoration: 'underline' }}
                variant="span"
              >
                TypeScript Developer
              </Typography>{' '}
              job for{' '}
              <Typography
                color={theme.colors.textPrimary}
                style={{ textDecoration: 'underline' }}
                variant="span"
              >
                John Deo
              </Typography>
            </Typography>
          </UIBox>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSentModal(false);
              onClose();
            }}
            size="sm"
            width={200}
            height={40}
          >
            Look Job List
          </Button>
        </UIBox>
      </AppModal>
    </UIBox>
  );
};

export default InviteToJobModal;
