import { Outlet } from 'react-router-dom';
import AuthLayout from './layout';

function AuthPage() {
  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
}

export default AuthPage;
