import { IconInfoCircle } from '@/assets/icons';
import { UIBox } from './Box';
import { theme } from '@/constants';
import Label from './Label';
import { PropsWithChildren } from 'react';

export interface AdditionalTextProps {
  color?: string;
  fontSize?: string | number;
  textAlign?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset'
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase';
  fontWeight?: number;
  opacity?: number | string;
  lineHeight?: string;
  marginBottom?: string | number;
  marginTop?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;
}

interface UITypographyProps
  extends AdditionalTextProps,
    React.HTMLAttributes<HTMLHeadingElement>,
    React.HTMLAttributes<HTMLParagraphElement> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
}
export const UITypography: React.FC<UITypographyProps> = ({
  variant = 'p',
  children,
  color,
  fontSize,
  textAlign,
  textTransform,
  fontWeight,
  lineHeight,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  opacity,
  style,
  ...props
}) => {
  const styles = {
    ...(style || {}),
    color,
    fontSize,
    textAlign,
    textTransform,
    fontWeight,
    lineHeight,
    marginBottom,
    marginTop,
    marginLeft,
    marginRight,
    opacity,
  };
  switch (variant) {
    case 'h1':
      return (
        <h1 style={styles} {...props}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 style={styles} {...props}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 style={styles} {...props}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 style={styles} {...props}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 style={styles} {...props}>
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6 style={styles} {...props}>
          {children}
        </h6>
      );
    case 'span':
      return (
        <span style={styles} {...props}>
          {children}
        </span>
      );
    default:
      return (
        <p style={styles} {...props}>
          {children}
        </p>
      );
  }
};

interface StatusAlertProps extends PropsWithChildren {
  status?: 'error' | 'info' | 'valid';
}
export const StatusAlert: React.FC<StatusAlertProps> = ({
  status = 'info',
  children,
}) => {
  const helperTextColor =
    status === 'error'
      ? theme.colors.red500
      : status === 'info'
        ? theme.colors.blue300
        : theme.colors.textSecondary;
  return (
    <UIBox display="flex" alignItems="flex-start" gap={10} mt={0.4}>
      <IconInfoCircle width={14} height={14} color={helperTextColor} />
      <Label
        fontSize={12}
        fontWeight={400}
        lineHeight="15px"
        color={helperTextColor}
        marginBottom={0}
      >
        {children}
      </Label>
    </UIBox>
  );
};
