import { AppTypes } from '@/types';

export default function IconGoogle({
  width = 20,
  height = 20,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1616 10.2157C19.1616 9.539 19.1008 8.88832 18.988 8.26367H10V11.9552H15.136C14.9148 13.1481 14.2424 14.1588 13.2317 14.8355V17.23H16.3159C18.1205 15.5686 19.1616 13.1221 19.1616 10.2157Z"
        fill="#39B5D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99991 19.5445C12.5766 19.5445 14.7368 18.69 16.3158 17.2324L13.2316 14.8379C12.3771 15.4105 11.2839 15.7489 9.99991 15.7489C7.51431 15.7489 5.41045 14.0701 4.66 11.8145H1.47168V14.287C3.04198 17.4059 6.26935 19.5445 9.99991 19.5445Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66005 11.8139C4.46918 11.2413 4.36074 10.6296 4.36074 10.0006C4.36074 9.37166 4.46918 8.76002 4.66005 8.18742V5.71484H1.47172C0.825383 7.00319 0.456665 8.46071 0.456665 10.0006C0.456665 11.5406 0.825383 12.9981 1.47172 14.2864L4.66005 11.8139Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99991 4.25266C11.401 4.25266 12.659 4.73416 13.648 5.67981L16.3852 2.94262C14.7325 1.40268 12.5723 0.457031 9.99991 0.457031C6.26935 0.457031 3.04198 2.59559 1.47168 5.71451L4.66 8.18709C5.41045 5.93141 7.51431 4.25266 9.99991 4.25266Z"
        fill="#EA4335"
      />
    </svg>
  );
}
