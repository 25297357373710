import { IconTickSquare } from '@/assets/icons';
import { AppModal } from '@/components/App';
import { Button, Typography, UIBox } from '@/components/UI';
import { theme } from '@/constants';

interface ResumePublishedModalProps {
  show: boolean;
  onClose: () => void;
}
const ResumePublishedModal: React.FC<ResumePublishedModalProps> = ({
  show,
  onClose,
}) => {
  return (
    <AppModal
      isOpen={show}
      headerAlign="center"
      onClose={onClose}
      header={
        <UIBox display="flex" alignItems="center" gap={10}>
          New resume published
          <IconTickSquare />
        </UIBox>
      }
    >
      <UIBox display="flex" flexDirection="column" alignItems="center" gap={20}>
        <UIBox display="flex" flexDirection="column" alignItems="center">
          <Typography textAlign="center">
            You're all set! We've published{' '}
            <Typography
              variant="span"
              color={theme.colors.textPrimary}
              style={{ textDecoration: 'underline' }}
            >
              Team Lead
            </Typography>
            <br />
            and{' '}
            <Typography
              variant="span"
              color={theme.colors.textPrimary}
              style={{ textDecoration: 'underline' }}
            >
              Java Engineer
            </Typography>{' '}
            resumes.
          </Typography>
        </UIBox>
        <Button variant="secondary" height={40} onClick={onClose} width={170}>
          Look my resume
        </Button>
      </UIBox>
    </AppModal>
  );
};

export default ResumePublishedModal;
