import { UIBox, Button, Typography } from '@/components/UI';
import { ButtonVariant } from '../UI/Button';

interface AppEmptyItemsProps {
  title?: string;
  description?: string;
  hideButton?: boolean;
  buttonLabel?: string;
  onClickButton?: () => void;
  buttonVariant?: ButtonVariant;
}
const AppEmptyItems: React.FC<AppEmptyItemsProps> = ({
  title = "There's nothing there",
  description = 'Try add a new item, or browse all items',
  buttonLabel = 'Add a new item',
  onClickButton,
  buttonVariant = 'secondary',
  hideButton,
}) => {
  return (
    <UIBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={2}
      gap={20}
    >
      <Typography variant="h6" marginBottom={0}>
        {title}
      </Typography>
      <Typography>{description}</Typography>
      {!hideButton && (
        <UIBox display="flex" flexDirection="column" width={190} gap={20}>
          <Button size="sm" onClick={onClickButton} variant={buttonVariant}>
            {buttonLabel}
          </Button>
        </UIBox>
      )}
    </UIBox>
  );
};

export default AppEmptyItems;
