import {
  IconDelete,
  IconEdit1,
  IconEllipsis,
  IconSearch,
} from '@/assets/icons';
import {
  UIBox,
  Button,
  Dropdown,
  IconButton,
  Input,
  Typography,
  DataTableColumn,
  DataTable,
} from '@/components/UI';
import { DropdownButton } from '@/components/UI/Dropdown';
import { theme } from '@/constants';
import { SortDirection } from '@/enums';
import { useConfirmModal } from '@/hooks';
import { SidebarAddNewSkills } from '@/modules/Shared';
import { useDeleteSkillMutation, useSkillsQuery } from '@/redux/apis';
import { AppTypes, SkillJson } from '@/types';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
const AdminSkillsPage: React.FC = () => {
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();
  const [deleteSkill, { isSuccess: isDeleted }] = useDeleteSkillMutation();
  const [pagination, setPagination] = useState<AppTypes.PaginationParam>({
    search: '',
    sortField: 'createdAt',
    sortBy: SortDirection.DESC,
    offset: 0,
    limit: 10,
  });
  const {
    data: { data: skills = [], total = 0 } = {},
    isLoading,
    refetch,
  } = useSkillsQuery({
    ...pagination,
  });
  const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickSkillRow = (skillId: string) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.skillId = `${skillId}`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };

  const columns: DataTableColumn<SkillJson.Skill>[] = [
    {
      field: 'id',
      label: 'ID',
      width: 50,
      renderer: (row, id) => <Typography fontSize={14}>{id + 1}</Typography>,
    },
    {
      field: 'name',
      label: 'Name',
      width: 250,
      sortable: true,
    },
    {
      field: 'description',
      label: 'Description',
      sortable: true,
      renderer: (row) => (
        <Typography
          color={theme.colors.textPrimary}
          fontWeight={500}
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
          }}
        >
          {row.description}
        </Typography>
      ),
    },
    {
      field: 'industry',
      label: 'Industry',
      width: 250,
      renderer: (row) => row.industry?.name,
    },
    {
      field: 'createdAt',
      label: 'Created At',
      align: 'right',
      width: 120,
      sortable: true,
      renderer: (row) => new Date(row.createdAt).toLocaleDateString(),
    },
    {
      field: 'action',
      width: 50,
      renderer: (row) => (
        <Dropdown
          button={
            <IconButton>
              <IconEllipsis />
            </IconButton>
          }
          disableDefaultToggleStyle
        >
          <DropdownButton variant="primary">
            <IconEdit1 />
            Edit
          </DropdownButton>
          <DropdownButton
            variant="danger"
            onClick={(e) => {
              e.stopPropagation();
              showConfirmModal({
                onConfirm: async () =>
                  await deleteSkill({ skillId: row?.id || '' }),
              });
            }}
          >
            <IconDelete width={8} height={9} />
            Delete
          </DropdownButton>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    if (isDeleted) {
      hideConfirmModal();
      refetch();
    }
  }, [isDeleted]);
  return (
    <>
      <UIBox display="flex" justifyContent="space-between">
        <Typography variant="h5">Skills</Typography>
        <UIBox display="flex" gap={20}>
          <Input
            placeholder={
              <>
                <IconSearch color={theme.colors.icon} />
                Search
              </>
            }
            height={40}
            value={pagination.search}
            onChange={(e) =>
              setPagination({
                ...pagination,
                search: e.target.value,
              })
            }
          />
          <Button
            size="sm"
            height={40}
            width={110}
            onClick={() => setShow(true)}
          >
            Create
          </Button>
        </UIBox>
      </UIBox>
      <UIBox>
        <DataTable
          columns={columns}
          data={skills}
          total={total}
          loading={isLoading}
          pagination={pagination}
          onUpdatePagination={(p) => setPagination({ ...pagination, ...p })}
          onClickRow={(row) => handleOnClickSkillRow(row.id)}
        />
      </UIBox>
      <SidebarAddNewSkills
        show={show}
        setShow={(v) => {
          setShow(v);
          if (!v) refetch();
        }}
      />
    </>
  );
};

export default AdminSkillsPage;
