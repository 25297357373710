import { MessageJson } from '@/types';
import { faker } from '@faker-js/faker';

export function createRandomMessage(): MessageJson.Message {
  const firstName = faker.person.firstName();
  const lastName = faker.person.lastName();
  return {
    message: faker.lorem.paragraph(),
    user: {
      id: faker.string.uuid(),
      firstName: firstName,
      lastName: lastName,
      userName: faker.internet.userName({ firstName, lastName }),
      email: faker.internet.email({ firstName, lastName }),
      createdAt: '',
    },
    arrivedAt: new Date(),
  };
}
export const newMessagesMock: MessageJson.Message[] = faker.helpers.multiple(
  createRandomMessage,
  { count: 3 }
);

export const channelMessagesMock: MessageJson.Message[] =
  faker.helpers.multiple(createRandomMessage, { count: 8 });
