import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconArrowDown({
  width = 9,
  height = 5,
  color = theme.colors.secondary,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.165 0.226266C7.455 -0.0737341 7.925 -0.0737341 8.225 0.216266C8.375 0.366266 8.445 0.556266 8.445 0.756266C8.445 0.946266 8.375 1.13627 8.225 1.27627L4.755 4.76627C4.615 4.90627 4.425 4.98627 4.225 4.98627C4.025 4.98627 3.835 4.90627 3.695 4.76627L0.225 1.27627C-0.075 0.986266 -0.075 0.516266 0.225 0.216266C0.515 -0.0737341 0.995 -0.0737341 1.285 0.226266L4.225 3.17627L7.165 0.226266Z"
        fill={color}
      />
    </svg>
  );
}
