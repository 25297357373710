import { IconRightArrow } from '@/assets/icons';
import {
  UIBox,
  Input,
  Typography,
  UIContainer,
  UIFlexCenterBox,
  UIFlexColumnBox,
  UIFlexSpaceBox,
  RichEditor,
  Spinner,
} from '@/components/UI';
import styled, { useTheme } from 'styled-components';
import HomeFooterNavbar from './FooterNavbar';
import * as yup from 'yup';
import { AppTypes } from '@/types';
import { useFormik } from 'formik';
import { inputStatus } from '@/utils';
import { useContactUsMutation } from '@/redux/apis';
import { useEffect } from 'react';
import { useToast } from '@/hooks';

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('First name is required'),
  email: yup
    .string()
    .email('Invalid email format')
    .required('First name is required'),
  bodyText: yup.string().required('Message is required'),
});

const initialValues: AppTypes.ContactUsInput = {
  firstName: '',
  lastName: '',
  email: '',
  bodyText: '',
};

export default function Contact() {
  const { showToast } = useToast();
  const [contactUs, { isLoading, isSuccess, error }] = useContactUsMutation();
  const theme = useTheme();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (v) => {
      contactUs(v);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      showToast('Successfully submitted', {
        variant: 'success',
      });
      formik.resetForm();
    }
  }, [isSuccess]);
  useEffect(() => {
    if (error) {
      showToast('Failed submitting', { variant: 'error' });
    }
  }, [error]);
  return (
    <UIBox
      id="contact_us"
      background="url('/images/img_contact_bg.png') center no-repeat"
      backgroundSize="cover"
      color={theme.colors.white}
      pt={8}
    >
      <UIContainer>
        <UIBox display="flex">
          <UIFlexSpaceBox
            alignItems="flex-start"
            gap="2rem"
            justifyContent="space-around"
          >
            <UIFlexColumnBox
              color="white"
              width="100%"
              gap="1rem"
              maxWidth={340}
            >
              <Typography variant="h2" color={theme.colors.white}>
                Contact us
              </Typography>
              <Typography color={theme.colors.gray700} fontSize={18}>
                Please send your questions, comments and feedback using the
                provided form. We typically respond within 1-2 days
              </Typography>
              <Typography color={theme.colors.white}>
                Non-Linear Careers, LLC 123 Main Street Boston, MA 02110
              </Typography>
              <Typography color={theme.colors.white}>
                info@nl-careers.com
              </Typography>
            </UIFlexColumnBox>
            <UIFlexColumnBox
              gap="1rem"
              width="100%"
              backgroundColor="#FFFFFF26"
              p="24px"
              borderRadius="20px"
              maxWidth={460}
            >
              <Typography textAlign="center" color={theme.colors.white}>
                Get in touch with us, we would love to hear from you:
              </Typography>
              <Input
                fullWidth
                placeholder="First name"
                variant="transparent"
                {...formik.getFieldProps('firstName')}
                {...inputStatus(formik, 'firstName')}
              />
              <Input
                fullWidth
                placeholder="Last name"
                variant="transparent"
                {...formik.getFieldProps('lastName')}
                {...inputStatus(formik, 'lastName')}
              />
              <Input
                id="email"
                fullWidth
                placeholder="Email"
                variant="transparent"
                {...formik.getFieldProps('email')}
                {...inputStatus(formik, 'email')}
              />
              <UIBox width={'100%'}>
                <RichEditor
                  placeholder="Message"
                  variant="transparent"
                  defaultValue={formik.values.bodyText}
                  onBlur={() => formik.setFieldTouched('bodyText')}
                  onChange={(v) => formik.setFieldValue('bodyText', v)}
                  {...inputStatus(formik, 'bodyText')}
                />
              </UIBox>
              <UIFlexCenterBox width="100%">
                <StyledSubmitButton
                  onClick={() => formik.handleSubmit()}
                  disabled={isLoading}
                >
                  {isLoading && <Spinner />}
                  Submit
                  <IconRightArrow />
                </StyledSubmitButton>
              </UIFlexCenterBox>
            </UIFlexColumnBox>
          </UIFlexSpaceBox>
        </UIBox>
      </UIContainer>
      <HomeFooterNavbar />
      <StyledFooter>
        <Typography>
          Copyright © {new Date().getFullYear()} Non-Linear Careers, LLC. All
          Rights Reserved.
        </Typography>
      </StyledFooter>
    </UIBox>
  );
}

const StyledSubmitButton = styled.button(({ theme }) => ({
  cursor: 'pointer',
  marginTop: 40,
  background: 'transparent',
  border: 'none',
  color: theme.colors.white,
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '23px',
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

const StyledFooter = styled.footer(({ theme }) => ({
  height: 54,
  borderTop: `solid 1px ${theme.colors.gray710}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.colors.gray700,
}));
