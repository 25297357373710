import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconLocation({
  width = 11,
  height = 13,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.198242 5.4486C0.198242 2.57368 2.61249 0.25 5.53276 0.25C8.46127 0.25 10.8755 2.57368 10.8755 5.4486C10.8755 6.89731 10.3461 8.24227 9.4746 9.38223C8.5132 10.6397 7.32825 11.7353 5.99446 12.5953C5.6892 12.794 5.4137 12.809 5.07867 12.5953C3.73728 11.7353 2.55232 10.6397 1.59916 9.38223C0.727071 8.24227 0.198242 6.89731 0.198242 5.4486ZM3.77471 5.61079C3.77471 6.57389 4.56447 7.33136 5.53283 7.33136C6.50182 7.33136 7.29918 6.57389 7.29918 5.61079C7.29918 4.65519 6.50182 3.86084 5.53283 3.86084C4.56447 3.86084 3.77471 4.65519 3.77471 5.61079Z"
        fill={color}
      />
    </svg>
  );
}
