import { IconBookmark } from '@/assets/icons';
import { UIBox, Button, UICard, Tag, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { UserJson } from '@/types';
import SponsorWorkerStatus from './SponsorWorkerStatus';

interface SponsorReviewProposalCardProps {
  worker: UserJson.Worker;
  onClick: () => void;
  onClickBookmark: () => void;
  onClickHire: () => void;
  onClickMessage: () => void;
}

const SponsorReviewProposalCard: React.FC<SponsorReviewProposalCardProps> = ({
  worker,
  onClick,
  onClickBookmark,
  onClickHire,
  onClickMessage,
}) => {
  return (
    <UICard
      borderRadius={10}
      display="flex"
      flexDirection="column"
      onClick={onClick}
      cursor="pointer"
    >
      <UIBox display="flex" justifyContent="space-between">
        <SponsorWorkerStatus worker={worker} />
        <UIBox display="flex" gap={10}>
          <Button
            variant="outlined-primary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onClickBookmark();
            }}
          >
            <IconBookmark color={theme.colors.primary} />
          </Button>
          <Button
            variant="outlined-primary"
            size="sm"
            style={{ minWidth: 140 }}
            onClick={(e) => {
              e.stopPropagation();
              onClickHire();
            }}
          >
            Message
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onClickMessage();
            }}
          >
            Hire
          </Button>
        </UIBox>
      </UIBox>
      <UIBox my={1}>
        <Typography
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
          }}
        >
          {worker.coverLetter}
        </Typography>
      </UIBox>
      <UIBox display="flex" gap={10} flexWrap="wrap">
        {worker.userSkills?.map((skill, id) => (
          <Tag key={id}>{skill.skill?.name}</Tag>
        ))}
      </UIBox>
    </UICard>
  );
};

export default SponsorReviewProposalCard;
