import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useGenerateSignedUrlMutation } from '@/redux/apis';
import { AssetJson } from '@/types';
import { UserAssetType } from '@/enums';
import axios from 'axios';
import { appConfig } from '@/constants';

type UseUploadFileParams = {
  onGeneratedSignedUrl?: (url: string) => void;
  onUploadedFile?: (url: string) => void;
};
export const useUploadFile = ({
  onGeneratedSignedUrl,
  onUploadedFile,
}: UseUploadFileParams) => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [
    generateSignedUrl,
    { data: signedUrl, isSuccess: isGeneratedUrl, isLoading: isGeneratingUrl },
  ] = useGenerateSignedUrlMutation();

  async function uploadFile({
    contentType,
    userId,
    orgId,
  }: {
    contentType: UserAssetType;
    userId: string;
    orgId?: string;
  }) {
    if (acceptedFiles && acceptedFiles.length <= 0) {
      setError('Please select your file');
      return;
    }
    setError('');
    const dataToSave: AssetJson.GenerateSignedUrl = {
      userId,
      orgId,
      fileName: acceptedFiles[0].name,
      contentType,
    };
    await generateSignedUrl(dataToSave);
  }

  const uploadToStorage = async () => {
    const url = signedUrl?.url;
    if (!url || !acceptedFiles.length) return;
    onGeneratedSignedUrl?.(url);
    setUploading(true);
    setError('');
    try {
      await axios.put(url, acceptedFiles[0], {
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.min(
              Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total || 1)
              ),
              100
            )
          );
        },
      });
      setUploading(false);
      const fileName = acceptedFiles[0].name.replaceAll(' ', '+');
      const uploadedUrl = `${appConfig.S3_URL}/users/${user?.id || ''}/${fileName}`;
      onUploadedFile?.(uploadedUrl);
    } catch (err) {
      setError('Failed to upload file');
      setUploading(false);
    }
  };
  useEffect(() => {
    if (signedUrl && isGeneratedUrl) {
      uploadToStorage();
    }
  }, [signedUrl, isGeneratedUrl]);

  const loading = isGeneratingUrl || uploading;
  return {
    acceptedFiles,
    setAcceptedFiles,
    uploading,
    progress,
    loading,
    error,
    uploadFile,
  };
};
