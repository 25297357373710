import { appConfig, appRoutes } from '@/constants';
import { RoleNames } from '@/enums';
import { useMyInfoMutation, useOrganizationQuery } from '@/redux/apis';
import {
  clearAuth,
  setAuth as setAuthStore,
  setAuthUser as setAuthUserStore,
} from '@/redux/reducers';
import { setUserOrg } from '@/redux/reducers/auth.reducer';
import { RootState } from '@/redux/store';
import { AuthJson, OrganizationJson, UserJson } from '@/types';
import { readCookie, removeCookie, writeCookie } from '@/utils';
import React, { createContext, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { shallowEqual } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

interface AuthContextType {
  loadingUserInfo: boolean;
  auth: AuthJson.Response | undefined;
  user: UserJson.User | undefined;
  org: OrganizationJson.Organization | undefined;
  setAuth: (authResponse: AuthJson.Response, rememberMe?: boolean) => void;
  setAuthUser: (user: UserJson.User) => void;
  refetchUser: () => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

// AuthProvider component that will wrap your app's component tree
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [getMyInfo, { data: myInfo, isLoading: isLoadingMyInfo, error }] =
    useMyInfoMutation();
  const { auth, user, org } = useSelector(
    (state: RootState) => state.authState,
    shallowEqual
  );
  const userOrg = user?.organizations?.find((item) => item.ownerId === user.id);
  const { data: organization, isSuccess } = useOrganizationQuery(
    {
      id: userOrg?.id || '',
    },
    {
      skip: !userOrg?.id,
    }
  );
  const dispatch = useDispatch();
  const setAuth = async (auth: AuthJson.Response, rememberMe?: boolean) => {
    writeCookie(appConfig.cookieName, auth, rememberMe ? 4 : undefined);
    dispatch(setAuthStore(auth));
    await getMyInfo();
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserOrg(organization));
    }
  }, [organization, isSuccess]);
  useEffect(() => {
    if (myInfo && !isLoadingMyInfo) {
      dispatch(setAuthUserStore(myInfo));
    }
  }, [myInfo, isLoadingMyInfo, error]);
  const setAuthUser = (user: UserJson.User) => {
    dispatch(setAuthUserStore(user));
  };
  const logout = () => {
    removeCookie(appConfig.cookieName);
    dispatch(clearAuth());
    navigate(appRoutes.home);
  };
  useEffect(() => {
    const data: AuthJson.Response = readCookie(appConfig.cookieName);
    if (data) {
      setAuth(data, true);
      if (pathname === appRoutes.home) {
        if (data.roleName === RoleNames.SUPER_ADMIN) {
          navigate(appRoutes.adminDashboard);
        } else if (data.roleName === RoleNames.SITE_ADMIN) {
          navigate(appRoutes.adminDashboard);
        } else if (data.roleName === RoleNames.ORG_ADMIN) {
          navigate(appRoutes.orgDashboard);
        } else if (data.roleName === RoleNames.SPONSOR) {
          navigate(appRoutes.sponsorMyJobs);
        } else if (data.roleName === RoleNames.WORKER) {
          navigate(appRoutes.workerFindWork());
        }
      }
    } else {
      if (
        pathname !== appRoutes.home &&
        !pathname.includes('auth') &&
        !pathname.includes('public/profile')
      ) {
        navigate(appRoutes.home);
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loadingUserInfo: isLoadingMyInfo,
        auth,
        user,
        org,
        setAuth,
        setAuthUser,
        logout,
        refetchUser: getMyInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
