import { UIBox, UICard, UIGrid, Image, Typography } from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { Link } from 'react-router-dom';

const registerTypes = [
  {
    img: '/images/img_guy_working.png',
    label: 'Worker',
    route: appRoutes.registerWorker,
  },
  {
    img: '/images/img_sponsor.png',
    label: 'Sponsor',
    route: appRoutes.registerSponsor,
  },
  {
    img: '/images/img_building.png',
    label: 'Organization',
    route: appRoutes.registerOrganization,
  },
];
export default function Register() {
  return (
    <UIBox display="flex" flexDirection="column" alignItems="center" mb={12}>
      <Typography variant="h2">Welcome to be part of us!</Typography>
      <Typography
        marginTop={0}
        fontSize={18}
        color={theme.colors.textPurpleLight}
      >
        Choose a category for registration
      </Typography>
      <UIBox mb={2} />
      <UIGrid columns={3} gap={20}>
        {registerTypes.map((registerType, id) => (
          <Link to={registerType.route} key={id}>
            <UICard
              borderRadius={32}
              p={2.5}
              width={280}
              display="flex"
              flexDirection="column"
              alignItems="center"
              opacity={id === 1 ? 1 : 0.7}
              boxShadow="0px 4px 40px 0px #9FB5EF4D"
            >
              <Image src={registerType.img} />
              <Typography
                marginTop={20}
                marginBottom={0}
                fontSize={18}
                fontWeight={600}
                color={theme.colors.textPrimary}
              >
                {registerType.label}
              </Typography>
            </UICard>
          </Link>
        ))}
      </UIGrid>
    </UIBox>
  );
}
