import {
  IconArrowDown,
  IconArrowSquareOut,
  IconClose,
  IconMessage,
} from '@/assets/icons';
import {
  UIBox,
  Dropdown,
  IconButton,
  ReadMoreButton,
  Typography,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { Link } from 'react-router-dom';
import { MessageNotification } from '@/modules/Message';
import { newMessagesMock } from '@/mock';
import { useAuth } from '@/hooks';
import { RoleNames } from '@/enums';
const MessagesDropdown: React.FC = () => {
  const { auth } = useAuth();
  const isOrg = auth?.roleName === RoleNames.ORG_ADMIN;
  const isSponsor = auth?.roleName === RoleNames.SPONSOR;
  const isAdmin =
    auth?.roleName === RoleNames.SUPER_ADMIN ||
    auth?.roleName === RoleNames.SITE_ADMIN;
  return (
    <Dropdown
      button={
        <IconButton>
          <IconMessage active />
        </IconButton>
      }
      disableDefaultToggleStyle
      disableCloseMenuOnClickItem
    >
      <UIBox width={430}>
        <UIBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={1}
          py={1}
        >
          <Typography fontWeight={500} color={theme.colors.textPrimary}>
            Messages
          </Typography>
          <UIBox display="flex" gap={20}>
            <Link
              to={
                isAdmin
                  ? appRoutes.adminMessages
                  : isOrg
                    ? appRoutes.orgMessages
                    : isSponsor
                      ? appRoutes.sponsorMessages
                      : appRoutes.workerMessages
              }
              target="_blank"
            >
              <IconButton>
                <IconArrowSquareOut />
              </IconButton>
            </Link>
            <IconButton>
              <IconClose width={16} height={16} color={theme.colors.primary} />
            </IconButton>
          </UIBox>
        </UIBox>
        <UIBox display="flex" flexDirection="column" gap={6}>
          {newMessagesMock.map((msg, id) => (
            <MessageNotification key={id} message={msg} onClose={() => null} />
          ))}
        </UIBox>
        <UIBox display="flex" justifyContent="center" mt={1.5} mb={0.5}>
          <ReadMoreButton color={theme.colors.icon}>
            Read more <IconArrowDown color={theme.colors.icon} />
          </ReadMoreButton>
        </UIBox>
      </UIBox>
    </Dropdown>
  );
};

export default MessagesDropdown;
