import { createApi } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { OrganizationJson } from '@/types';
import { authorizeQueryWithRefreshToken } from '@/utils';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: authorizeQueryWithRefreshToken,
  tagTypes: ['Organization'],
  endpoints: (builder) => ({
    createOrganization: builder.mutation<
      OrganizationJson.Organization,
      OrganizationJson.OrganizationInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/organizations`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as OrganizationJson.Organization,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    updateOrganization: builder.mutation<
      OrganizationJson.Organization,
      OrganizationJson.OrganizationInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/organizations/${arg.id}`,
            method: 'PATCH',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as OrganizationJson.Organization,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    deleteOrganization: builder.mutation<
      OrganizationJson.Organization,
      { orgId: string }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/organizations/${arg.orgId}`,
            method: 'DELETE',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as OrganizationJson.Organization,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    organizations: builder.query<
      OrganizationJson.PagedOrganization,
      OrganizationJson.OrganizationPagination
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const queryString = new URLSearchParams(arg as any).toString();
          const result = await baseQuery({
            url: `/organizations?${queryString}`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as OrganizationJson.PagedOrganization,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    organization: builder.query<OrganizationJson.Organization, { id: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/organizations/${arg.id}`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as OrganizationJson.Organization,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const {
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useOrganizationQuery,
  useOrganizationsQuery,
} = organizationApi;
