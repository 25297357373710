import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconPhotoCamera({
  width = 27,
  height = 24,
  color = theme.colors.white,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.844 4.20732C20.898 4.29822 20.9925 4.36315 21.114 4.36315C24.354 4.36315 27 6.90832 27 10.0249V17.7383C27 20.8548 24.354 23.4 21.114 23.4H5.886C2.6325 23.4 0 20.8548 0 17.7383V10.0249C0 6.90832 2.6325 4.36315 5.886 4.36315C5.994 4.36315 6.102 4.31121 6.1425 4.20732L6.2235 4.0515C6.27005 3.95714 6.31783 3.86023 6.36634 3.76185C6.71169 3.06137 7.09375 2.28642 7.3305 1.83097C7.9515 0.662264 9.0045 0.0129856 10.314 0H16.6725C17.982 0.0129856 19.0485 0.662264 19.6695 1.83097C19.8821 2.24006 20.2061 2.89905 20.5184 3.53417C20.5828 3.66514 20.6467 3.7951 20.709 3.92164L20.844 4.20732ZM19.8584 9.19353C19.8584 9.84281 20.3984 10.3622 21.0734 10.3622C21.7484 10.3622 22.302 9.84281 22.302 9.19353C22.302 8.54425 21.7484 8.01184 21.0734 8.01184C20.3984 8.01184 19.8584 8.54425 19.8584 9.19353ZM11.164 11.2065C11.7985 10.5961 12.622 10.2715 13.4995 10.2715C14.377 10.2715 15.2005 10.5961 15.8215 11.1935C16.4425 11.7908 16.78 12.5829 16.78 13.427C16.7665 15.1671 15.3085 16.5825 13.4995 16.5825C12.622 16.5825 11.7985 16.2579 11.1775 15.6605C10.5565 15.0632 10.219 14.2711 10.219 13.427V13.414C10.2055 12.5959 10.543 11.8038 11.164 11.2065ZM17.2393 17.037C16.2808 17.9589 14.9578 18.5303 13.4998 18.5303C12.0823 18.5303 10.7593 17.9979 9.74678 17.037C8.74778 16.063 8.19428 14.7904 8.19428 13.427C8.18078 12.0765 8.73428 10.8039 9.73328 9.82996C10.7458 8.85604 12.0823 8.32363 13.4998 8.32363C14.9173 8.32363 16.2538 8.85604 17.2528 9.81697C18.2518 10.7909 18.8053 12.0765 18.8053 13.427C18.7918 14.8424 18.1978 16.115 17.2393 17.037Z"
        fill={color}
      />
    </svg>
  );
}
