import { IconCircleClose, IconSearch } from '@/assets/icons';
import { UIBox, IconButton, Input } from '@/components/UI';
import MessagesDropdown from './MessagesDropdown';
import NotificationsDropdown from './NotificationsDropdown';
import UserDropdown from './UserDropdown';
import { useState } from 'react';

const SharedNavbar: React.FC = () => {
  const [expandSearch, setExpandSearch] = useState(false);
  return (
    <UIBox display="flex" alignItems="center" gap={18}>
      <UIBox
        borderLeftStyle="solid"
        borderLeftColor="#9A9AA933"
        borderLeftWidth={2}
        height={20}
      />
      <UIBox position="relative">
        <IconButton onClick={() => setExpandSearch(true)}>
          <IconSearch />
        </IconButton>
        {expandSearch && (
          <UIBox position="absolute" right={0} top={-9} width={400}>
            <Input
              placeholder={
                <UIBox display="flex" alignItems="center" gap={10}>
                  <IconSearch />
                  Search
                </UIBox>
              }
              fullWidth
              height={40}
              autoFocus
            />
            <UIBox position="absolute" right={10} top={7}>
              <IconButton onClick={() => setExpandSearch(false)}>
                <IconCircleClose />
              </IconButton>
            </UIBox>
          </UIBox>
        )}
      </UIBox>
      <MessagesDropdown />
      <NotificationsDropdown />
      <UserDropdown />
    </UIBox>
  );
};

export default SharedNavbar;
