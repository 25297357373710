import { EmploymentType, ProjectType, UserStatus } from '@/enums';
import { UserJson } from '@/types';
import { faker } from '@faker-js/faker';
import { createRandomUserSkill } from './skills.mock';
import { createRandomExperience } from './experiences.mock';
import { createRandomEducation } from './educations.mock';
import { createRandomCertification } from './certifications.mock';

export function createRandomUserProfile(id?: string): UserJson.User {
  const firstName = faker.person.firstName();
  const lastName = faker.person.lastName();
  return {
    id: id || faker.string.uuid(),
    firstName,
    lastName,
    userName: faker.internet.userName({ firstName, lastName }),
    email: faker.internet.email({ firstName, lastName }),
    profession: faker.helpers.arrayElement([
      'Full Stack JS Engineer',
      'Full stack engineer',
      'Frontend developer',
      'Blockchain developer',
    ]),
    phone: '(128) 889 99 000',
    address1: faker.location.streetAddress(),
    zipcode: faker.location.zipCode(),
    city: faker.location.city(),
    state: faker.location.state({ abbreviated: true }),
    country: faker.location.countryCode('alpha-2'),
    summary: faker.lorem.paragraphs({ min: 3, max: 6 }),
    status: faker.helpers.arrayElement([
      UserStatus.DEFAULT,
      UserStatus.BLOCKED,
      UserStatus.PENDING,
    ]),
    desiredSalary: faker.number.int({ min: 60000, max: 200000 }),
    hourlyRate: faker.number.int({ min: 40, max: 200 }),
    employmentType: faker.helpers.arrayElement([
      EmploymentType.CONTRACT,
      EmploymentType.FULL_TIME,
      EmploymentType.PART_TIME,
    ]),
    jobType: faker.helpers.arrayElement([
      ProjectType.HYBRID,
      ProjectType.ONSITE,
      ProjectType.REMOTE,
    ]),
    isMentor: faker.helpers.arrayElement([false, true]),
    createdAt: '',
    roleId: faker.string.uuid(),
    timezoneId: faker.string.uuid(),
    userAssets: [],
    userSkills: faker.helpers.multiple(createRandomUserSkill, { count: 2 }),
    userExperiences: faker.helpers.multiple(createRandomExperience, {
      count: 2,
    }),
    userEducations: faker.helpers.multiple(createRandomEducation, { count: 2 }),
    userCertifications: faker.helpers.multiple(createRandomCertification, {
      count: 2,
    }),
  };
}

export const workerProfileMock: UserJson.User = createRandomUserProfile('1');
