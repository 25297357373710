import { IconFilter, IconSearch, IconSwap } from '@/assets/icons';
import {
  UIBox,
  Dropdown,
  DropdownItem,
  Input,
  Pagination,
  Typography,
} from '@/components/UI';
import { theme } from '@/constants';
import { workersMock } from '@/mock';

import {
  SponsorReviewProposalCard,
  SponsorSidebarWorkerProposal,
} from '@/modules/Job/SponsorJobs';
import { useSearchParams } from 'react-router-dom';

const SponsorReviewProposals: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnClickWorkerCard = (workerId: string) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    currentSearchParams.workerId = `${workerId}`;
    setSearchParams(new URLSearchParams(currentSearchParams));
  };
  return (
    <UIBox display="flex" flexDirection="column" gap={30}>
      <UIBox display="flex" gap={30} alignItems="center">
        <UIBox flexGrow={1}>
          <Input
            placeholder={
              <>
                <IconSearch color={theme.colors.companyBrand} />
                Search
              </>
            }
            fullWidth
          />
        </UIBox>
        <Dropdown
          button={
            <UIBox display="flex" alignItems="center" gap={10} minWidth={150}>
              <IconFilter />
              <Typography
                fontSize={14}
                fontWeight={600}
                color={theme.colors.textPurple}
              >
                Filters
              </Typography>
            </UIBox>
          }
        >
          <DropdownItem>filters</DropdownItem>
        </Dropdown>
        <Dropdown
          button={
            <UIBox display="flex" alignItems="center" gap={10} minWidth={150}>
              <IconSwap />
              <Typography
                fontSize={14}
                fontWeight={600}
                color={theme.colors.textPurple}
              >
                Best Match
              </Typography>
            </UIBox>
          }
        >
          <DropdownItem>Best match</DropdownItem>
        </Dropdown>
      </UIBox>
      <UIBox display="flex" flexDirection="column" gap={20}>
        {workersMock.map((item, id) => (
          <SponsorReviewProposalCard
            key={id}
            worker={item}
            onClick={() => handleOnClickWorkerCard(item.id)}
            onClickBookmark={() => null}
            onClickHire={() => null}
            onClickMessage={() => null}
          />
        ))}
      </UIBox>
      <UIBox display="flex" justifyContent="flex-end">
        <Pagination onChangeOffset={() => null} total={10} />
      </UIBox>
      <SponsorSidebarWorkerProposal />
    </UIBox>
  );
};

export default SponsorReviewProposals;
