import {
  UIBox,
  Button,
  UICard,
  Image,
  Input,
  Label,
  Typography,
  Spinner,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { useForgotPasswordMutation } from '@/redux/apis';
import { inputStatus } from '@/utils';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

export default function AuthForgotPasswordPage() {
  const navigate = useNavigate();
  const [forgotPassword, { isLoading, isSuccess, data }] =
    useForgotPasswordMutation();
  const formik = useFormik<{ email: string }>({
    initialValues: { email: '' },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Invalid email format.')
        .required('Email is required'),
    }),
    onSubmit: (v) => {
      forgotPassword(v);
    },
  });
  useEffect(() => {
    if (isSuccess && data)
      navigate(`${appRoutes.authChangePassword}?userId=${data.id}`);
  }, [isSuccess, data]);
  return (
    <UIBox display="flex" justifyContent="center" flexGrow={1}>
      <UICard
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth={520}
        px={3}
        py={2}
        borderRadius={32}
        boxShadow="0px 4px 35px 0px #00000014"
      >
        <Typography
          variant="h3"
          textAlign="center"
          fontSize={26}
          lineHeight="35px"
          marginBottom={10}
        >
          Forgot password?
        </Typography>
        <Typography
          textAlign="center"
          fontWeight={400}
          marginBottom={40}
          color={theme.colors.primary}
        >
          Don’t worry we can help you out! if you still remember your email
          address you can quickly reset your password.
        </Typography>
        <Image src="/images/forgot-password.png" />
        <UIBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={10}
          width={300}
          mt={1}
        >
          <Label>Enter your Email</Label>
          <Input
            type="email"
            placeholder="Email"
            fullWidth
            {...formik.getFieldProps('email')}
            {...inputStatus(formik, 'email')}
          />
          <Typography
            fontSize={14}
            textAlign="center"
            lineHeight="17px"
            marginTop={10}
          >
            This will send you a new email that will link to the password change
            website
          </Typography>
        </UIBox>
        <UIBox
          mt={2}
          width={300}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Button
            fullWidth
            rounded
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
          >
            {isLoading && <Spinner size="sm" />}
            Request password change
          </Button>
        </UIBox>
      </UICard>
    </UIBox>
  );
}
