import { UIBox, Button, Image, Typography } from '@/components/UI';
import { useOutletContext } from 'react-router-dom';
import { NewJobStep1, NewJobStep2, NewJobStep3, NewJobStep4 } from './NewJob';
import { ProjectJson } from '@/types';

interface SponsorEditJobPostProps {
  onCancel: () => void;
  onPublish: () => void;
  onDelete: () => void;
}
const SponsorEditJobPost: React.FC<SponsorEditJobPostProps> = ({
  onCancel,
  onDelete,
  onPublish,
}) => {
  const { job } = useOutletContext<{ job: ProjectJson.Project }>();
  return (
    <UIBox display="flex" gap={30}>
      <UIBox flexGrow={1} display="flex" flexDirection="column" gap={20}>
        <NewJobStep1 job={job} updateJob={() => null} />
        <NewJobStep2 job={job} updateJob={() => null} />
        <NewJobStep3 job={job} updateJob={() => null} />
        <NewJobStep4 job={job} updateJob={() => null} />
        <UIBox display="flex" justifyContent="space-between" mt={1}>
          <Button variant="danger" height={35}>
            Delete Job
          </Button>
          <UIBox display="flex" gap={20}>
            <Button variant="secondary" height={35}>
              Cancel
            </Button>
            <Button height={35}>Publish</Button>
          </UIBox>
        </UIBox>
      </UIBox>

      <UIBox
        width={390}
        minWidth={390}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Image src="/images/woman-thinking.png" />
        <Typography marginTop={20}>
          To ensure your post captures the attention of the right audience, make
          your headline compelling. It's the first impression they'll get, so
          make it memorable!
        </Typography>
      </UIBox>
    </UIBox>
  );
};

export default SponsorEditJobPost;
