import { BackButton, UIBox, Button, UIGrid, Typography } from '@/components/UI';
import { appRoutes } from '@/constants';
import { SponsorJobRouteCategory } from '@/enums';
import { jobsMock } from '@/mock';
import { HireToJobModal, InviteToJobModal } from '@/modules/Shared';
import { ProjectJson, UserJson } from '@/types';
import { useState } from 'react';

import { Link, Outlet, useLocation } from 'react-router-dom';

const routerLabels = [
  {
    link: SponsorJobRouteCategory.VIEW_JOB_POST,
    label: 'View job post',
  },
  {
    link: SponsorJobRouteCategory.INVITE_WORKERS,
    label: 'Invite workers',
  },
  {
    link: SponsorJobRouteCategory.REVIEW_PROPOSALS,
    label: 'Review proposals',
  },
  {
    link: SponsorJobRouteCategory.CONTACTED_WORKERS,
    label: 'Contacted workers',
  },
];

export interface SponsorViewJobPageOutletContext {
  job?: ProjectJson.Project;
  setShowInviteModal: (worker: UserJson.User) => void;
  setShowHireModal: (worker: UserJson.User) => void;
}

const SponsorViewJobPostPage: React.FC = () => {
  const job = jobsMock[0];
  const { pathname } = useLocation();
  const [, userType, , , category] = pathname.split('/');
  const isSponsor = userType === 's';
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showHireModal, setShowHireModal] = useState(false);
  return (
    <UIBox display="flex" flexDirection="column" gap={20}>
      <BackButton
        to={isSponsor ? appRoutes.sponsorMyJobs : appRoutes.orgProjects}
      >
        Back to Job List
      </BackButton>
      <Typography variant="h3" marginTop={0} marginBottom={0}>
        {job.title}
      </Typography>
      <UIGrid columns={4} gap={20}>
        {routerLabels.map((item, id) => (
          <Link key={id} to={item.link}>
            <Button
              variant={category === item.link ? 'primary' : 'gray'}
              fullWidth
              height={40}
            >
              {item.label}
            </Button>
          </Link>
        ))}
      </UIGrid>
      <UIBox>
        <Outlet context={{ job, setShowInviteModal, setShowHireModal }} />
      </UIBox>
      <InviteToJobModal
        show={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      />
      <HireToJobModal
        show={showHireModal}
        onClose={() => setShowHireModal(false)}
      />
    </UIBox>
  );
};

export default SponsorViewJobPostPage;
