import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconBuildings({
  width = 13,
  height = 13,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      className="stroke-icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.8125 10.9668H12.1875"
        stroke={color}
        strokeWidth="1.28"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31152 10.9668V2.0293C7.31152 1.92155 7.26872 1.81822 7.19254 1.74203C7.11635 1.66585 7.01302 1.62305 6.90527 1.62305H2.03027C1.92253 1.62305 1.8192 1.66585 1.74301 1.74203C1.66682 1.81822 1.62402 1.92155 1.62402 2.0293V10.9668"
        stroke={color}
        strokeWidth="1.28"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.374 10.9668V5.2793C11.374 5.17155 11.3312 5.06822 11.255 4.99203C11.1788 4.91585 11.0755 4.87305 10.9678 4.87305H7.31152"
        stroke={color}
        strokeWidth="1.28"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.24902 3.6543H4.87402"
        stroke={color}
        strokeWidth="1.28"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.06152 6.9043H5.68652"
        stroke={color}
        strokeWidth="1.28"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.24902 8.93555H4.87402"
        stroke={color}
        strokeWidth="1.28"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.93652 8.93555H9.74902"
        stroke={color}
        strokeWidth="1.28"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.93652 6.9043H9.74902"
        stroke={color}
        strokeWidth="1.28"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
