import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconTickSquare({
  width = 18,
  height = 18,
  color = theme.colors.green800,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39297 0.666016H12.618C15.443 0.666016 17.3346 2.64935 17.3346 5.59935V12.4085C17.3346 15.3493 15.443 17.3327 12.618 17.3327H5.39297C2.56797 17.3327 0.667969 15.3493 0.667969 12.4085V5.59935C0.667969 2.64935 2.56797 0.666016 5.39297 0.666016ZM8.5263 11.491L12.4846 7.53268C12.768 7.24935 12.768 6.79102 12.4846 6.49935C12.2013 6.21602 11.7346 6.21602 11.4513 6.49935L8.00963 9.94102L6.5513 8.48268C6.26797 8.19935 5.8013 8.19935 5.51797 8.48268C5.23464 8.76602 5.23464 9.22435 5.51797 9.51602L7.5013 11.491C7.64297 11.6327 7.8263 11.6993 8.00963 11.6993C8.2013 11.6993 8.38463 11.6327 8.5263 11.491Z"
        fill={color}
      />
    </svg>
  );
}
