import { AppUserAvatar } from '@/components/App';
import { UIBox, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { MessageJson } from '@/types';
import { Notification } from '../Notification';

interface MessageNotificationProps {
  message: MessageJson.Message;
  onClose?: () => void;
}
const MessageNotification: React.FC<MessageNotificationProps> = ({
  message,
  onClose,
}) => {
  return (
    <Notification onClose={onClose}>
      <Typography color={theme.colors.icon} fontSize={12} fontWeight={500}>
        {message.arrivedAt?.toLocaleTimeString()}
      </Typography>
      <UIBox display="flex" gap={10} mt={0.5}>
        <AppUserAvatar size={25} user={message.user} />
        <UIBox>
          <Typography
            color={theme.colors.textPrimary}
            fontSize={14}
            lineHeight="17px"
            fontWeight={600}
            marginBottom={10}
          >
            {message.user.firstName} {message.user.lastName}
          </Typography>
          <Typography
            fontSize={14}
            lineHeight="17px"
            color={theme.colors.textDescription}
          >
            {message.message}
          </Typography>
        </UIBox>
      </UIBox>
    </Notification>
  );
};

export default MessageNotification;
