import { IconPlus } from '@/assets/icons';
import {
  UIBox,
  UICard,
  IconButton,
  Typography,
  Spinner,
} from '@/components/UI';
import { useDeleteUserExperienceMutation } from '@/redux/apis';
import ExperienceCard from './ExperienceCard';
import ExperienceEditModal from './ExperienceEditModal';
import { useEffect, useMemo, useState } from 'react';
import { UserJson } from '@/types';
import { useConfirmModal } from '@/hooks';
import { AppEmptyItems } from '@/components/App';

interface ProfileExperienceProps {
  experiences?: UserJson.Experience[];
  isLoading?: boolean;
  publicView?: boolean;
  refetch?: () => void;
}
const ProfileExperience: React.FC<ProfileExperienceProps> = ({
  publicView,
  isLoading,
  experiences = [],
  refetch,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { showConfirmModal, hideConfirmModal } = useConfirmModal();

  const [deleteExperience, { isSuccess: isDeleted }] =
    useDeleteUserExperienceMutation();

  async function handleDeleteExperience(experience: UserJson.Experience) {
    await deleteExperience({ expId: experience.id });
  }

  useEffect(() => {
    if (isDeleted) {
      refetch?.();
      hideConfirmModal();
    }
  }, [isDeleted]);

  const sortedExperiences = useMemo(
    () =>
      [...experiences].sort(
        (a, b) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime()
      ),
    [experiences]
  );
  return (
    <UICard borderRadius={10} id="profile-experience-section">
      <UIBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="h5" marginBottom={0}>
          Experience
        </Typography>
        {!publicView && (
          <IconButton onClick={() => setShowModal(true)}>
            <IconPlus />
          </IconButton>
        )}
      </UIBox>
      {isLoading && (
        <UIBox display="flex" justifyContent="center" p={2}>
          <Spinner size="xl" />
        </UIBox>
      )}
      {!isLoading && experiences.length === 0 && (
        <AppEmptyItems
          description="Try add a new experience"
          buttonLabel="Add Experience"
          onClickButton={() => setShowModal(true)}
        />
      )}
      <UIBox display="flex" flexDirection="column" gap={20}>
        {sortedExperiences.map((experience, id) => (
          <ExperienceCard
            key={id}
            experience={experience}
            editable={!publicView}
            onClickDelete={() => {
              showConfirmModal({
                question: 'Do you want to delete this?',
                confirmButton: 'Yes, Delete',
                onClose: hideConfirmModal,
                onConfirm: () => handleDeleteExperience(experience),
              });
            }}
            onClickSave={() => refetch?.()}
          />
        ))}
      </UIBox>
      <ExperienceEditModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onAdded={() => {
          setShowModal(false);
          refetch?.();
        }}
      />
    </UICard>
  );
};

export default ProfileExperience;
