import { theme } from '@/constants';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { UIBox } from './Box';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div<{ $color: string; $size: number }>`
  border: 3px solid rgba(0, 0, 0, 0.1); // Light grey border
  border-top: 3px solid ${(props) => props.$color}; // Blue top border
  border-radius: 50%; // Make it a circle
  width: ${(props) => props.$size}px; // Width of the spinner
  height: ${(props) => props.$size}px; // Height of the spinner
  animation: ${spin} 1s linear infinite; // Use the spin animation
`;

interface SpinnerProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  variant?: 'primary' | 'secondary' | 'success' | 'danger';
}
const colors = {
  primary: theme.colors.primary,
  secondary: theme.colors.secondary,
  success: theme.colors.success,
  danger: theme.colors.error,
};
const sizes = {
  xs: 14,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 32,
};
const Spinner: React.FC<SpinnerProps> = ({
  size = 'md',
  variant = 'primary',
}) => {
  return <StyledSpinner $color={colors[variant]} $size={sizes[size]} />;
};

export const SectionSpinner: React.FC<SpinnerProps> = ({
  size = 'xl',
  variant,
}) => {
  return (
    <UIBox display="flex" justifyContent="center" py={4} mx="auto">
      <Spinner size={size} variant={variant} />
    </UIBox>
  );
};
export default Spinner;
