import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconMessage({
  width = 16,
  height = 19,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9398 6.22997C13.9398 7.38124 14.2441 8.05981 14.9137 8.84179C15.4212 9.41789 15.5833 10.1574 15.5833 10.9597C15.5833 11.7611 15.32 12.5218 14.7926 13.1394C14.102 13.8799 13.1281 14.3526 12.1341 14.4347C10.6938 14.5575 9.25252 14.6609 7.79212 14.6609C6.33081 14.6609 4.89046 14.5991 3.45011 14.4347C2.45525 14.3526 1.48135 13.8799 0.791693 13.1394C0.264201 12.5218 0 11.7611 0 10.9597C0 10.1574 0.163076 9.41789 0.669614 8.84179C1.36018 8.05981 1.64443 7.38124 1.64443 6.22997V5.83944C1.64443 4.29764 2.02889 3.28948 2.82058 2.30254C3.99764 0.863222 5.88441 0 7.75113 0H7.83312C9.73992 0 11.6877 0.904767 12.8447 2.40594C13.5954 3.37257 13.9398 4.33827 13.9398 5.83944V6.22997ZM5.1092 16.5558C5.1092 16.0942 5.53284 15.8827 5.92458 15.7923C6.38283 15.6953 9.17517 15.6953 9.63342 15.7923C10.0252 15.8827 10.4488 16.0942 10.4488 16.5558C10.426 16.9952 10.1682 17.3848 9.81199 17.6323C9.35009 17.9923 8.80802 18.2204 8.24136 18.3025C7.92796 18.3431 7.62003 18.3441 7.31756 18.3025C6.74998 18.2204 6.20792 17.9923 5.74693 17.6313C5.3898 17.3848 5.13198 16.9952 5.1092 16.5558Z"
        fill={color}
      />
    </svg>
  );
}
