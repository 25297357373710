import {
  IconDelete,
  IconEdit1,
  IconEllipsis,
  IconUsers2,
} from '@/assets/icons';
import {
  UIBox,
  UICard,
  Dropdown,
  IconButton,
  Image,
  Typography,
} from '@/components/UI';
import { DropdownButton } from '@/components/UI/Dropdown';
import { appRoutes, theme } from '@/constants';
import { ProjectBudgetType, SponsorJobRouteCategory } from '@/enums';
import { ProjectJson } from '@/types';
import { formatCurrency } from '@/utils';
import { useNavigate } from 'react-router-dom';

interface SponsorJobCardProps {
  job: ProjectJson.Project;
  onClickDelete: () => void;
}
const SponsorJobCard: React.FC<SponsorJobCardProps> = ({
  job,
  onClickDelete,
}) => {
  const navigate = useNavigate();
  return (
    <UICard borderRadius={10} p={1.5} display="flex" gap={30}>
      <UIBox>
        <Image src={'job.companyData?.logo'} width={26} />
      </UIBox>
      <UIBox
        display="flex"
        flexDirection="column"
        gap={10}
        width={175}
        minWidth={175}
      >
        <Typography
          variant="h6"
          color={theme.colors.textPrimary}
          marginBottom={2}
        >
          {job.title}
        </Typography>

        <UIBox
          display="flex"
          alignItems="center"
          gap={10}
          transform="capitalize"
        >
          {job.type} - {job.employmentType}
        </UIBox>
        <Typography color={theme.colors.textPrimary}>
          {job.budget === ProjectBudgetType.HOURLY
            ? `${formatCurrency(job.minRate)} - ${formatCurrency(job.maxRate)}`
            : `${formatCurrency(job.minSalary)} - ${formatCurrency(job.maxSalary)}`}
        </Typography>
        <Typography fontSize={14}>Posted 23 minutes ago</Typography>

        <UIBox display="flex" alignItems="center" gap={10}>
          <IconUsers2 />
          Candidate (0)
        </UIBox>
      </UIBox>
      <UIBox>
        <Typography
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 6,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
          }}
        >
          {job.description}
        </Typography>
      </UIBox>
      <UIBox>
        <Dropdown
          button={
            <IconButton>
              <IconEllipsis />
            </IconButton>
          }
          disableDefaultToggleStyle
        >
          <DropdownButton
            variant="primary"
            onClick={() =>
              navigate(
                appRoutes.sponsorJobDetail(
                  job.id,
                  SponsorJobRouteCategory.VIEW_JOB_POST
                )
              )
            }
          >
            <IconEdit1 />
            <Typography
              variant="span"
              color={theme.colors.blue300}
              fontSize={12}
            >
              Edit
            </Typography>
          </DropdownButton>
          <DropdownButton variant="danger" onClick={onClickDelete}>
            <IconDelete width={8} height={9} color={theme.colors.red600} />
            <Typography
              variant="span"
              color={theme.colors.red600}
              fontSize={12}
            >
              Delete
            </Typography>
          </DropdownButton>
        </Dropdown>
      </UIBox>
    </UICard>
  );
};

export default SponsorJobCard;
