import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { IconLogo, IconRightArrow } from '@/assets/icons';
import {
  Button,
  UIContainer,
  UIFlexCenterBox,
  UIFlexColumnBox,
  UIFlexSpaceBox,
} from '@/components/UI';
import { appRoutes, theme } from '@/constants';
import { useScrollTrigger } from '@/hooks';

export default function HomeNavbar() {
  const navigate = useNavigate();
  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <StyledNavbarContainer scrolled={scrolled}>
      <UIContainer>
        <UIFlexSpaceBox>
          <UIFlexColumnBox>
            <Link to={appRoutes.home}>
              <IconLogo />
            </Link>
          </UIFlexColumnBox>
          <StyledNavLinkContainer scrolled={scrolled}>
            <StyledNavLink to={'#welcome'}>Home</StyledNavLink>
            <StyledNavLink to={'#about_us'}>About us</StyledNavLink>
            <StyledNavLink to={'#individuals_and_employers'}>
              Individuals
            </StyledNavLink>
            <StyledNavLink to={'#individuals_and_employers'}>
              Employers
            </StyledNavLink>
            <StyledNavLink to={'#contact_us'}>Contact</StyledNavLink>
          </StyledNavLinkContainer>
          <UIFlexCenterBox gap={30}>
            {scrolled && (
              <Button
                variant="outlined-primary"
                rounded
                size="sm"
                onClick={() => navigate(appRoutes.register)}
              >
                Get started
              </Button>
            )}
            <StyledNavLink to={appRoutes.login}>
              Login
              <IconRightArrow
                color={scrolled ? theme.colors.black : theme.colors.white}
              />
            </StyledNavLink>
          </UIFlexCenterBox>
        </UIFlexSpaceBox>
      </UIContainer>
    </StyledNavbarContainer>
  );
}

const StyledNavbarContainer = styled(
  ({
    scrolled,
    ...rest
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
    scrolled: boolean;
  }) => <header {...rest} />
)<{ scrolled: boolean }>(({ theme, scrolled }) => ({
  flexGrow: 1,
  position: 'fixed',
  zIndex: 99,
  left: 0,
  right: 0,
  paddingTop: scrolled ? '10px' : '30px',
  paddingBottom: '10px',
  backgroundColor: scrolled ? theme.colors.white : 'transparent',
  boxShadow: scrolled ? theme.colors.lightBoxShadow : 'none',
  transition: 'all 300ms linear',
  ...(scrolled
    ? {
        a: {
          color: theme.colors.black,
          transitionProperty: 'color',
        },
      }
    : {}),
}));

const StyledNavLinkContainer = styled(
  ({
    scrolled,
    ...rest
  }: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { scrolled: boolean }) => <div {...rest} />
)<{ scrolled: boolean }>(({ theme, scrolled }) => ({
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
  border: '1px solid #FFFFFF26',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: 45,
  width: 620,
  borderRadius: 30,
  paddingLeft: 20,
  paddingRight: 20,
}));

const StyledNavLink = styled(Link)(({ theme: { colors } }) => ({
  color: colors.white,
  fontSize: '1rem',
  textDecoration: 'none',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));
