import {
  Autocomplete,
  UIBox,
  Button,
  UICard,
  Input,
  Typography,
  RichEditor,
} from '@/components/UI';
import { theme } from '@/constants';
import { JobPostingSteps } from '@/enums';
import { ProjectJson } from '@/types';

interface NewJobStep1Props {
  job: ProjectJson.Project;
  onClickNext?: () => void;
  updateJob: (updated: Partial<ProjectJson.Project>) => void;
}
const NewJobStep1: React.FC<NewJobStep1Props> = ({ onClickNext }) => {
  return (
    <UICard
      id={JobPostingSteps.STEP_1}
      borderRadius={10}
      display="flex"
      flexDirection="column"
      gap={20}
      p={2}
    >
      <Typography variant="h6" marginBottom={0}>
        Company
      </Typography>
      <Input label="Your company's name" fullWidth />
      <Autocomplete label="Your company's number of employees" fullWidth />
      <UIBox mb={0.5} />
      <Typography variant="h6" marginBottom={0}>
        Job
      </Typography>
      <Input label="Write a title for your job post" fullWidth />
      <RichEditor label="Describe your job" />
      {onClickNext && (
        <UIBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>
            <Typography variant="span" color={theme.colors.textPrimary}>
              1
            </Typography>
            /5
          </Typography>
          <Button size="sm" onClick={onClickNext}>
            Next
          </Button>
        </UIBox>
      )}
    </UICard>
  );
};

export default NewJobStep1;
