import { UIBox, UICard, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { UserJson } from '@/types';
import { useEffect, useRef, useState } from 'react';
import ProfileEditButton from '../Shared/ProfileEditButton';
import ExperienceEditForm, {
  ExperienceEditFormHandle,
} from './ExperienceEditForm';
import { useUpdateUserExperienceMutation } from '@/redux/apis';

interface ExperienceCardProps {
  editable?: boolean;
  experience: UserJson.Experience;
  onClickSave?: () => void;
  onClickDelete?: () => void;
}
const ExperienceCard: React.FC<ExperienceCardProps> = ({
  editable,
  experience,
  onClickDelete,
  onClickSave,
}) => {
  const [editing, setEditing] = useState(false);
  const [updateUserExperience, { isLoading: isSaving, isSuccess }] =
    useUpdateUserExperienceMutation();
  const editFormRef = useRef<ExperienceEditFormHandle>(null);
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnSave = async (v: UserJson.ExperienceInput) => {
    await updateUserExperience(v);
  };
  useEffect(() => {
    if (isSuccess) {
      onClickSave?.();
      setEditing(false);
    }
  }, [isSuccess]);
  const handleClickOnSave = () => {
    editFormRef.current?.submitForm();
  };
  const handleOnCancel = () => {
    setEditing(false);
  };

  return (
    <UICard
      position="relative"
      borderRadius={12}
      p={1.5}
      borderWidth={1}
      borderStyle="solid"
      borderColor={theme.colors.gray810}
      boxShadow={theme.colors.lightBoxShadow}
    >
      {editable && (
        <UIBox
          position="absolute"
          right={15}
          top={15}
          display="flex"
          alignItems="center"
          gap={10}
        >
          <ProfileEditButton
            editing={editing}
            hasDelete
            saving={isSaving}
            onClickCancel={handleOnCancel}
            onClickEdit={handleOnEdit}
            onClickSave={handleClickOnSave}
            onClickDelete={onClickDelete}
          />
        </UIBox>
      )}
      {editing ? (
        <ExperienceEditForm
          ref={editFormRef}
          experience={experience}
          onSubmit={handleOnSave}
        />
      ) : (
        <>
          <UIBox display="flex" alignItems="center" gap={10}>
            <Typography color={theme.colors.textPrimary} fontWeight={500}>
              {experience.title}
            </Typography>
            <Typography fontWeight={500} fontSize={14}>
              Full-time
            </Typography>
          </UIBox>
          <Typography
            color={theme.colors.textPrimary}
            fontSize={14}
            fontWeight={500}
          >
            {experience.company}
          </Typography>
          <Typography
            color={theme.colors.textDescription}
            fontSize={14}
            fontWeight={500}
          >
            {`${new Date(experience.startAt).toLocaleDateString()} - ${experience.endAt ? new Date(experience.endAt).toLocaleDateString() : 'Present'}`}
          </Typography>
          {experience.description
            ?.split('\n')
            .map((p, id) => <Typography key={id}>{p}</Typography>)}
        </>
      )}
    </UICard>
  );
};

export default ExperienceCard;
