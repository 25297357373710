import { UIBox } from '@/components/UI';
import CompanyHeroSection from './CompanyHeroSection';
import CompanyAboutSection from './CompanyAboutSection';
import CompanyDetailSection from './CompanyDetailSection';
import { useOrganizationQuery } from '@/redux/apis';

interface CompanyProfileProps {
  editable?: boolean;
  companyId?: string;
}
const CompanyProfile: React.FC<CompanyProfileProps> = ({
  companyId,
  editable,
}) => {
  const {
    data: company,
    isLoading,
    refetch,
  } = useOrganizationQuery(
    {
      id: companyId || '',
    },
    { skip: !companyId }
  );
  return (
    <UIBox display="flex" flexDirection="column" gap={20}>
      <CompanyHeroSection
        company={company}
        editable={editable}
        loading={isLoading}
        refetch={refetch}
      />
      <CompanyAboutSection
        company={company}
        editable={editable}
        loading={isLoading}
        refetch={refetch}
      />
      <CompanyDetailSection
        company={company}
        editable={editable}
        loading={isLoading}
        refetch={refetch}
      />
    </UIBox>
  );
};

export default CompanyProfile;
