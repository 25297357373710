import { createApi } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { AppTypes, IndustryJson, SkillJson } from '@/types';
import { authorizeQueryWithRefreshToken } from '@/utils';

export const industryApi = createApi({
  reducerPath: 'industryApi',
  baseQuery: authorizeQueryWithRefreshToken,
  tagTypes: ['Industry'],
  endpoints: (builder) => ({
    industry: builder.query<IndustryJson.Industry, { id: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/industries/${arg.id}`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as IndustryJson.Industry,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    createIndustry: builder.mutation<
      IndustryJson.Industry,
      IndustryJson.IndustryInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/industries`,
            method: 'POST',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as IndustryJson.Industry,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    updateIndustry: builder.mutation<
      IndustryJson.Industry,
      IndustryJson.IndustryInput
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/industries/${arg.id}`,
            method: 'PATCH',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as IndustryJson.Industry,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    deleteIndustry: builder.mutation<
      IndustryJson.Industry,
      { industryId: string }
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/industries/${arg.industryId}`,
            method: 'DELETE',
            body: arg,
          });
          if (result?.data) {
            return {
              data: result.data as IndustryJson.Industry,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    industries: builder.query<
      IndustryJson.PagedIndustry,
      AppTypes.PaginationParam
    >({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const queryString = new URLSearchParams(arg as any).toString();
          const result = await baseQuery({
            url: `/industries?${queryString}`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as IndustryJson.PagedIndustry,
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    industryParents: builder.query<IndustryJson.Industry[], void>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/industries/parents`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as IndustryJson.Industry[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    industryChildren: builder.query<IndustryJson.Industry[], { id: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/industries/${arg.id}/childrens`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as IndustryJson.Industry[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
    industrySkills: builder.query<SkillJson.Skill[], { id: string }>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        try {
          const result = await baseQuery({
            url: `/industries/${arg.id}/skills`,
            method: 'GET',
          });
          if (result?.data) {
            return {
              data: result.data as SkillJson.Skill[],
            };
          } else {
            return {
              error: result.error as FetchBaseQueryError,
            };
          }
        } catch (err) {
          return { error: err as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const {
  useIndustryQuery,
  useCreateIndustryMutation,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,
  useIndustriesQuery,
  useIndustryParentsQuery,
  useIndustryChildrenQuery,
  useIndustrySkillsQuery,
} = industryApi;
