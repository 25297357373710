import { UIBox, UICard, Typography } from '@/components/UI';
import { theme } from '@/constants';
import { UserJson } from '@/types';
import { useEffect, useRef, useState } from 'react';
import ProfileEditButton from '../Shared/ProfileEditButton';
import CertificationEditForm, {
  CertificationEditFormHandle,
} from './CertificationEditForm';
import { useUpdateUserCertificationMutation } from '@/redux/apis';

interface CertificationCardProps {
  editable?: boolean;
  certification: UserJson.Certification;
  onClickSave?: () => void;
  onClickDelete?: () => void;
}
const CertificationCard: React.FC<CertificationCardProps> = ({
  editable,
  certification,
  onClickDelete,
  onClickSave,
}) => {
  const editFormRef = useRef<CertificationEditFormHandle>(null);

  const [updateUserCertification, { isLoading: isSaving, isSuccess }] =
    useUpdateUserCertificationMutation();

  const [editing, setEditing] = useState(false);
  const handleOnEdit = () => {
    setEditing(true);
  };
  const handleOnSave = async (v: UserJson.CertificationInput) => {
    await updateUserCertification(v);
  };
  useEffect(() => {
    if (isSuccess) {
      onClickSave?.();
      setEditing(false);
    }
  }, [isSuccess]);
  const handleClickOnSave = () => {
    editFormRef.current?.submitForm();
  };
  const handleOnCancel = () => {
    setEditing(false);
  };
  return (
    <UICard
      position="relative"
      borderRadius={12}
      p={1.5}
      borderWidth={1}
      borderStyle="solid"
      borderColor={theme.colors.gray810}
      boxShadow={theme.colors.lightBoxShadow}
    >
      {editable && (
        <UIBox
          position="absolute"
          right={15}
          top={15}
          display="flex"
          alignItems="center"
          gap={10}
        >
          <ProfileEditButton
            editing={editing}
            hasDelete
            saving={isSaving}
            onClickCancel={handleOnCancel}
            onClickEdit={handleOnEdit}
            onClickSave={handleClickOnSave}
            onClickDelete={onClickDelete}
          />
        </UIBox>
      )}
      {editing ? (
        <CertificationEditForm
          ref={editFormRef}
          certification={certification}
          onSubmit={handleOnSave}
        />
      ) : (
        <UIBox display="flex" gap={20}>
          <UIBox>
            <Typography color={theme.colors.textPrimary} fontWeight={500}>
              {certification.name}
            </Typography>
            <Typography
              color={theme.colors.textPrimary}
              fontSize={14}
              fontWeight={500}
            >
              {certification.providerName}
            </Typography>
            {certification.issuedAt && (
              <Typography
                color={theme.colors.textDescription}
                fontSize={14}
                fontWeight={500}
              >
                {`Issued ${new Date(certification.issuedAt).toLocaleDateString()}${certification.expireAt ? ' - ' + new Date(certification.expireAt).toLocaleDateString() : ''}`}
              </Typography>
            )}
            {certification.description
              ?.split('\n')
              .map((p, id) => <Typography key={id}>{p}</Typography>)}
          </UIBox>
        </UIBox>
      )}
    </UICard>
  );
};

export default CertificationCard;
