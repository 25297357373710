import { UserJson } from '@/types';
import { faker } from '@faker-js/faker';

export function createRandomCertification(): UserJson.Certification {
  return {
    id: faker.string.uuid(),
    name: faker.helpers.arrayElement([
      'Mathematics',
      'Science',
      'History',
      'English',
      'Geography',
      'Art',
      'Music',
      'Computer Science',
    ]),
    providerName: faker.helpers.arrayElement(['CodeAcademy', 'Linedin']),
    issuedAt: faker.date.past({ years: 1 }).toLocaleDateString(),
    // logo: '/images/non-linear-career.png',
    userId: faker.string.uuid(),
    certificationId: faker.string.uuid(),
    expireAt: faker.date.past({ years: 1 }).toLocaleDateString(),
    credentialId: faker.string.uuid(),
    credentialUrl: faker.internet.url(),
    isVerified: true,
    description: faker.lorem.paragraph(),
    createdAt: faker.date.past({ years: 1 }).toLocaleDateString(),
    updatedAt: faker.date.past({ years: 1 }).toLocaleDateString(),
  };
}
export const certificationsMock: UserJson.Certification[] =
  faker.helpers.multiple(createRandomCertification, { count: 1 });
