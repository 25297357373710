import { AppModal } from '@/components/App';
import { Button, Typography, UIBox } from '@/components/UI';
import { theme } from '@/constants';

interface JobDeleteConfirmModalProps {
  jobTitle?: string;
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
}
const JobDeleteConfirmModal: React.FC<JobDeleteConfirmModalProps> = ({
  jobTitle,
  show,
  onClose,
  onDelete,
}) => {
  return (
    <AppModal isOpen={show} headerAlign="center" onClose={onClose} width={350}>
      <UIBox display="flex" flexDirection="column" alignItems="center" gap={20}>
        <UIBox display="flex" flexDirection="column" alignItems="center">
          <Typography
            textAlign="center"
            color={theme.colors.textPrimary}
            fontWeight={600}
          >
            Do you want to delete{' '}
            <Typography
              variant="span"
              color={theme.colors.textPrimary}
              style={{ textDecoration: 'underline' }}
            >
              {jobTitle}
            </Typography>
            <br />
            vacancy?
          </Typography>
        </UIBox>
        <Button variant="danger" height={40} onClick={onDelete} width={170}>
          Yes, Delete
        </Button>
      </UIBox>
    </AppModal>
  );
};

export default JobDeleteConfirmModal;
