const services = [
  {
    id: 1,
    label: 'Retirees',
    src: '/images/ic_man.svg',
  },
  {
    id: 2,
    label: 'Caregivers re-entering workforce',
    src: '/images/ic_woman.svg',
  },
  {
    id: 3,
    label: 'Career changers',
    src: '/images/ic_book_reload.svg',
  },
  {
    id: 4,
    label: 'Those trained on-the-job',
    src: '/images/ic_microscope.svg',
  },
  {
    id: 5,
    label: 'Veterans',
    src: '/images/ic_medal.svg',
  },
  {
    id: 6,
    label: 'Previously incarcerated',
    src: '/images/ic_prison.svg',
  },
  {
    id: 7,
    label: 'New graduates',
    src: '/images/ic_education.svg',
  },
  {
    id: 8,
    label: 'Others',
    src: '/images/ic_people_team.svg',
  },
];

export default services;
