import { UserJson } from '@/types';
import { UIBox, Image, Typography, IconButton } from '@/components/UI';
import { theme } from '@/constants';
import { IconPhotoCamera } from '@/assets/icons';
import { getUserAvatarUrl } from '@/utils';

interface UserAvatarProps {
  editing?: boolean;
  showOnlineStatus?: boolean;
  user?: UserJson.User;
  size?: number;
  backgroundColor?: string;
  onClickEdit?: () => void;
}
const UserAvatar: React.FC<UserAvatarProps> = ({
  editing,
  user,
  size = 45,
  backgroundColor,
  showOnlineStatus,
  onClickEdit,
}) => {
  const url = getUserAvatarUrl(user);
  return (
    <UIBox
      width={size}
      minWidth={size}
      height={size}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={size}
      backgroundColor={backgroundColor || theme.colors.gray550}
      position="relative"
    >
      {showOnlineStatus && (
        <UIBox
          width={8}
          height={8}
          position="absolute"
          background={theme.colors.green400}
          borderRadius={8}
          top={size / 2 + size / 4 / 0.71 - 3}
          left={size / 2 + size / 4 / 0.71 - 3}
        />
      )}
      {url ? (
        <Image src={url} width={size} height={size} borderRadius={size} />
      ) : (
        <Typography
          marginBottom={0}
          marginTop={0}
          fontSize={size * 0.7}
          fontWeight={800}
          color={theme.colors.textPrimary}
        >
          {user?.firstName?.charAt(0).toUpperCase()}
        </Typography>
      )}
      {editing && (
        <UIBox
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          background="#1C295566"
          borderRadius={120}
        >
          <IconButton onClick={onClickEdit}>
            <IconPhotoCamera />
          </IconButton>
        </UIBox>
      )}
    </UIBox>
  );
};

export default UserAvatar;
