import {
  Autocomplete,
  UIBox,
  Checkbox,
  UIGrid,
  Input,
  RichEditor,
  LocationAutocomplete,
} from '@/components/UI';
import { monthOptions, pastYears } from '@/constants';
import { UserJson } from '@/types';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  formatAddressFromLocation,
  formatIndustriesToGrouped,
  getFormatISODate,
  getMonthYearFromString,
  inputStatus,
} from '@/utils';
import { useIndustriesQuery } from '@/redux/apis';

const validationSchema = Yup.object({
  company: Yup.string().required('Company is required'),
  title: Yup.string().required('Title is required'),
  fromMonth: Yup.string().required('From month is required'),
  fromYear: Yup.number().required('From year is required'),
});

export interface ExperienceEditFormHandle {
  submitForm: () => void;
}

type FormikData = Omit<UserJson.ExperienceInput, 'startAt' | 'endAt'> & {
  fromMonth?: string;
  fromYear?: number;
  toMonth?: string;
  toYear?: number;
};

interface ExperienceEditFormProps {
  experience?: UserJson.Experience;
  onSubmit: (updatedExperience: UserJson.ExperienceInput) => void;
}
const ExperienceEditForm = forwardRef<
  ExperienceEditFormHandle,
  ExperienceEditFormProps
>(function ({ experience, onSubmit }, ref) {
  const { data: { data: industries = [] } = {}, isFetching } =
    useIndustriesQuery({ limit: 1000 });
  const formik = useFormik<FormikData>({
    initialValues: {
      id: experience?.id,
      title: experience?.title || '',
      company: experience?.company || '',
      description: experience?.description,
      city: experience?.city || '',
      state: experience?.state || '',
      country: experience?.country || '',
      industryId: experience?.industryId,
      isProgress: !!experience?.isProgress,
      isDefault: !!experience?.isDefault,
      isVerified: false,
      fromMonth: getMonthYearFromString(experience?.startAt).month,
      fromYear: getMonthYearFromString(experience?.startAt).year,
      toMonth: getMonthYearFromString(experience?.endAt).month,
      toYear: getMonthYearFromString(experience?.endAt).year,
    },
    validationSchema,
    onSubmit: (values) => {
      const {
        fromMonth,
        fromYear,
        toMonth,
        toYear,
        address1,
        address2,
        zipcode,
        ...v
      } = values as any;
      const saveToValue: UserJson.ExperienceInput = {
        ...v,
        startAt: getFormatISODate(`${fromMonth} ${fromYear}`),
        endAt:
          toMonth && toYear && !v.isProgress
            ? getFormatISODate(`${toMonth} ${toYear}`)
            : undefined,
      };
      onSubmit(saveToValue);
    },
  });

  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
  }));
  const groupedIndustries = useMemo(
    () => formatIndustriesToGrouped(industries),
    [industries]
  );
  return (
    <UIBox display="flex" flexDirection="column" gap={20} mt={1.5}>
      <Input
        label="Company"
        fullWidth
        {...formik.getFieldProps('company')}
        {...inputStatus(formik, 'company')}
      />
      <LocationAutocomplete
        label="Location"
        fullWidth
        value={formatAddressFromLocation(experience)}
        types={['(cities)']}
        onChange={(a, p, l) => {
          formik.setValues({
            ...formik.values,
            ...l,
          });
        }}
      />
      <Input
        label="Title"
        fullWidth
        {...formik.getFieldProps('title')}
        {...inputStatus(formik, 'title')}
      />
      <UIGrid columns={2} gap={20}>
        <Autocomplete
          label="From (month)"
          fullWidth
          isClearable
          options={monthOptions}
          value={monthOptions.find((m) => formik.values.fromMonth === m.value)}
          onChange={(v) => formik.setFieldValue('fromMonth', v?.value)}
          onBlur={() => formik.setFieldTouched('fromMonth')}
          {...inputStatus(formik, 'fromMonth')}
        />
        <Autocomplete
          label="From (or expected), year"
          fullWidth
          isClearable
          options={pastYears}
          value={pastYears.find((m) => formik.values.fromYear === m.value)}
          onChange={(v) => formik.setFieldValue('fromYear', v?.value)}
          onBlur={() => formik.setFieldTouched('fromYear')}
          {...inputStatus(formik, 'fromYear')}
        />
        {!formik.values.isProgress && (
          <>
            <Autocomplete
              label="To (month)"
              fullWidth
              isClearable
              options={monthOptions}
              value={monthOptions.find(
                (m) => formik.values.toMonth === m.value
              )}
              onChange={(v) => formik.setFieldValue('toMonth', v?.value)}
            />
            <Autocomplete
              label="To (or expected), year"
              fullWidth
              isClearable
              options={pastYears}
              value={pastYears.find((m) => formik.values.toYear === m.value)}
              onChange={(v) => formik.setFieldValue('toYear', v?.value)}
            />
          </>
        )}
      </UIGrid>
      <Checkbox
        id={`work-here-${experience?.id || '0'}`}
        label="I currently attend here"
        checked={formik.values.isProgress}
        {...formik.getFieldProps('isProgress')}
        onChange={(v) => formik.setFieldValue('isProgress', v.target.checked)}
      />
      <Autocomplete
        label="Area of Study (Optional)"
        fullWidth
        isLoading={isFetching}
        options={groupedIndustries}
        getOptionLabel={(industry) => industry.name}
        getOptionValue={(industry) => industry.id}
        value={industries.find((m) => formik.values.industryId === m.id)}
        onChange={(v) => formik.setFieldValue('industryId', v?.id)}
        isClearable
      />
      <UIBox>
        <RichEditor
          label="Description (Optional)"
          defaultValue={formik.values.description}
          onChange={(v) => formik.setFieldValue('description', v)}
        />
      </UIBox>
    </UIBox>
  );
});

export default ExperienceEditForm;
