import { UIBox, Typography } from '@/components/UI';
import { companyMock } from '@/mock';
import { CompanyProfile } from '@/modules/Profile';

const AdminProfilePage: React.FC = () => {
  return (
    <UIBox display="flex" flexDirection="column" gap={20}>
      <Typography variant="h5" marginBottom={0}>
        Admin profile
      </Typography>
      <CompanyProfile companyId={companyMock.id} editable />
    </UIBox>
  );
};

export default AdminProfilePage;
