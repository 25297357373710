import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconLogout({
  width = 12,
  height = 11,
  color = theme.colors.icon,
}: AppTypes.IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 11"
      fill="none"
      className="fill-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.35966 5.08357C4.12268 5.08357 3.9353 5.26773 3.9353 5.50065C3.9353 5.72815 4.12268 5.91773 4.35966 5.91773H7.66634V8.5069C7.66634 9.83398 6.56962 10.9173 5.21388 10.9173H2.52996C1.17973 10.9173 0.0830078 9.8394 0.0830078 8.51232V2.4944C0.0830078 1.1619 1.18524 0.0839844 2.53547 0.0839844H5.22491C6.56962 0.0839844 7.66634 1.1619 7.66634 2.48898V5.08357H4.35966ZM9.6327 3.62659L11.2144 5.20284C11.2956 5.28409 11.339 5.38701 11.339 5.50076C11.339 5.60909 11.2956 5.71743 11.2144 5.79326L9.6327 7.36951C9.55145 7.45076 9.44312 7.49409 9.3402 7.49409C9.23187 7.49409 9.12353 7.45076 9.04228 7.36951C8.87978 7.20701 8.87978 6.94159 9.04228 6.77909L9.90895 5.91784H7.66645V5.08368H9.90895L9.04228 4.22243C8.87978 4.05993 8.87978 3.79451 9.04228 3.63201C9.20478 3.46409 9.4702 3.46409 9.6327 3.62659Z"
        fill={color}
      />
    </svg>
  );
}
