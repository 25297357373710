import { IconDone, IconGlobalCompany } from '@/assets/icons';
import {
  Dropdown,
  Typography,
  UIBox,
  UICard,
  UITable,
  UIThead,
} from '@/components/UI';
import { theme } from '@/constants';
import { jobsMock } from '@/mock';
import styled from 'styled-components';

export const UITh = styled.th`
  font-size: 12px;
  line-height: 14px;
  color: ${theme.colors.textDescription};
  border-bottom: solid 1px #e9edf7;
  padding-left: 0 !important;
`;
const AdminNewJobs: React.FC = () => {
  return (
    <UICard>
      <UIBox display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" fontSize={20} fontWeight={500}>
          New Jobs
        </Typography>
        <Dropdown button="Weekly"></Dropdown>
      </UIBox>
      <UITable width={'100%'}>
        <UIThead style={{ height: 40 }}>
          <tr>
            <UITh>Vacancies</UITh>
            <UITh>Status</UITh>
            <UITh>Organization</UITh>
            <UITh align="right">Posted at</UITh>
          </tr>
        </UIThead>
        <tbody>
          {jobsMock.slice(0, 5).map((job, id) => (
            <tr key={id} style={{ height: 28 }}>
              <td>
                <Typography
                  fontSize={14}
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                >
                  {job.title}
                </Typography>
              </td>
              <td>
                <UIBox display="flex" alignItems="center" gap={10}>
                  <IconDone />
                  <Typography
                    color={theme.colors.textDescription}
                    fontWeight={500}
                    fontSize={14}
                  >
                    Approved
                  </Typography>
                </UIBox>
              </td>
              <td width={150}>
                <UIBox display="flex" alignItems="center" gap={10}>
                  <IconGlobalCompany />
                  <Typography
                    fontSize={14}
                    color={theme.colors.textPrimary}
                    fontWeight={500}
                  >
                    {job.companyData?.name}
                  </Typography>
                </UIBox>
              </td>
              <td align="right" width={150}>
                <Typography
                  fontSize={14}
                  color={theme.colors.textPrimary}
                  fontWeight={500}
                >
                  {job.postedAt}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </UITable>
    </UICard>
  );
};

export default AdminNewJobs;
