import { theme } from '@/constants';
import { AppTypes } from '@/types';

export default function IconFacebook({
  width = 19,
  height = 19,
  disabled,
}: AppTypes.IconProps & { disabled?: boolean }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8893 9.60122C18.8893 4.52019 14.7703 0.401215 9.68926 0.401215C4.60823 0.401215 0.489258 4.52019 0.489258 9.60122C0.489258 14.1932 3.85357 17.9993 8.25176 18.6895V12.2606H5.91582V9.60122H8.25176V7.57434C8.25176 5.26859 9.62525 3.99496 11.7267 3.99496C12.7333 3.99496 13.7861 4.17465 13.7861 4.17465V6.43871H12.626C11.4832 6.43871 11.1268 7.14789 11.1268 7.87544V9.60122H13.6783L13.2704 12.2606H11.1268V18.6895C15.525 17.9993 18.8893 14.1932 18.8893 9.60122Z"
        fill={disabled ? theme.colors.gray710 : '#1877F2'}
      />
    </svg>
  );
}
